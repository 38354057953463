import { RichTextContent } from 'contentful';
import { Dispatch, SetStateAction } from 'react';
import { InputParams } from '../workflowv2/types';
import { Workflow as WorkflowObject } from './workflow-creation/types';

export interface WorkflowList {
  workflows?: Workflow[];
  count: number;
}
export interface CapabilityList {
  capabilities?: Workflow[];
  count: number;
}

export interface Workflow {
  name: string;
  workflowId: string;
  createdAt: number;
  updatedAt: number;
  createdBy?: string;
  updatedBy?: string;
  active?: boolean;
}

export interface Capability {
  id: string;
  name: string;
  description: string;
  object?: WorkflowObject | null;
  plansWithAccess: string[];
  input: InputParams[];
  output: string[];
  createdAt: number;
  updatedAt: number;
  createdBy?: string;
  updatedBy?: string;
  active?: boolean;
  industry?: string[];
  geography?: string[];
  useCase?: string[];
}

export interface CapabilityFilterField {
  industry?: string;
  geography?: string;
  useCase?: string;
}

export interface WorkflowFilterField {
  outcome?: string[];
  createdAt?: string[];
}

export interface WorkflowListContentProps {
  // filterFields: WorkflowFilterField;
  data: Capability[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
  // searchTerm: string;
  onCreateWorkflow: () => void;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  setSelectedAction: (action: string) => void;
  setWorkflowDetails: Dispatch<SetStateAction<Capability>>;
}

export interface ListData {
  pageParams: unknown[];
  pages: unknown[];
  listData: Capability[];
  count: number;
}

export enum ToastMessage {
  CAPABILITY_UPDATED_MESSAGE = 'Capability Updated successfully',
  CAPABILITY_DELETED_MESSAGE = 'Capability deleted successfully',
  ERROR_MESSAGE = 'Some error occurred. Please try after some time.',
  EMPTY_SELECTED_LIST_MESSAGE = 'No Capability selected',
  OVER_SIZED_LIST_MESSAGE = 'Select a single Capability',
}

export interface ContentfulTemplate {
  fields: {
    id: string;
    title: string;
    shortDescription: string;
    supportedCountry: string[];
    tags: string[];
    documentation: RichTextContent;
    thumbnail: {
      fields: {
        file: {
          url: string;
        };
      };
    };
  };
}

export interface CapabilityDataTypes {
  id?: string;
  name: string;
  description?: string;
  object?: WorkflowObject | null;
  plansWithAccess?: string[];
  industry?: string[];
  geography?: string[];
  useCase?: string[];
  isAsync?: boolean;
  active?: boolean;
  input?: InputParams[];
  output?: string[];
  createdAt?: number;
  updatedAt?: number;
}

export interface SaveCapabilityFormDataTypes {
  id: string;
  name: string;
  description: string;
  object?: WorkflowObject | null;
  plansWithAccess: string[];
  industry?: string;
  geography?: string;
  useCase?: string;
  active?: boolean;
  input: InputParams[];
  output: string[];
  createdAt: number;
  updatedAt: number;
}
