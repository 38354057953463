import { atom } from 'recoil';
import { UserListResponse } from '@/screens/admin/types';

export const UserList = atom({
  key: 'userList',
  default: [] as UserListResponse[],
});

export const UserOrg = atom({
  key: 'userOrg',
  default: '' as string,
});
