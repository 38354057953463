import { atom } from 'recoil';
import { Workflow, ColumnPosition, Position, ModifyNodeDetails, EdgeButtonPosition, Service, Tag } from '../types';

export enum AtomKey {
  WorkflowJson,
  WorkflowJsonList,
  NewNodePosition,
  ExpandedNodeIds,
  DependIds,
  AddedServiceIds,
  AddedTags,
  ModifyNodePositions,
  NodeDetails,
  NewEdgeButtonNode,
  Services,
  Tags,
  ShowInputAttributes,
  SelectedService,
  ActiveService,
  SelectedPosition,
  WorkflowModified,
}

export const WorkflowJson = atom<Workflow>({
  key: AtomKey.WorkflowJson.toString(),
  default: { columns: [] },
});

export const NewNodePosition = atom<ColumnPosition>({
  // details stored when new node is added
  // After setting this position corresponding popup to create tag, service or end state is opened
  key: AtomKey.NewNodePosition.toString(),
  default: {
    type: '',
    columnPosition: 0,
    nodePosition: 0,
    newColumn: false,
  },
});

export const NewEdgeButtonNode = atom<EdgeButtonPosition>({
  // details stored when node added through button edge to handle the visibility of add node panel
  key: AtomKey.NewEdgeButtonNode.toString(),
  default: {
    sourceX: 0,
    insertColumnId: 0,
  },
});

export const ModifyNodePositions = atom<Position>({
  // position of node which needs to be modified or deleted
  key: AtomKey.ModifyNodePositions.toString(),
  default: {
    columnPosition: 0,
    nodePosition: 0,
  },
});

export const NodeDetails = atom<ModifyNodeDetails>({
  key: AtomKey.NodeDetails.toString(),
  default: {
    type: '',
    oldName: '',
    oldId: '',
    action: '',
    awaits: [],
    inputs: [],
    description: '',
  },
});

export const AddedServiceIds = atom<string[]>({
  // to remove the already added services from dropdown
  key: AtomKey.AddedServiceIds.toString(),
  default: [],
});

export const AddedTags = atom<string[]>({
  // to restrict addition of same tag
  key: AtomKey.AddedTags.toString(),
  default: [],
});

export const ServicesToTheLeft = atom<Service[]>({
  // to show services to the left of selected node
  // required when adding conditions to selected node
  key: AtomKey.Services.toString(),
  default: [],
});

export const TagsToTheLeft = atom<Tag[]>({
  // to show services to the left of selected node
  // required when adding conditions to selected node
  key: AtomKey.Tags.toString(),
  default: [],
});

export const ShowInputAttributes = atom<boolean>({
  // to show input attributes block on clicking input node
  key: AtomKey.ShowInputAttributes.toString(),
  default: false,
});

export const SelectedService = atom<Service>({
  // selected service from the action panel
  key: AtomKey.SelectedService.toString(),
  default: {},
});

export const ActiveService = atom<Service>({
  // selected service from the workflow chart
  key: AtomKey.ActiveService.toString(),
  default: {
    id: '',
  },
});

export const SelectedPosition = atom<Position>({
  // position stored when clicking a plus button to handle the visibility of panel
  key: AtomKey.SelectedPosition.toString(),
  default: {
    columnPosition: 0,
    nodePosition: 0,
  },
});

export const WorkflowModified = atom<boolean>({
  // to show input attributes block on clicking input node
  key: AtomKey.WorkflowModified.toString(),
  default: false,
});
