import React, { ReactElement } from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { Plus } from 'phosphor-react';
import { useRecoilValue } from 'recoil';
import { colors } from '@/utils/colors';
import { FunctionItem, OperatorData } from '../../../types';
import FunctionCondition from './FunctionCondition';
import { ContentfulCustomFunctions } from '../../../states/contentfulCustomFunctions';

const CustomFunctionBlock = ({
  functionOperator,
  functionItems,
  getOperatorData,
  updateFunctionCondition,
  onItemDelete,
  insertNewFunctionCondition,
}: {
  functionOperator: string;
  functionItems: FunctionItem[];
  getOperatorData: (val: string) => OperatorData | undefined;
  updateFunctionCondition: (data: FunctionItem, index: number) => void;
  onItemDelete: (pos: number) => void;
  insertNewFunctionCondition: () => void;
}): ReactElement => {
  const contentfulCustomFunctions = useRecoilValue(ContentfulCustomFunctions);
  const contentfulCustomFunction = contentfulCustomFunctions?.find(
    item => item.fields.id === getOperatorData(functionOperator)?.description,
  );

  return (
    <Box ml={8} mt={4} w="95%">
      <Text textTransform="capitalize" ml={6} color="green.500" fontSize="sm" fontWeight="medium">
        {functionOperator}
      </Text>
      <Box ml={6} width={8} borderWidth={2} borderColor="green.500" borderRadius="lg" bg="green.500" />
      <Box borderRadius="lg" borderWidth={1} borderColor="blue.100" bg="white.100" w="full" p={4}>
        <Flex alignItems="center">
          <Image src="/assets/icons/functionIcon.svg" />
          <Box ml={2}>
            <Flex fontSize="sm" fontWeight="medium">
              Using
              <Text color="green.500" px={2} textTransform="capitalize">
                {functionOperator}
              </Text>
              custom function
            </Flex>
            <Text fontSize="xs" fontWeight="normal" textTransform="capitalize" color="gray.600">
              {contentfulCustomFunction?.fields.description}
            </Text>
          </Box>
        </Flex>
        <Box ml="18px" position="relative">
          <Box className="custom-function-container" pt={3}>
            {functionItems.map((item, functionItemIndex, functionsArray) => (
              <FunctionCondition
                item={item}
                description={
                  contentfulCustomFunction?.fields.selectArguments?.find(
                    argument =>
                      argument.fields.id ===
                      getOperatorData(functionOperator)?.arguments?.[functionItemIndex]?.description,
                  )?.fields?.argumentDescription
                }
                key={Math.random()}
                setItem={(data, index) => updateFunctionCondition(data, index)}
                isVariadic={getOperatorData(functionOperator)?.isVariadic}
                attributeType={getOperatorData(functionOperator)?.arguments?.[functionItemIndex]?.type || ''}
                onItemDelete={pos => onItemDelete(pos)}
                functionItemsCount={functionsArray.length}
                index={functionItemIndex}
              />
            ))}
          </Box>
        </Box>
        {getOperatorData(functionOperator)?.isVariadic && (
          <Flex paddingTop={4} alignItems="center">
            <Plus size={16} color={colors.blue[500]} />
            <Text
              pl="10px"
              textColor="blue.500"
              fontSize="md"
              fontWeight="normal"
              cursor="pointer"
              onClick={insertNewFunctionCondition}
            >
              Add More
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default CustomFunctionBlock;
