import React, { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Circle } from 'phosphor-react';
import { UNKNOWN } from '@/screens/transactions/transaction-list/types';
import { getCellStyle } from '../paginated-table/utils/getCellStyle';
import AwaitStatus from './AwaitStatus';
import { ParameterResponse } from './types';

const StatusText = ({
  status,
  awaitData,
  type,
  icon = <Circle size={8} weight="fill" />,
}: {
  status: string;
  awaitData?: ParameterResponse;
  type?: string;
  icon?: ReactElement;
}): ReactElement => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-around"
      px={3}
      py={1}
      fontWeight="light"
      width="fit-content"
      borderRadius="6px"
      {...getCellStyle(status)}
    >
      {icon}
      <Text fontSize="xs" pl={1} textTransform="capitalize" fontWeight="light">
        {status.toLowerCase() || UNKNOWN}
      </Text>
      {status.toLowerCase() === 'awaiting' &&
        (awaitData?.transactions?.[0]?.awaits?.[0]?.response || awaitData?.awaits?.Awaits?.response) && (
          <AwaitStatus
            awaitData={
              type === 'entities'
                ? awaitData?.transactions?.[0]?.awaits?.[0]?.response
                : awaitData?.awaits?.Awaits?.response
            }
          />
        )}
    </Flex>
  );
};

export default StatusText;
