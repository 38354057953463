import React, { ReactElement } from 'react';
import PlatformTooltip from '@/components/tooltip/PlatformTooltip';
import { Tag } from '@chakra-ui/react';

const AsyncTag = (): ReactElement => {
  return (
    <PlatformTooltip
      title="Asynchronous Capabilities"
      description="The results for all such capabilities will be available after some time & you would need to call the API multiple times to fetch the result."
    >
      <Tag
        fontSize="10px"
        fontWeight="light"
        textTransform="uppercase"
        color="gray.700"
        bg="white.50"
        borderColor="gray.300"
        borderWidth={1}
        borderRadius="full"
        minHeight="unset"
        h="5"
      >
        Async
      </Tag>
    </PlatformTooltip>
  );
};

export default AsyncTag;
