import React, { FC } from 'react';
import { Input, Stack } from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-number-input';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPhoneInput from 'react-phone-number-input/input';
import CountrySelectorInput from './CountrySelectorInput';

export type PhoneInputProps = ReactPhoneInputProps & {
  onChange: (value: string) => void;
  countryCode?: string;
};

const InputComponent = React.forwardRef<HTMLInputElement>((props, ref) => {
  return <Input {...props} ref={ref} borderTopLeftRadius={0} borderBottomLeftRadius={0} />;
});

InputComponent.displayName = 'InputComponent';

export const PhoneInput: FC<PhoneInputProps> = ({ value, onChange, countryCode, ...props }: PhoneInputProps) => {
  return (
    <Stack direction="row" spacing={0}>
      <ReactPhoneInput
        {...props}
        international
        withCountryCallingCode={false}
        country={countryCode}
        inputComponent={InputComponent}
        value={value}
        onChange={onChange}
      />
    </Stack>
  );
};

export default PhoneInput;
