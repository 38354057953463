export interface StatusCellStyle {
  bg?: string | undefined;
  color?: string | undefined;
}

export const getSuccessTextStyle = (): StatusCellStyle => ({
  bg: 'green.50',
  color: 'green.500',
});

export const getErrorTextStyle = (): StatusCellStyle => ({
  color: 'red.500',
  bg: 'red.50',
});

export const getFailedTextStyle = (): StatusCellStyle => ({
  color: 'orange.500',
  bg: 'orange.50',
});

export const getSecondaryTextStyle = (): StatusCellStyle => ({
  color: 'blue.500',
  bg: 'blue.100',
});

export const getMiscellaneousTextStyle = (): StatusCellStyle => ({
  bg: 'gray.500',
  color: 'gray.50',
});

export const getProcessingTextStyle = (): StatusCellStyle => ({
  bg: 'orange.100',
  color: 'orange.500',
});

export const getInQueueTextStyle = (): StatusCellStyle => ({
  color: 'orange.500',
  bg: 'orange.100',
});

export const getLightBgTextStyle = (): StatusCellStyle => ({
  bg: 'white.50',
  color: 'gray.600',
});

export const getPendingTextStyle = (): StatusCellStyle => ({
  color: 'yellow.500',
  bg: 'yellow.50',
});

export const getPausedTextStyle = (): StatusCellStyle => ({
  color: 'blue.500',
});

export const getInProgressTextStyle = (): StatusCellStyle => ({
  color: 'orange.500',
  bg: 'orange.100',
});

export const getCompletedTextStyle = (): StatusCellStyle => ({
  color: 'green.500',
  bg: 'green.50',
});

export const getStoppedTextStyle = (): StatusCellStyle => ({
  color: 'gray.700',
  bg: 'blueGray.50',
});

export const getDraftTextStyle = (): StatusCellStyle => ({
  color: 'purple.200',
  bg: 'lavender.100',
});
