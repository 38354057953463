import React, { ReactElement } from 'react';
import { Copy, Play } from 'phosphor-react';
import {
  Modal,
  Image,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import CopyButton from '@/components/copy-button/CopyButton';
import { colors } from '@/utils/colors';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SelectedNodeAtom } from '../states/selectedNodeAtom';
import { AlertsAtom } from '../states/alertsAtom';

const ModalOption = ({
  text,
  button,
  onClick,
}: {
  text: string;
  button: string;
  onClick: () => void;
}): ReactElement => {
  return (
    <Box h="50px" borderRadius="lg" borderWidth={1} borderColor="gray.200" px={4}>
      <Flex justifyContent="space-between" h="full" alignItems="center">
        <Text fontSize="sm" fontWeight="normal">
          {text}
        </Text>
        <Flex alignItems="center" color="blue.900" cursor="pointer" onClick={onClick}>
          <Text pl={2} color="blue.900" fontWeight="normal" fontSize="xs">
            {button}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const WorkflowPublishModal = ({
  onClose,
  id,
  onTryoutClick,
}: {
  onClose: () => void;
  id: string;
  onTryoutClick: () => void;
}): React.ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const alertsList = useRecoilValue(AlertsAtom);

  return (
    <Modal isOpen onClose={onClose} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent borderRadius="lg" overflow="hidden">
        <ModalHeader fontSize="md" fontWeight="500" p={0}>
          <Flex
            w="full"
            h="full"
            direction="column"
            justifyContent="center"
            px="16"
            py="10"
            alignItems="center"
            bg="linear-gradient(180deg, #D4DFF7 -15.71%, rgba(212, 223, 247, 0) 69.95%)"
          >
            <Image src="/assets/icons/publish.svg" mb={4} />
            <Text fontSize="md" fontWeight="500" color="gray.800">
              Good job publishing that Workflow
              <span role="img" aria-label="emoji">
                🎉
              </span>
            </Text>
            <Flex alignItems="center" fontWeight="light" fontSize="xs" h="8">
              <Text pr={2} color="gray.500">
                ID
              </Text>
              <Text color="gray.700">{id}</Text>
              <CopyButton text={id} copyIcon={<Copy color={colors.gray[400]} />} />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody fontSize="sm" fontWeight="light" p="6">
          <Flex direction="column">
            <ModalOption
              text="What's next after publishing the workflow?"
              button="View Integration"
              onClick={() => {
                onTryoutClick();
                setSelectedNode({ id: 'integrations', type: 'integrations', columns: [] });
                onClose();
              }}
            />
          </Flex>
        </ModalBody>
        <ModalFooter p="6">
          <Flex justifyContent="flex-end" w="full" gridGap="4">
            <Button
              borderRadius="lg"
              fontSize="sm"
              fontWeight="normal"
              borderWidth={1}
              borderColor="gray.200"
              bg="white.50"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              colorScheme="blue"
              borderRadius="lg"
              fontSize="sm"
              fontWeight="light"
              onClick={() => {
                onTryoutClick();
                setSelectedNode({ id: 'tryout', type: 'tryout', columns: [] });
              }}
              leftIcon={<Play weight="fill" />}
              isDisabled={!!alertsList.length}
            >
              Tryout
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkflowPublishModal;
