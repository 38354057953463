import React, { ReactElement, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Text,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import useGetWorkflow from '../queries/useGetWorkflow';
import { ModifyWorkflowDetails } from '../types';

export const AddDuplicateWorkflowModal = ({
  details = { name: '', workflowId: '' },
  nameExistsError,
  setNameExistsError,
  onCancel,
  onClose,
}: {
  details?: ModifyWorkflowDetails;
  nameExistsError: boolean;
  setNameExistsError: (val: boolean) => void;
  onCancel: () => void;
  onClose: (details: ModifyWorkflowDetails) => void;
}): ReactElement => {
  const [workflowName, setWorkflowName] = useState(`${details?.name}_copy`);
  const { data } = useGetWorkflow(details.workflowId);

  return (
    <Modal isOpen onClose={onCancel} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent py={2}>
        <ModalHeader fontSize="md" fontWeight="500">
          Duplicate Workflow{' '}
          <Heading as="h6" fontSize="sm" fontWeight={300} color="gray.500" mt={4}>
            The copy of
            <Heading as="span" fontSize="sm" mx={1} textDecoration="underline" fontWeight={600} color="blue.500">
              {workflowName}
            </Heading>
            The copy of will be created and added to the list of workflows. You can rename it if you want.{' '}
          </Heading>
        </ModalHeader>
        <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ email: '' }}
          onSubmit={() => onClose({ ...details, name: workflowName, workflowObject: data?.workflowObject })}
        >
          {() => {
            return (
              <Form>
                <ModalBody>
                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="400">
                      Workflow Name
                    </FormLabel>
                    <Input
                      fontSize="sm"
                      placeholder="Enter workflow name"
                      fontWeight="light"
                      value={workflowName}
                      onChange={evt => {
                        setWorkflowName(evt.target.value);
                        setNameExistsError(false);
                      }}
                      borderColor={nameExistsError ? 'red.500' : 'gray.300'}
                      borderRadius="lg"
                    />
                    {nameExistsError && (
                      <Text fontSize="xs" color="red.500">
                        A workflow with the given name already exists
                      </Text>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter pt={6}>
                  <Button
                    h="38px"
                    w="124px"
                    color="blue.800"
                    bg="purple.300"
                    borderRadius="lg"
                    fontSize="sm"
                    fontWeight="300"
                    _hover={{ opacity: '0.8' }}
                    mr={3}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    h="38px"
                    w="124px"
                    color="white"
                    bg="blue.500"
                    disabled={!workflowName}
                    fontSize="sm"
                    borderRadius="lg"
                    fontWeight="300"
                    _hover={workflowName ? { opacity: '0.8', background: 'blue.500' } : {}}
                    _disabled={{ color: 'gray.600', bg: 'gray.200' }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AddDuplicateWorkflowModal;
