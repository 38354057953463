import React, { ReactElement } from 'react';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { ArrowClockwise } from 'phosphor-react';
import { Cell } from 'react-table';
import { useretryWebhook } from '@/screens/webhooks-v2/queries/useRetryWebhook';
import { SingleEvent, ToastMessage } from '@/screens/webhooks-v2/types';
import { ActionButtonColumnProps } from '../../utils/types';

export const RetryActionButton = ({ value, row, column }: Cell): ReactElement => {
  const { getProps } = column as ActionButtonColumnProps;
  const toast = useToast();
  const { mutateAsync: retryWebhook, isLoading } = useretryWebhook({
    onSuccess: () => {
      toast({
        title: 'Retry Successful',
        status: 'success',
        duration: 1000,
        isClosable: true,
      });
      getProps?.refetch?.();
    },
    onError: mutateError => {
      toast({
        title: ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    },
  });
  return (
    <>
      <Flex align="center" size={24}>
        {value === 'Fail' ? (
          <>
            <ArrowClockwise color="#23368C" style={{ marginRight: 2 }} />
            <Button
              variant="link"
              colorScheme="blue"
              onClick={() => {
                const { id, eventType, url, webhookId, disabled } = row.original as SingleEvent;
                retryWebhook({ webhookId, messageId: id, filterTypes: [eventType], url, description: '', disabled });
              }}
              isDisabled={isLoading}
            >
              Retry
            </Button>{' '}
          </>
        ) : null}
      </Flex>
    </>
  );
};
