/* eslint-disable no-nested-ternary */
import React, { ReactElement, useMemo } from 'react';
import { Badge, Button, Tooltip, Text, Box } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Warning } from 'phosphor-react';
import { WorkflowAtom } from './states/workflowAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { ColumnV2 } from './types';
import { AlertsAtom } from './states/alertsAtom';

const AlertsButton = ({ openConfigureModal }: { openConfigureModal: () => void }): ReactElement => {
  const workflows = useRecoilValue(WorkflowAtom);
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const setAlerts = useSetRecoilState(AlertsAtom);
  const alertList = useMemo(() => {
    const processedWorkflows = workflows.filter(workflow => {
      const nodes = workflow.nodes
        .flatMap(node => node.inputs)
        .filter(input => !input?.isStatic)
        .filter(input => input?.location === '..' && !input.isOptional);
      return !!nodes.length;
    }) as ColumnV2[];
    const processedTags = workflows
      .filter(workflow => workflow.metadata.kind === 'tag')
      .filter(workflow => {
        const childNodes = workflows.filter(wrkFlw => wrkFlw.metadata.parentID === workflow.metadata.capabilityId);
        const isConditionPresent = !workflow.nodes[0].conditions?.length;
        const isBranchConfigured = !workflow.metadata.branch;
        const isBranchAPresent = childNodes.findIndex(node => node.metadata.branch === 'Branch A') === -1;
        const isBranchBPresent = childNodes.findIndex(node => node.metadata.branch === 'Branch B') === -1;
        return isBranchAPresent || isBranchBPresent || isConditionPresent || isBranchConfigured;
      });

    const initialObj: ColumnV2[] = [];
    const alertsList = [...processedWorkflows, ...processedTags].reduce((result, workflow) => {
      const currentIndex = result.findIndex(res => res.metadata.capabilityId === workflow.metadata.capabilityId);
      if (currentIndex !== -1) {
        return result;
      }
      result.push(workflow);

      return result;
    }, initialObj);
    setAlerts(alertsList);
    return alertsList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(workflows)]);

  return (
    <Tooltip
      label={
        <Text fontSize="xs" fontWeight="normal" color="gray.50">
          Workflow alerts
        </Text>
      }
      aria-label="A tooltip for Alerts button"
      hasArrow
      placement="bottom-start"
      openDelay={200}
      closeDelay={300}
      p="3"
      borderRadius="lg"
      bg="gray.800"
      maxW="52"
      isDisabled={alertList.length !== 0}
    >
      {/* Added Box as wrapper to make tooltip work as expected */}
      <Box>
        <Button
          variant="solid"
          bgColor={alertList.length ? 'orange.100' : 'gray.100'}
          color={alertList.length ? 'orange.500' : 'gray.500'}
          fontWeight="normal"
          disabled={!alertList.length}
          onClick={() => {
            setSelectedNode({ id: 'alerts', type: 'alerts', columns: alertList });
            openConfigureModal();
          }}
          _disabled={{ cursor: 'pointer', opacity: 0.5 }}
        >
          <Warning size={20} color={alertList.length ? 'orange' : 'gray'} style={{ marginRight: '8' }} />
          {alertList.length ? 'Alerts' : null}
          {alertList.length ? (
            <Badge
              position="absolute"
              top="0"
              right="0"
              transform="translate(50%, -50%)"
              fontSize="xs"
              minW="5"
              minH="5"
              bg="orange.500"
              color="white"
              pt="2px"
              borderRadius="xl"
            >
              {alertList.length}
            </Badge>
          ) : null}
        </Button>
      </Box>
    </Tooltip>
  );
};

export default AlertsButton;
