import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex } from '@chakra-ui/react';
import { commonHeaderHeight, headerTabsHeight } from '@/constants/constants';
import { LockKey } from 'phosphor-react';
import useCustomTabs from './hooks/useCustomTabs';

export interface TabItem {
  label: string;
  content: React.ReactNode;
  id: string;
  isDisabled?: boolean;
  isComingSoon?: boolean;
  isDefault?: boolean;
}

export interface CustomTabsProps {
  tabs: TabItem[];
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs }) => {
  const { tabIndex, history, tabSelectedStyles } = useCustomTabs(tabs);

  return (
    <Tabs
      color="gray.600"
      h={`calc(100% - ${commonHeaderHeight}px)`}
      index={tabIndex}
      onChange={index => {
        history.push(`/${tabs[index].id}`);
      }}
      isLazy
    >
      <TabList px="5" pt="3" mb="1" fontSize="sm" gridGap="10" bg="white">
        {tabs.map(tab => (
          <Tab
            as="a"
            href={`/${tab.id}`} // to copy tab path or open in new tab/window
            key={tab.id}
            px="0"
            pt="0"
            pb="3"
            display="flex"
            gridGap="2"
            fontSize="sm"
            fontWeight="medium"
            isDisabled={tab.isDisabled}
            _selected={{ ...tabSelectedStyles }}
            _focus={{ boxShadow: 'none' }}
            _active={{ bg: 'none' }}
            onClick={event => event.preventDefault()} // so that page is not redirected to href
          >
            {tab.label}{' '}
            {tab.isComingSoon && (
              <Flex
                h="4"
                w="4"
                borderRadius="full"
                alignItems="center"
                justifyContent="center"
                color="blue.400"
                bg="blue.50"
              >
                <LockKey size={10} weight="fill" />
              </Flex>
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanels h={`calc(100% - ${headerTabsHeight}px)`} overflowY="auto">
        {tabs.map(tab => (
          <TabPanel key={tab.id} minH="full" h="full" px="5" py="5">
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default CustomTabs;
