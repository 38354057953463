import React, { ReactElement } from 'react';
import { Box, Button, Text, Tooltip } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { CodeSimple } from 'phosphor-react';
import { SelectedNodeAtom } from './states/selectedNodeAtom';

const SDKPreviewButton = ({
  openConfigureModal,
  workflowId,
}: {
  openConfigureModal: () => void;
  workflowId: string;
}): ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);

  return (
    <Tooltip
      label={
        <Text fontSize="xs" fontWeight="normal" color="gray.50">
          {!workflowId ? 'To preview the workflow please publish first.' : ''}
        </Text>
      }
      aria-label="A tooltip for Preview button"
      isDisabled={!!workflowId}
      hasArrow
      placement="bottom-start"
      openDelay={200}
      closeDelay={300}
      p="3"
      borderRadius="lg"
      bg="gray.800"
      maxW="52"
    >
      {/* Added Box as wrapper to make tooltip work as expected */}
      <Box>
        <Button
          type="submit"
          borderRadius="lg"
          fontWeight="normal"
          gridGap="2"
          bg="white.50"
          borderWidth={1}
          borderColor="gray.200"
          color="gray.800"
          id="preview-workflow"
          onClick={() => {
            openConfigureModal();
            setSelectedNode({ id: 'preview', type: 'preview', columns: [] });
          }}
          isDisabled={!workflowId}
        >
          <CodeSimple size={18} weight="light" color="black" /> Preview
        </Button>
      </Box>
    </Tooltip>
  );
};

export default SDKPreviewButton;
