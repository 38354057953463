import React, { ReactElement, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowSquareOut, Copy, CaretDown, CaretUp } from 'phosphor-react';
import { prettyPrintJson } from 'pretty-print-json';
import {
  Modal,
  Image,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import CopyButton from '@/components/copy-button/CopyButton';
import { useDocumentation } from '@/queries/UseDocumentation';
import useGetInputDetails from '../../workflow-creation/queries/useGetInputParams';
import { getCurl } from './helper';

const ModalOption = ({
  text,
  button,
  onClick,
}: {
  text: string;
  button: string;
  onClick: () => void;
}): ReactElement => {
  return (
    <Box mt={4} h="50px" borderRadius="lg" borderWidth={1} borderColor="gray.200" px={4}>
      <Flex justifyContent="space-between" h="full" alignItems="center">
        <Text fontSize="sm" fontWeight="normal">
          {text}
        </Text>
        <Flex alignItems="center" color="blue.900" cursor="pointer" onClick={onClick}>
          <ArrowSquareOut color="#1C2D77" />
          <Text pl={2} color="blue.900" fontWeight="normal" fontSize="xs">
            {button}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const WorkflowPublishModal = ({ onClose, id }: { onClose: () => void; id: string }): ReactElement => {
  const [showCurl, setShowCurl] = useState(false);
  const history = useHistory();

  const { data: docPath } = useDocumentation();
  const { data: inputParameters } = useGetInputDetails(id);

  useEffect(() => {
    if (showCurl) {
      const headingDom: HTMLElement | null = document.getElementById('curl-request');
      const options = {
        lineNumbers: true,
        quoteKeys: true,
        indent: 2,
      };
      if (headingDom) headingDom.innerHTML = prettyPrintJson.toHtml(getCurl(id, inputParameters), options);
    }
  }, [showCurl, id, inputParameters]);

  return (
    <Modal isOpen onClose={onClose} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent borderRadius="lg" overflow="hidden">
        <ModalHeader fontSize="md" fontWeight="500" p={0}>
          <Flex
            w="full"
            h="full"
            direction="column"
            justifyContent="center"
            p={6}
            alignItems="center"
            bg="linear-gradient(180deg, #D4DFF7 -15.71%, rgba(212, 223, 247, 0) 69.95%)"
          >
            <Image src="/assets/icons/publish.svg" mb={4} />
            <Text fontSize="md" fontWeight="500" color="gray.800">
              Good job publishing that Workflow
              <span role="img" aria-label="emoji">
                🎉
              </span>
            </Text>
            <Flex alignItems="center" mt={3} fontWeight="light" fontSize="xs">
              <Text pr={2} color="gray.500">
                ID
              </Text>
              <Text color="gray.700">{id}</Text>
              <CopyButton text={id} copyIcon={<Copy color="#BCC2D4" />} />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody fontSize="sm" fontWeight="light">
          <Flex direction="column">
            <Text fontSize="sm" fontWeight="medium" pt={1}>
              You can start testing your Workflow now
            </Text>
            <Box
              mt={4}
              minH="50px"
              borderRadius="lg"
              borderWidth={1}
              bg={showCurl ? 'gray.100' : ''}
              borderColor="gray.200"
              px={4}
            >
              <Flex justifyContent="space-between" h="full" py={3}>
                <Text fontSize="sm" fontWeight="normal">
                  View cURL
                </Text>
                <Flex alignItems="center" cursor="pointer" h={6} color="blue.900" fontWeight="normal" fontSize="xs">
                  <CopyButton text={getCurl(id, inputParameters)} label="Copy" />
                  {showCurl ? (
                    <CaretUp size={20} color="#BCC2D4" onClick={() => setShowCurl(!showCurl)} />
                  ) : (
                    <CaretDown size={20} color="#BCC2D4" onClick={() => setShowCurl(!showCurl)} />
                  )}
                </Flex>
              </Flex>
              {showCurl && (
                <Box overflowY="scroll">
                  <pre id="curl-request" className="json-container" />
                </Box>
              )}
            </Box>
            <ModalOption
              text="What's next after creating a workflow?"
              button="Learn More"
              onClick={() => window.open(docPath?.url, '_blank')}
            />
            <ModalOption
              text="See and review responses"
              button="See Transactions"
              onClick={() => history.push('/reports')}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="center" w="full" mb={2}>
            <Button
              w="73px"
              borderRadius="lg"
              _hover={{ bg: 'blue.600' }}
              bg="blue.500"
              color="white"
              fontWeight="light"
              fontSize="sm"
              onClick={onClose}
            >
              Got it!
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkflowPublishModal;
