import { CSSProperties } from 'react';
import { Position, FlowElement, Node, Edge } from 'react-flow-renderer';
import { nodeXOffset, nodeWidth, nodeYOffset } from '../constants';

import { Workflow, Column, WorkflowNode, Output, NodeKind, NodeType, EdgeType, Inputs, ResponseInputs } from '../types';

export const parseWorkflowJson = (workflowJson: Workflow): Array<FlowElement> => {
  let chartElementsArr: Array<FlowElement> = [];
  const edgeButtonsArr: Array<FlowElement> = [];

  workflowJson?.columns?.forEach((col: Column, colIndex: number) => {
    col?.nodes.forEach((nd: WorkflowNode, ndIndex: number) => {
      chartElementsArr.push(getNodeData(nd, colIndex, ndIndex) as Node);
      const nodesLength = workflowJson.columns[colIndex]?.nodes?.length;
      const kind = workflowJson.columns[colIndex]?.nodes[0].kind.toLowerCase();
      if (kind !== NodeKind.input && nodesLength === ndIndex + 1) {
        chartElementsArr.push({
          id: `column-plus-${colIndex}-${ndIndex}`,
          type: NodeType.addToColumn,
          data: {
            icon: nd.id.split('_')[0].toLowerCase(),
            columnPosition: colIndex,
            nodePosition: ndIndex + 1,
          },
          position: getPosition(colIndex, ndIndex + 1),
          style: {
            zIndex: 3,
            marginLeft: '92px',
          },
          className: 'new-column-node',
        });
      }
      if (nd?.dependencies) {
        nd.dependencies.forEach((dep: string, depIndex: number) => {
          if (ndIndex === 0 && depIndex === 0) {
            edgeButtonsArr.push(getEdgeData(nd, dep, colIndex, ndIndex, depIndex) as Edge);
          } else chartElementsArr.push(getEdgeData(nd, dep, colIndex, ndIndex, depIndex) as Edge);
        });
      }
    });
  });
  chartElementsArr = [...appendAddNodeToLast(chartElementsArr, workflowJson?.columns?.length), ...edgeButtonsArr];
  return chartElementsArr;
};

export const appendAddNodeToLast = (chartElementsArr: FlowElement[], lastColumn: number): FlowElement[] => {
  // adds add button in the last column
  chartElementsArr.push({
    id: `column-plus-${lastColumn}-0`,
    type: NodeType.addToColumn,
    data: {
      lastColumn: true,
      icon: '',
      columnPosition: lastColumn,
      nodePosition: 0,
    },
    position: getPosition(lastColumn, 0),
    style: {
      zIndex: 4,
    },
  });
  return chartElementsArr;
};

export const getNodeType = (kind: string): string => {
  switch (kind.toLowerCase()) {
    case NodeKind.input: {
      return NodeType.input;
    }
    case NodeKind.accept:
    case NodeKind.reject:
    case NodeKind.manualReview: {
      return NodeType.output;
    }
    default: {
      return NodeType.source;
    }
  }
};

export const getNodeStyles = (kind: string): { style: CSSProperties } => {
  switch (kind.toLowerCase()) {
    case NodeKind.input:
    case NodeKind.accept:
    case NodeKind.manualReview:
    case NodeKind.reject: {
      return {
        style: {
          border: 'none',
          width: `${nodeWidth}px`,
          height: '48px',
          padding: '1px 0 2px 1px',
          textAlign: 'left',
          boxShadow: 'none',
          cursor: 'pointer',
          background: '#f7fafc',
          fontSize: '14px',
        },
      };
    }
    default: {
      return {
        style: {
          borderWidth: '1px',
          borderColor: '#ADC5F9',
          background: 'white',
          borderRadius: '8px',
          width: `${nodeWidth}px`,
          textAlign: 'left',
          cursor: 'pointer',
          fontSize: '14px',
        },
      };
    }
  }
};

const getNodeData = (node: WorkflowNode, colIndex: number, ndIndex: number): Node => {
  const kind = node.kind.toLowerCase();
  return {
    id: node?.id,
    type: getNodeType(node.kind),
    position: getPosition(colIndex, ndIndex),
    ...(kind !== NodeKind.accept &&
      kind !== NodeKind.reject &&
      kind !== NodeKind.manualReview && { sourcePosition: Position.Right }),
    ...(kind !== NodeKind.input && { targetPosition: Position.Left }),
    ...getNodeStyles(node.kind),
    data: {
      ...node,
      icon: node.id.split('_')[0].toLowerCase(),
      columnPosition: node?.columnPosition ? +node?.columnPosition : 0,
      nodePosition: node?.nodePosition ? +node?.nodePosition : 0,
      ...((kind === NodeKind.input ||
        kind === NodeKind.accept ||
        kind === NodeKind.reject ||
        kind === NodeKind.manualReview) && {
        className: 'inputOutput',
      }),
    },
  };
};

const getEdgeData = (
  node: WorkflowNode,
  depend: string,
  colIndex: number,
  ndIndex: number,
  depIndex: number,
): FlowElement => {
  return {
    id: `${depend}-${node.id}`,
    source: depend,
    type: ndIndex === 0 && depIndex === 0 ? EdgeType.buttonEdge : EdgeType.smoothstep,
    target: node.id,
    data: {
      insertColId: colIndex,
      open: false,
    },
    style: {
      position: 'relative',
      zIndex: 0,
      strokeWidth: '2px',
      stroke: '#ADC5F9',
    },
  };
};

const getPosition = (colIndex: number, ndIndex: number): { x: number; y: number } => {
  const x = colIndex * nodeXOffset;
  const y = ndIndex * nodeYOffset;
  return { x, y };
};

export const replaceCharacter = (value: string, source: string, target = ''): string => {
  return value.replaceAll(source, target);
};

export const parseOutputs = (outputs: Output[], nodeId: string): Output[] => {
  const response = outputs?.map((op: Output) => {
    const item = {
      key: op.key,
      ...(nodeId ? { namespace: nodeId } : {}),
      type: op.type,
      description: op.description,
      format: op.format,
      regex: '',
      constraints: {
        numerical_max: null,
        numerical_min: null,
        length_max: null,
        length_min: null,
      },
      template: '',
      parentKey: '',
      location: '',
      displayName: op.displayName,
      allowedValues: op.allowedValues,
      operators: op.operators,
    };
    return item;
  });
  return response;
};

export const parseInputs = (inputs: Inputs[]): ResponseInputs[] => {
  const item = inputs.map(input => ({
    ...input,
    format: input.format || '',
    location: `${input.locationKind}.${input.locationId}.${input.locationType}`,
    isStatic: input.isStatic || false,
    ...(!!input.isStatic && {
      staticValue: input.staticValue,
    }),
  }));
  return item;
};
