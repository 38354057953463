import React from 'react';
import { Cell } from 'react-table';
import { Circle } from 'phosphor-react';
import { Flex, Text } from '@chakra-ui/react';
import { UserRole } from '@/screens/admin/types';
import { UserRoles } from '../../utils/types';
import { getUserColumnStyle } from '../../utils/getUserColumnStyle';

export const RoleStatusCell = ({ value, row, column }: Cell): React.ReactElement | null => (
  <Flex>
    {value.map((role: UserRole) => (
      <Flex
        key={role.id}
        alignItems="center"
        justifyContent="space-around"
        py={2}
        px={4}
        mr={2}
        fontWeight="500"
        width="fit-content"
        borderRadius={10}
        {...getUserColumnStyle(role?.name?.toLowerCase())}
      >
        <Circle size={10} weight="fill" />
        <Text fontSize="xs" pl={1} textTransform="capitalize" fontWeight={300}>
          {role.name || UserRoles.UNASSIGNED}
        </Text>
      </Flex>
    ))}
  </Flex>
);
