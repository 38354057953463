import React, { ReactElement } from 'react';
import { Copy } from 'phosphor-react';
import { IconButton, useToast, Flex, Text } from '@chakra-ui/react';
import { ToastMessage } from './types';

const CopyButton = ({
  text,
  copyIcon = <Copy color="#23368C" />,
  label = '',
  disabled = false,
  id,
}: {
  text: string;
  copyIcon?: ReactElement;
  label?: string;
  disabled?: boolean;
  id?: string;
}): ReactElement => {
  const toast = useToast();
  const copyText = (): void => {
    toast({
      title: ToastMessage.COPIED_TO_CLIPBOARD,
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    navigator.clipboard.writeText(text as string);
  };

  return (
    <Flex
      alignItems="center"
      onClick={copyText}
      cursor="pointer"
      _hover={label ? { opacity: '0.85' } : {}}
      {...(disabled && { pointerEvents: 'none', color: 'gray.600' })}
      mr={2}
    >
      <IconButton
        borderRadius="full"
        variant="ghost"
        aria-label="Copy"
        icon={copyIcon}
        _focus={{ border: 'none' }}
        {...(label && { _hover: { background: 'none' } })}
        id={id}
      />
      <Text>{label}</Text>
    </Flex>
  );
};

export default CopyButton;
