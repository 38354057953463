import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { CapabilityFilters } from '../types';

export const useCapabilityFilters = (): UseQueryResult<CapabilityFilters, AxiosError> => {
  return useQuery<CapabilityFilters, AxiosError>('capability-filters', getCapabilityFilters(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    enabled: !!bureauPlatformApi.defaults.headers.common.Authorization,
  });
};

const getCapabilityFilters = (): (() => Promise<CapabilityFilters>) => {
  return async () => {
    const res = await bureauPlatformApi.get('classifiers');
    return res.data;
  };
};
