import React, { MouseEvent, ReactElement, useEffect } from 'react';
import ReactFlow, {
  Edge,
  Elements,
  FlowElement,
  getIncomers,
  getOutgoers,
  isEdge,
  isNode,
  MiniMap,
  Node,
  ReactFlowProvider,
} from 'react-flow-renderer';
import { Box } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { colors } from '@/utils/colors';
import ButtonEdge from '../ButtonEdge';
import addToColumnNode from '../NewColumnNode';
import sourceTagNode from '../SourceTagNode';
import outputNode from '../OutputNode';
import inputNode from '../InputNode';
import {
  ModifyNodePositions,
  NewEdgeButtonNode,
  NewNodePosition,
  NodeDetails,
  SelectedPosition,
} from '../../states/workflowChart';
import './styles.css';
import { nodeWidth, nodeXOffset } from '../../constants';
import { ChartProps, EdgeType, NodeKind, NodeType } from '../../types';
import AddNodePanel from '../AddNodePanel';
import { WorkflowsActionButtons } from '../WorkflowsActionButtons';

const WorkflowChart = ({
  elements,
  saveElements,
  onNodeClick,
  onPanelClick,
  columnsLength,
}: ChartProps): ReactElement => {
  const newEdgeButtonNodeData = useRecoilValue(NewEdgeButtonNode);
  const setNewNodePosition = useSetRecoilState(NewNodePosition);
  const setModifyNodePosition = useSetRecoilState(ModifyNodePositions);
  const setNodeDetails = useSetRecoilState(NodeDetails);
  const [selectedPosition, setSelectedPosition] = useRecoilState(SelectedPosition);

  const onNodeContextMenu = (event: MouseEvent, node: Node): void => {
    event.preventDefault();
  };

  const edgeTypes = {
    buttonEdge: ButtonEdge,
  };

  const nodeTypes = {
    input: inputNode,
    output: outputNode,
    source: sourceTagNode,
    addToColumn: addToColumnNode,
  };

  const getAllIncomers = (node: Node, elem: Elements): Node[] => {
    return getIncomers(node, elem).reduce<Node[]>(
      (memo, incomer) => [...memo, incomer, ...getAllIncomers(incomer, elem)],
      [],
    );
  };

  const getAllOutgoers = (node: Node, elem: Elements): Node[] => {
    return getOutgoers(node, elem).reduce<Node[]>(
      (memo, outgoer) => [...memo, outgoer, ...getAllOutgoers(outgoer, elem)],
      [],
    );
  };

  const resetNodeStyles = (): void => {
    onPanelClick();
    saveElements((prevElements: Elements) => {
      return prevElements?.map((elem: FlowElement) => {
        const element = { ...elem };
        if (isNode(elem)) {
          element.style = {
            ...element.style,
            opacity: 1,
          };
        } else {
          element.style = {
            ...element.style,
            stroke: colors.blue[200],
            opacity: 1,
          };
        }

        return element;
      });
    });
  };

  const highlightPath = (node: Node | Edge, elems: Elements, selection: boolean): void => {
    if (node && elems && node?.type !== NodeType.addToColumn) {
      const allIncomers = getAllIncomers(node as Node, elems);
      const allOutgoers = getAllOutgoers(node as Node, elems);

      saveElements((prevElements: Elements) => {
        return prevElements?.map((elem: FlowElement) => {
          const element = { ...elem };
          const incomerIds = allIncomers.map(i => i.id);
          const outgoerIds = allOutgoers.map(o => o.id);

          if (isNode(element) && (allOutgoers.length > 0 || allIncomers.length > 0)) {
            const highlight =
              element.id === node.id || incomerIds.includes(element.id) || outgoerIds.includes(element.id);

            element.style = {
              ...element.style,
              opacity: highlight ? 1 : 0.25,
            };
          }

          if (isEdge(element)) {
            if (selection) {
              const selected =
                incomerIds.includes(element.source) &&
                (incomerIds.includes(element.target) || node.id === element.target);

              element.style = {
                ...element.style,
                stroke: selected ? '#276EF1' : colors.blue[200],
                opacity: selected ? 1 : 0.25,
              };
            } else {
              element.animated = false;
              element.style = {
                ...element.style,
                stroke: colors.blue[200],
                opacity: 1,
              };
            }
          }

          return element;
        });
      });
    }
  };

  const onElementClick = (event: MouseEvent, element: Node | Edge): void => {
    if (element?.type !== EdgeType.smoothstep) {
      setSelectedPosition({
        columnPosition: element?.data?.columnPosition || element?.data?.insertColId || 0,
        nodePosition:
          element?.data?.nodePosition || element?.data?.nodePosition === 0 ? element?.data?.nodePosition : -1, // -1 for edge buttons
      });
    }
    if (element?.type !== EdgeType.buttonEdge) {
      highlightPath(element, elements, false);
    }
    if (isNode(element) && element?.type !== NodeType.addToColumn) {
      onNodeClick(element);
      if (element.data.kind === NodeKind.await) {
        setNodeDetails({
          type: element.data?.kind || '',
          oldName: element.data?.name || '',
          oldId: element.data?.id || '',
          action: 'edit',
          color: element.data?.color || '',
          awaits: element.data?.awaits,
          description: element.data?.description,
        });
        setModifyNodePosition({
          columnPosition: element?.data?.columnPosition || element?.data?.insertColId || 0,
          nodePosition:
            element?.data?.nodePosition || element?.data?.nodePosition === 0 ? element?.data?.nodePosition : -1, // -1 for edge buttons
        });
      }
    } else resetNodeStyles();
  };

  const addStep = (type: string): void => {
    setNewNodePosition({
      type,
      columnPosition: newEdgeButtonNodeData.insertColumnId,
      nodePosition: 0,
      newColumn: true,
    });
  };

  const handleServicesScroll = (event: WheelEvent | Event): void => {
    event.preventDefault();
    const scrollContainer = document.querySelector('.react-flow');
    if (scrollContainer) {
      scrollContainer.scrollTop += (event as WheelEvent).deltaY;
    }
  };

  useEffect(() => {
    const scrollContainer = document.querySelector('.react-flow');
    scrollContainer?.addEventListener('wheel', handleServicesScroll);
    return () => {
      scrollContainer?.removeEventListener('wheel', handleServicesScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const buttonEdgeWidth = nodeXOffset - nodeWidth;
      const scrollWidth = columnsLength * (nodeWidth + buttonEdgeWidth);
      const scrollHeight = document.querySelector<HTMLElement>('.react-flow')?.scrollHeight;
      const svgElement = document.querySelector<HTMLElement>('svg.react-flow__edges');
      if (svgElement) {
        svgElement.style.width = `${scrollWidth}px`;
        svgElement.style.height = `${scrollHeight}px`;
      }
      const columnNodeElement = document.querySelector<HTMLElement>('.steps-menu');
      if (columnNodeElement?.parentNode?.parentNode) {
        (columnNodeElement.parentNode.parentNode as HTMLElement).style.zIndex = '4';
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useEffect(() => {
    // reverts the z-index of opened plus node to 3 when closed
    const openedNode = document.querySelector<HTMLElement>('.steps-menu')?.parentNode?.parentNode;
    return () => {
      if (openedNode) {
        (openedNode as HTMLElement).style.zIndex = '3';
      }
    };
  }, [selectedPosition]);

  return (
    <>
      {elements.length > 0 && (
        <ReactFlowProvider>
          <ReactFlow
            elements={elements}
            selectNodesOnDrag={false}
            onNodeContextMenu={onNodeContextMenu}
            onElementClick={(evt, node) => onElementClick(evt, node)}
            onPaneClick={resetNodeStyles}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            edgeTypes={edgeTypes}
            zoomOnScroll
            zoomOnPinch
            zoomOnDoubleClick
            paneMoveable
            arrowHeadColor={colors.blue[200]}
            onSelectionChange={selectedElements => highlightPath(selectedElements?.[0] as Node, elements, true)}
          >
            {newEdgeButtonNodeData.insertColumnId > 0 &&
              newEdgeButtonNodeData.insertColumnId === selectedPosition.columnPosition &&
              selectedPosition?.nodePosition === -1 && (
                <Box
                  bg="white"
                  mt={4}
                  h="425px"
                  w="730px"
                  borderRadius="lg"
                  position="absolute"
                  top="72px"
                  left={
                    newEdgeButtonNodeData.insertColumnId !== 1 && newEdgeButtonNodeData?.sourceX
                      ? newEdgeButtonNodeData?.sourceX - 332
                      : 0
                  }
                  _after={{
                    content: '""',
                    position: 'absolute',
                    bottom: '100%',
                    left: newEdgeButtonNodeData.insertColumnId === 1 ? '34%' : '50%',
                    borderBottom: 'solid 12px #E8EBF1',
                    borderLeft: 'solid 8px transparent',
                    borderRight: 'solid 8px transparent',
                  }}
                  zIndex="4"
                >
                  <AddNodePanel addStep={addStep} />
                </Box>
              )}

            <WorkflowsActionButtons />

            <MiniMap nodeColor="#23368C" nodeBorderRadius={2} nodeStrokeWidth={2} />
          </ReactFlow>
        </ReactFlowProvider>
      )}
    </>
  );
};

export default WorkflowChart;
