import React, { ReactElement } from 'react';
import { Flex, Image, Text, TextProps } from '@chakra-ui/react';

function WorkflowLoader({ text, textStyles }: { text: string; textStyles: TextProps }): ReactElement {
  return (
    <Flex direction="column" alignItems="center" gridGap="4" justifyContent="center" h="full">
      <Image src="/assets/loaderGif.gif" w={12} />
      <Text width="52" fontSize="sm" textAlign="center" lineHeight="normal" {...textStyles}>
        {text}
      </Text>
    </Flex>
  );
}

export default WorkflowLoader;
