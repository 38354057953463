import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Flex, Button, Text } from '@chakra-ui/react';
import { Repeat } from 'phosphor-react';

import useInterval from '@/hooks/useInterval';

const AwaitCapabilityCard = ({
  // tryoutResponse,
  isTransactionLoading,
  onRefreshClick,
}: {
  // tryoutResponse: TransactionDetail;
  isTransactionLoading: boolean;
  onRefreshClick: () => void;
}): ReactElement => {
  const initialTimerCount = 9;
  const [timerCount, setTimerCount] = useState(initialTimerCount);

  useInterval(
    () => {
      setTimerCount(prevCount => prevCount - 1);
    },
    timerCount >= 0 ? 1000 : null,
  );

  // Start timer again on success of transaction API after refresh button click
  useEffect(() => {
    if (!isTransactionLoading) setTimerCount(initialTimerCount);
  }, [isTransactionLoading]);

  return (
    <Box p="4" borderRadius="lg" bg="gray.50">
      <Text textTransform="uppercase" fontSize="10px" fontWeight="light" color="gray.600">
        Awaiting
      </Text>
      {/* <Text fontSize="sm" fontWeight="normal" color="gray.700">
        {Object.keys(tryoutResponse.awaits)?.[0]}
      </Text> */}
      <Text mt="2" fontSize="xs" fontWeight="light" lineHeight="130%" color="gray.500">
        The results for this capability is currently being fetched. Please refresh every 10 seconds until you get the
        results.
      </Text>
      <Flex mt="4" gridGap="2" alignItems="center">
        <Button
          p="3"
          colorScheme="blue"
          leftIcon={<Repeat />}
          fontSize="sm"
          fontWeight="normal"
          borderRadius="lg"
          _hover={{ opacity: '0.85' }}
          _disabled={{ bg: 'gray.100', color: 'gray.600', _active: { bg: 'gray.100' } }}
          isLoading={isTransactionLoading}
          isDisabled={timerCount >= 0}
          onClick={() => onRefreshClick()}
        >
          Refresh
        </Button>
        {timerCount >= 0 && (
          <Text fontSize="xs" fontWeight="light" lineHeight="130%" color="blue.900">
            00:0{timerCount}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default AwaitCapabilityCard;
