import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { Flex, IconButton, Image } from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { FlagCheckered, GearSix, Play } from 'phosphor-react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import FactsLoader from '@/components/loader/Loader';
import { bannerHeight } from '@/constants/constants';
import CapabilityNode from './CapabilityNode';
import BoundaryNode from './components/BoundaryNode';
import { useCreateWorkflowRendererData } from './hooks/useCreateWorkflowRenderer';
import { WorkflowRenderObj } from './types';
import ConditionNode from './ConditionNode';
import CapabilityDrop from './CapabilityDrop';
import BranchingNodes from './BranchingNodes';
import { WorkflowAtom } from './states/workflowAtom';
import { WorkflowNameAtom } from './states/workflowName';
import { EndnodeAtom } from './states/endnodeAtom';
import { useCapabilityFilters } from '../user-onboarding/queries/useCapabilityFilters';
import { CapabilityFilterItem } from '../user-onboarding/types';
import { initialUseCaseValues } from './Workflow';
import useGetWorkflow from './queries/useGetWorkflow';

const WorkflowLayout = ({
  isCapabilityListOpen,
  openConfigureModal,
  setSelectedCapability,
  setSelectedUseCase,
  isConfigureModalOpen,
  onConfigureModalClose,
  setIsCapabilityListOpen,
}: {
  isCapabilityListOpen: boolean;
  openConfigureModal: () => void;
  setSelectedCapability: Dispatch<SetStateAction<string>>;
  setSelectedUseCase: Dispatch<SetStateAction<CapabilityFilterItem>>;
  isConfigureModalOpen: boolean;
  onConfigureModalClose: () => void;
  setIsCapabilityListOpen: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const { data } = useCreateWorkflowRendererData();
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();
  const { data: workflowObject, isFetching: isDetailsFetching } = useGetWorkflow(id);
  const { data: capabilityFilters } = useCapabilityFilters();
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const setWorkflowName = useSetRecoilState(WorkflowNameAtom);
  const setEndColumn = useSetRecoilState(EndnodeAtom);

  useEffect(() => {
    if (workflowObject)
      setSelectedUseCase(
        capabilityFilters?.useCases?.find(useCase => useCase.value === workflowObject?.useCase) ?? initialUseCaseValues,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowObject, capabilityFilters]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const workflowColumn = workflowObject?.workflowObject.columns || [];
    setWorkflow(workflowColumn.slice(1, workflowColumn.length - 1) || []);
    if (path.indexOf('edit') > -1) {
      setWorkflowName(workflowObject?.name || '');
    }
    setEndColumn(workflowColumn.slice(workflowColumn.length - 1)[0] || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setWorkflow, workflowObject]);

  if (id && isDetailsFetching) {
    return (
      <Flex direction="column" w="full">
        <FactsLoader />
      </Flex>
    );
  }

  const handleCapabilityNodeClick = (nodeId: string): void => {
    setSelectedCapability(nodeId);
    openConfigureModal();
  };
  const renderWorkflow = (nodeData: WorkflowRenderObj, index: number): ReactElement => {
    if (nodeData.kind === 'START') {
      return (
        <Flex direction="column">
          <BoundaryNode
            title="Start Workflow"
            description="Drag and drop the Capabilities you wish to use in this Workflow below."
            startIcon={<Play size="16" color="green" weight="fill" />}
            endIcon={<></>}
          />
          <Image src="/assets/connection.svg" alt="connection" height="30" />
        </Flex>
      );
    }

    if (nodeData.kind === 'tag') {
      return (
        <Flex direction="column" alignItems="center">
          <ConditionNode id={nodeData.id} handleCapabilityNodeClick={handleCapabilityNodeClick} />
          <Image src="/assets/workflow_branching.svg" alt="top-connection" />
          <Flex gridGap="5">
            <BranchingNodes
              id={nodeData.id}
              type="Branch A"
              handleBranchingNodesClick={handleCapabilityNodeClick}
              handleCapabilityNodeClick={handleCapabilityNodeClick}
            />
            <BranchingNodes
              id={nodeData.id}
              type="Branch B"
              handleBranchingNodesClick={handleCapabilityNodeClick}
              handleCapabilityNodeClick={handleCapabilityNodeClick}
            />
          </Flex>
          <Image src="/assets/bottom_connection.svg" alt="bottom-connection" />
        </Flex>
      );
    }

    if (nodeData.kind === 'source') {
      return (
        <Flex direction="column">
          <CapabilityNode
            id={nodeData.id}
            handleCapabilityNodeClick={handleCapabilityNodeClick}
            nodeData={nodeData}
            isCapabilityListOpen={isCapabilityListOpen}
            onConfigureModalClose={onConfigureModalClose}
            setIsCapabilityListOpen={setIsCapabilityListOpen}
          />
          <Image src="/assets/connection.svg" alt="connection" height="30" />
        </Flex>
      );
    }

    if (nodeData.kind === 'DROPZONE') {
      return (
        <>
          <Flex direction="column">
            <CapabilityDrop type="source" parentId={nodeData.id} />
            <Image src="/assets/connection.svg" alt="connection" height="30" />
          </Flex>
        </>
      );
    }

    if (nodeData.kind === 'END') {
      return (
        <BoundaryNode
          nodeData={nodeData}
          id={nodeData.id}
          handleCapabilityNodeClick={handleCapabilityNodeClick}
          title="End Workflow"
          description="Final termination point for the 
Workflow."
          startIcon={<FlagCheckered size={20} color={colors.green[500]} weight="fill" />}
          endIcon={
            <IconButton
              aria-label="Workflow settings"
              icon={<GearSix size={20} color={colors.blue[400]} weight="fill" />}
              h="auto"
              minW="auto"
              mr="3"
              paddingInline="0"
              bg="initial"
              _focus={{ boxShadow: 'none' }}
              _hover={{ bg: 'initial' }}
            />
          }
        />
      );
    }

    return <></>;
  };

  return (
    <Flex
      pt="16"
      mx={isConfigureModalOpen ? '6' : 'auto'}
      direction="column"
      alignItems="center"
      maxW={isConfigureModalOpen ? '40vw' : 'unset'}
      w="full"
      maxH={`calc(100vh - ${bannerHeight + 115}px)`}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none' /* IE and Edge */,
        'scrollbar-width': 'none' /* Firefox */,
      }}
    >
      {data.map((d, i) => renderWorkflow(d, i))}
    </Flex>
  );
};

export default WorkflowLayout;
