/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Switch,
  VStack,
  Text,
  Grid,
  Flex,
  Heading,
  Link,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import { CopyButton } from '@bureau/components/src';
import { Barcode } from 'phosphor-react';
import QrCode from './QrCode';
import { QuickLinkData } from '../types';
import { useQuickLink } from './useQuickLink';

interface QuickLinkProps {
  workflowId: string;
}

interface QuickLinkFormProps {
  values: QuickLinkData;
  errors: FormikErrors<QuickLinkData>;
  touched: FormikTouched<QuickLinkData>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  isLoading: boolean;
}

interface QuickLinkPreviewProps {
  quicklinkUrl: string;
  trimmedUrl: string;
  navigateToQuickLink: () => void;
}

const QuickLinkForm: React.FC<QuickLinkFormProps> = ({ values, errors, touched, handleChange, isLoading }) => (
  <VStack spacing={6} align="stretch">
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="sm">
      <VStack spacing={4} align="stretch">
        <FormControl isInvalid={!!errors.name && touched.name}>
          <FormLabel fontSize="md" fontWeight="medium">
            Quicklink Name
          </FormLabel>
          <Input name="name" value={values.name} onChange={handleChange} placeholder="Enter quicklink name" />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl display="flex" alignItems="center">
          <FormLabel mb="0" fontSize="md" fontWeight="medium">
            Show Response After Tryout
          </FormLabel>
          <Switch
            name="showResponseOutput"
            isChecked={values.showResponseOutput}
            onChange={handleChange}
            colorScheme="blue"
          />
        </FormControl>

        <FormControl isInvalid={!!errors.transactionLimit && touched.transactionLimit}>
          <FormLabel>Link Validity (No. of Transactions)</FormLabel>
          <Input name="transactionLimit" type="number" value={values.transactionLimit} onChange={handleChange} />
          <FormErrorMessage>{errors.transactionLimit}</FormErrorMessage>
        </FormControl>
      </VStack>
    </Box>

    <Button type="submit" colorScheme="blue" width="fit-content" isLoading={isLoading}>
      Generate Link
    </Button>
  </VStack>
);

const QuickLinkPreview: React.FC<QuickLinkPreviewProps> = ({ quicklinkUrl, trimmedUrl, navigateToQuickLink }) => (
  <Flex direction="column">
    <Heading fontSize="md" color="gray.800" fontWeight="500" mb={4}>
      Scan QR for a Live Preview
    </Heading>
    <Text fontSize="xs" fontWeight="300" color="gray.600" lineHeight="160%" mb={6}>
      Configure and generate weblink for executing the workflow
    </Text>

    <Box p={4} boxShadow="lg" borderRadius="lg" bg="white" border="1px" borderColor="gray.200" h="full">
      <VStack align="center">
        <QrCode url={quicklinkUrl} />
        <HStack pb={2}>
          <Link href={quicklinkUrl} color="blue.500" isExternal>
            <Text>{trimmedUrl}</Text>
          </Link>
          <CopyButton text={quicklinkUrl} />
        </HStack>

        <VStack spacing={1} textAlign="center">
          <Text fontSize="lg" fontWeight="bold">
            How To Integrate This?
          </Text>
          <Text color="gray.600">For more information & steps about integration,</Text>
          <Link href="https://docs.bureau.id" color="blue.500" isExternal>
            <Text>Go to Documentation</Text>
          </Link>
        </VStack>

        <VStack spacing={1} textAlign="center" w="full">
          <Text fontSize="lg" fontWeight="bold">
            Manage your QuickLinks
          </Text>
          <Text color="gray.600">To manage and view all previously generated QuickLinks</Text>
          <Text
            onClick={navigateToQuickLink}
            as="span"
            color="blue.500"
            cursor="pointer"
            _hover={{
              color: 'blue.600',
              textDecoration: 'underline',
            }}
            transition="color 0.2s ease"
          >
            Go to QuickLink Manager
          </Text>
        </VStack>
      </VStack>
    </Box>
  </Flex>
);

const QuickLink: React.FC<QuickLinkProps> = ({ workflowId }) => {
  const {
    generatedLinkID,
    isLoading,
    initialValues,
    validationSchema,
    handleSubmit,
    trimUrl,
    navigateToQuickLink,
    quicklinkUrl,
  } = useQuickLink(workflowId);

  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Grid templateColumns="1fr 1fr" h="full">
      <Flex h="full" borderRightWidth={1} borderColor={borderColor} direction="column" bg="white.100" py="6" px="4">
        <Box mb={6}>
          <Text fontSize="md" fontWeight={500} mb={4}>
            Open Link
          </Text>
          <Text fontSize="sm" color="gray.600">
            Configure and generate a weblink for executing this workflow
          </Text>
        </Box>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <QuickLinkForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                isLoading={isLoading}
              />
            </Form>
          )}
        </Formik>
      </Flex>

      <Flex direction="column" py="6" px="4" h="full" w="full">
        {generatedLinkID ? (
          <QuickLinkPreview
            quicklinkUrl={quicklinkUrl}
            trimmedUrl={trimUrl(quicklinkUrl)}
            navigateToQuickLink={navigateToQuickLink}
          />
        ) : (
          <Flex h="full" w="full" alignItems="center" justifyContent="center" direction="column">
            <Box color="blue.700" px="1" py="4">
              <Barcode size="24" />
            </Box>
            <Text color="gray.500">Your QuickLink will be shown here</Text>
          </Flex>
        )}
      </Flex>
    </Grid>
  );
};

export default QuickLink;
