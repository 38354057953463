import React, { ReactElement, useMemo } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { CodeSimple } from 'phosphor-react';
import { ToastMessage } from '@/components/copy-button/types';
import { bureauPlatformApi, getBaseUrl } from '@/utils/api';
import { getAuthDetails } from '@/helpers/getAuthDetails';
import { useLocation } from 'react-router-dom';
import { TryoutStateAtom } from '../states/tryoutStateAtom';

const CurlCopyButton = ({ workflowId }: { workflowId: string }): ReactElement => {
  const { pathname } = useLocation();
  const isTemplate = pathname.includes('templates');
  const { tryoutResponse } = useRecoilValue(TryoutStateAtom);
  const toast = useToast();

  const getCopyUrl = useMemo((): string => {
    const getCurl = (): string => {
      const authDetails = getAuthDetails();
      const url = authDetails.region === 'us' ? process.env.REACT_APP_US_ENDPOINT : getBaseUrl();
      return `curl --location --request POST '${url}/transactions' \\
    --header 'Authorization: ${bureauPlatformApi.defaults.headers.common.Authorization}' \\
    --header 'Content-Type: application/json' \\
    --header 'x-bureau-auth-org-id: ${bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID']}' \\
    --data-raw '{
        "${isTemplate ? 'templateId' : 'workflowId'}": "${workflowId}",
        "data": ${JSON.stringify(tryoutResponse?.transactionRawInput)}
    }'`;
    };
    return getCurl();
  }, [tryoutResponse, workflowId, isTemplate]);

  const copyText = (): void => {
    toast({
      title: ToastMessage.COPIED_TO_CLIPBOARD,
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    navigator.clipboard.writeText(getCopyUrl as string);
  };

  return (
    <Button
      variant="link"
      leftIcon={<CodeSimple />}
      mr="8"
      color="blue.400"
      fontSize="sm"
      fontWeight="normal"
      _hover={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      disabled={!tryoutResponse.transactionRawInput}
      onClick={() => copyText()}
    >
      CURL
    </Button>
  );
};

export default CurlCopyButton;
