import { OptionType } from '@bureau/components';
import { Operator } from '@/screens/workflows/workflow-creation/types';

export const newJson = {
  columns: [
    {
      id: 'column_0',
      nodes: [
        {
          dependencies: [],
          description: '',
          id: 'start_ghdvng',
          kind: 'START',
          name: 'Input',
          outputs: [],
          columnPosition: 0,
          nodePosition: 0,
        },
      ],
    },
  ],
};

export const workflowSteps = [
  {
    id: 'source',
    name: 'Service',
    icon: '/assets/icons/service.svg',
  },
  {
    id: 'tag',
    name: 'Tag',
    icon: '/assets/icons/tag.svg',
  },
  {
    id: 'end_state',
    name: 'End state',
    icon: '/assets/icons/end.svg',
  },
];

export const endTypes = [
  {
    value: 'accept',
    label: 'Accept',
  },
  {
    value: 'reject',
    label: 'Reject',
  },
  {
    value: 'manual_review',
    label: 'Manual Review',
  },
];

// would be removed when this is moved to BE
export const defaultOutput = {
  key: 'status',
  displayName: 'status',
  type: 'STRING',
  description: '',
  format: '',
  regex: '',
  constraints: {
    numerical_max: null,
    numerical_min: null,
    length_max: null,
    length_min: null,
  },
  template: '',
  parentKey: '',
  location: '',
  allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
};

export const tagConstants = [
  { label: 'ok', value: 'OK' },
  { label: 'failed', value: 'FAILED' },
];

export const tagOperators = [{ label: '==', value: 'eq', hiddenParams: false }];

export const functionOperators = ['hasprefix', 'hassuffix', 'contains', 'in', 'matchstring'];

export const nodeWidth = 210;
export const nodeXOffset = 300;
export const nodeYOffset = 160;
export const dropdownXOffset = 180;
export const edgeWidth = 89;

export const staticDynamicFieldOptions = [
  { label: 'Static', value: 'static' },
  { label: 'Dynamic', value: 'dynamic' },
];

// for demo purpose
export const inputOperators: Operator[] = [
  { hiddenParams: false, label: '==', value: 'eq' },
  { hiddenParams: false, label: '!=', value: 'not' },
  { hiddenParams: false, label: '>', value: 'gt' },
  { hiddenParams: false, label: '<', value: 'lt' },
  { hiddenParams: false, label: '>=', value: 'gte' },
  { hiddenParams: false, label: '<=', value: 'lte' },
  { hiddenParams: false, label: 'Contains', value: 'contains' },
  { hiddenParams: false, label: 'In', value: 'in' },
  { hiddenParams: false, label: 'Has Prefix', value: 'beginsWith' },
  { hiddenParams: false, label: 'Has Suffix', value: 'endsWith' },
];

export const conditionTypes: OptionType[] = [
  { label: 'Input attribute', value: 'input' },
  { label: 'Service', value: 'source' },
  { label: 'Tag', value: 'tag' },
];

export const defaultOperators: OptionType[] = [
  { label: '==', value: 'eq' },
  { label: '!=', value: 'not' },
];

export const integerOperators: Operator[] = [
  { hiddenParams: false, label: '==', value: 'eq' },
  { hiddenParams: false, label: '!=', value: 'not' },
  { hiddenParams: false, label: '>', value: 'gt' },
  { hiddenParams: false, label: '<', value: 'lt' },
  { hiddenParams: false, label: '>=', value: 'gte' },
  { hiddenParams: false, label: '<=', value: 'lte' },
];

export const booleanAllowedValues = ['true', 'false'];

export const initialCondition = {
  type: '',
  items: [],
};

export const initialItem = {
  kind: '',
  id: '',
  type: '',
  operator: '',
  value: '',
  isDynamic: false,
  dynamicItem: {
    id: '',
    kind: '',
    type: '',
  },
  group: {
    type: '',
    items: [],
  },
  isFunctionOperator: false,
  functionOperator: '',
  functionItems: [],
};

export const functionItem = {
  kind: '',
  id: '',
  type: '',
  value: '',
};

export const newConditionItems = [initialItem, initialItem];

export const awaitsNodeName = 'start_ghdvng';

export const workflowInput = {
  description: '',
  displayName: '',
  format: '',
  key: '',
  locationKind: '',
  locationId: '',
  locationType: '',
  namespace: '',
  type: '',
};

export const workflowResponseInput = {
  description: '',
  displayName: '',
  format: '',
  key: '',
  location: '',
  namespace: '',
  type: '',
};

export const workflowKindOptions = [
  { label: 'Input Variables', value: 'input' },
  { label: 'Service Output Variables', value: 'source' },
  { label: 'Static input', value: 'static' },
];
