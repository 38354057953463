import { atom } from 'recoil';
import { OUTPUTS } from '../constant';
import { ColumnV2 } from '../types';
import { generateUUID } from '../utils';

const nodeId = `accept_${generateUUID()}`;
const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', nodeId));
const initialData = {
  id: nodeId,
  nodes: [
    {
      id: nodeId,
      kind: 'end_accepted',
      name: 'accept',
      outputs,
      dependencies: ['start_ghdvng'],
      inputs: [],
    },
  ],
  metadata: {
    kind: 'end',
  },
} as ColumnV2;
export const EndnodeAtom = atom({
  key: 'end-node',
  default: initialData as ColumnV2,
});
