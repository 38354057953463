import { EntryCollection } from 'contentful';
import { contentfulApi } from '@/contentfulApi';

const useConfigureServiceDescriptions = (): {
  getConfigureServiceDescriptions: () => Promise<EntryCollection<unknown> | null>;
} => {
  const getConfigureServiceDescriptions = async (): Promise<EntryCollection<unknown> | null> => {
    const entries = await contentfulApi.getEntries({
      content_type: 'workflowConfigureCapabilities',
      select: 'fields',
    });
    return entries;
  };

  return { getConfigureServiceDescriptions };
};

export default useConfigureServiceDescriptions;
