import React, { ReactElement } from 'react';
import { ConditionsType } from './types';
import { NestedCondition } from '../workflows/workflow-creation/components/workflow-properties/types';
import Conditions from './Conditions';
import ConditionsRow from './ConditionsRow';

function IndividualCondition({
  type,
  condition,
  pathArray,
}: {
  type: ConditionsType;
  condition: NestedCondition;
  pathArray: (string | number)[];
}): ReactElement {
  const conditionKey = Object.keys(condition)[0];
  if (conditionKey === 'and' || conditionKey === 'or') {
    return <Conditions type={type} conditions={condition} pathArray={[...pathArray, conditionKey]} />;
  }
  return (
    <ConditionsRow
      type={type}
      pathArray={[...pathArray, conditionKey]}
      conditionKey={conditionKey}
      conditions={condition}
    />
  );
}

export default IndividualCondition;
