import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { Flex, Switch } from '@chakra-ui/react';
import { ActionButtonColumnProps } from '../../utils/types';

export const WebhookToggleCell = ({ value, row, column }: Cell): ReactElement => {
  const { getProps } = column as ActionButtonColumnProps;

  return (
    <Flex
      w={10}
      cursor="pointer"
      onClick={() => {
        getProps?.onWebhookToggle?.(row.values.webhookId?.id);
      }}
    >
      <Switch isChecked={!value} pointerEvents="none" colorScheme="blue" />
    </Flex>
  );
};
