import React, { ReactElement, useMemo } from 'react';
import { Flex, Text, Image, Menu, MenuButton, MenuList, MenuItem, useToast } from '@chakra-ui/react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CheckCircle, DotsThree, ShieldWarning } from 'phosphor-react';
import { colors } from '@/utils/colors';
import { WorkflowAtom } from './states/workflowAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { ConditionsAtom } from './states/conditionsAtom';
import { Conditions, ConditionsType } from './types';
import { BranchAtom } from './states/branchAtom';

const ConditionNode = ({
  id,
  handleCapabilityNodeClick,
}: {
  id: string;
  handleCapabilityNodeClick: (id: string) => void;
}): ReactElement => {
  const toast = useToast();
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const workflows = useRecoilValue(WorkflowAtom);
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const setConditions = useSetRecoilState(ConditionsAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const setBranch = useSetRecoilState(BranchAtom);
  const isAlertsAdded = useMemo(() => {
    const processedTags = workflows.filter(workflow => workflow.metadata.parentID === id);
    return processedTags.length <= 2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(workflows)]);
  const isNodeSelected = selectedNode.id === id && selectedNode.type === 'branch';
  return (
    <Flex
      bg="white"
      borderWidth={1}
      borderColor={isNodeSelected ? 'blue.400' : 'gray.200'}
      boxShadow={
        isNodeSelected ? `2px 6px 8px 0px rgba(17, 25, 99, 0.04), 0px 0px 0px 3px ${colors.shadows.blue}` : 'none'
      }
      borderRadius="3xl"
      w="172px"
      p="3"
      gridGap="2"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={() => {
        const columns = workflows.filter(workflow => workflow.metadata.capabilityId === id);
        setSelectedNode(() => ({ id, type: 'branch', columns }));
        setBranch(columns[0].metadata.branch || '');
        const { conditions } = columns[0].nodes[0];
        if (conditions?.length) {
          const conditionsObject = {
            [ConditionsType.accept]: [],
            [ConditionsType.reject]: [],
            [ConditionsType.manual_review]: [],
          } as Conditions;
          conditionsObject[ConditionsType.accept] = conditions;
          setConditions(conditionsObject);
        }
        handleCapabilityNodeClick(id);
      }}
    >
      <Image src="/assets/icons/Conditions.svg" alt="tree icon" color="blue.400" />
      <Text fontSize="sm">Condition</Text>
      {isAlertsAdded ? (
        <ShieldWarning size={16} color={colors.orange[500]} weight="fill" />
      ) : (
        <CheckCircle color="green" weight="fill" />
      )}
      <Menu>
        <MenuButton onClick={event => event.stopPropagation()}>
          <DotsThree size={16} color={colors.gray[900]} />
        </MenuButton>
        <MenuList>
          <MenuItem
            fontWeight={300}
            fontSize="sm"
            onClick={event => {
              event.stopPropagation();
              setWorkflow(workflow =>
                workflow.filter(column => !(column.metadata.capabilityId === id || column.metadata.parentID === id)),
              );
              toast({
                title: 'Condition Node successfully deleted',
                status: 'success',
                duration: 1000,
                isClosable: true,
              });
              resetConditions();
            }}
            color="red.500"
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ConditionNode;
