import React, { ReactElement } from 'react';
import { Minus, Plus } from 'phosphor-react';
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionProps } from '@chakra-ui/react';

interface FAQTypes {
  id: number;
  question: string;
  answer: string;
}

const FAQAccordion = ({ faqs, styles }: { faqs: FAQTypes[]; styles?: AccordionProps }): ReactElement => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple {...styles} m="auto" borderRadius="lg" w="full">
      {faqs.map(faq => {
        return (
          <AccordionItem borderWidth={1} borderColor="blue.100" key={faq.id}>
            {({ isExpanded }) => (
              <Box bg={isExpanded ? 'gray.50' : 'unset'}>
                <Box py="2" key={faq.id}>
                  <AccordionButton>
                    <Box as="h2" flex="1" textAlign="left" fontSize="sm" fontWeight="medium" color="gray.900">
                      {faq.question}
                    </Box>
                    {isExpanded ? <Minus fontSize="12px" /> : <Plus fontSize="12px" />}
                  </AccordionButton>
                </Box>
                <AccordionPanel pb={4} as="p" fontSize="xs" color="gray.600">
                  {faq.answer}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default FAQAccordion;
