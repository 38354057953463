import React, { ReactElement, useRef, useState, useEffect } from 'react';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { Flex, Button, Input, Box } from '@chakra-ui/react';
import { CalendarContainerProps, DatePickerProps, CalendarContainerTypes } from './types';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

const setTime = (date: Date, hours: number, minutes: number, seconds: number): Date => {
  date?.setHours(hours);
  date?.setMinutes(minutes);
  date?.setSeconds(seconds);
  return date;
};

const InputButton = ({
  date,
  type,
  setStartDate,
  setEndDate,
  setDateError,
  disable,
}: {
  date: string;
  type: string;
  setStartDate?: (date: Date) => void;
  setEndDate?: (date: Date) => void;
  setDateError: (val: boolean) => void;
  disable: boolean;
}): ReactElement => {
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');

  const updateDates = (): void => {
    if (type === 'start') {
      const dateArray = customStartDate.split('/');
      if (
        dateArray.length === 3 &&
        !Number.isNaN(+dateArray[0]) &&
        !Number.isNaN(+dateArray[1]) &&
        !Number.isNaN(+dateArray[2])
      ) {
        setStartDate?.(setTime(new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0]), 0, 0, 0));
      } else setDateError(true);
    } else {
      const dateArray = customEndDate.split('/');
      if (
        dateArray.length === 3 &&
        !Number.isNaN(+dateArray[0]) &&
        !Number.isNaN(+dateArray[1]) &&
        !Number.isNaN(+dateArray[2])
      ) {
        setEndDate?.(setTime(new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0]), 23, 59, 59));
      } else setDateError(true);
    }
  };

  useEffect(() => {
    if (type === 'start') setCustomStartDate(date);
    else setCustomEndDate(date);
  }, [date, type]);

  return (
    <Input
      value={type === 'start' ? customStartDate : customEndDate}
      ml={2}
      px={2}
      width="130px"
      bg="white"
      borderRadius="lg"
      borderWidth={1}
      borderColor="gray.300"
      fontSize="sm"
      fontWeight="light"
      color={date.includes('date') ? 'gray.500' : 'black'}
      _hover={{ bg: 'none' }}
      _focusWithin={{ outline: 'none', boxShadow: 'none' }}
      onChange={e => {
        if (type === 'start') setCustomStartDate?.(e.target.value);
        else setCustomEndDate?.(e.target.value);
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') updateDates();
      }}
      onBlur={updateDates}
      disabled={disable}
    />
  );
};

const CalendarContainer = ({
  className,
  child,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setDateError,
  disable,
}: CalendarContainerProps): ReactElement => (
  <Flex className={className} direction="column" p={6}>
    <Flex>
      <InputButton
        date={startDate || 'Start date'}
        type="start"
        setStartDate={setStartDate}
        setDateError={setDateError}
        disable={disable}
      />
      <InputButton
        date={endDate || 'End date'}
        type="end"
        setEndDate={setEndDate}
        setDateError={setDateError}
        disable={disable}
      />
    </Flex>
    <Flex direction="column">{child}</Flex>
  </Flex>
);

const DateRangePicker = ({ selectedDates, onChange, onCancel, disable = false }: DatePickerProps): ReactElement => {
  const [dateError, setDateError] = useState(false);
  const pickerRef = useRef<ReactDatePicker>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onApply = (): void => {
    if (startDate > endDate) setDateError(true);
    else onChange(startDate.toISOString(), endDate.toISOString());
  };

  useEffect(() => {
    if (selectedDates && selectedDates.length > 0 && selectedDates[0] !== '')
      setStartDate(setTime(new Date(selectedDates[0]), 0, 0, 0));
    if (selectedDates && selectedDates.length > 1 && selectedDates[1] !== '')
      setEndDate(setTime(new Date(selectedDates[1]), 23, 59, 59));
  }, [selectedDates]);

  return (
    <Flex alignItems="center">
      <DatePicker
        open
        ref={pickerRef}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        disabledKeyboardNavigation
        onChange={dates => {
          if (!disable) {
            setStartDate(setTime((dates as Date[])?.[0], 0, 0, 0));
            setEndDate(setTime((dates as Date[])?.[1], 23, 59, 59));
          }
        }}
        closeOnScroll
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        dateFormat="dd-MM-yyyy"
        shouldCloseOnSelect={false}
        calendarContainer={({ className, children }: CalendarContainerTypes) => (
          <CalendarContainer
            className={className}
            child={children}
            startDate={startDate?.toLocaleDateString()?.slice(0, 10)}
            endDate={endDate?.toLocaleDateString()?.slice(0, 10)}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDateError={setDateError}
            disable={disable}
          />
        )}
        disabled={disable}
      >
        {dateError && (
          <Box fontSize="xs" color="red.500" pt={2}>
            Please provide a valid date range
          </Box>
        )}
        <Flex alignItems="center" justifyItems="center" mt={3}>
          <Button
            type="button"
            bg="blue.100"
            borderRadius={8}
            fontSize="sm"
            fontWeight={300}
            _hover={{ opacity: '0.85' }}
            w="49%"
            mr={2}
            onClick={() => {
              setStartDate(new Date(selectedDates[0]));
              setEndDate(new Date(selectedDates[1]));
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            borderRadius={8}
            fontSize="sm"
            fontWeight={300}
            _hover={{ opacity: '0.85' }}
            w="49%"
            onClick={onApply}
          >
            Apply
          </Button>
        </Flex>
      </DatePicker>
    </Flex>
  );
};

export default DateRangePicker;
