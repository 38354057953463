import { atom } from 'recoil';
import { Conditions, ConditionsType } from '../types';

const initialData = {
  [ConditionsType.accept]: [],
  [ConditionsType.reject]: [],
  [ConditionsType.manual_review]: [],
};
export const ConditionsAtom = atom({
  key: 'conditions',
  default: initialData as Conditions,
});
