import React, { ReactElement, useState } from 'react';
import { Text } from '@chakra-ui/react';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import AddServiceModal from './AddServiceModal';
import AddParamsModal from './AddParamsModal';
import AddTagModal from './AddTagModal';
import AddEndStateModal from './AddEndStateModal';
import { Service, StepDetails, ModifyNodeDetails, NodeKind, Inputs } from '../types';

export const AddStepModal = ({
  addStepType,
  onCloseModal,
  onAddStep,
  nodeDetails,
}: {
  addStepType: string;
  onCloseModal: () => void;
  onAddStep: (type: string, data: Service | StepDetails | Inputs[], action: string) => void;
  nodeDetails: ModifyNodeDetails;
}): ReactElement => {
  const [modifyNodeData, setModifyNodeData] = useState<Service>();
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);

  const handleAddStep = (type: string, details: Service, action: string): void => {
    if (action === 'replace') {
      setShowEditConfirmation(true);
      setModifyNodeData({
        type,
        ...details,
        action,
      });
    } else onAddStep(type, details, action);
  };

  const handleAddParam = (type: string, details: Inputs[], action: string): void => {
    onAddStep(type, details, action);
  };

  const handleEditStep = (): void => {
    const details = { ...modifyNodeData };
    onAddStep(modifyNodeData?.type || '', details as Service, modifyNodeData?.action || '');
  };

  const getConfirmationBody = (): ReactElement => {
    return (
      <Text fontSize="sm" fontWeight="light">
        {getKind() === 'tag' ? (
          <>
            Are you sure you want to change <b>&apos;{nodeDetails?.oldName}&apos;</b> tag?
          </>
        ) : (
          <>
            Are you sure you want to change <b>&apos;{nodeDetails?.oldName}&apos;</b> to{' '}
            <b>&apos;{modifyNodeData?.displayName}&apos;</b>?
          </>
        )}{' '}
        This will remove &apos;{nodeDetails?.oldName}&apos; {getKind()} from all the conditions that have been defined
        in the workflow. You will have have to redefine all the conditions again.
      </Text>
    );
  };

  const getKind = (): string => {
    switch (modifyNodeData?.type?.toLowerCase()) {
      case NodeKind.tag: {
        return 'tag';
      }
      default: {
        return 'service';
      }
    }
  };

  if (addStepType === NodeKind.service && nodeDetails.action === 'replace' && !showEditConfirmation) {
    return (
      <AddServiceModal
        onClose={(data, action) => handleAddStep('source', data, action)}
        onCancel={onCloseModal}
        nodeDetails={nodeDetails}
      />
    );
  }

  if (addStepType === NodeKind.service && !showEditConfirmation)
    return (
      <AddParamsModal
        onClose={(data, action) => handleAddParam('source', data as Inputs[], action)}
        nodeDetails={nodeDetails}
        onCancel={onCloseModal}
      />
    );
  if (addStepType === NodeKind.tag && !showEditConfirmation)
    return (
      <AddTagModal
        onClose={(name, color, action) => handleAddStep('tag', { name, color }, action)}
        onCancel={onCloseModal}
        nodeDetails={nodeDetails}
      />
    );
  if (showEditConfirmation)
    return (
      <ConfirmationModal
        headerText={`Change ${getKind()}`}
        bodyText={getConfirmationBody()}
        onClose={handleEditStep}
        onCancel={onCloseModal}
        primaryButtonText="Update"
        secondaryButtonText="Cancel"
      />
    );
  return (
    <AddEndStateModal
      onClose={(name, action) => onAddStep('end', { name }, action)}
      onCancel={onCloseModal}
      nodeDetails={nodeDetails}
    />
  );
};

export default AddStepModal;
