/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-extraneous-dependencies
import { cloneDeep } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NestedCondition } from '../../capabilitiesV2/workflow-creation/components/workflow-properties/types';
import { ConditionsAtom } from '../states/conditionsAtom';
import { ConditionsType } from '../types';
import { addObjectAtPath, findObjectAtPath, removeObjectAtPath, updateObject } from '../utils/utils';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useConditions() {
  const conditionsBlock = useRecoilValue(ConditionsAtom);
  const setConditionsBlock = useSetRecoilState(ConditionsAtom);
  const addConditions = (pathArray: (string | number)[], type: ConditionsType, objToUpdate: NestedCondition): void => {
    const _conditions = cloneDeep(conditionsBlock);
    const tempConditions = _conditions[type][0].when[pathArray[0]];
    if (Array.isArray(tempConditions)) {
      const _tempConditions = { [pathArray[0]]: [...tempConditions] } as NestedCondition;
      const updatedConditions = addObjectAtPath(_tempConditions, pathArray, objToUpdate);
      setConditionsBlock(prevConditions => ({
        ...prevConditions,
        [type]: [{ when: updatedConditions }],
      }));
    }
  };

  const updateConditions = (
    pathArray: (string | number)[],
    type: ConditionsType,
    objToUpdate: NestedCondition,
  ): void => {
    const _conditions = cloneDeep(conditionsBlock);
    if (!pathArray.length) {
      setConditionsBlock(prevConditions => ({
        ...prevConditions,
        [type]: [{ when: objToUpdate }],
      }));
    }
    const tempConditions = _conditions[type][0].when[pathArray[0]];
    if (Array.isArray(tempConditions)) {
      const _tempConditions = { [pathArray[0]]: [...tempConditions] } as NestedCondition;
      const updatedObject = updateObject(_tempConditions, pathArray, objToUpdate);
      setConditionsBlock(prevConditions => ({
        ...prevConditions,
        [type]: [{ when: updatedObject }],
      }));
    }
  };

  // eslint-disable-next-line consistent-return
  function getCurrentConditions(pathArray: (string | number)[], type: ConditionsType): any {
    const _conditions = cloneDeep(conditionsBlock);
    if (!pathArray.length) {
      return _conditions[type][0].when;
    }
    const tempConditions = _conditions[type][0].when[pathArray[0]];
    if (Array.isArray(tempConditions)) {
      const _tempConditions = { [pathArray[0]]: [...tempConditions] } as NestedCondition;
      const objectAtPath = findObjectAtPath(_tempConditions, pathArray);
      return objectAtPath;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function deleteConditions(pathArray: (string | number)[], type: ConditionsType) {
    const _conditions = cloneDeep(conditionsBlock);
    const tempConditions = _conditions[type][0].when[pathArray[0]];
    if (Array.isArray(tempConditions)) {
      const _tempConditions = { [pathArray[0]]: [...tempConditions] } as NestedCondition;
      const objectAtPath = findObjectAtPath(_tempConditions, pathArray.slice(0, pathArray.length - 2));
      if (objectAtPath?.length === 2 && pathArray.length === 3) {
        setConditionsBlock(prevConditions => ({
          ...prevConditions,
          [type]: [],
        }));
        return;
      }
      const updatedPathArray =
        objectAtPath?.length === 2
          ? pathArray.slice(0, pathArray.length - 3)
          : pathArray.slice(0, pathArray.length - 1);

      const updatedCondition = removeObjectAtPath(_tempConditions, updatedPathArray);
      setConditionsBlock(prevConditions => ({
        ...prevConditions,
        [type]: [{ when: updatedCondition }],
      }));
    }
  }

  return { updateConditions, getCurrentConditions, addConditions, deleteConditions };
}

export default useConditions;
