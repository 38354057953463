import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

interface Subscription {
  active: boolean;
  balanceLeft: number;
  endTimestamp: number;
  invoiceId: string;
  merchantId: string;
  planId: string;
  requestId: string;
  startTimestamp: number;
  statusCode: number;
  timestamp: number;
}

export const useGetSubscription = (): UseQueryResult<Subscription, AxiosError> => {
  return useQuery('get-subscription', getSubscription(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

const getSubscription = () => {
  return async () => {
    const url = window.location.href.includes('stg1')
      ? `${process.env.REACT_APP_STG1_URL}/suppliers/subscription`
      : '/suppliers/subscription';
    const res = await bureauPlatformApi.get<Subscription>(url);
    return res.data;
  };
};
export default useGetSubscription;
