import React from 'react';
import { Cell, Row } from 'react-table';
import { Tr, Td, TableRowProps } from '@chakra-ui/react';

export const TableRow = ({
  row,
  onCellClick,
  onRowClick,
  rowIndex,
  fontSize,
  ...rest
}: {
  row: Row<object>;
  onCellClick?: (e: React.MouseEvent, cell: Cell, index: number) => void;
  onRowClick?: (e: React.MouseEvent, row: Row, index: number) => void;
  rowIndex: number;
} & TableRowProps): React.ReactElement => {
  const bg = row.isSelected ? 'blue.100' : 'white';
  const rowProps = row.getRowProps();
  const { cells } = row;
  return (
    <Tr
      {...rowProps}
      bg={bg}
      {...rest}
      {...(onRowClick && { cursor: 'pointer', onClick: e => onRowClick(e, row, rowIndex) })}
      _hover={{ bg: 'lavender.100' }}
      height="56px"
      borderColor="blue.100"
      borderWidth="1px"
    >
      {cells.map((cell: Cell, index: number) => {
        const cellProps = cell.getCellProps();
        return (
          <Td
            {...cellProps}
            key={cellProps.key}
            {...(onCellClick &&
              ((cells[0].column.id === 'selection' && index === 1) ||
                index === 0 ||
                index === 1 ||
                index === 2 ||
                index === 3 ||
                index === 4) && {
                cursor: 'pointer',
                onClick: e => onCellClick(e, cell, index),
              })}
            fontSize={fontSize}
            fontWeight="light"
            color="gray.700"
            maxW="290px"
            isTruncated
            verticalAlign="center"
          >
            {cell.render('Cell')}
          </Td>
        );
      })}
    </Tr>
  );
};
