import React, { ReactElement } from 'react';
import { Flex, Text, Image } from '@chakra-ui/react';

export const EmptyTableBody = ({
  title,
  customEmptyTableBody,
  showCustomEmptyTableBody,
}: {
  title?: string;
  showCustomEmptyTableBody?: boolean;
  customEmptyTableBody?: ReactElement;
}): ReactElement => {
  if (showCustomEmptyTableBody && customEmptyTableBody) return customEmptyTableBody;
  return (
    <Flex w="full" h="80%" justifyContent="center" alignSelf="center" alignItems="center" direction="column" pt={6}>
      <Image src="/assets/icons/no-data.png" h="100" w="100" />
      <Text fontWeight="normal" fontSize="md" color="gray.400" pt={2}>
        You have no {title} yet
      </Text>
    </Flex>
  );
};
