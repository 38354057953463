import { EntryCollection } from 'contentful';
import { contentfulApi } from '@/contentfulApi';

const useGetServiceDescriptions = (): { getServiceDescriptions: () => Promise<EntryCollection<unknown> | null> } => {
  const getServiceDescriptions = async (): Promise<EntryCollection<unknown> | null> => {
    const entries = await contentfulApi.getEntries({
      content_type: 'capabilities',
      select: 'fields',
    });
    return entries;
  };

  return { getServiceDescriptions };
};

export default useGetServiceDescriptions;
