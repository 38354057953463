import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { createWorkflowRenderData } from '../utils';
import { WorkflowRenderObj } from '../types';
import { WorkflowAtom } from '../states/workflowAtom';

interface UseCreateWorkFlowDataReturnType {
  data: WorkflowRenderObj[];
  setData: (workflowObject: WorkflowRenderObj[]) => void;
}

export function useCreateWorkflowRendererData(): UseCreateWorkFlowDataReturnType {
  const workflowObject = useRecoilValue(WorkflowAtom);
  const [workflowRenderData, setWorkflowRenderData] = useState<WorkflowRenderObj[]>(() =>
    createWorkflowRenderData(workflowObject),
  );

  useEffect(() => {
    setWorkflowRenderData(() => createWorkflowRenderData(workflowObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowObject.length]);

  return { data: workflowRenderData, setData: setWorkflowRenderData };
}
