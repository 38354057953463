import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { TransactionDetail } from '@/screens/transactions/transaction-details/types';

interface TryOutRequest {
  workflowId?: string;
  templateId?: string;
  data: {
    [key: string]: string | boolean | string[];
  };
}

export const useTryOut = (
  options?: UseMutationOptions<TransactionDetail, AxiosError, TryOutRequest>,
): UseMutationResult<TransactionDetail, AxiosError, TryOutRequest> => useMutation(tryOut, options);

const tryOut = async (data: TryOutRequest): Promise<TransactionDetail> => {
  const url = window.location.href.includes('stg1')
    ? `${process.env.REACT_APP_STG1_URL}/transactions` || ''
    : '/transactions';
  const res = await bureauPlatformApi.post<TransactionDetail>(url, data);
  return res.data;
};
