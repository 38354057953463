import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

import { UpdateWorkflowDataV2 } from '../types';

const useUpdateWorkflow = (
  options?: UseMutationOptions<UpdateWorkflowDataV2, AxiosError, UpdateWorkflowDataV2>,
): UseMutationResult<UpdateWorkflowDataV2, AxiosError, UpdateWorkflowDataV2> => {
  return useMutation(updateWorkflow, options);
};

const updateWorkflow = async (data: UpdateWorkflowDataV2): Promise<UpdateWorkflowDataV2> => {
  const { data: response } = await bureauPlatformApi.patch<UpdateWorkflowDataV2>(
    `/workflows/${data.id}`,
    data.workflow,
  );
  return response || data;
};

export default useUpdateWorkflow;
