import { RichTextContent } from 'contentful';

export type WorkflowType = 'workflows' | 'templates';

export type WorkflowList = {
  [key in WorkflowType]: Workflow[];
} & {
  count: number;
};

export interface Workflow {
  name: string;
  workflowId: string;
  templateId: string;
  createdAt: number;
  updatedAt: number;
  createdBy?: string;
  updatedBy?: string;
  releaseType?: string;
  isDemo?: boolean;
}

export interface WorkflowFilterField {
  outcome?: string[];
  createdAt?: string[];
}

export interface WorkflowListContentProps {
  filterFields: WorkflowFilterField;
  data: Workflow[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  searchTerm: string;
  onCreateWorkflow: () => void;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  setSelectedAction: (action: string) => void;
  setWorkflowDetails: (action: Workflow) => void;
  refetch: () => void;
}

export interface ListData {
  pageParams: unknown[];
  pages: unknown[];
  listData: Workflow[];
  count: number;
}

export enum ToastMessage {
  WORKFLOW_UPDATED_MESSAGE = 'Workflow Updated successfully',
  WORKFLOW_DELETED_MESSAGE = 'Workflow deleted successfully',
  ERROR_MESSAGE = 'Some error occurred. Please try after some time.',
  EMPTY_SELECTED_LIST_MESSAGE = 'No Workflow selected',
  OVER_SIZED_LIST_MESSAGE = 'Select a single workflow',
}

export interface ContentfulTemplate {
  fields: {
    id: string;
    title: string;
    shortDescription: string;
    supportedCountry: string[];
    tags: string[];
    documentation: RichTextContent;
    thumbnail: {
      fields: {
        file: {
          url: string;
        };
      };
    };
  };
}
