import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Flex,
  ModalFooter,
  Button,
  Box,
  Text,
  Textarea,
  Image,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CaretDown, CaretUp } from 'phosphor-react';
import useDebounce from '@/hooks/useDebounce';
import { REQUIRED_FIELD } from '@/constants/constants';
import { colors } from '@/utils/colors';
import { prettifyJsonString } from './helpers';
import './styles.css';

interface RefObject extends Monaco.editor.ICodeEditor {
  _domElement?: HTMLElement;
}

const CopyPasteJsonModal = ({
  onCancel,
  onClose,
  nameExistError,
  setNameExistError,
  showCopyPasteJsonPopup,
  apiError,
}: {
  onCancel: () => void;
  onClose: (name: string, json: string) => void;
  nameExistError: boolean;
  setNameExistError: (val: boolean) => void;
  showCopyPasteJsonPopup: boolean;
  apiError: boolean;
}): ReactElement => {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [jsonValue, setJsonValue] = useState('');
  const [errors, setErrors] = useState<{ startLineNumber: number; startColumn: number; message: string }[]>([]);
  const [EditorComponent, setEditorComponent] = useState<typeof import('@monaco-editor/react').default>();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    workflowName: Yup.string().required(REQUIRED_FIELD),
  });
  const editorRef = useRef<RefObject | null>(null);

  const handleEditorPrettify = useCallback(() => {
    editorRef.current?.getAction('editor.action.formatDocument').run();
  }, []);

  const handleEditorDidMount: import('@monaco-editor/react').OnMount = editor => {
    editorRef.current = editor;
    editor.getModel()?.updateOptions({ tabSize: 2, insertSpaces: false });
    // need to use formatted prettify json string
    jsonValue && handleEditorUpdateValue(prettifyJsonString(jsonValue));
  };

  const handleEditorUpdateValue = useCallback((json?: string) => {
    const editor = editorRef.current;
    if (!editor) return;
    editor.setValue(json || '');
    json && editor.getAction('editor.action.formatDocument').run();
  }, []);

  const handleEditorValidation: import('@monaco-editor/react').OnValidate = useCallback(markers => {
    const errorObject = markers.map(({ startLineNumber, startColumn, message }) => {
      return { startLineNumber, startColumn, message };
    });
    setErrors(errorObject);
  }, []);

  const jsonData = useDebounce(jsonValue, 800);

  useEffect(() => {
    import('@monaco-editor/react').then(data => {
      setEditorComponent(data.default);
    });
  }, []);

  useEffect(() => {
    if (jsonData) handleEditorPrettify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData]);

  useEffect(() => {
    if (nameExistError || apiError) setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameExistError, apiError]);

  useEffect(() => {
    toast({
      position: 'bottom-right',
      render: JsonErrorToast,
      duration: null,
      isClosable: true,
    });
    return () => toast.closeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (!showCopyPasteJsonPopup) {
      toast.closeAll();
      setTextAreaValue('');
      setErrors([]);
      setJsonValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCopyPasteJsonPopup]);

  const renderEditorBlock = useMemo((): ReactElement => {
    if (textAreaValue && EditorComponent) {
      return (
        <EditorComponent
          language="json"
          value={jsonValue}
          options={{
            automaticLayout: true,
            autoClosingBrackets: 'always',
            autoClosingQuotes: 'always',
            formatOnPaste: true,
            formatOnType: true,
            scrollBeyondLastLine: false,
            renderLineHighlight: 'none',
            overviewRulerLanes: 0,
            wordWrap: 'on',
            fontFamily: 'Lexend',
            minimap: {
              enabled: false,
            },
            guides: {
              indentation: false,
            },
          }}
          onMount={handleEditorDidMount}
          onChange={data => setJsonValue(data || '')}
          onValidate={handleEditorValidation}
        />
      );
    }
    return (
      <Textarea
        height="100%"
        border="none"
        outline="none"
        resize="none"
        value={textAreaValue}
        placeholder="Paste JSON"
        _focus={{ outline: 'none' }}
        onChange={e => {
          setTextAreaValue(e.target.value);
          setJsonValue(e.target.value);
        }}
        _placeholder={{ color: 'gray.500', fontSize: 'sm' }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaValue, EditorComponent, jsonValue]);

  const handleServicesScroll = (event: Event): void => {
    event.stopPropagation();
  };

  useEffect(() => {
    const scrollContainer = document.querySelector('.error-block');
    scrollContainer?.addEventListener('wheel', handleServicesScroll);
    return () => {
      scrollContainer?.removeEventListener('wheel', handleServicesScroll);
    };
  });

  const JsonErrorToast = (): ReactElement => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        {errors?.length > 0 && (
          <Box bg="white" borderWidth={1} borderColor="blue.100" borderRadius="lg" px={3} py={3}>
            <Flex mb={3} justifyContent="space-between" alignItems="center">
              <Flex>
                <Image src="/assets/icons/error_warning.svg" borderRadius="full" mr="2" />
                <Box color="gray.800" mr="2">
                  Error
                </Box>
                <Text color="gray.600">({errors.length})</Text>
              </Flex>

              <Box onClick={() => setIsExpanded(!isExpanded)} cursor="pointer">
                {isExpanded ? (
                  <CaretUp size={16} color={colors.gray[600]} weight="bold" />
                ) : (
                  <CaretDown size={16} color={colors.gray[600]} weight="bold" />
                )}
              </Box>
            </Flex>
            <Box className="error-block" overflow="auto" {...(!isExpanded ? { maxH: '200px' } : { h: '91vh' })}>
              {errors.map(error => (
                <Flex key={`${error.message}${error.startLineNumber}${error.startColumn}`} mb={3}>
                  <Flex direction="column" ml={2}>
                    <Box color="gray.900" fontSize="sm" fontWeight="light">
                      {error.message}
                    </Box>
                    <Box
                      color="gray.500"
                      fontSize="xs"
                      fontWeight="light"
                    >{`[Code ${error.startLineNumber}, Structure ${error.startColumn} ]`}</Box>
                  </Flex>
                </Flex>
              ))}
            </Box>
          </Box>
        )}
      </>
    );
  };

  if (showCopyPasteJsonPopup) {
    return (
      <Modal isOpen onClose={onCancel} isCentered>
        <ModalOverlay bg="transparentGray.100" />
        <ModalContent borderRadius="lg" maxW="496px" minH="602px">
          <ModalHeader fontSize="md" color="gray.900" fontWeight="500" p={6}>
            Copy/Paste JSON
          </ModalHeader>
          <ModalCloseButton top={4} _focus={{ border: 'none' }} />
          <Formik
            initialValues={{
              workflowName: '',
            }}
            onSubmit={({ workflowName }) => {
              onClose(workflowName, jsonValue);
              setIsLoading(true);
            }}
            validationSchema={validationSchema}
          >
            {({ values, isValid, dirty, handleChange }) => {
              return (
                <Form>
                  <ModalBody maxH="438px" overflow="auto" className="copy-modal-body" py={0}>
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.800" fontWeight="400">
                        Workflow Name
                      </FormLabel>
                      <Input
                        name="workflowName"
                        placeholder="Enter workflow name"
                        value={values?.workflowName}
                        fontSize="sm"
                        borderColor="gray.300"
                        onChange={e => {
                          handleChange(e);
                          setNameExistError(false);
                        }}
                      />
                      {nameExistError && (
                        <Text fontSize="xs" color="red.500">
                          A workflow with the given name already exists
                        </Text>
                      )}
                    </FormControl>
                    <FormControl mt="6">
                      <FormLabel fontSize="sm" color="gray.800" fontWeight="normal">
                        JSON
                      </FormLabel>
                      <Flex
                        height="316px"
                        borderWidth={1}
                        borderColor={errors.length ? 'red.300' : 'gray.300'}
                        borderRadius="lg"
                        p={2}
                      >
                        {renderEditorBlock}
                      </Flex>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter py={7}>
                    <Button
                      h="38px"
                      w="124px"
                      color="blue.800"
                      bg="purple.300"
                      fontSize="sm"
                      fontWeight="300"
                      _hover={{ opacity: '0.8' }}
                      mr={3}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      h="38px"
                      w="124px"
                      color={!(isValid && dirty) || errors.length > 0 ? 'gray.900' : 'white'}
                      bg={!(isValid && dirty) || errors.length > 0 ? 'gray.100' : 'blue.500'}
                      disabled={!(isValid && dirty) || errors.length > 0 || nameExistError}
                      fontSize="sm"
                      fontWeight="300"
                      _hover={
                        !(isValid && dirty) || errors.length > 0 ? {} : { opacity: '0.8', background: 'blue.600' }
                      }
                    >
                      Import
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalContent>
      </Modal>
    );
  }
  return <></>;
};
export default CopyPasteJsonModal;
