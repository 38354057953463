import React, { ReactElement, useMemo } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { Box, Flex, Link, LinkProps, Text } from '@chakra-ui/react';
import { BureauIcon } from './NavbarIcons';
import { NavItemTypes } from './types';

const NavigationBar = ({ navItems }: { navItems: NavItemTypes[] }): ReactElement => {
  const topListItems = useMemo(() => navItems.filter(({ inBottomList }) => !inBottomList), [navItems]);
  const bottomListItems = useMemo(() => navItems.filter(({ inBottomList }) => inBottomList), [navItems]);

  return (
    <Flex h="full" direction="column" width="20" py="6" gridGap="8" bg="purple.800" alignItems="center" color="gray.50">
      <Box py="2.5">
        <BureauIcon />
      </Box>
      {/* Navbar */}
      <Flex as="nav" h="full" w="full" direction="column" justifyContent="space-between" overflow="hidden">
        {/* Top list of Navbar */}
        <Flex w="full" direction="column" gridGap="6" grow={1} overflowY="auto">
          {topListItems.map(item => (
            <NavItem key={item.id} {...item} />
          ))}
        </Flex>
        {/* Bottom list of Navbar */}
        <Box pt="4" borderTop="1px solid" borderColor="purple.600">
          {bottomListItems.map(item => (
            <NavItem key={item.id} {...item} />
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};

const NavItem = ({ icon, title, path, ...rest }: NavItemTypes): ReactElement => {
  const match = useRouteMatch({
    path,
  });

  // Clone the icon element and pass the weight prop
  const iconWithProps = useMemo(() => React.cloneElement(icon, { weight: match ? 'fill' : undefined }), [icon, match]);

  const selectedStyles: LinkProps = match
    ? {
        borderColor: 'white.100',
        _before: {
          content: '""',
          pos: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          bg: 'white.100',
          opacity: 0.1,
        },
      }
    : {};

  return (
    <Link
      as={RouterLink}
      to={path}
      w="full"
      display="flex"
      flexDirection="column"
      py="2"
      gridGap="1"
      alignItems="center"
      color="white"
      borderLeft="3px solid"
      borderColor="transparent"
      pos="relative"
      _hover={{ color: match ? 'unset' : 'purple.200' }}
      _focus={{ boxShadow: 'none' }}
      {...selectedStyles}
    >
      {iconWithProps}
      <Text fontSize="10px" lineHeight="140%" textAlign="center">
        {title}
      </Text>
    </Link>
  );
};

export default NavigationBar;
