import React from 'react';
import { Bank, Buildings, GameController, Heart, Money, ShoppingCartSimple } from 'phosphor-react';
import { colors } from '@/utils/colors';

export const ALL_INDUSTRIES = {
  FINANCE: 'finance',
  FINTECH: 'fintech',
  NEO_BANK: 'neo bank',
  MATRIMONY: 'matrimony and dating',
  BETTING: 'gaming/betting',
  GAMING: 'gaming',
  ECOMMERCE: 'ecommerce',
  OTHERS: 'others',
};

export const ALL_COUNTRIES = {
  INDIA: 'india',
  USA: 'usa',
  INDONESIA: 'indonesia',
  BRAZIL: 'brazil',
};

const FINANCE_ICON = <Money size={32} color={colors.gray[900]} weight="light" />;
const NEO_BANK_ICON = <Bank size={32} color={colors.gray[900]} weight="light" />;
const MATRIMONY_ICON = <Heart size={32} color={colors.gray[900]} weight="light" />;
const GAMING_AND_BETTING_ICON = <GameController size={32} color={colors.gray[900]} weight="light" />;
const ECOMMERCE_ICON = <ShoppingCartSimple size={32} color={colors.gray[900]} weight="light" />;
export const OTHERS_ICON = <Buildings size={32} color={colors.gray[900]} weight="light" />;

export const getIndustryIcon = new Map([
  [ALL_INDUSTRIES.FINANCE, FINANCE_ICON],
  [ALL_INDUSTRIES.FINTECH, FINANCE_ICON],
  [ALL_INDUSTRIES.NEO_BANK, NEO_BANK_ICON],
  [ALL_INDUSTRIES.MATRIMONY, MATRIMONY_ICON],
  [ALL_INDUSTRIES.GAMING, GAMING_AND_BETTING_ICON],
  [ALL_INDUSTRIES.BETTING, GAMING_AND_BETTING_ICON],
  [ALL_INDUSTRIES.ECOMMERCE, ECOMMERCE_ICON],
  [ALL_INDUSTRIES.OTHERS, OTHERS_ICON],
]);
