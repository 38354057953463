import React, { ReactElement, useMemo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { X } from 'phosphor-react';
import { Box, Flex, Image, Icon } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import { AddedServiceIds, SelectedPosition, SelectedService } from '../states/workflowChart';
import { Service } from '../types';
import ServicesList from './ServicesList';

const AddNodePanel = ({ addStep }: { addStep: (id: string) => void }): ReactElement => {
  const location = useLocation();
  const resetSelectedPosition = useResetRecoilState(SelectedPosition);
  const setSelectedService = useSetRecoilState(SelectedService);
  const [serviceIds, setServiceIds] = useRecoilState(AddedServiceIds);

  const onAddService = (service: Service): void => {
    const cloneIds = [...serviceIds];
    if (service.id && cloneIds.indexOf(service.id) < 0) cloneIds.push(service.id);
    setServiceIds(cloneIds);
    setSelectedService({ ...service, supplierId: service.id });
    onAddStep('source');
  };

  const onAddStep = (type: string): void => {
    resetSelectedPosition();
    addStep(type);
  };

  const renderHeader = useMemo(
    (): ReactElement => (
      <Flex bg="gray.200" w="full" p={3} borderRadius="8px 8px 0 0" alignItems="center" justifyContent="space-between">
        <Flex direction="column">
          <Box color="gray.800" fontSize="md" fontWeight="500">
            Add Action
          </Box>
          <Box color="gray.700" fontSize="xs" fontWeight="light">
            An event that a workflow performs after it starts
          </Box>
        </Flex>
        <Icon
          boxSize={5}
          as={X}
          weight="regular"
          color="gray.500"
          mr={2}
          cursor="pointer"
          _hover={{ opacity: '0.8' }}
          onClick={resetSelectedPosition}
        />
      </Flex>
    ),
    [resetSelectedPosition],
  );

  const renderNodeTypes = useMemo(
    (): ReactElement => (
      <Flex direction="column" w="45%" bg="gray.50" h="full" borderRadius="0 0 8px 0">
        <Flex
          alignItems="flex-start"
          p={3}
          m={2}
          cursor="pointer"
          _hover={{ bg: 'gray.200', borderRadius: 'lg' }}
          onClick={() => onAddStep('AWAIT')}
        >
          <Image src="/assets/icons/await.svg" mr={2} />
          <Flex direction="column">
            <Box color="gray.900" fontSize="sm" fontWeight="400">
              Awaits
            </Box>
            <Box color="gray.600" fontSize="xs" fontWeight="light">
              Pauses the execution and waits for user to add input.
            </Box>
          </Flex>
        </Flex>
        <Box borderBottomWidth={1} borderColor="gray.100" />
        <Flex
          alignItems="flex-start"
          p={3}
          m={2}
          cursor="pointer"
          _hover={{ bg: 'gray.200', borderRadius: 'lg' }}
          onClick={() => onAddStep('tag')}
        >
          <Image src="/assets/icons/tag.svg" mr={2} />
          <Flex direction="column">
            <Box color="gray.900" fontSize="sm" fontWeight="400">
              Tag
            </Box>
            <Box color="gray.600" fontSize="xs" fontWeight="light">
              Intermediate steps that can be applied when a particular condition is met.
            </Box>
          </Flex>
        </Flex>
        {location.pathname.includes('workflows') && (
          <>
            <Box borderBottomWidth={1} borderColor="gray.100" />
            <Flex
              alignItems="flex-start"
              p={3}
              m={2}
              cursor="pointer"
              _hover={{ bg: 'gray.200', borderRadius: 'lg' }}
              onClick={() => onAddStep('end_state')}
            >
              <Image src="/assets/icons/end.svg" mr={2} />
              <Flex direction="column">
                <Box color="gray.900" fontSize="sm" fontWeight="400">
                  End State
                </Box>
                <Box color="gray.600" fontSize="xs" fontWeight="light">
                  End States are the final outcomes of a transaction.
                </Box>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addStep, resetSelectedPosition],
  );

  return (
    <Flex
      direction="column"
      h="full"
      borderRadius="lg"
      boxShadow="0px 4px 10px rgba(17, 25, 99, 0.12)"
      onClick={e => e.stopPropagation()}
    >
      {renderHeader}
      <Flex h="calc(100% - 66px)" borderRadius="0 0 8px 8px">
        <Flex p={5} pb={3} w="55%" direction="column">
          <Flex pb={5}>
            <Image w="40px" h="40px" src="/assets/icons/service.svg" mr={2} />
            <Flex direction="column">
              <Box color="gray.900" fontSize="sm" fontWeight="400">
                Services
              </Box>
              <Box color="gray.600" fontSize="xs" fontWeight="light">
                A Data sources that provide rich information about the input attributes.
              </Box>
            </Flex>
          </Flex>
          <ServicesList serviceIds={serviceIds} onAddService={onAddService} />
        </Flex>
        {renderNodeTypes}
      </Flex>
    </Flex>
  );
};

export default AddNodePanel;
