import { Dispatch, SetStateAction } from 'react';

export interface Webhooks {
  id: string;
  description: string;
  url: string;
  version: number;
  disabled: boolean;
  filterTypes: Array<string>;
  createdAt: string;
  updatedAt: string;
}

export interface WebhooksApiResponse {
  data?: Webhooks[];
  iterator: string;
  prevIterator: string;
}

export interface WebhooksContentProps {
  data: Webhooks[];
  isLoading: boolean;
  isFetching: boolean;
  searchTerm?: string;
  count?: number;
  limit?: string;
  setLimit?: (limit: string) => void;
  showSidebar?: boolean;
  showCreateWebhookPopUp: Dispatch<SetStateAction<boolean>>;
  onWebhookToggle: (val: string) => void;
}

export interface ListData {
  listData: Webhooks[];
}

export interface CreateWebhookResponse {
  url: string;
  description: string;
  disabled: boolean;
  filterTypes: Array<string>;
}

export interface RetryWebhookRequest {
  url: string;
  description: string;
  disabled: boolean;
  filterTypes: Array<string>;
  webhookId: string;
  messageId: string;
}

export interface WebhookCreatedResponse {
  id: string;
  description: string;
  url: string;
  disable: boolean;
  filterTypes: Array<string>;
  createdAt: string;
  updatedAt: string;
}

export enum ToastMessage {
  ERROR_MESSAGE = 'Some error occurred. Please try after some time.',
  WEBHOOK_CREATED_MESSAGE = 'Webhook created successfully, Please test the webhook connection',
  WEBHOOK_MODIFIED_MESSAGE = 'Webhook status changed successfully',
  WEBHOOK_DELETED_MESSAGE = 'Webhook deleted successfully',
  WEBHOOK_ARCHIVED_MESSAGE = 'Webhook archived successfully',
  WEBHOOK_UNARCHIVED_MESSAGE = 'Webhook unarchived successfully',
  WEBHOOK_TESTED_MESSAGE = 'Webhook connection successful',
}

export interface SortField {
  field: string;
  order: string;
}

export interface EventMessage {
  events: Array<string>;
  prevIterator: string;
  nextIterator: string;
}

export interface SingleEvent {
  id: string;
  eventType: string;
  url: string;
  webhookId: string;
  disabled: boolean;
}
