import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { GetSupplierInputResponse, Output, Inputs } from '@/screens/capabilitiesV2/workflow-creation/types';

export const useFetchSupplierInputOutput = (
  supplierList: string[],
): UseQueryResult<GetSupplierInputResponse, AxiosError> => {
  return useQuery(['supplier-input-output', supplierList], getAttributes(supplierList), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: data => {
      const inputs = Object.values(data.inputs) as Inputs[];
      const outputs = Object.values(data.outputs) as Output[];
      return {
        inputs,
        outputs,
      };
    },
  });
};

const getAttributes = (supplierList: string[]) => {
  return async () => {
    const list = supplierList.map((item: string) => `"${item}"`).join(',');
    const url = window.location.href.includes('stg1')
      ? `${process.env.REACT_APP_STG1_URL}/suppliers/input-output?supplierList=[${list}]`
      : `/suppliers/input-output?supplierList=[${list}]`;

    const res = await bureauPlatformApi.get<GetSupplierInputResponse>(url);
    return res.data;
  };
};

export default useFetchSupplierInputOutput;
