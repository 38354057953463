import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { OptionType } from '@bureau/components';
import { useBlocklist } from '@/screens/blocklist/queries/useBlocklists';
import { ListData, Blocklist } from '@/screens/blocklist/types';
import useGetInputAttributes from '../queries/useGetInputAttributes';
import useGetOperators from '../queries/useGetOperators';
import { ServicesToTheLeft, TagsToTheLeft } from '../states/workflowChart';
import { tagConstants, tagOperators } from '../constants';
import { FunctionalOperator } from '../components/workflow-properties/types';
import { Service, Tag, Output, ConditionGroupItem, OperatorData } from '../types';

export interface SelectOptionsType<T = string> {
  label: string;
  value: T;
  name?: string;
  description?: string | undefined;
  type?: string;
}

const useConditionFieldHandler = (): {
  getOperators: (type: string, kind: string) => SelectOptionsType[];
  allowedValues: OptionType[];
  attributes: Output[];
  valueType: string;
  valueFormat: string;
  tagsToLeft: Tag[];
  servicesToLeft: Service[];
  dynamicAttributes: Output[];
  showOperator: boolean;
  setShowOperator: (val: boolean) => void;
  getBlocklist: () => { label: string; value: string; type?: string }[];
  getOperatorData: (val: string) => OperatorData | undefined;
  handleDropdownData: (condition: ConditionGroupItem) => void;
  getIdsDropdownData: (key: string) => Service[] | Output[];
  getTypesDropdownData: (key: string) => Output[];
  getDynamicTypesDropdownData: (key?: string) => Output[];
} => {
  const [attributes, setAttributes] = useState<Output[]>([]);
  const [allowedValues, setAllowedValues] = useState<OptionType[]>([]);
  const [valueType, setValueType] = useState('');
  const [valueFormat, setValueFormat] = useState('');
  const [dynamicAttributes, setDynamicAttributes] = useState<Output[]>([]);
  const [showOperator, setShowOperator] = useState(false);

  const { data: inputAttributes } = useGetInputAttributes();
  const { data: operatorsData } = useGetOperators();
  const { data: blockList, refetch: refetchBlocklist } = useBlocklist(false);

  const { listData } = blockList ? (blockList as ListData) : { listData: [] };

  const servicesToLeft = useRecoilValue(ServicesToTheLeft);
  const tagsToLeft = useRecoilValue(TagsToTheLeft);

  const handleDropdownData = (condition: ConditionGroupItem): void => {
    if (condition.value || condition.isDynamic || condition.isFunctionOperator) {
      setShowOperator(true);
    }
    switch (condition?.kind || condition?.functionItems?.[0]?.kind) {
      case 'source': {
        setServiceRelatedData(condition);
        break;
      }
      case 'input': {
        if (condition?.type || condition.functionItems?.[0]?.type) {
          const attrs = inputAttributes?.find(
            (service: Output) => service.key === (condition.type || condition.functionItems?.[0]?.type),
          );
          if (!condition.functionItems?.[0]?.type) {
            setValueType(attrs?.type || '');
            setValueFormat(attrs?.format || '');
            const values = attrs?.allowedValues
              ? attrs.allowedValues.map(val => ({ label: val.toString(), value: val }))
              : [];
            setAllowedValues(values);
          }
        }
        break;
      }
      default: {
        setTagRelatedData(condition);
      }
    }
    if (condition?.dynamicItem?.kind === 'source') {
      setDynamicServiceRelatedData(condition);
    }
    if (condition?.functionOperator === FunctionalOperator.IS_BLOCKED) refetchBlocklist();
  };

  const setServiceRelatedData = (condition: ConditionGroupItem): void => {
    const services = servicesToLeft;
    if (condition?.id || condition?.functionItems?.[0]?.id) {
      const attrs = condition?.functionItems?.[0]?.id
        ? services?.find((service: Service) => service.id === condition.functionItems[0].id)
        : services?.find((service: Service) => service.id === condition.id);
      setAttributes(attrs?.outputParameters || []);
      if (condition?.type || condition?.functionItems?.[0]?.type) {
        const ops = condition?.type
          ? attrs?.outputParameters?.find((att: Output) => att.key === condition.type)
          : attrs?.outputParameters?.find((att: Output) => att.key === condition?.functionItems?.[0]?.type);
        setValueType(ops?.type || '');
        setValueFormat(ops?.format || '');
        const values = ops?.allowedValues
          ? ops.allowedValues.map(val => ({
              label: val === 'NO_RECORD_FOUND' ? 'NO RECORD FOUND' : val.toString(),
              value: val,
            }))
          : [];
        setAllowedValues(values);
      }
    }
  };

  const setDynamicServiceRelatedData = (condition: ConditionGroupItem): void => {
    const services = servicesToLeft;
    if (condition?.dynamicItem?.id) {
      const attrs = services?.find((ser: Service) => ser.id === condition.dynamicItem?.id);
      setDynamicAttributes(attrs?.outputParameters || []);
    }
  };

  const setTagRelatedData = (condition: ConditionGroupItem): void => {
    const tags: Tag[] = tagsToLeft;
    if (condition?.id) {
      const tag = tags?.find((tg: Tag) => tg.value === condition.id);
      setValueType(tag?.outputs?.[0]?.type || '');
      setValueFormat(tag?.outputs?.[0]?.format || '');
      setAllowedValues(tagConstants);
    }
  };

  const getIdsDropdownData = (key: string): Service[] | Output[] => {
    switch (key) {
      case 'source': {
        return servicesToLeft || [];
      }
      default: {
        return tagsToLeft || [];
      }
    }
  };

  const getTypesDropdownData = (key: string): Output[] => {
    switch (key) {
      case 'source': {
        return attributes || [];
      }
      default: {
        return inputAttributes || [];
      }
    }
  };

  const getDynamicTypesDropdownData = (key?: string): Output[] => {
    switch (key) {
      case 'source': {
        return dynamicAttributes || [];
      }
      default: {
        return inputAttributes || [];
      }
    }
  };

  const getOperators = (type: string, kind: string): SelectOptionsType[] => {
    if (kind === 'tag') return tagOperators;
    const typesList = getTypesDropdownData(kind);
    const selectedType = typesList.find(item => item.value === type);
    const tempOperators = operatorsData?.operators.filter(item =>
      item.supportedDataTypes.includes(selectedType?.type || ''),
    );
    return (
      tempOperators?.map(item => ({
        label: item.symbol === 'is blocked' ? 'Is in Allow List or Block List' : item.symbol,
        value: item.label,
        name: item.name,
        description: item.description,
        type: item.symbol === 'is blocked' ? 'others' : item.type,
      })) || []
    );
  };

  const getOperatorData = (val: string): OperatorData | undefined => {
    const operatorData = operatorsData?.operators.find(item => item.label === val);
    if (operatorData?.type === 'functional') {
      operatorData.isVariadic = operatorData?.arguments?.[operatorData?.arguments?.length - 1]?.variadic;
    }
    return operatorData;
  };

  const getBlocklist = (): { label: string; value: string; type?: string }[] => {
    const blocklistOptions =
      listData?.map((item: Blocklist) => ({ label: item.name, value: item?.blocklistId || '', type: item.type })) || [];
    return blocklistOptions;
  };

  return {
    allowedValues,
    attributes,
    valueType,
    valueFormat,
    tagsToLeft,
    servicesToLeft,
    dynamicAttributes,
    showOperator,
    setShowOperator,
    getBlocklist,
    getOperatorData,
    getOperators,
    getIdsDropdownData,
    handleDropdownData,
    getTypesDropdownData,
    getDynamicTypesDropdownData,
  };
};

export default useConditionFieldHandler;
