import { atom } from 'recoil';
import { SidebarItemsType } from './types';

export const SidebarItems = atom({
  key: 'sidebarItems',
  default: {
    home: 'Home',
    admin: 'Admin',
    apiKeys: 'API Keys',
    apiLogs: 'API Logs',
    auditLogs: 'Audit Logs',
    bulkVerification: 'Bulk Verification',
    customCapabilities: 'Custom Capabilities',
    dashboard: 'Dashboard ',
    demoApps: 'Demo Apps',
    documentation: 'Documentation',
    entities: 'Entities',
    lists: 'Lists',
    logs: 'Logs',
    sessionLogs: 'Session Logs',
    transactions: 'Transactions',
    users: 'Users',
    webhooks: 'Webhooks',
    workflows: 'Workflows',
    deviceAndBehavior: 'Device and Behavior',
  } as SidebarItemsType,
});
