import React from 'react';
import { Cell } from 'react-table';
import { Copy } from 'phosphor-react';
import { Flex, Text, Tooltip, useClipboard, Box } from '@chakra-ui/react';

export const CopyButtonCell = ({ value, row }: Cell): React.ReactElement => {
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <Tooltip label="copied" isOpen={hasCopied} placement="top">
      <Flex alignItems="center">
        <Text fontSize="xs" pr={2} maxW={330} isTruncated>
          {value}
        </Text>
        <Box w={4}>{value ? <Copy onClick={onCopy} cursor="pointer" /> : <></>}</Box>
      </Flex>
    </Tooltip>
  );
};
