/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { SortField } from '../types';

export const getSortField = (sortField?: SortField): string => {
  const sortValue = {
    field: sortField?.field || 'eventTimestamp',
    order: sortField?.order || 'desc',
  };
  return `${JSON.stringify([sortValue])}`;
};

const getWebhooks = (pageParam: number, sortField?: SortField, limit = '0', webhookId?: string) => {
  return async () => {
    const res = await bureauPlatformApi.get<any>(`/platform/webhooks/${webhookId}/messages`, {
      params: {
        limit: +limit,
        offset: pageParam * +limit,
        sortBy: getSortField(sortField),
      },
    });
    return res?.data;
  };
};

export const useGetWebhookMessages = (
  sortField?: SortField,
  offset?: number,
  limit?: string,
  webhookId?: string,
): UseQueryResult<any, AxiosError> => {
  return useQuery(
    ['webhooks-message', webhookId, sortField, offset, limit],
    getWebhooks(offset || 0, sortField, limit, webhookId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );
};
