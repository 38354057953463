import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { OrganizationDetails } from '@/screens/admin/types';

export const useFetchUserOrganization = (): UseQueryResult<OrganizationDetails[], AxiosError> => {
  return useQuery<OrganizationDetails[], AxiosError>('organizationlist', fetchUserOrganization(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    enabled: !!bureauPlatformApi.defaults.headers.common.Authorization,
  });
};

const fetchUserOrganization = (): (() => Promise<OrganizationDetails[]>) => {
  return async () => {
    const res = await bureauPlatformApi.get('v1/auth/user/organizations');
    return res.data;
  };
};
