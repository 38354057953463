import {
  // getCompletedTextStyle,
  getErrorTextStyle,
  // getInProgressTextStyle,
  getInQueueTextStyle,
  getLightBgTextStyle,
  getMiscellaneousTextStyle,
  getPausedTextStyle,
  getPendingTextStyle,
  getProcessingTextStyle,
  getSecondaryTextStyle,
  // getStoppedTextStyle,
  getSuccessTextStyle,
  StatusCellStyle,
  getFailedTextStyle,
} from './styles';
import { StatusValue, UserInvitationStatus } from './types';

export const getCellStyle = (val: string): StatusCellStyle => {
  switch (val?.toLowerCase()) {
    case StatusValue.DONE.toLowerCase():
    case StatusValue.ACCEPTED.toLowerCase():
    case StatusValue.SUCCESS.toLowerCase():
    case UserInvitationStatus.ACCEPTED.toLowerCase(): {
      return getSuccessTextStyle();
    }
    case StatusValue.MANUAL_REVIEW.toLowerCase():
    case UserInvitationStatus.INVITED.toLowerCase(): {
      return getSecondaryTextStyle();
    }
    case StatusValue.CANCELLED.toLowerCase(): {
      return getErrorTextStyle();
    }
    case StatusValue.UNKNOWN.toLowerCase(): {
      return getMiscellaneousTextStyle();
    }
    case StatusValue.FAILED.toLowerCase(): {
      return getFailedTextStyle();
    }
    case StatusValue.UPLOADING.toLowerCase(): {
      return getProcessingTextStyle();
    }
    case StatusValue.IN_QUEUE.toLowerCase():
    case StatusValue.PROCESSING.toLowerCase(): {
      return getInQueueTextStyle();
    }
    case StatusValue.AWAITING.toLowerCase(): {
      return getLightBgTextStyle();
    }
    case StatusValue.PENDING_DELIVERY.toLowerCase(): {
      return getPendingTextStyle();
    }
    case StatusValue.PAUSED.toLowerCase(): {
      return getPausedTextStyle();
    }

    default: {
      return getErrorTextStyle();
    }
  }
};
