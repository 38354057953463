/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormatDateCell } from '@/components/paginated-table/table-components/PaginatedTableCells';
import { RetryActionButton } from '@/components/paginated-table/table-components/table-cells/RetryActionButton';

export const messageColumn = (count: number, toggleApikey: any, refetch: () => {}): any[] => [
  {
    Header: 'Event Name',
    accessor: 'eventType',
    disableSortBy: true,
    id: 'eventType',
  },
  {
    Header: 'Event ID',
    accessor: 'id',
    id: 'id',
  },

  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
  },

  {
    Header: 'Created On',
    accessor: 'timestamp',
    Cell: FormatDateCell,
    id: 'createdOn',
  },

  {
    Header: '',
    accessor: 'status',
    Cell: RetryActionButton,
    id: 'retry',
    getProps: { toggleApikey, refetch },
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'url',
    id: 'url',
  },
];
