import { UserRoles } from './types';
import {
  StatusCellStyle,
  getSuccessTextStyle,
  getSecondaryTextStyle,
  getLightBgTextStyle,
  getMiscellaneousTextStyle,
} from './styles';

export const getUserColumnStyle = (value: string): StatusCellStyle => {
  if (value?.includes(UserRoles.ADMIN)) {
    return getSuccessTextStyle();
  }
  if (value?.includes(UserRoles.MEMBER)) {
    return getSecondaryTextStyle();
  }
  if (value?.includes(UserRoles.OWNER)) {
    return getLightBgTextStyle();
  }
  if (value?.includes(UserRoles.SALES)) {
    return {
      bg: 'orange.100',
      color: 'orange.500',
    };
  }
  return getMiscellaneousTextStyle();
};
