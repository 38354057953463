import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { QuickLinkResponse } from '../workflowv2/types';

type DeleteQuickLinkVariables = {
  id: string;
};

const deleteQuickLink = async ({ id }: DeleteQuickLinkVariables): Promise<QuickLinkResponse> => {
  const { data: response } = await bureauPlatformApi.delete<QuickLinkResponse>(`/workflows/quicklink/${id}`);
  return response;
};

const useQuickLinkDelete = (
  options?: UseMutationOptions<QuickLinkResponse, AxiosError, DeleteQuickLinkVariables>,
): UseMutationResult<QuickLinkResponse, AxiosError, DeleteQuickLinkVariables> => {
  return useMutation(deleteQuickLink, options);
};

export default useQuickLinkDelete;
