import { EntryCollection } from 'contentful';
import { contentfulApi } from '@/contentfulApi';

const useGetOperatorDescriptions = (): { getOperatorDescriptions: () => Promise<EntryCollection<unknown> | null> } => {
  const getOperatorDescriptions = async (): Promise<EntryCollection<unknown> | null> => {
    const entries = await contentfulApi.getEntries({
      content_type: 'workflowOperatorType',
      select: 'fields',
    });
    return entries;
  };

  return { getOperatorDescriptions };
};

export default useGetOperatorDescriptions;
