import React, { ReactElement } from 'react';
import { ColorProps, Flex, MenuItem, Text } from '@chakra-ui/react';
import { ArrowRight } from 'phosphor-react';

export interface DropdownItemProps {
  leftIcon: ReactElement;
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  color?: ColorProps['color'];
  hoverBgColor?: ColorProps['color'];
}

export const DropdownItem = ({ leftIcon, title, color, hoverBgColor, onClick }: DropdownItemProps): ReactElement => {
  return (
    <MenuItem
      px="2"
      py="3"
      borderRadius="4px"
      icon={leftIcon}
      color={color || 'gray.500'}
      _hover={{ color: color || 'gray.800', bg: hoverBgColor || 'white.50' }}
      _focus={{ color: color || 'gray.800', bg: hoverBgColor || 'white.50' }}
      onClick={onClick}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="sm" fontWeight="medium">
          {title}
        </Text>
        <ArrowRight />
      </Flex>
    </MenuItem>
  );
};
