import { atom } from 'recoil';
import { Config } from '../types';

export const InitialThemeData = {
  branding: {
    logo: '', // Binary Blob of data Should be less than 10MB.
    font: '', // string from system variables
    primaryColor: '#4462EC',
    secondaryColor: '#4462EC',
  },
  text: {
    color: '',
    objects: {
      title: {
        color: '#4462EC',
        fontSize: 24,
      },
      subtitle: {
        color: '',
        fontSize: 18,
      },
      bodyTitle: {
        color: '',
        fontSize: 16,
      },
      content: {
        color: '',
        fontSize: 14,
      },
    },
  },
  buttons: {
    type: '',
    borderRadius: 32,
  },
  background: {
    color: '',
  },
};

export const ThemeAtom = atom({
  key: 'theme-node',
  default: {
    themes: [InitialThemeData],
    currentIndex: 0,
  } as {
    themes: Config[];
    currentIndex: number;
  },
});
