import React, { ReactElement } from 'react';
import { Text, Button, Flex, Box } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Image as PhosphorousImage } from 'phosphor-react';
import { colors } from '@/utils/colors';
import { ThemeAtom } from './states/themeAtom';

const LogoCustomization = (): ReactElement => {
  const setThemeState = useSetRecoilState(ThemeAtom);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const base64Image = e.target?.result as string;
        // eslint-disable-next-line no-shadow
        setThemeState(themeState => {
          const lastThemeState = themeState.themes[themeState.currentIndex];
          const newThemeState = [
            ...themeState.themes,
            {
              ...lastThemeState,
              branding: {
                ...lastThemeState.branding,
                logo: base64Image.split(',')[1],
              },
            },
          ];
          return {
            themes: newThemeState,
            currentIndex: themeState.currentIndex + 1,
          };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Flex direction="column" gridGap="4">
      <Text color="gray.900" fontSize="sm">
        Add your Logo
      </Text>
      <Box>
        <Flex gridGap="4">
          <Box p="2.5" bg="gray.200" borderRadius="50%">
            <PhosphorousImage size={20} color={colors.gray[700]} weight="fill" />
          </Box>
          <Box alignItems="center" flexGrow={1} ml="1">
            <label htmlFor="fileInput">
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
              <Button w="full" variant="outline" as="span" fontSize="sm" fontWeight="normal" cursor="pointer">
                Upload Logo
              </Button>
            </label>
          </Box>
        </Flex>
        <Flex mt="2" justifyContent="space-between" fontSize="sm" color="gray.500">
          <Text>Max Size - 5 MB</Text> <Text>Ratio - 1:1</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default LogoCustomization;
