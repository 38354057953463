import React, { useState } from 'react';
import { Node, Handle, Position } from 'react-flow-renderer';
import { Flex, Box, Image, Text } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ArrowsClockwise, TrashSimple } from 'phosphor-react';

import { ModifyNodePositions, NodeDetails, SelectedPosition } from '../states/workflowChart';
import { SupplierDescriptions } from '../states/serviceDescriptions';
import { NodeKind, EndState } from '../types';
import { renderDataTestId } from '../helpers/utilityMethods';
import './workflow-chart/styles.css';

const OutputNode = ({ data }: Node): React.ReactElement => {
  const [showPopup, setShowPopup] = useState(false);

  const setModifyNodePosition = useSetRecoilState(ModifyNodePositions);
  const setEditNodeDetails = useSetRecoilState(NodeDetails);
  const selectedPosition = useRecoilValue(SelectedPosition);
  const serviceDescriptions = useRecoilValue(SupplierDescriptions);

  const onActionClick = (type: string): void => {
    let oldName;
    let oldId;
    if (data?.kind?.toLowerCase() === NodeKind.reject) {
      oldName = 'Reject';
      oldId = 'reject';
    } else if (data?.kind?.toLowerCase() === NodeKind.accept) {
      oldName = 'Accept';
      oldId = 'accept';
    } else {
      oldName = 'Manual Review';
      oldId = 'manual_review';
    }
    setEditNodeDetails({
      type: data?.kind || '',
      oldName,
      oldId,
      action: type,
      color: data?.color || '',
      description: data?.description || '',
    });
    setModifyNodePosition({
      columnPosition: data.columnPosition || 0,
      nodePosition: data.nodePosition || 0,
    });
  };

  const renderStateName = (stateName: string): React.ReactElement => {
    if (stateName.indexOf(EndState.ACCEPT) > -1) return <Box color="green.400">Accept</Box>;
    if (stateName.indexOf(EndState.REJECT) > -1) return <Box color="red.400">Reject</Box>;
    return <Box color="blue.400">Manual Review</Box>;
  };

  const renderOutputIcon = (stateName: string): React.ReactElement => {
    if (stateName.indexOf(EndState.ACCEPT) > -1)
      return <Image src="/assets/icons/accept.svg" w="6" h="6" mr="2" ml="2" />;
    if (stateName.indexOf(EndState.REJECT) > -1)
      return <Image src="/assets/icons/reject.svg" w="6" h="6" mr="2" ml="2" />;
    return <Image src="/assets/icons/manualReview.svg" w="6" h="6" mr="2" ml="2" />;
  };

  return (
    <Flex
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
      data-test-id={renderDataTestId(data, 'outputNode')}
      id={data?.id}
    >
      <Box
        role="group"
        borderWidth={2}
        borderRadius="lg"
        height="full"
        w="209px"
        borderColor="white.50"
        bg={
          selectedPosition.columnPosition === data.columnPosition && selectedPosition.nodePosition === data.nodePosition
            ? 'blue.100'
            : 'white'
        }
        px={2}
        pt={3}
        py={2}
      >
        <Handle type="target" position={Position.Left} style={{ borderRadius: 0, width: 0, background: 'none' }} />
        <Flex flexDirection="row" alignItems="center" borderBottomWidth={1} borderColor="white.50" pb={2} pt={1}>
          {renderOutputIcon(data?.name)}
          <Flex justifyContent="space-between" width="25">
            {data?.name && renderStateName(data.name.toLowerCase())}
          </Flex>
        </Flex>
        <Text fontSize="xs" fontWeight="light" color="gray.600" p={2}>
          {serviceDescriptions[data.kind]}
        </Text>
      </Box>
      <Box position="absolute" bg="transparent" h={6} w="80px" bottom="85px" left="60px" />
      {showPopup && (
        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          borderRadius={19}
          width="fit-content"
          height={46}
          left={35}
          bottom={110}
          overflow="hidden"
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            h="full"
            w={71}
            color="gray.700"
            bg="blue.300"
            _hover={{ color: 'gray.900', bg: 'blue.500' }}
            onClick={e => {
              e.stopPropagation();
              onActionClick('replace');
            }}
            cursor="pointer"
          >
            <ArrowsClockwise color="white" size="16" />
            <Text fontSize="8px" pt={1} fontWeight="normal" color="white">
              Replace
            </Text>
          </Flex>
          <Flex
            color="red.400"
            direction="column"
            alignItems="center"
            justifyContent="center"
            bg="blue.300"
            h="full"
            w={71}
            _hover={{ color: 'red.500', bg: 'blue.500' }}
            onClick={e => {
              e.stopPropagation();
              onActionClick('delete');
            }}
            cursor="pointer"
          >
            <TrashSimple color="white" size="16" />
            <Text fontSize="8px" pt={1} fontWeight="normal" color="white">
              Delete
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default OutputNode;
