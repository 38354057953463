import React from 'react';
import QRCode from 'react-qr-code';
import { Box } from '@chakra-ui/react';

interface QRCodeProps {
  url: string;
}

const QrCode: React.FC<QRCodeProps> = ({ url }) => {
  return (
    <Box bg="white" p={4} borderRadius="md" display="inline-block">
      <QRCode value={url || ' '} />
    </Box>
  );
};

export default QrCode;
