/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

export const useGetWebhookSignature = (
  options?: UseMutationOptions<any, AxiosError, {}>,
): UseMutationResult<any, AxiosError, any> => useMutation(getWebhookSignature, options);

const getWebhookSignature = async (webhookId: any): Promise<any> => {
  const res = await bureauPlatformApi.get(`/platform/webhooks/${webhookId}/secret`);
  return res.data;
};
