/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React, { ReactElement, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  useDisclosure,
  useToast,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD, INVALID_URL } from '@/constants/constants';
import { refetchDelay } from '@/screens/workflows/constants';
import { Eye, EyeSlash, Play } from 'phosphor-react';
import { CreateWebhookResponse, ToastMessage } from '../types';
import { useCreateWebhook } from '../queries/useCreateWebhook';
import { useTestWebhook } from '../queries/useTestWebhook';
import { useGetWebhookSignature } from '../queries/useGetWebhookSignature';

export const AddWebhookDialog = ({ setOpenDialog, refetch, webhookEvents }: any): ReactElement => {
  const [showErrorMessage, setShowErrorMesssage] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [testButtonLoading, setTestButtonLoading] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [generatedData, setGeneratedData] = useState<any>();
  const [signatureString, setSignatureString] = useState<any>();

  const webhookId = generatedData?.id;

  const { onClose } = useDisclosure();
  const toast = useToast();
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(),
    url: Yup.string()
      .required(REQUIRED_FIELD)
      .url(INVALID_URL),
  });

  const { mutateAsync: createWebhook } = useCreateWebhook({
    onSuccess: async response => {
      setIsButtonLoading(false);
      setGeneratedData(response);
      setTimeout(() => {
        toast({
          description: ToastMessage.WEBHOOK_CREATED_MESSAGE,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        refetch();
      }, refetchDelay);
    },
    onError: mutateError => {
      setIsButtonLoading(false);
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      onModalClose();
    },
  });

  const onAddWebhook = (details: CreateWebhookResponse): void => {
    setIsButtonLoading(true);
    createWebhook(details);
  };

  const { mutateAsync: testWebhook } = useTestWebhook({
    onSuccess: async => {
      setTestButtonLoading(false);
      setTimeout(() => {
        toast({
          description: ToastMessage.WEBHOOK_TESTED_MESSAGE,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }, refetchDelay);
    },
    onError: mutateError => {
      setTestButtonLoading(false);
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      onModalClose();
    },
  });

  const { mutateAsync: webhookSignature } = useGetWebhookSignature({
    onSuccess: async response => {
      setTestButtonLoading(false);
      setSignatureString(response);
    },
    onError: mutateError => {
      setTestButtonLoading(false);
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      onModalClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      url: '',
      description: '',
      filterTypes: webhookEvents.eventTypes,
      disabled: false,
    },
    onSubmit: onAddWebhook,
    validationSchema,
  });
  const onModalClose = (): void => {
    setOpenDialog(false);
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose} onOverlayClick={onModalClose} isCentered size="xl">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent borderRadius={10}>
        <ModalHeader fontSize="md" fontWeight="500">
          Create New Webhook
        </ModalHeader>
        <ModalCloseButton onClick={onModalClose} _focus={{ boxShadow: 'none' }} />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody fontSize="sm" fontWeight="light">
            <FormControl mb="6">
              <FormLabel fontSize="sm" fontWeight="normal" htmlFor="description">
                Webhook Name
              </FormLabel>
              <Input
                borderRadius={8}
                placeholder="Enter Webhook Name"
                id="description"
                name="description"
                value={formik.values?.description}
                color="gray.900"
                onChange={e => {
                  formik.handleChange(e);
                  if (showErrorMessage) setShowErrorMesssage(false);
                }}
                fontSize="sm"
                paddingInlineStart={2}
                _placeholder={{ fontWeight: 'light' }}
                onBlur={() => setShowErrorMesssage(true)}
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm" fontWeight="normal" htmlFor="url">
                URL
              </FormLabel>
              <Input
                borderRadius={8}
                placeholder="Enter Endpoint URL"
                id="url"
                name="url"
                value={formik.values?.url}
                color="gray.900"
                onChange={e => {
                  formik.handleChange(e);
                  if (showErrorMessage) setShowErrorMesssage(false);
                }}
                fontSize="sm"
                paddingInlineStart={2}
                _placeholder={{ fontWeight: 'light' }}
                onBlur={() => setShowErrorMesssage(true)}
              />
            </FormControl>
            {!formik.isValid && showErrorMessage && (
              <Text fontSize="xs" fontWeight="light" color="red" mt={2} ml={2}>
                Enter a valid URL. eg: https://www.google.com
              </Text>
            )}
            <FormControl mt={8} d={generatedData ? 'block' : 'none'}>
              <FormLabel fontSize="sm" htmlFor="webhooksignature">
                Webhook Signature
              </FormLabel>
              <Flex>
                <Text isTruncated fontSize="xs">
                  {showSignature ? signatureString?.key : '**********************'}
                </Text>
                <Box
                  onClick={() => {
                    setShowSignature(!showSignature);
                    webhookSignature(webhookId);
                  }}
                  ml={2}
                  cursor="pointer"
                >
                  {showSignature ? <EyeSlash height="16px" width="16px" /> : <Eye height="16px" width="16px" />}
                </Box>
                {/* <Box w={4}>
                  <CopyButton cursor="pointer" />
                </Box> */}
              </Flex>
            </FormControl>
          </ModalBody>
          <ModalFooter mt="4" pb="6">
            <Flex align="center" justifyContent="space-between" w="full">
              <Flex flex="1" w="full" align="center">
                <Button
                  h={38}
                  px={4}
                  fontSize="sm"
                  fontWeight="medium"
                  borderWidth={1}
                  borderColor="gray.300"
                  bg="gray.200"
                  color="gray.800"
                  disabled={!generatedData}
                  isLoading={testButtonLoading}
                  onClick={() => testWebhook({})}
                >
                  <Play style={{ marginRight: '4' }} />
                  Send Test Events
                </Button>
              </Flex>
              <Button
                h={38}
                px={6}
                fontSize="sm"
                fontWeight="normal"
                borderWidth={1}
                borderColor="gray.300"
                bg="gray.200"
                color="gray.800"
                mr={3}
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                h={38}
                px={8}
                fontSize="sm"
                color="white"
                bg="blue.500"
                disabled={!formik.isValid || generatedData}
                isLoading={isButtonLoading}
                fontWeight="light"
                type="submit"
                _hover={!(formik.isValid || formik.dirty) ? {} : { opacity: '0.8', background: 'blue.500' }}
                _disabled={{ color: 'gray.600', bg: 'gray.200', cursor: 'not-allowed' }}
              >
                Create Webhook
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
