import { ReactNode } from 'react';

export interface DatePickerProps {
  selectedDates: string[];
  onChange: (startDate: string, endDate: string) => void;
  onCancel: () => void;
  disable: boolean;
}

export interface CalendarContainerProps {
  className: string;
  child: ReactNode[];
  startDate: string;
  endDate: string;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setDateError: (val: boolean) => void;
  disable: boolean;
}

export interface CalendarContainerTypes {
  className: string;
  children: ReactNode[];
}

export enum CustomDateType {
  CUSTOM_DATE = 'customDate',
}
