import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import useSaveQuickLink from '../queries/useSaveQuickLink';

export interface QuickLinkData {
  name: string;
  workflowId: string;
  showResponseOutput: boolean;
  transactionLimit: number;
  isEnabled: boolean;
}

type ValidationSchema = {
  name: Yup.StringSchema;
  transactionLimit: Yup.NumberSchema;
};

interface QuickLinkHookReturn {
  generatedLinkID: string | null;
  isLoading: boolean;
  initialValues: QuickLinkData;
  validationSchema: Yup.ObjectSchema<ValidationSchema>;
  handleSubmit: (values: QuickLinkData) => void;
  quicklinkUrl: string;
  trimUrl: (url: string) => string;
  navigateToQuickLink: () => void;
}

export const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object({
  name: Yup.string()
    .required('Quicklink name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be less than 50 characters'),
  transactionLimit: Yup.number()
    .required('Transaction limit is required')
    .min(1, 'Transaction limit must be at least 1')
    .integer('Transaction limit must be a whole number'),
});

export const useQuickLink = (workflowId: string): QuickLinkHookReturn => {
  const [generatedLinkID, setGeneratedLinkID] = useState<string | null>(null);
  const toast = useToast();
  const history = useHistory();

  const initialValues: QuickLinkData = {
    name: '',
    workflowId,
    showResponseOutput: false,
    transactionLimit: 100,
    isEnabled: true,
  };

  const { mutate: saveQuickLink, isLoading, data: quicklinkSavedData } = useSaveQuickLink({
    onSuccess: response => {
      setGeneratedLinkID(response.id);
      toast({
        title: 'Link Generated',
        description: 'QuickLink has been generated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: error => {
      setGeneratedLinkID(null);
      toast({
        title: 'Error',
        description: error.message || 'Failed to generate QuickLink',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const trimUrl = (sdkUrl: string): string => {
    return sdkUrl.length > 40 ? `${sdkUrl.substring(0, 37)}...` : sdkUrl;
  };

  const handleSubmit = (values: QuickLinkData): void => {
    saveQuickLink({ ...values, workflowId });
  };

  const navigateToQuickLink = (): void => {
    history.push('/quicklinks');
  };

  return {
    generatedLinkID,
    isLoading,
    initialValues,
    validationSchema,
    handleSubmit,
    trimUrl,
    navigateToQuickLink,
    quicklinkUrl: quicklinkSavedData?.url || '',
  };
};
