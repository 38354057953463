import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

import { GetAttributesResponse, Output, Operator } from '../types';

export const useGetInputAttributes = (): UseQueryResult<Output[], AxiosError> => {
  return useQuery('input', getAttributes(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: data => {
      const attributesData = data.inputParameters?.map((item: Output) => ({
        ...item,
        label: item?.displayName,
        value: item?.key,
        operators: item?.operators?.map((op: Operator) => ({
          ...op,
          label: op.key,
        })),
      }));
      return process?.env?.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development'
        ? [...attributesData]
        : attributesData;
    },
  });
};

const getAttributes = () => {
  return async () => {
    const res = await bureauPlatformApi.get<GetAttributesResponse>('/input');
    return res.data;
  };
};

export default useGetInputAttributes;
