import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { QuickLinkData, QuickLinkSaveResponse } from '../types';

const saveConfig = async (data: QuickLinkData): Promise<QuickLinkSaveResponse> => {
  const { data: response } = await bureauPlatformApi.post<QuickLinkSaveResponse>(`/workflows/quicklink`, data);
  return response;
};

const useSaveQuickLink = (
  options?: UseMutationOptions<QuickLinkSaveResponse, AxiosError, QuickLinkData>,
): UseMutationResult<QuickLinkSaveResponse, AxiosError, QuickLinkData> => {
  return useMutation(saveConfig, options);
};

export default useSaveQuickLink;
