import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { UsageMetrics } from '@/types/common';

export const useGetUsageMetrics = (): UseQueryResult<UsageMetrics, AxiosError> => {
  return useQuery('get-usage-metrics', getUsageMetrics(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: window.location.href.includes('stg'),
  });
};

const getUsageMetrics = () => {
  return async () => {
    const url = `/v1/auth/credits/${bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID']}`;
    const res = await bureauPlatformApi.get<UsageMetrics>(url);
    return res.data;
  };
};
export default useGetUsageMetrics;
