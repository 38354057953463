import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { WebhookDetails } from './WebhookDetails';
import { WebhooksList } from './WebhooksList';

const WebhooksWrapper = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <WebhooksList />
      </Route>
      <Route exact path={`${path}/:id`}>
        <WebhookDetails />
      </Route>
    </Switch>
  );
};

export default WebhooksWrapper;
