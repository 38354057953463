import { EntryCollection } from 'contentful';
import { contentfulApi } from '@/contentfulApi';

const useGetStartEndNodeDescriptions = (): {
  getStartEndNodeDescriptions: () => Promise<EntryCollection<unknown> | null>;
} => {
  const getStartEndNodeDescriptions = async (): Promise<EntryCollection<unknown> | null> => {
    const entries = await contentfulApi.getEntries({
      content_type: 'platformServiceDescriptions',
      select: 'fields',
    });
    return entries;
  };

  return { getStartEndNodeDescriptions };
};

export default useGetStartEndNodeDescriptions;
