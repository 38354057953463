import React from 'react';
import { Cell } from 'react-table';
import { format, formatRelative } from 'date-fns';
import { Flex, Text } from '@chakra-ui/react';
import { DATE_FORMAT } from '@/constants/constants';

export const RelativeFormatDateCell = ({ value }: Cell): React.ReactElement => {
  const date = new Date(value);
  let formattedDate = formatRelative(date, new Date());
  if (formattedDate.length === 10 || formattedDate[0] === 'l') {
    formattedDate = format(date, `dd LLL  'at'  hh:mm a`);
  }
  return (
    <Flex>
      <Text color="gray.900">{formattedDate}</Text>
    </Flex>
  );
};

export const FormatDateCell = ({ value }: Cell): React.ReactElement => {
  if (value) {
    const date = new Date(value);
    const formattedDate = format(date, DATE_FORMAT);
    return (
      <Flex>
        <Text color="gray.700">{formattedDate}</Text>
      </Flex>
    );
  }
  return <></>;
};
