import { PaginatedTableColumnsWithProps } from '@/components/paginated-table/utils/types';
import { BlueColorTextCell, FormatDateCell } from '@/components/paginated-table/table-components/PaginatedTableCells';
import { RedirectCellWithRow } from '@/components/paginated-table/table-components/table-cells/RedirectCellWithRow';
import { WebhookToggleCell } from '@/components/paginated-table/table-components/table-cells/WebhookToggleCell';
// import { Webhooks } from './types';

export const columns = (count: number, onWebhookToggle: (val: string) => void): PaginatedTableColumnsWithProps[] => [
  {
    Header: 'Name',
    accessor: 'description',
    disableSortBy: true,
    id: 'description',
  },
  {
    Header: 'URL',
    accessor: 'url',
    Cell: BlueColorTextCell,
    disableSortBy: true,
    id: 'url',
  },
  // {
  //   Header: 'Events',
  //   accessor: (data: Webhooks) => {
  //     const eventCount = data.filterTypes.length;
  //     return eventCount.toString();
  //   },
  //   disableSortBy: true,
  //   id: 'events',
  // },
  {
    Header: 'Last Updated On',
    accessor: 'updatedAt',
    Cell: FormatDateCell,
    disableSortBy: true,
    id: 'lastUpdatedOn',
  },
  {
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: FormatDateCell,
    disableSortBy: true,
    id: 'createdOn',
  },
  {
    Header: 'Status',
    accessor: 'disabled',
    disableSortBy: true,
    Cell: WebhookToggleCell,
    getProps: { onWebhookToggle },
    id: 'active',
  },
  {
    Header: '',
    accessor: row => row,
    Cell: RedirectCellWithRow,
    disableSortBy: true,
    id: 'webhookId',
  },
];
