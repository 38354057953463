/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useToken, Flex, Text, Box, Image } from '@chakra-ui/react';
import { CaretDown, CaretUp } from 'phosphor-react';
import { SelectInput, SelectInputProps } from '@bureau/components';
import { colors } from '@/utils/colors';
import { DropdownVariant } from './types';

const Dropdown = ({
  options,
  value,
  placeholder,
  isMulti,
  onChange,
  controlWidth,
  maxWidth,
  menuHeading,
  variant,
  isDisabled = false,
  isSearchable = false,
  style = {},
  components,
  createNewLabel = '',
  onCreateOption,
  onInputChange,
}: SelectInputProps): React.ReactElement => {
  const bgColor = useToken('colors', 'white');

  const customStyles = {
    control: () => ({
      borderRadius: '8px',
      background: isDisabled ? colors.white[50] : bgColor,
      minWidth: controlWidth,
      maxWidth,
      paddingLeft: '8px',
      paddingRight: '8px',
      ...(variant === DropdownVariant.GHOST
        ? {
            '&:focus-within': {
              boxShadow: 'none',
            },
          }
        : {
            '&:focus-within': {
              border: '1px solid #ADC5F9',
              boxShadow: 'none',
            },
          }),
      fontSize: '12px',
      ...style,
    }),
  };

  const MultiValue = (props: any): React.ReactElement => {
    const { children, innerProps, getValue } = props;
    const selectedValues = getValue();
    const isLastElement = children === selectedValues[selectedValues?.length - 1]?.label;
    const isAllSelected = options.length === selectedValues.length;
    return (
      <Flex {...innerProps} bg="white">
        <Text fontWeight={400} fontSize="xs" color="gray.900">
          {isAllSelected ? isLastElement && 'All' : `${children}${isLastElement ? '' : ','}`}
        </Text>
      </Flex>
    );
  };

  const Menu = (props: any): React.ReactElement => {
    const { innerRef, children, innerProps } = props;
    return (
      <Box
        {...innerProps}
        ref={innerRef}
        bg="white"
        mt={10}
        p={2}
        minW={150}
        w={controlWidth}
        borderRadius="lg"
        boxShadow="lg"
        position="absolute"
        right={0}
        top={0}
        zIndex={10}
      >
        {menuHeading && (
          <Text fontWeight="light" fontSize="xs" p={2} color="gray.600">
            {menuHeading}
          </Text>
        )}
        {children}
      </Box>
    );
  };

  const Option = (props: any): React.ReactElement => {
    const { isSelected, children, innerProps } = props;
    return (
      <Flex
        {...innerProps}
        bg="white"
        p={2}
        alignItems="center"
        cursor="pointer"
        borderRadius="lg"
        _hover={{ bg: '#F5F6F9' }}
      >
        {isMulti ? (
          <Flex alignItems="center">
            <Box
              h={5}
              padding={1}
              w={5}
              mr={2}
              borderWidth={1}
              borderRadius="md"
              borderColor={isSelected ? 'blue.500' : 'gray.300'}
              bg={isSelected ? 'blue.500' : ''}
            >
              <Image src="/assets/icons/checkboxTick.svg" alignSelf="center" pt="2px" />
            </Box>
            <Text fontSize="xs" color="gray.900" fontWeight="light" isTruncated>
              {children}
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text color="gray.900" fontSize={variant === DropdownVariant.GHOST ? 'xs' : 'sm'} fontWeight="light">
              {children}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const SingleValue = (props: any): React.ReactElement => {
    const { children, innerProps } = props;
    return (
      <Flex
        color="gray.900"
        {...innerProps}
        minW={variant === DropdownVariant.GHOST ? 'fit-content' : '50px'}
        fontWeight="light"
      >
        {children}
      </Flex>
    );
  };

  const MultiValueRemove = (): null => null;
  const DropdownIndicator = (props: any): React.ReactElement => {
    const { selectProps } = props;
    if (selectProps.menuIsOpen) {
      return <CaretUp size={16} color={variant === DropdownVariant.GHOST ? colors.gray[900] : colors.gray[600]} />;
    }
    return <CaretDown size={16} color={variant === DropdownVariant.GHOST ? colors.gray[900] : colors.gray[600]} />;
  };

  const ValueContainer = (props: any): React.ReactElement => {
    const { children, innerProps } = props;
    return (
      <Flex {...innerProps} maxW="450px" alignItems="center" isTruncated>
        {children}
      </Flex>
    );
  };
  const ClearIndicator = (): null => null;

  const DropdownComponents = {
    MultiValue,
    MultiValueRemove,
    DropdownIndicator,
    Option,
    Menu,
    SingleValue,
    ClearIndicator,
    ValueContainer,
    ...components,
  };

  return (
    <SelectInput
      onChange={onChange}
      value={value}
      options={options}
      isMulti={isMulti}
      components={DropdownComponents}
      closeMenuOnSelect={!isMulti}
      isClearable
      isDisabled={isDisabled}
      hideSelectedOptions={false}
      placeholder={placeholder}
      isSearchable={isSearchable}
      styles={customStyles}
      createNewLabel={createNewLabel}
      onCreateOption={onCreateOption}
      onInputChange={onInputChange}
    />
  );
};

export default Dropdown;
