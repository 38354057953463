import React, { useEffect, useState } from 'react';
import { Box, FormLabel } from '@chakra-ui/react';
import Select from 'react-select';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ThemeAtom } from './states/themeAtom';

interface FontOption {
  label: string;
  value: string;
}

function FontSelector(): React.ReactElement {
  // Function to fetch available system fonts
  const getSystemFonts = (): FontOption[] => {
    return [
      { label: 'Arial', value: 'Arial, sans-serif' },
      { label: 'Helvetica', value: 'Helvetica, sans-serif' },
      { label: 'Times New Roman', value: 'Times New Roman, serif' },
      { label: 'Courier New', value: 'Courier New, monospace' },
      { label: 'Verdana', value: 'Verdana, sans-serif' },
      { label: 'Georgia', value: 'Georgia, serif' },
    ];
  };

  const themeState = useRecoilValue(ThemeAtom);
  const [selectedFont, setSelectedFont] = useState<FontOption>(() => {
    const currentFont = themeState.themes[themeState.currentIndex].branding.font;
    return (
      getSystemFonts().find(fonts => fonts.value === currentFont) || { label: 'Arial', value: 'Arial, sans-serif' }
    );
  });
  const setThemeState = useSetRecoilState(ThemeAtom);

  useEffect(() => {
    const currentValue = themeState.themes[themeState.currentIndex].branding.font;
    if (currentValue !== selectedFont.value) {
      setSelectedFont(
        getSystemFonts().find(fonts => fonts.value === currentValue) || { label: 'Arial', value: 'Arial, sans-serif' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeState.currentIndex]);

  return (
    <Box>
      <FormLabel htmlFor="select-font" color="gray.900" fontSize="sm" fontWeight="normal">
        Font
      </FormLabel>
      <Select
        id="select-font"
        options={getSystemFonts()}
        value={selectedFont}
        onChange={event => {
          if (event === null) return;
          setSelectedFont(event);
          setThemeState(prevThemeState => {
            const lastThemeState = prevThemeState.themes[prevThemeState.currentIndex];
            const newThemeState = [
              ...prevThemeState.themes,
              {
                ...lastThemeState,
                branding: {
                  ...lastThemeState.branding,
                  font: event.value || 'Arial, sans-serif',
                },
              },
            ];
            return {
              themes: newThemeState,
              currentIndex: prevThemeState.currentIndex + 1,
            };
          });
        }}
        placeholder="Select Font"
        className="react-select-container"
        classNamePrefix="react-select"
        menuPlacement="auto"
      />
    </Box>
  );
}

export default FontSelector;
