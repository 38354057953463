import { formatCurrentTime } from '@/screens/capabilitiesV2/helpers';
import { defaultWorkflowName } from '@/screens/workflows/constants';
import { atom } from 'recoil';

const initialData = `${defaultWorkflowName}-${formatCurrentTime()}`;

export const WorkflowNameAtom = atom({
  key: 'workflow-name',
  default: initialData as string,
});
