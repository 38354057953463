import React, { useState } from 'react';
import { Box, Flex, Input, Tag, TagLabel, TagCloseButton, BoxProps } from '@chakra-ui/react';

interface TagsInputProps extends Omit<BoxProps, 'onChange'> {
  onChange: (item: string[]) => void;
  value: string[];
  placeholder?: string;
}

const TagsInput: React.FC<TagsInputProps> = ({
  onChange,
  value,
  placeholder = 'Press Enter to add tags to list',
  ...boxProps
}) => {
  const [input, setInput] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInput(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && input.trim()) {
      e.preventDefault();
      const newTag = input.trim();
      onChange([...value, newTag]);
      setInput('');
    }
  };

  const removeTag = (indexToRemove: number): void => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Box border="1px" borderColor="gray.200" borderRadius="md" p={2} {...boxProps}>
      <Flex flexWrap="wrap" alignItems="center" gridRowGap="2" gridColumnGap="1">
        [
        {value.map((tag, index) => (
          <Tag
            key={tag}
            size="sm"
            borderRadius="full"
            variant="solid"
            backgroundColor="gray.300"
            color="gray.900"
            fontSize="12px"
          >
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton fontSize="sm" onClick={() => removeTag(index)} />
          </Tag>
        ))}
        ]
        <Input
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={placeholder}
          size="sm"
          fontSize="12px"
          variant="unstyled"
          flex={1}
          minWidth="180px"
        />
      </Flex>
    </Box>
  );
};

export default TagsInput;
