import React, { ReactElement, useMemo } from 'react';
import { CheckCircle, DotsThree, Files, ShieldWarning } from 'phosphor-react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Menu, MenuButton, MenuItem, MenuList, Flex, Text, useToast } from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { WorkflowAtom } from './states/workflowAtom';
import CapabilityDrop from './CapabilityDrop';
import { ColumnV2, Conditions, ConditionsType } from './types';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { ConditionsAtom } from './states/conditionsAtom';
import { BranchAtom } from './states/branchAtom';

const BranchingNodes = ({
  id,
  type,
  handleBranchingNodesClick,
  handleCapabilityNodeClick,
}: {
  id: string;
  type: string;
  handleBranchingNodesClick?: (capability: string) => void;
  handleCapabilityNodeClick: (id: string) => void;
}): ReactElement => {
  const toast = useToast();
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const resetNode = useResetRecoilState(SelectedNodeAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const workflows = useRecoilValue(WorkflowAtom);
  const setConditions = useSetRecoilState(ConditionsAtom);
  const setBranch = useSetRecoilState(BranchAtom);
  const isAlertsAdded = useMemo(() => {
    const processedWorkflows = workflows
      .filter(workflow => workflow.metadata.parentID === id)
      .flatMap(workflow => workflow.nodes)
      .flatMap(workflow => workflow.inputs)
      .filter(input => input?.location === '..');
    return !!processedWorkflows.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(workflows)]);
  const findNode = (): ColumnV2[] => {
    return workflows.filter(workflow => workflow.metadata.parentID === id && workflow.metadata.branch === type) || [];
  };

  const branchNodeId = findNode()[0]?.metadata?.capabilityId;

  if (!findNode().length) {
    return <CapabilityDrop type={type} parentId={id} />;
  }

  const isNodeSelected = selectedNode.id === id && selectedNode.columns?.[0]?.metadata.branch === type;
  return (
    <Flex
      direction="column"
      bg="white"
      borderWidth={1}
      borderColor={isNodeSelected ? 'blue.400' : 'gray.200'}
      borderRadius="lg"
      w="80"
      h="24"
      p="2"
      gridGap="2"
      boxShadow={
        isNodeSelected ? `2px 6px 8px 0px rgba(17, 25, 99, 0.04), 0px 0px 0px 3px ${colors.shadows.blue}` : 'none'
      }
      cursor="pointer"
      onClick={() => {
        resetNode();
        resetConditions();
        setBranch(type);
        const columns = findNode();
        const conditionsArray =
          workflows.filter(workflow => workflow.metadata.parentID === columns[0].metadata.capabilityId) || [];
        if (conditionsArray.length) {
          const flattenedArray = conditionsArray.flatMap(condition => condition.nodes);
          const conditionsObject = {
            [ConditionsType.accept]: [],
            [ConditionsType.reject]: [],
            [ConditionsType.manual_review]: [],
          } as Conditions;
          flattenedArray.forEach(arr => {
            if (arr.kind === 'end_accepted' && arr.conditions) {
              const acceptedCondition = arr.conditions;
              conditionsObject[ConditionsType.accept] = acceptedCondition;
            }
            if (arr.kind === 'end_rejected' && arr.conditions) {
              const rejectedCondition = arr.conditions;
              conditionsObject[ConditionsType.reject] = rejectedCondition;
            }
            if (arr.kind === 'end_manual_review' && arr.conditions) {
              const manualReviewCondition = arr.conditions;
              conditionsObject[ConditionsType.manual_review] = manualReviewCondition;
            }
          });
          setConditions(conditionsObject);
        }
        setSelectedNode(() => ({ id, type: 'source', columns }));
        handleCapabilityNodeClick(branchNodeId ?? id);
      }}
    >
      <Flex gridGap="2" alignItems="center">
        <Flex bg="white.50" borderRadius="full" p="2" mr="1">
          <Files size="16" />
        </Flex>
        <Text fontSize="sm" flexGrow={1} maxW="52" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {findNode()[0]?.metadata.capabilityName || ''}
        </Text>

        {isAlertsAdded ? (
          <ShieldWarning size={16} color={colors.orange[500]} weight="fill" />
        ) : (
          <CheckCircle color="green" weight="fill" />
        )}

        <Menu>
          <MenuButton onClick={event => event.stopPropagation()}>
            <DotsThree size={16} color={colors.gray[900]} />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontWeight={300}
              fontSize="sm"
              onClick={event => {
                event.stopPropagation();
                setWorkflow(workflow => workflow.filter(column => !(column.metadata.capabilityId === branchNodeId)));
                toast({
                  title: 'Branch Node successfully deleted',
                  status: 'success',
                  duration: 1000,
                  isClosable: true,
                });
                resetConditions();
              }}
              color="red.500"
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Text fontSize="xs" color="gray.600" noOfLines={[1, 2]} fontWeight="300">
        {findNode()[0]?.metadata.description || ''}
      </Text>
    </Flex>
  );
};

export default BranchingNodes;
