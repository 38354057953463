import React, { ReactElement } from 'react';
import { Flex, Box, Input, IconButton } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { ArrowsLeftRight, XCircle } from 'phosphor-react';

import Dropdown from '@/components/dropdown/Dropdown';
import { OptionType } from '@bureau/components';
import { colors } from '@/utils/colors';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Select, { StylesConfig } from 'react-select';
import TagsInput from '@/components/tag-input/TagsInput';
import useGetInputAttributes from '../queries/useGetInputAttributes';
import { workflowInput, workflowKindOptions } from '../constants';
import { ServicesToTheLeft } from '../states/workflowChart';
import { Service, Inputs, NodeKind } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: any): ReactElement => {
  const { children, innerProps, data } = props;
  return (
    <Flex
      {...innerProps}
      justifyContent="space-between"
      alignItems="center"
      color="gray.800"
      p={2}
      mb={1}
      borderRadius={4}
      cursor="pointer"
      bg="initial"
      fontSize="xs"
      _hover={{ bg: 'gray.50' }}
    >
      <Box fontWeight="normal">{children}</Box>
      {data.key && (
        <Box px={2} py={1} bg="gray.200" fontWeight="light" borderRadius={4}>
          {data.key}
        </Box>
      )}
    </Flex>
  );
};

type GroupType = { label: string; options: OptionType[] };
type IsMulti = true;

const reactSelectStyles: StylesConfig<OptionType, IsMulti, GroupType> = {
  control: provided => ({
    ...provided,
    borderColor: colors.gray[200],
    borderRadius: '6px',
    // padding: '0.25rem',
    minWidth: 300,
  }),
  valueContainer: provided => ({ ...provided, maxHeight: 60, overflowY: 'auto' }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: '8px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#98A3B8',
    fontSize: '12px',
    fontWeight: 300,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const WorkflowInputCondition = ({
  onValueUpdate,
  index,
  inputItem,
}: {
  onValueUpdate: (data: Inputs, position: number) => void;
  index: number;
  inputItem: Inputs;
}): ReactElement => {
  const servicesToTheLeft = useRecoilValue(ServicesToTheLeft);
  // TODO: revert this input api
  const { data: inputAttributes } = useGetInputAttributes();
  const getServiceParameters = (): OptionType[] => {
    const services = servicesToTheLeft;
    const attrs = services?.find((service: Service) => service.id === inputItem.locationId);
    return (attrs?.outputParameters as OptionType[]) || [];
  };

  const renderInputField = (): ReactElement => {
    return (
      <Box>
        <Dropdown
          options={inputAttributes as OptionType[]}
          controlWidth="296px"
          placeholder="Select Input Variable"
          value={inputItem?.locationType}
          components={{ Option }}
          isSearchable
          onChange={field => {
            const tempData = { ...inputItem };
            tempData.isStatic = false;
            tempData.staticValue = undefined;
            tempData.locationId = 'start_ghdvng';
            tempData.locationType = (field as OptionType)?.value;
            onValueUpdate(tempData, index);
          }}
        />
      </Box>
    );
  };

  const renderStaticField = (): ReactElement => {
    // multi-select dropdown
    if (inputItem.type === 'INTERFACE' && inputItem.format === 'LIST' && inputItem.allowedValues?.length) {
      const dropdownOptions = inputItem.allowedValues?.map(option => ({
        label: option.toString(),
        value: option,
      })) as OptionType[];

      return (
        <Box>
          <Select
            isMulti
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            options={dropdownOptions}
            placeholder="Static Input as List"
            onChange={selectedOptions => {
              const tempData = { ...inputItem };
              tempData.locationId = 'start_ghdvng';
              tempData.isStatic = true;
              tempData.staticValue = selectedOptions.map(option => option.value);
              onValueUpdate(tempData, index);
            }}
            value={(inputItem.staticValue as string[])?.map(item => ({
              value: item,
              label: item,
            }))}
            styles={reactSelectStyles}
            components={{
              DropdownIndicator: () => <ChevronDownIcon boxSize="5" mr="2" color="gray.600" />,
            }}
          />
        </Box>
      );
    }
    // multi-select text field => on enter text will convert into tag
    if (inputItem.type === 'INTERFACE' && inputItem.format === 'LIST' && !inputItem.allowedValues?.length) {
      return (
        <Box>
          <TagsInput
            value={(inputItem.staticValue || []) as string[]}
            onChange={itemTag => {
              const tempData = { ...inputItem };
              tempData.locationId = 'start_ghdvng';
              tempData.isStatic = true;
              tempData.staticValue = itemTag;
              onValueUpdate(tempData, index);
            }}
            maxH="60px"
            overflowY="auto"
          />
        </Box>
      );
    }

    // simple dropdown: options are allowed values otherwise add 'true' and 'false' when type is 'BOOLEAN' and allowedValues are not present
    if (inputItem.type === 'BOOLEAN' || inputItem.allowedValues?.length) {
      const booleanOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ];
      const dropdownOptions = (inputItem.allowedValues?.length
        ? inputItem.allowedValues?.map(option => ({
            label: option.toString(),
            value: option,
          }))
        : booleanOptions) as OptionType[];

      return (
        <Box>
          <Dropdown
            options={dropdownOptions}
            controlWidth="145px"
            placeholder="Static input"
            value={inputItem.isStatic ? inputItem.staticValue : ''}
            components={{ Option }}
            isSearchable
            onChange={field => {
              const tempData = { ...inputItem };
              tempData.locationId = 'start_ghdvng';
              tempData.isStatic = true;
              tempData.staticValue = (field as OptionType)?.value;
              onValueUpdate(tempData, index);
            }}
          />
        </Box>
      );
    }

    // text field => type based on input type 'number' or 'string'
    return (
      <Box>
        <Input
          fontSize="12px"
          placeholder="Static Input"
          type={inputItem.type?.toLowerCase()} // 'number' or 'string'
          onChange={e => {
            const tempData = { ...inputItem };
            tempData.locationId = 'start_ghdvng';
            tempData.isStatic = true;
            const { value } = e.target;
            tempData.staticValue = inputItem.type === 'NUMBER' ? +value : value;
            onValueUpdate(tempData, index);
          }}
          value={inputItem.isStatic ? inputItem.staticValue : ''}
        />
      </Box>
    );
  };

  const renderServiceField = (): ReactElement => {
    return (
      <Box>
        <Dropdown
          options={servicesToTheLeft as OptionType[]}
          controlWidth="145px"
          placeholder="Service"
          value={inputItem?.locationId?.split('node_id')[0] || ''}
          components={{ Option }}
          isSearchable
          onChange={field => {
            const tempData = { ...inputItem, locationType: '' };
            tempData.locationId = (field as OptionType)?.value;
            tempData.isStatic = false;
            tempData.staticValue = undefined;
            onValueUpdate(tempData, index);
          }}
        />
      </Box>
    );
  };

  const renderServiceParamField = (): ReactElement => {
    return (
      <Box>
        <Dropdown
          options={getServiceParameters()}
          controlWidth="145px"
          placeholder="Param"
          isSearchable
          value={inputItem?.locationType}
          onChange={field => {
            const tempData = { ...inputItem };
            tempData.locationType = (field as OptionType)?.value;
            tempData.isStatic = false;
            tempData.staticValue = undefined;
            onValueUpdate(tempData, index);
          }}
        />
      </Box>
    );
  };

  return (
    <Flex p={4} pb={2} gridGap="4" alignItems="center">
      <Dropdown
        controlWidth="145px"
        isDisabled
        options={[]}
        placeholder={inputItem?.key || inputItem?.displayName}
        value={inputItem?.key}
      />
      <Box>
        <ArrowsLeftRight color="#BCC2D4" />
      </Box>
      <Dropdown
        options={workflowKindOptions}
        controlWidth="145px"
        placeholder="Select Source"
        value={inputItem ? inputItem?.locationKind : ''}
        onChange={field => {
          const tempData = inputItem ? { ...inputItem, locationType: '', locationId: '' } : { ...workflowInput };
          const selectedValue = field as OptionType;
          tempData.locationKind = selectedValue?.value;
          if (selectedValue.value === 'static') {
            tempData.isStatic = true;
          }
          onValueUpdate(tempData, index);
        }}
      />
      {inputItem?.locationKind === 'input' && renderInputField()}
      {inputItem?.locationKind === NodeKind.service && renderServiceField()}
      {inputItem?.locationKind === NodeKind.service && inputItem?.locationId && renderServiceParamField()}
      {inputItem?.locationKind === 'static' && renderStaticField()}
      {inputItem?.locationKind && (
        <IconButton
          variant="unstyled"
          aria-label="Reset Input Fields"
          title="Reset Selection"
          color="gray.600"
          icon={<XCircle size={18} weight="fill" />}
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            const tempData = { ...inputItem };
            tempData.isStatic = false;
            tempData.staticValue = undefined;
            tempData.locationId = '';
            tempData.locationType = '';
            tempData.locationKind = '';
            onValueUpdate(tempData, index);
          }}
        />
      )}
    </Flex>
  );
};

export default WorkflowInputCondition;
