import React, { ReactElement } from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';

const TryoutEmptyState = (): ReactElement => {
  return (
    <Flex direction="column" alignItems="center">
      <Image src="/assets/icons/csvExport.svg" w={10} h={7} mb={3} />
      <Text width="36" fontSize="sm" fontWeight="light" textAlign="center" lineHeight="normal" color="gray.600">
        Tryout results will be shown here
      </Text>
    </Flex>
  );
};

export default TryoutEmptyState;
