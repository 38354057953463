import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Text, Box, Flex } from '@chakra-ui/react';
import { CheckCircle, Eye, Plus, XCircle } from 'phosphor-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ConditionsType } from './types';
import ConditionsBlock from './ConditionsBlock';
import { INITIAL_AND_CONDITION } from './constant';
import { ConditionsAtom } from './states/conditionsAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';

function ConditionsWrapper(): ReactElement {
  const conditionsBlock = useRecoilValue(ConditionsAtom);
  const setConditionsBlock = useSetRecoilState(ConditionsAtom);
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const [clickedCondition, setClickedCondition] = useState<ConditionsType | null>(null);

  const acceptRef = useRef<HTMLDivElement | null>(null);
  const rejectRef = useRef<HTMLDivElement | null>(null);
  const manualReviewRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (clickedCondition === ConditionsType.accept && acceptRef.current) {
      acceptRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (clickedCondition === ConditionsType.reject && rejectRef.current) {
      rejectRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (clickedCondition === ConditionsType.manual_review && manualReviewRef.current) {
      manualReviewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [clickedCondition, conditionsBlock]);

  if (selectedNode.type === 'branch') {
    return (
      <Flex gridGap="4" direction="column">
        <Button
          leftIcon={<Plus size={16} weight="fill" />}
          borderRadius="lg"
          fontSize="xs"
          fontWeight="normal"
          p="2"
          onClick={() => {
            setConditionsBlock(prevConditions => ({
              ...prevConditions,
              [ConditionsType.accept]: INITIAL_AND_CONDITION,
            }));
          }}
          w="50%"
          colorScheme="gray"
        >
          Add a Condition
        </Button>
        {conditionsBlock[ConditionsType.accept].length ? <ConditionsBlock type={ConditionsType.accept} /> : null}
      </Flex>
    );
  }

  return (
    <>
      <ConditionHeader />
      <Flex gridGap="2" direction="column" px="4" pb="6">
        <Flex gridGap="2">
          <Button
            variant="outline"
            leftIcon={<Plus size={16} />}
            rightIcon={<CheckCircle size={16} weight="fill" />}
            borderRadius="lg"
            borderColor="green.100"
            color="green.500"
            bg="green.50"
            fontSize="xs"
            fontWeight="normal"
            p="2"
            disabled={!!conditionsBlock[ConditionsType.accept].length}
            onClick={() => {
              setClickedCondition(ConditionsType.accept);
              setConditionsBlock(prevConditions => ({
                ...prevConditions,
                [ConditionsType.accept]: INITIAL_AND_CONDITION,
              }));
            }}
          >
            Accept Conditions
          </Button>
          <Button
            variant="outline"
            leftIcon={<Plus size={16} />}
            rightIcon={<XCircle size={16} weight="fill" />}
            borderRadius="lg"
            borderColor="red.50"
            color="red.500"
            bg="red.200"
            fontSize="xs"
            fontWeight="normal"
            p="2"
            disabled={!!conditionsBlock[ConditionsType.reject].length}
            onClick={() => {
              setClickedCondition(ConditionsType.reject);
              setConditionsBlock(prevConditions => ({
                ...prevConditions,
                [ConditionsType.reject]: INITIAL_AND_CONDITION,
              }));
            }}
          >
            Reject Conditions
          </Button>
          <Button
            variant="outline"
            leftIcon={<Plus size={16} />}
            rightIcon={
              <Box p="0.5" borderRadius="full" bg="linear-gradient(37deg, #A5C0FB -18.87%, #598EF4 125.28%)">
                <Eye size={12} color="white" />
              </Box>
            }
            borderRadius="lg"
            borderColor="blueGray.100"
            color="blue.400"
            bg="blue.100"
            fontSize="xs"
            fontWeight="normal"
            p="2"
            disabled={!!conditionsBlock[ConditionsType.manual_review].length}
            onClick={() => {
              setClickedCondition(ConditionsType.manual_review);
              setConditionsBlock(prevConditions => ({
                ...prevConditions,
                [ConditionsType.manual_review]: INITIAL_AND_CONDITION,
              }));
            }}
          >
            Manual Review Conditions
          </Button>
        </Flex>
        {conditionsBlock[ConditionsType.accept].length ? (
          <div ref={acceptRef}>
            <ConditionsBlock type={ConditionsType.accept} />
          </div>
        ) : null}
        {conditionsBlock[ConditionsType.reject].length ? (
          <div ref={rejectRef}>
            <ConditionsBlock type={ConditionsType.reject} />
          </div>
        ) : null}
        {conditionsBlock[ConditionsType.manual_review].length ? (
          <div ref={manualReviewRef}>
            <ConditionsBlock type={ConditionsType.manual_review} />
          </div>
        ) : null}
      </Flex>
    </>
  );
}

function ConditionHeader(): ReactElement {
  return (
    <Text p="4" fontWeight="500">
      Conditions
    </Text>
  );
}

export default ConditionsWrapper;
