import { baseTheme } from '@bureau/components';
import { extendTheme } from '@chakra-ui/react';
import { ButtonStyles as Button } from './ButtonStyles';
import { colors } from './colors';

export const getTheme = (): {} => {
  return extendTheme(
    {
      fonts: {
        heading: 'Lexend',
        body: 'Lexend',
      },
      colors,
      styles: {
        global: {
          'html, body, #root': {
            height: 'full',
            width: 'full',
            bg: 'white.100',
          },
        },
      },
      components: {
        Tabs: {
          baseStyle: {
            tab: {
              _selected: {
                color: 'blue.500',
                borderBottom: '2px solid',
                borderBottomColor: 'blue.500',
                fontWeight: '600',
              },
            },
          },
        },
        Radio: {
          baseStyle: {
            control: {
              height: '22px',
              width: '22px',
              _checked: {
                color: 'blue.500',
                height: '22px',
                width: '22px',
                borderColor: 'blue.500',
                borderWidth: '1px',
                fontSize: 'xs',
              },
            },
          },
        },
        Button,
        Checkbox: {
          baseStyle: {
            control: {
              borderRadius: '4px',
            },
          },
        },
      },
    },
    baseTheme,
  );
};
