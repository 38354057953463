import React, { ReactElement, useEffect, useState } from 'react';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Heading,
  Box,
  Flex,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import SearchInput from '@/components/search-input/SearchInput';
import FactsLoader from '@/components/loader/Loader';
import { Output, SortOption } from '../types';
import useGetInputAttributes from '../queries/useGetInputAttributes';

const TableHeaderItem = ({
  value,
  sortClicked,
}: {
  value: string;
  sortClicked: (value: string, asc: boolean) => void;
}): ReactElement => {
  const [ascOrder, setAscOder] = useState(false);
  const handleSortClick = (): void => {
    sortClicked(value, ascOrder);
    setAscOder(!ascOrder);
  };

  return (
    <Flex alignItems="center">
      <Text mr={2}>{value === 'displayName' ? 'DISPLAY NAME' : 'KEY'}</Text>
      {ascOrder ? (
        <ArrowUp size={14} cursor="pointer" onClick={handleSortClick} />
      ) : (
        <ArrowDown size={14} cursor="pointer" onClick={handleSortClick} />
      )}
    </Flex>
  );
};

const TableBody = ({
  tableData,
  isLoading,
  selectedParams,
  setSelectedParams,
}: {
  tableData: Output[];
  isLoading: boolean;
  selectedParams: string[];
  setSelectedParams: (values: string[]) => void;
}): ReactElement => {
  const handleAwaitParams = (attribute: Output): void => {
    if (attribute?.key) {
      const selectedValues = [...selectedParams];
      const index = selectedValues.indexOf(`${attribute?.key}:${attribute?.displayName}`);
      if (index > -1) {
        selectedValues.splice(index, 1);
      } else selectedValues.push(`${attribute?.key}:${attribute?.displayName}`);
      setSelectedParams(selectedValues);
    }
  };

  if (isLoading) {
    return <FactsLoader />;
  }
  if (tableData?.length) {
    return (
      <Box maxH="208px" overflow="auto" pr={4}>
        {tableData.map((attribute: Output) => (
          <Flex
            key={attribute?.key}
            justifyContent="space-between"
            alignItems="center"
            color="gray.800"
            p={2}
            mb={1}
            borderRadius={4}
            cursor="pointer"
            bg={attribute?.key && selectedParams.indexOf(attribute.key) > -1 ? 'blue.100' : 'initial'}
            _even={attribute?.key && selectedParams.indexOf(attribute.key) < 0 ? { bg: 'gray.50' } : {}}
            onClick={() => handleAwaitParams(attribute)}
          >
            <Flex>
              <Checkbox
                as="div"
                mr={2}
                value={`${attribute?.key}:${attribute?.displayName}`}
                isChecked={
                  attribute?.key && attribute?.displayName
                    ? selectedParams?.indexOf?.(`${attribute?.key}:${attribute?.displayName}`) >= 0
                    : false
                }
              />
              <Box>{attribute?.displayName}</Box>
            </Flex>
            {attribute?.key && (
              <Box px={2} py={1} bg="gray.200" borderRadius={4}>
                {attribute.key}
              </Box>
            )}
          </Flex>
        ))}
      </Box>
    );
  }
  return (
    <Box color="gray.500" textAlign="center" my={8}>
      No attributes found
    </Box>
  );
};

export const AwaitAttributesModal = ({
  parameters,
  onCancel,
  onAdd,
}: {
  parameters?: string[];
  onCancel: () => void;
  onAdd: (params: string[]) => void;
}): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState<Output[]>([]);
  const [selectedParams, setSelectedParams] = useState<string[]>([]);

  const { data: inputAttributes, isLoading } = useGetInputAttributes();

  useEffect(() => {
    if (searchTerm) {
      const filteredData = inputAttributes?.filter(item => item.key?.toLowerCase().includes(searchTerm.toLowerCase()));
      setTableData(filteredData || []);
    } else if (inputAttributes) setTableData(inputAttributes);
  }, [searchTerm, inputAttributes]);

  useEffect(() => {
    setSelectedParams(parameters || []);
  }, [parameters]);

  const handleSort = (key: string, ascOrder: boolean): void => {
    const cloneAttributes = tableData ? [...tableData] : [];
    if (ascOrder) {
      cloneAttributes.sort((a, b) => ((a as SortOption)[key] < (b as SortOption)[key] ? 1 : -1));
    } else {
      cloneAttributes.sort((a, b) => ((a as SortOption)[key] > (b as SortOption)[key] ? 1 : -1));
    }
    setTableData(cloneAttributes);
  };

  return (
    <Modal isOpen onClose={onCancel} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent py={2} w="418px" minH="480px">
        <ModalHeader fontSize="md" fontWeight="500" color="gray.800">
          Awaits
          <Heading as="h6" fontSize="xs" fontWeight="light" color="gray.600" mt={2}>
            Pause the execution and return the evaluation state to transaction service.
          </Heading>
        </ModalHeader>
        <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
        <ModalBody pr={0}>
          <Box pr={6}>
            <SearchInput
              setSearchTerm={setSearchTerm}
              placeholder="Search Values"
              styles={{ maxW: '100%', _placeholder: { fontSize: 'xs' } }}
            />
          </Box>
          <Box mt={5} className="parameters-table" fontSize="xs">
            {selectedParams?.length > 0 && (
              <Box color="gray.800" fontWeight="medium" mb={4} pr={6}>
                {`${selectedParams.length} ${selectedParams.length === 1 ? 'Value is' : 'Values are'} Selected`}
              </Box>
            )}
            <Flex justifyContent="space-between" color="gray.500" pb={2} pr={6} fontWeight="light">
              <TableHeaderItem value="displayName" sortClicked={handleSort} />
              <TableHeaderItem value="key" sortClicked={handleSort} />
            </Flex>
            <TableBody
              tableData={tableData}
              isLoading={isLoading}
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
            />
          </Box>
        </ModalBody>
        <ModalFooter pt={3} pb={2}>
          <Button
            h="38px"
            w="124px"
            color="blue.800"
            bg="purple.300"
            borderRadius="lg"
            fontSize="sm"
            fontWeight="300"
            _hover={{ opacity: '0.8' }}
            mr={3}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            h="38px"
            w="124px"
            color="white"
            bg="blue.500"
            fontSize="sm"
            borderRadius="lg"
            fontWeight="300"
            onClick={() => onAdd(selectedParams)}
            _disabled={{ color: 'gray.600', bg: 'gray.200' }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AwaitAttributesModal;
