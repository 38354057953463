import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

interface WebhookEventsResponse {
  eventTypes: Array<string>;
}

export const useGetWebhookEvents = (): UseQueryResult<WebhookEventsResponse, AxiosError> => {
  return useQuery(['webhook-events'], getWebhooksEvents(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const getWebhooksEvents = () => {
  return async () => {
    const res = await bureauPlatformApi.get<WebhookEventsResponse>(`/platform/webhooks/events`, {});
    return res?.data;
  };
};
