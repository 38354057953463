import React from 'react';
import { Cell } from 'react-table';
import { Flex, Text } from '@chakra-ui/react';
import { UserRole } from '@/screens/admin/types';
import { UserRoles } from '../../utils/types';
import { getCellStyle } from '../../utils/getCellStyle';

export const UserInvitationStatusCell = ({ value, row }: Cell): React.ReactElement => {
  if (
    value &&
    (row.original as { roles: UserRole[] }).roles.findIndex(
      (role: UserRole) => role.name?.toLowerCase() === UserRoles.OWNER,
    ) < 0
  ) {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-around"
        pl={3}
        pt={1}
        pb="5px"
        pr={3}
        fontWeight="500"
        width="fit-content"
        borderRadius="6px"
        {...getCellStyle(value)}
      >
        <Text fontSize="xs" pl={1} textTransform="capitalize" fontWeight={300}>
          {value}
        </Text>
      </Flex>
    );
  }
  return <></>;
};
