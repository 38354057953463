import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { GetSupplierInputResponse, WorkflowInputParams } from '../types';

export const useGetSupplierInputParams = (
  id: string,
  enabled = true,
): UseQueryResult<WorkflowInputParams, AxiosError> => {
  return useQuery('supplier-input-details', getSupplierInputDetails(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled,
    select: data => {
      const newInputParams = Object.values(data.inputs).map(input => ({
        ...input,
        isOptional: input.optionality === 'OPTIONAL',
      }));
      return { inputParameters: newInputParams } as WorkflowInputParams;
    },
  });
};

const getSupplierInputDetails = (id: string) => {
  return async () => {
    const url = window.location.href.includes('stg1')
      ? `${process.env.REACT_APP_STG1_URL}/suppliers/input-output?supplierList=["${id}"]`
      : `/suppliers/input-output?supplierList=["${id}"]`;
    const res = await bureauPlatformApi.get<GetSupplierInputResponse>(url);
    return res.data;
  };
};

export default useGetSupplierInputParams;
