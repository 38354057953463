import React, { useEffect, useMemo } from 'react';
import { Text, Box, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex } from '@chakra-ui/react';
import { Files } from 'phosphor-react';
import { useDrag } from 'react-dnd';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { colors } from '@/utils/colors';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { CapabilityPropTypes, DRAG_ITEM_TYPE, DropItemType, OutputV2, ResponseInputsV2 } from './types';
import { WorkflowAtom } from './states/workflowAtom';
import { InputSection, OutputSection } from './components/InputOutputSection';
import { CONDITIONS_DATA } from './constant';
import AsyncTag from './components/AsyncTag';
import { Column } from '../capabilitiesV2/workflow-creation/types';
import { generateAwaitNodeValue } from './utils/utils';

interface DropResult {
  type: string;
  parentId: string;
}

function Capability({ capability }: { capability: CapabilityPropTypes }): React.ReactElement {
  const { name, description, isAsync } = capability;
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const addedCapabilities = useRecoilValue(WorkflowAtom);
  const isDragAdded = useMemo(() => addedCapabilities.some(column => column.metadata.capabilityId === capability.id), [
    addedCapabilities,
    capability,
  ]);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: DRAG_ITEM_TYPE.CAPABILITY,
    item: capability,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        onDragEnd(item, dropResult.type, dropResult.parentId);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  function onDragEnd(item: DropItemType, type: string, parentID: string): void {
    const awaitNode = generateAwaitNodeValue(item.object?.columns || []);
    const columns = [awaitNode, ...(item.object?.columns || [])] as Column[];
    let updatedColumnObject =
      columns.map(column => ({
        ...column,
        metadata: {
          capabilityId: item.id,
          capabilityName: item.name,
          kind: type === 'source' ? 'source' : 'branch',
          parentID,
          branch: type,
          description: item.description,
          isAsync: item.isAsync,
        },
      })) ?? [];

    setWorkflow(prevWorkFlow => {
      let conditions;
      const parentItem = prevWorkFlow.find(column => column.id === parentID);
      if (type === 'Branch A' && parentItem?.metadata.branch) {
        const conditionTag = `tag.${parentID}.status`;
        conditions = JSON.stringify(CONDITIONS_DATA);

        if (parentItem.metadata.branch === 'branch A') {
          conditions = conditions
            .replace('tag.gerua_887b25acb9eb401b95e486c3fc123061.status', conditionTag)
            .replace('Success', 'OK');
        } else {
          conditions = conditions
            .replace('tag.gerua_887b25acb9eb401b95e486c3fc123061.status', conditionTag)
            .replace('Success', 'FAILED');
        }
      }
      if (type === 'Branch B' && parentItem?.metadata.branch) {
        const conditionTag = `tag.${parentID}.status`;
        conditions = JSON.stringify(CONDITIONS_DATA);

        if (parentItem.metadata.branch === 'branch A') {
          conditions = conditions
            .replace('tag.gerua_887b25acb9eb401b95e486c3fc123061.status', conditionTag)
            .replace('Success', 'FAILED');
        } else {
          conditions = conditions
            .replace('tag.gerua_887b25acb9eb401b95e486c3fc123061.status', conditionTag)
            .replace('Success', 'OK');
        }
      }
      if (conditions) {
        const finalConditions = JSON.parse(conditions);
        updatedColumnObject = updatedColumnObject.map(column => {
          if (column.nodes[0].kind === 'AWAIT') {
            return column;
          }
          return {
            ...column,
            nodes: column.nodes.map(node => ({
              ...node,
              conditions: finalConditions,
            })),
          };
        });
      }
      const isAlreadyAdded = prevWorkFlow.some(column => column.metadata.capabilityId === item.id);

      if (isAlreadyAdded) {
        return prevWorkFlow;
      }
      return [...prevWorkFlow, ...updatedColumnObject];
    });
  }

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInputs(): ResponseInputsV2[] {
    const capabilityInputs = capability.object?.columns
      .flatMap(column => column.nodes)
      .flatMap(nodes => nodes.inputs)
      .filter(item => item !== null) as ResponseInputsV2[];

    // get Inputs for the capability which are added in the workflow
    const addedCapabilityInputs = addedCapabilities
      .filter(object => object.metadata.capabilityId === capability.id)
      .flatMap(column => column.nodes)
      .flatMap(node => node.inputs)
      .filter(item => item !== null) as ResponseInputsV2[];

    // condition added to sync the inputs with the input config panel(as inputs which are configured as some service outputs need to remove)
    return (isDragAdded ? addedCapabilityInputs : capabilityInputs) || [];
  }

  function getOutputs(): OutputV2[] {
    const capabilityInput = capability.object?.columns
      .flatMap(column => column.nodes)
      .flatMap(nodes => nodes.outputs)
      .filter(item => item !== null) as OutputV2[];
    return capabilityInput || [];
  }
  return (
    <AccordionItem border="none" ref={drag}>
      {({ isExpanded, isDisabled }) => (
        <Box
          border="1px"
          bg="white"
          borderRadius="lg"
          p="2"
          borderColor={isExpanded ? 'blue.200' : 'gray.200'}
          boxShadow={isExpanded ? `0px 0px 0px 2px ${colors.shadows.blue}` : 'none'}
          opacity={isDragAdded || isDragging ? 0.5 : 1}
        >
          <Box as="h2">
            <AccordionButton p="0" _hover={{ bg: 'white' }} _focus={{ boxShadow: 'none' }}>
              <Flex as="span" flex="1" textAlign="left" direction="column" gridGap="2">
                <Flex gridGap="2" alignItems="center">
                  <Box p="2" bg="white.50" borderRadius="50%">
                    <Files size={16} color="#2c303e" weight="thin" />
                  </Box>
                  <Text fontSize="sm" color="gray.800" flexGrow={1} w="44">
                    {name}
                  </Text>
                  {isAsync && <AsyncTag />}
                  <AccordionIcon color="gray.500" alignSelf="center" />
                </Flex>
                <Text
                  fontSize="xs"
                  fontWeight="300"
                  color="gray.600"
                  lineHeight="160%"
                  inlineSize="308px"
                  overflowWrap="break-word"
                >
                  {description}
                </Text>
              </Flex>
            </AccordionButton>
          </Box>
          <AccordionPanel px="0" py="2" mt="2" display="flex" flexDirection="column" gridGap="4">
            {getInputs().length ? <InputSection sectionName="input" data={getInputs()} /> : null}
            <OutputSection sectionName="output" data={getOutputs()} />
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
}

export default Capability;
