import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Text, Grid, GridItem, Icon, Tooltip } from '@chakra-ui/react';
import { CaretDown } from 'phosphor-react';
import SearchInput from '@/components/search-input/SearchInput';
import useDebounce from '@/hooks/useDebounce';
import FactsLoader from '@/components/loader/Loader';
import useGetServices from '../queries/useGetServices';
import { Service } from '../types';

const serviceTagStyle = {
  fontSize: 'xs',
  fontWeight: '400',
  color: 'gray.900',
  py: 2,
  px: 3,
  mb: 1,
  mr: 1,
  borderRadius: 'lg',
  _hover: { bg: 'gray.200' },
};

const ServicesList = ({
  serviceIds,
  selectedId,
  onAddService,
}: {
  serviceIds: string[];
  selectedId?: string;
  onAddService: (service: Service) => void;
}): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [services, setServices] = useState<Service[]>([]);
  const [showAllServices, setShowAllServices] = useState(false);
  const { data: allServices, isLoading, error } = useGetServices(false);
  const searchItem = useDebounce(searchTerm.toLowerCase(), 800);
  const serviceExists = (serviceId?: string): boolean => {
    if (serviceId) return serviceIds.indexOf(serviceId) >= 0;
    return false;
  };

  const handleServicesScroll = (event: Event): void => {
    event.stopPropagation();
  };

  useEffect(() => {
    const scrollContainer = document.querySelector('.services-block');
    scrollContainer?.addEventListener('wheel', handleServicesScroll);
    return () => {
      scrollContainer?.removeEventListener('wheel', handleServicesScroll);
    };
  }, [showAllServices]);

  useEffect(() => {
    if (searchItem) {
      setServices(
        allServices
          ? allServices.filter(
              service => service.displayName && service.displayName?.toLowerCase()?.indexOf(searchItem) >= 0,
            )
          : [],
      );
    } else setServices(allServices || []);
  }, [searchItem, allServices]);

  const renderServices = useMemo((): ReactElement => {
    const limitedServices = [...(services || [])];
    if (!showAllServices) limitedServices.splice(8);
    if (isLoading) {
      return <FactsLoader />;
    }

    if (error) {
      return (
        <Text fontSize="xs" color="gray.400" textAlign="center">
          Something went wrong while fetching services
        </Text>
      );
    }

    if (!limitedServices.length) {
      return (
        <Box color="gray.400" fontSize="xs" fontWeight="light" textAlign="center" mt={4}>
          No services found
        </Box>
      );
    }

    return (
      <Grid className="services-block" templateColumns="repeat(2, 1fr)" mt={4} overflow="auto">
        {limitedServices.map(service => (
          <Box key={service?.id}>
            {serviceExists(service?.id) ? (
              <Tooltip
                label={`${service?.displayName} service is already being used in this workflow.`}
                bg="gray.800"
                color="gray.50"
                placement="bottom"
                px={3}
                py={2}
                borderRadius="lg"
                fontWeight="light"
                fontSize="xs"
                w="222px"
                hasArrow
              >
                <GridItem
                  {...serviceTagStyle}
                  cursor="not-allowed"
                  {...(selectedId === service?.id ? { bg: 'gray.200' } : { opacity: '0.5' })}
                >
                  {service?.label}
                </GridItem>
              </Tooltip>
            ) : (
              // <>
              //   <GridItem {...serviceTagStyle} cursor="pointer" onClick={() => onAddService(service)}>
              //     {service?.label}
              //   </GridItem>
              // </>
              <GridItem {...serviceTagStyle} cursor="pointer" onClick={() => onAddService(service)}>
                {service?.label}
              </GridItem>

              // <>
              //   {!service.active ? (
              //     <Tooltip
              //       label="Contact sales to enable the capability"
              //       bg="gray.800"
              //       color="gray.50"
              //       placement="bottom-start"
              //       px={3}
              //       py={2}
              //       borderRadius="lg"
              //       fontWeight="light"
              //       fontSize="xs"
              //       w="222px"
              //       hasArrow
              //     >
              //       <Flex
              //         fontSize="xs"
              //         fontWeight="medium"
              //         color="gray.900"
              //         py={2}
              //         px={3}
              //         mb={1}
              //         mr={1}
              //         borderRadius="lg"
              //         bg="orange.100"
              //         opacity=".6"
              //         cursor="not-allowed"
              //         align="center"
              //       >
              //         <Text flex="1" color="orange.500">
              //           {service?.label}
              //         </Text>
              //         <ShieldStar size={18} color={colors.orange[500]} />
              //       </Flex>
              //     </Tooltip>
              //   ) : (
              //     <GridItem {...serviceTagStyle} cursor="pointer" onClick={() => onAddService(service)}>
              //       {service?.label}
              //     </GridItem>
              //   )}
              // </>
            )}
          </Box>
        ))}
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, showAllServices]);

  return (
    <>
      <SearchInput
        setSearchTerm={setSearchTerm}
        placeholder="Search Services"
        styles={{ _placeholder: { fontSize: 'xs' } }}
      />
      {renderServices}
      {!showAllServices && services && services.length > 8 && !isLoading && (
        <Text
          color="blue.500"
          fontSize="xs"
          fontWeight="400"
          cursor="pointer"
          w="fit-content"
          py={2}
          px={3}
          mt={2}
          _hover={{ bg: 'blue.100', borderRadius: 'lg' }}
          onClick={() => setShowAllServices(true)}
        >
          {`View ${services.length - 8}+ more services`}
          <Icon boxSize={3} as={CaretDown} weight="bold" ml={1} />
        </Text>
      )}
    </>
  );
};

export default ServicesList;
