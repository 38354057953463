const appendZero = (number: number): string => {
  // appends zero if length is 1
  const string = number.toString();
  return string.length === 1 ? 0 + string : string;
};

export const formatCurrentTime = (): string => {
  // returns date in DDMMHHMMSS format
  const now = new Date();
  return (
    appendZero(now.getDate()) +
    appendZero(now.getMonth() + 1) +
    appendZero(now.getHours()) +
    appendZero(now.getMinutes()) +
    appendZero(now.getSeconds())
  );
};
