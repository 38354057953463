import React from 'react';
import { Cell } from 'react-table';
import StatusText from '@/components/status-text/StatusText';
import { AwaitParameterResponse, StatusColumnProps } from '../../utils/types';
import { getOutcome } from '../../utils/getOutcome';

export const StatusCell = ({ value, row, column }: Cell): React.ReactElement => {
  const { type } = column as StatusColumnProps;
  return (
    <StatusText
      status={getOutcome(value)}
      // icon={getIcon(value)}
      awaitData={row?.original as AwaitParameterResponse}
      type={type}
    />
  );
};
