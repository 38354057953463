/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateUUID } from '.';
import { NestedCondition } from '../../capabilitiesV2/workflow-creation/components/workflow-properties/types';

export function addObjectAtPath(
  obj: NestedCondition,
  path: (string | number)[],
  newValue: NestedCondition,
): NestedCondition | NestedCondition[] | undefined {
  let currentObject: NestedCondition | NestedCondition[] | undefined = obj;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of path) {
    if (Array.isArray(currentObject)) {
      if (typeof key === 'number' && key < currentObject.length) {
        currentObject = currentObject[key];
      } else {
        return undefined;
      }
    } else if (typeof currentObject === 'object' && currentObject !== null) {
      if (currentObject[key] !== undefined) {
        currentObject = currentObject[key] as NestedCondition | NestedCondition[];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  if (Array.isArray(currentObject)) {
    currentObject.push(newValue);
    return obj; // Return the top-level object
  }
  return undefined;
}

export function findObjectAtPath(
  obj: NestedCondition,
  path: (string | number)[],
): NestedCondition | NestedCondition[] | undefined {
  let currentObject: NestedCondition | NestedCondition[] | undefined = obj;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of path) {
    if (Array.isArray(currentObject)) {
      const index = key as number;
      if (index >= 0 && index < currentObject.length) {
        currentObject = currentObject[index];
      } else {
        return undefined;
      }
    } else if (typeof currentObject === 'object' && currentObject !== null) {
      if (currentObject[key] !== undefined) {
        currentObject = currentObject[key] as NestedCondition | NestedCondition[];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  return currentObject;
}

export function updateObject(obj: any, path: (string | number)[], newValue: NestedCondition): any {
  // Create a copy of the original object to avoid mutating it.
  const newObj = Array.isArray(obj) ? [...obj] : { ...obj };

  // Base case: If the path is empty, assign the new value and return the modified object.
  if (path.length === 0) {
    return newValue;
  }

  // Get the next key in the path.
  const key = path[0];

  // Check if the current object is an object or an array.
  if (typeof newObj === 'object' && newObj !== null) {
    if (Array.isArray(newObj) && typeof key === 'number' && key >= 0 && key < newObj.length) {
      // If it's an array and the key is a valid index, update the array element.
      newObj[key] = updateObject(newObj[key], path.slice(1), newValue);
      // eslint-disable-next-line no-prototype-builtins
    } else if (newObj.hasOwnProperty(key)) {
      // If it's an object and the key exists, update the object property.
      newObj[key] = updateObject(newObj[key], path.slice(1), newValue);
    }
  }

  // Return the modified object.
  return newObj;
}

// export function removeObject(obj: any, path: (string | number)[]): any {
//   // Create a copy of the original object to avoid mutating it.
//   const newObj = Array.isArray(obj) ? [...obj] : { ...obj };

//   // Base case: If the path is empty, return the modified object (property removed).
//   if (path.length === 0) {
//     return newObj;
//   }

//   // Get the next key in the path.
//   const key = path[0];

//   // Check if the current object is an object or an array.
//   if (typeof newObj === 'object' && newObj !== null) {
//     if (Array.isArray(newObj) && typeof key === 'number' && key >= 0 && key < newObj.length) {
//       // If it's an array and the key is a valid index, remove the array element.
//       newObj.splice(key, 1);
//     } else if (newObj.hasOwnProperty(key)) {
//       // If it's an object and the key exists, remove the object property.
//       delete newObj[key];
//     }
//   }

//   // Continue the removal process recursively.
//   return removeObject(newObj, path.slice(1));
// }

export function removeObjectAtPath(
  obj: NestedCondition,
  path: (string | number)[],
): NestedCondition | NestedCondition[] | undefined {
  let currentObject: NestedCondition | NestedCondition[] | undefined = obj;
  const currentPath = path.slice(0, path.length - 1);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of currentPath) {
    if (Array.isArray(currentObject)) {
      if (typeof key === 'number' && key < currentObject.length) {
        currentObject = currentObject[key];
      } else {
        return undefined;
      }
    } else if (typeof currentObject === 'object' && currentObject !== null) {
      if (currentObject[key] !== undefined) {
        currentObject = currentObject[key] as NestedCondition | NestedCondition[];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  if (Array.isArray(currentObject)) {
    const indexToRemove = path[path.length - 1] as number;
    currentObject.splice(indexToRemove, 1);
    return obj;
  }
  // if (typeof currentObject === 'object') {
  //   delete currentObject[path[path.length - 1]];
  //   return obj;
  // }
  return obj;
}

export function deleteNestedPath(obj: any, path: (string | number)[]): any {
  if (!path.length) {
    return obj;
  }

  const keyToDelete = path[0];
  if (keyToDelete in obj) {
    if (path.length === 1) {
      delete obj[keyToDelete];
    } else if (typeof obj[keyToDelete] === 'object') {
      obj[keyToDelete] = deleteNestedPath(obj[keyToDelete], path.slice(1));
    }
  }

  return obj;
}

export const generateAwaitNodeValue = (workflowColumns: any): any => {
  const nodeId = generateUUID();
  const capabilityUserInputs =
    [...(workflowColumns || [])]
      .flatMap(column => column.nodes)
      .flatMap(node => node.inputs)
      .filter(input => input?.locationKind !== 'source' && !input?.isOptional)
      .filter(input => !input?.isStatic)
      .filter(input => input?.locationType) || [];
  const awaitValues = [
    ...capabilityUserInputs?.map(input => `${input?.location?.split('.')[2]}:${input?.displayName}`),
  ];
  const serviceId = [...(workflowColumns || [])].flatMap(columns => columns.nodes).flatMap(node => node.id) || [];
  const awaitNode = {
    id: `column_${nodeId}`,
    nodes: [
      {
        id: `await_${nodeId}`,
        dependencies: ['start_ghdvng'],
        kind: 'AWAIT',
        name: `Awaits_${nodeId}`,
        inputs: [],
        outputs: [
          {
            key: 'status',
            namespace: 'Awaits_149d1fef08ba4e8e8798b4dd933e9f5a',
            type: 'STRING',
            description: '',
            format: '',
            regex: '',
            constraints: {
              numerical_max: null,
              numerical_min: null,
              length_max: null,
              length_min: null,
            },
            template: '',
            parentKey: '',
            location: '',
            displayName: 'status',
            allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
          },
        ],
        open: false,
        columnPosition: 1,
        outputDependencies: [
          {
            columnPosition: 2,
            nodePosition: 0,
          },
        ],
        conditions: [],
        awaits: serviceId.map(service => ({
          nodeName: 'start_ghdvng',
          serviceId: service,
          values: awaitValues,
        })),
      },
    ],
    metadata: {
      capabilityId: 'item',
      capabilityName: 'item',
      kind: 'source',
      parentID: 'a',
      branch: '',
      description: 'a',
      isAsync: false,
    },
  };
  return awaitNode;
};
