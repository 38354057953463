import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash-es';
// eslint-disable-next-line import/no-extraneous-dependencies
import produce from 'immer';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Workflow, WorkflowNode, Column, NodeKind } from '../types';
import { workflowResponseInput } from '../constants';
import { NestedCondition, WhenCondition, ConditionData, AndOrCondition } from '../components/workflow-properties/types';
import { AddedServiceIds, AddedTags, WorkflowModified } from '../states/workflowChart';

export const useDeleteNodeHandler = (
  json: Workflow,
  setWorkflowJson: (json: Workflow) => void,
): {
  setDeleteNodePosition: (val: { columnPosition: number; nodePosition: number }) => void;
  getUpdatedColumnsAfter: (
    cloneColumns: Column[],
    columnPosition: number,
    nodePosition: number,
    action: string,
    modifiedNodeId: string,
  ) => Column[];
} => {
  const [deleteNodePosition, setDeleteNodePosition] = useState({
    columnPosition: 0,
    nodePosition: 0,
  });
  const [serviceIds, setServiceIds] = useRecoilState(AddedServiceIds);
  const [tagNames, setTagNames] = useRecoilState(AddedTags);
  const setWorkflowModified = useSetRecoilState(WorkflowModified);

  const findNdRemoveCondition = (details: NestedCondition, deletedNodeId: string): NestedCondition => {
    return produce(details, draft => {
      (draft[Object.keys(draft)[0]] as NestedCondition[])?.forEach(
        (el: NestedCondition | ConditionData, index: number) => {
          if (
            Object.keys(el).length > 0 &&
            Object.keys(el)[0] !== AndOrCondition.AND &&
            Object.keys(el)[0] !== AndOrCondition.OR
          ) {
            if (((el as ConditionData)[Object.keys(el)[0]]?.[0]?.obj as string)?.indexOf(deletedNodeId) > -1) {
              (draft[Object.keys(draft)[0]] as NestedCondition[]).splice(index, 1);
            }
          }
          if (Object.keys(el)[0] === AndOrCondition.AND || Object.keys(el)[0] === AndOrCondition.OR) {
            // eslint-disable-next-line no-param-reassign
            (draft[Object.keys(draft)[0]] as NestedCondition[])[index] = findNdRemoveCondition(
              el as NestedCondition,
              deletedNodeId,
            );
            const key = Object.keys((draft[Object.keys(draft)[0]] as NestedCondition[])[index])[0];
            if (!((draft[Object.keys(draft)[0]] as NestedCondition[])[index][key] as NestedCondition[]).length)
              (draft[Object.keys(draft)[0]] as NestedCondition[]).splice(index, 1); // if group has only one condition and its been removed, this code removes the group itself
          }
        },
      );
    });
  };

  const getUpdatedConditions = (nextNode: WorkflowNode, deletedNodeId: string): WhenCondition[] => {
    // find and remove condition related to the deleted node
    let cloneConditions: NestedCondition = {};
    if (nextNode?.conditions?.length)
      cloneConditions = findNdRemoveCondition(nextNode?.conditions?.[0]?.when as NestedCondition, deletedNodeId);
    return cloneConditions &&
      Object.keys(cloneConditions).length &&
      (cloneConditions[Object.keys(cloneConditions)[0]] as NestedCondition[]).length
      ? [{ when: cloneConditions }]
      : []; // checks if condition is empty. also checks if  and / or array is empty.
  };

  const getUpdatedNodes = (
    node: WorkflowNode,
    columnPosition: number,
    nodePosition: number,
    action: string,
  ): WorkflowNode => {
    // this fn updates column position of following nodes if the deleted node is the only node in that column. also the column positions in outputDependencies.
    // removes the deleted node id from dependencies of succeeding nodes
    // removes conditions linked to the deleted node from all other succeeding nodes
    const cloneNd = { ...node };
    if (action === 'delete') {
      if (json.columns[columnPosition].nodes.length === 1) {
        cloneNd.columnPosition = cloneNd.columnPosition ? cloneNd.columnPosition - 1 : cloneNd.columnPosition; // changes column positions of nodes in succeeding columns
        cloneNd.outputDependencies = cloneNd?.outputDependencies?.map(dependPositions => {
          const cloneDependPositions = { ...dependPositions };
          cloneDependPositions.columnPosition -= 1; // decrements column position in outputDependencies of following nodes if column is removed with the node.
          return cloneDependPositions;
        });
      }
      const dependsId = cloneNd.dependencies.indexOf(json.columns[columnPosition].nodes[nodePosition]?.id);
      if (dependsId > -1) cloneNd.dependencies.splice(dependsId, 1); // removed deleted node id if present in dependencies of other nodes.
    }
    cloneNd.conditions = getUpdatedConditions(cloneNd, json.columns[columnPosition].nodes[nodePosition]?.id);
    const newInputs = cloneNd?.inputs ? [...cloneNd.inputs] : [];
    if (newInputs) {
      newInputs.forEach((item, index) => {
        if (item.location?.includes(json.columns[columnPosition].nodes[nodePosition]?.id)) {
          newInputs[index] = { ...workflowResponseInput };
        }
      });
    }
    cloneNd.inputs = newInputs;
    return cloneNd;
  };

  const getUpdatedColumnsAfter = (
    cloneColumns: Column[],
    columnPosition: number,
    nodePosition: number,
    action: string,
    modifiedNodeId: string,
  ): Column[] => {
    const selectedNode = cloneColumns[columnPosition].nodes[nodePosition];
    if (selectedNode.kind === NodeKind.service) {
      // updates service ids used in chart
      const svcIds = [...serviceIds].filter(id => id !== modifiedNodeId);
      setServiceIds(svcIds);
    } else if (selectedNode.kind === NodeKind.tag) {
      const tags = [...tagNames].filter(name => name !== modifiedNodeId);
      setTagNames(tags);
    }
    const columnsAfter = cloneColumns.splice(columnPosition + 1); // to get columns after the new column
    return columnsAfter.map((cols: Column) => {
      const cloneCols = { ...cols };
      cloneCols.nodes = cloneCols.nodes.map((nd: WorkflowNode) => {
        return getUpdatedNodes(nd, columnPosition, nodePosition, action);
      });
      return cloneCols;
    });
  };

  const deleteNode = (columnPosition: number, nodePosition: number): void => {
    setWorkflowModified(true);
    const cloneColumns = cloneDeep(json.columns);
    const selectedNode = cloneColumns[columnPosition]?.nodes[nodePosition];
    const columnsAfter = getUpdatedColumnsAfter(
      cloneColumns,
      columnPosition,
      nodePosition,
      'delete',
      selectedNode.kind === NodeKind.tag ? selectedNode.name : selectedNode.id,
    );
    if (json.columns[columnPosition].nodes.length > 1) {
      const cloneNodes = cloneDeep(cloneColumns[columnPosition].nodes);
      let nodesAfter = cloneNodes.splice(nodePosition + 1);
      nodesAfter = nodesAfter.map((nd: WorkflowNode) => {
        return produce(nd, draft => {
          // eslint-disable-next-line no-param-reassign
          if (draft.nodePosition) draft.nodePosition -= 1;
        });
      });
      cloneNodes.splice(nodePosition);
      cloneColumns[columnPosition].nodes = [...cloneNodes, ...nodesAfter];
    } else cloneColumns.splice(columnPosition);
    setWorkflowJson({ columns: [...cloneColumns, ...columnsAfter] });
  };

  useEffect(() => {
    if (deleteNodePosition?.columnPosition) {
      deleteNode(deleteNodePosition.columnPosition, deleteNodePosition.nodePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteNodePosition]);

  return {
    setDeleteNodePosition,
    getUpdatedColumnsAfter,
  };
};

export default useDeleteNodeHandler;
