import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

export const useDocumentation = (): UseQueryResult<{ url: string }, AxiosError> => {
  return useQuery('documentation', getDocumentation(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    enabled:
      !!bureauPlatformApi.defaults.headers.common.Authorization &&
      !!bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'],
  });
};

const getDocumentation = () => {
  return async () => {
    const res = await bureauPlatformApi.get(`v1/auth/docs`);
    return res.data;
  };
};
