import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { GetWorkflowResponse } from '@/screens/workflowv2/types';
import { useLocation } from 'react-router-dom';

export const useGetWorkflow = (id: string, enabled = true): UseQueryResult<GetWorkflowResponse, AxiosError> => {
  const { pathname } = useLocation();
  const endpointPath = pathname.includes('templates') ? 'templates' : 'workflows';
  return useQuery('workflowById-v2', getDetails(endpointPath, id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled,
    select: data => ({ ...data, workflowObject: data.object || data.workflowObject }),
  });
};

const getDetails = (endpoint = 'workflows', id: string) => {
  return async () => {
    const res = await bureauPlatformApi.get<GetWorkflowResponse>(`/${endpoint}/${id}`);
    return res.data;
  };
};

export default useGetWorkflow;
