/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import {
  Flex,
  IconButton,
  Box,
  Heading,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Switch,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import { CaretLeft, Eye, EyeSlash, Play } from 'phosphor-react';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '@/types/common';
import FactsLoader from '@/components/loader/Loader';
import { PageError } from '@bureau/components/src';
import { Grid } from '@chakra-ui/layout';
import PaginatedTable from '@/components/paginated-table/PaginatedTable';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@/constants/constants';
import { useGetWebhookList } from './queries/useGetWebhookList';
import { useGetWebhookMessages } from './queries/useGetWebhookMessages';
import { messageColumn } from './messagesColumns';
import { ToastMessage, Webhooks, EventMessage } from './types';
import { useGetWebhookSignature } from './queries/useGetWebhookSignature';
import { useToggleWebhook } from './queries/useToggleWebhookStatus';
import { useTestWebhook } from './queries/useTestWebhook';

export const WebhookDetails = (): ReactElement => {
  const { id } = useParams() as ParamTypes;
  const { goBack } = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setIsButtonLoading] = useState(false);
  const [testButtonLoading, setTestButtonLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState('20');
  const [sortField, setSortField] = useState({ field: '', order: '' });

  const [signatureString, setSignatureString] = useState<any>();
  const [showSignature, setShowSignature] = useState(false);

  const { data, isLoading, isFetching, error, refetch } = useGetWebhookList();

  const webhookData: Webhooks = data?.data?.find(r => r.id === id) ?? {
    id: '',
    description: '',
    url: '',
    version: 0,
    disabled: false,
    filterTypes: [],
    createdAt: '',
    updatedAt: '',
  };

  const { data: webhookEventsMessage } = useGetWebhookMessages(sortField, offset, limit, webhookData.id);
  const listData: EventMessage[] = (webhookEventsMessage?.events as EventMessage[]) ?? [];
  const toast = useToast();

  const { mutateAsync: toggleApikey } = useToggleWebhook({
    onSuccess: response => {
      refetch();
    },
    onError: mutateError => {
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setIsButtonLoading(false);
    },
  });

  const { mutateAsync: webhookSignature } = useGetWebhookSignature({
    onSuccess: async response => {
      setIsButtonLoading(false);
      setSignatureString(response);
    },
    onError: mutateError => {
      setIsButtonLoading(false);
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const { mutateAsync: testWebhook } = useTestWebhook({
    onSuccess: async => {
      setTestButtonLoading(false);
      setTimeout(() => {
        toast({
          description: ToastMessage.WEBHOOK_TESTED_MESSAGE,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      });
    },
    onError: mutateError => {
      setTestButtonLoading(false);
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const { mutateAsync: toggleWebhookStatus } = useToggleWebhook({
    onSuccess: response => {
      setTimeout(() => {
        toast({
          description: ToastMessage.WEBHOOK_MODIFIED_MESSAGE,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        refetch();
      });

      refetch();
    },
    onError: mutateError => {
      toast({
        description: mutateError?.response?.data?.error?.message || ToastMessage.ERROR_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const onToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    toggleWebhookStatus({
      ...webhookData,
      disabled: !event.target.checked,
    });
  };

  const getFormatedDate = (date: string): string => {
    const formatedDate = new Date(date);
    return format(formatedDate, DATE_FORMAT);
  };

  const getBackButtonContent = (): ReactElement | null => (
    <Flex w="full" alignItems="center" color="gray.600" fontSize="md" pb={1}>
      <IconButton
        borderRadius="full"
        variant="ghost"
        aria-label="Back"
        icon={<CaretLeft size="14px" />}
        onClick={goBack}
      />
      <Text fontSize="xs">Webhooks</Text>
    </Flex>
  );

  if (isLoading || isFetching) {
    return (
      <Flex justifyContent="center" height="40vh" width="100%">
        <FactsLoader />
      </Flex>
    );
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <Flex flexDirection="column" h="full" width="100%" wordBreak="break-word" pb="4">
        <Flex justifyContent="space-between" bg="white" h="fit-content">
          <Box bg="white" pt={2} pb={2}>
            {getBackButtonContent()}
            <Flex direction="column">
              <Heading
                as="h3"
                size="md"
                fontWeight="medium"
                color="gray.900"
                pl="4"
                display="flex"
                alignItems="center"
                mb={1}
              >
                {webhookData?.description}
              </Heading>
            </Flex>
          </Box>
          <Flex justifyContent="flex-end" mr={5} pb="4" mt={14} align="center">
            <FormLabel>{!webhookData?.disabled ? 'Active' : 'Inactive'}</FormLabel>
            <Switch isChecked={!webhookData?.disabled} onChange={onToggle} colorScheme="blue" />
            <Button
              ml="4"
              mr="4"
              bg="white.50"
              borderWidth={1}
              borderColor="gray.200"
              color="gray.800"
              display="flex"
              fontSize="sm"
              borderRadius="lg"
              fontWeight="normal"
              height="44px"
              _hover={{ opacity: '0.85' }}
              onClick={onOpen}
            >
              View Expected Events
            </Button>
            <Button
              h={42}
              px={4}
              fontSize="sm"
              fontWeight="medium"
              borderWidth={1}
              borderColor="gray.300"
              bg="gray.200"
              color="gray.800"
              isLoading={testButtonLoading}
              onClick={() => testWebhook({})}
            >
              <Play style={{ marginRight: '4' }} />
              Send Test Events
            </Button>
          </Flex>
        </Flex>
        <Box mt="6" p="8" ml="6" borderRadius="sm" mr="6" bg="white">
          <Text mt="2" mb="2" color="black" fontSize="sm">
            Overview
          </Text>
          <Grid templateColumns="repeat(4, 1fr)" gap={6} justify="start" direction="row">
            <Flex mt="4" direction="column" justify="start" align="start">
              <Text fontSize="sm" color="gray.600" fontWeight="normal">
                URL
              </Text>
              <Text fontWeight="light" fontSize="sm">
                {webhookData?.url}
              </Text>
            </Flex>

            <Flex mt="4" direction="column" justify="start" align="start">
              <Text fontSize="sm" color="gray.600" fontWeight="normal">
                Signature
              </Text>
              <Flex>
                <Text isTruncated fontSize="xs">
                  {showSignature ? signatureString?.key : '**********************'}
                </Text>
                <Box
                  onClick={() => {
                    setShowSignature(!showSignature);
                    webhookSignature(webhookData?.id);
                  }}
                  ml={2}
                  cursor="pointer"
                >
                  {showSignature ? <EyeSlash height="16px" width="16px" /> : <Eye height="16px" width="16px" />}
                </Box>
                {/* <Box w={4}>
                  <CopyButton cursor="pointer" />
                </Box> */}
              </Flex>
            </Flex>

            <Flex mt="4" direction="column" justify="start" align="start">
              <Text fontSize="sm" color="gray.600" fontWeight="normal">
                Last Updated On
              </Text>
              <Text fontWeight="light" fontSize="sm">
                {getFormatedDate(webhookData?.createdAt)}
              </Text>
            </Flex>

            <Flex mt="4" direction="column" justify="start" align="start">
              <Text fontSize="sm" color="gray.600" fontWeight="normal">
                Last Created On
              </Text>
              <Text fontWeight="light" fontSize="sm">
                {getFormatedDate(webhookData?.updatedAt)}
              </Text>
            </Flex>
          </Grid>
        </Box>

        <Flex mt="4" direction="column" bg="white" p="4" h="full" ml="6" mr="6" borderRadius="lg">
          <Text mb="4">Events</Text>

          <PaginatedTable
            columns={messageColumn(listData.length || 0, toggleApikey, refetch)}
            data={listData.map(list => ({
              ...list,
              url: webhookData.url,
              webhookId: webhookData.id,
              disabled: webhookData.disabled,
            }))}
            isLoading={isLoading || isFetching}
            title="No Events Triggered"
            limit={limit}
            offset={offset}
            setLimit={setLimit}
            setOffset={setOffset}
            setSortField={setSortField}
            count={listData.length}
            hiddenColumns={['url']}
          />
        </Flex>
      </Flex>

      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="4">
          <Text fontSize="lg" fontWeight="semibold">
            Events for Webhooks
          </Text>
          <Text fontWeight="light" fontSize="sm">
            Last Updated on 30 August 2023
          </Text>
          <ModalCloseButton />
          <ModalBody pt="8" px="0">
            <Flex w="full" direction="column" borderBottomWidth={1} pb="4" borderColor="white.50" justify="start">
              {webhookData?.filterTypes?.map((event: any) => {
                const parts = event.split('.');
                const title = parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase();

                return (
                  <Flex key={Math.random()} mb="4">
                    <Text color="gray.800" fontWeight="light" fontSize="sm" flex="1">
                      {title}
                    </Text>
                    <Text
                      color="gray.800"
                      bg="white.50"
                      borderWidth={1}
                      borderColor="gray.50"
                      px="2"
                      py="1"
                      fontSize="xs"
                      fontWeight="normal"
                      borderRadius="xl"
                    >
                      {event}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
