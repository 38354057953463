import React, { ReactElement } from 'react';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { CaretDown, CaretUp } from 'phosphor-react';
import { ConditionsType } from './types';
import useConditions from './hooks/useConditions';

function ConditionsLeftBlock({
  type,
  pathArray,
}: {
  type: ConditionsType;
  pathArray: (string | number)[];
}): ReactElement {
  const { updateConditions, getCurrentConditions } = useConditions();
  function handleChange(): void {
    const currentObject = getCurrentConditions(pathArray.slice(0, pathArray.length - 1), type);
    const key = pathArray[pathArray.length - 1] === 'and' ? 'or' : 'and';
    const updatedCondition = { [key]: currentObject[pathArray[pathArray.length - 1]] };
    updateConditions(pathArray.slice(0, pathArray.length - 1), type, updatedCondition);
  }
  return (
    <Flex direction="column" ml="8" mt="8" mb="3">
      <Box h="100%" position="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          viewBox="0 0 40 125"
          fill="none"
          preserveAspectRatio="none"
          height="100%"
        >
          <g transform="scale(1, 1)">
            <path
              d="M40 1H3C1.89543 1 1 1.89543 1 3V122C1 123.105 1.89543 124 3 124H40"
              stroke="#D4DFF7"
              strokeLinejoin="round"
            />
          </g>
        </svg>
        <Box
          position="absolute"
          top="50%"
          left="6%"
          transform="translate(-50%, -50%)"
          p="3"
          borderRadius="lg"
          onClick={handleChange}
          borderColor={pathArray[pathArray.length - 1] === 'and' ? 'blue' : 'teal'}
        >
          <Box
            w="50px"
            h="50px"
            borderWidth={1}
            borderRadius="50%"
            borderColor={pathArray[pathArray.length - 1] === 'and' ? 'blueGray.100' : 'terracotta.100'}
            bg={pathArray[pathArray.length - 1] === 'and' ? 'blue.100' : 'terracotta.50'}
            color={pathArray[pathArray.length - 1] === 'and' ? 'blue.400' : 'terracotta.500'}
            textAlign="center"
            position="relative"
            cursor="pointer"
          >
            {/* Icon at the top */}
            <Box position="absolute" top="2px" left="50%" transform="translateX(-50%)">
              <CaretUp size={14} />
            </Box>

            {/* Text in the middle */}
            <Center h="full">
              <Text fontSize="xs" fontWeight="normal">
                {String(pathArray[pathArray.length - 1]).toUpperCase()}
              </Text>
            </Center>

            {/* Icon at the bottom */}
            <Box position="absolute" bottom="2px" left="50%" transform="translateX(-50%)">
              <CaretDown size={14} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default ConditionsLeftBlock;
