import React, { ReactElement } from 'react';
import { Flex, Text, Button, Image, TextProps } from '@chakra-ui/react';
import AddButtonLabel from './add-button-label/AddButtonLabel';

const EmptyListComponent = ({
  onButtonClick,
  heading,
  text,
  buttonText,
  textStyles,
}: {
  onButtonClick?: () => void;
  heading: string;
  text: string;
  textStyles?: TextProps;
  buttonText?: string;
}): ReactElement => (
  <Flex direction="column" alignItems="center" w="full" h="80%" justifyContent="center" alignSelf="center">
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Image src="/assets/icons/no-data.svg" h="130px" w="110px" />
      <Text color="gray.900" fontWeight="medium" fontSize="md" pt={2}>
        {heading}
      </Text>
      <Text fontSize="sm" color="gray.600" pt={2} fontWeight="light" textAlign="center" {...textStyles}>
        {text}
      </Text>
    </Flex>
    {buttonText && (
      <Button
        colorScheme="blue"
        size="sm"
        mt={8}
        px={4}
        py={5}
        w="180px"
        borderRadius="lg"
        fontWeight="light"
        fontSize="sm"
        onClick={() => {
          if (onButtonClick) onButtonClick();
        }}
      >
        <AddButtonLabel label={buttonText} />
      </Button>
    )}
  </Flex>
);

export default EmptyListComponent;
