import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { BulkQueryStatusValue } from '@/screens/bulk-verification/types';
import { statusDisplayNameMap } from '@/screens/bulk-verification/constants';
import { CheckCircle, Circle, CircleNotch, Clock, StopCircle, XCircle } from 'phosphor-react';
import { colors } from '@/utils/colors';
import { Flex } from '@chakra-ui/react';
import {
  getCompletedTextStyle,
  getDraftTextStyle,
  getErrorTextStyle,
  getInProgressTextStyle,
  getStoppedTextStyle,
  StatusCellStyle,
} from '@/components/paginated-table/utils/styles';
import { Text } from '@chakra-ui/layout';

const getOutcome = (statusText?: string): string => {
  switch (statusText?.toLowerCase()) {
    case BulkQueryStatusValue.DONE.toLowerCase():
      return statusDisplayNameMap.get(BulkQueryStatusValue.DONE) ?? '';
    case BulkQueryStatusValue.PROCESSING.toLowerCase():
      return statusDisplayNameMap.get(BulkQueryStatusValue.PROCESSING) ?? '';
    case BulkQueryStatusValue.UPLOADING.toLowerCase():
      return statusDisplayNameMap.get(BulkQueryStatusValue.UPLOADING) ?? '';
    case BulkQueryStatusValue.CANCELLED.toLowerCase():
      return statusDisplayNameMap.get(BulkQueryStatusValue.CANCELLED) ?? '';

    default:
      return statusDisplayNameMap.get(BulkQueryStatusValue.FAILED) ?? 'Failed';
  }
};

export const getIcon = (statusText?: string): ReactElement => {
  switch (statusText?.toLowerCase()) {
    case BulkQueryStatusValue.PROCESSING.toLowerCase():
      return <CircleNotch size={16} color={colors.orange[500]} />;
    case BulkQueryStatusValue.DONE.toLowerCase():
      return <CheckCircle size={16} color={colors.green[500]} weight="fill" />;
    case BulkQueryStatusValue.UPLOADING.toLowerCase():
      return <Clock size={16} color={colors.purple[200]} weight="fill" />;
    case BulkQueryStatusValue.FAILED.toLowerCase():
      return <XCircle size={16} color={colors.red[300]} weight="fill" />;
    case BulkQueryStatusValue.CANCELLED.toLowerCase():
      return <StopCircle size={16} color={colors.gray[700]} weight="fill" />;
    default:
      return <Circle size={8} weight="fill" />;
  }
};

export const getBulkQueryStatusStyle = (val?: string): StatusCellStyle => {
  switch (val?.toLowerCase()) {
    case BulkQueryStatusValue.PROCESSING?.toLowerCase(): {
      return getInProgressTextStyle();
    }
    case BulkQueryStatusValue.DONE?.toLowerCase(): {
      return getCompletedTextStyle();
    }
    case BulkQueryStatusValue.CANCELLED?.toLowerCase(): {
      return getStoppedTextStyle();
    }
    case BulkQueryStatusValue.UPLOADING?.toLowerCase(): {
      return getDraftTextStyle();
    }

    default: {
      return getErrorTextStyle();
    }
  }
};

export const BulkQueryStatusCell = ({ value }: Cell): React.ReactElement => {
  return <StatusText status={value} icon={getIcon(value)} />;
};

const StatusText = ({
  status,
  icon = <Circle size={8} weight="fill" />,
}: {
  status: string;
  icon?: ReactElement;
}): ReactElement => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-around"
      px={3}
      py={1}
      fontWeight="light"
      width="fit-content"
      borderRadius="6px"
      {...getBulkQueryStatusStyle(status)}
    >
      {icon}
      <Text fontSize="xs" pl={1} textTransform="capitalize" fontWeight="light">
        {getOutcome(status).toLowerCase() || 'Unknown'}
      </Text>
    </Flex>
  );
};
