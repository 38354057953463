import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

import { Config } from '../types';

const useUpdateConfig = (
  options?: UseMutationOptions<Config, AxiosError, Config>,
): UseMutationResult<Config, AxiosError, Config> => {
  return useMutation(updateConfig, options);
};

const updateConfig = async (data: Config): Promise<Config> => {
  const { data: response } = await bureauPlatformApi.patch<Config>(`/workflows/hostedconfig`, data);
  return response || data;
};

export default useUpdateConfig;
