import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import WorkflowCreation from './workflow-creation/WorkflowCreation';
import WorkflowList from './WorkflowList';

const WorkflowsWrapper = (): React.ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <WorkflowList />
      </Route>
      <Route exact path={`${path}/:id/create`}>
        <WorkflowCreation />
      </Route>
      <Route exact path={`${path}/:id/edit`}>
        <WorkflowCreation />
      </Route>
    </Switch>
  );
};

export default WorkflowsWrapper;
