import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Plus } from 'phosphor-react';
import useConditions from './hooks/useConditions';
import { ConditionsType } from './types';
import { AND_CONDITION, OR_CONDITION } from './constant';

function ConditionDropDown({
  conditionType,
  pathArray,
  type,
}: {
  conditionType: string | number;
  pathArray: (string | number)[];
  type: ConditionsType;
}): ReactElement {
  const [showConditionDropdown, setShowConditionDropdown] = useState(false);
  const { addConditions } = useConditions();
  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setShowConditionDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!showConditionDropdown) {
    return (
      <Flex paddingTop={7} w="full" alignItems="center" color={showConditionDropdown ? 'blue.400' : 'blue.500'}>
        <Plus size={20} weight="fill" />
        <Text
          pl="10px"
          fontSize="md"
          fontWeight="normal"
          cursor="pointer"
          onClick={() => setShowConditionDropdown(!showConditionDropdown)}
        >
          Add Condition
        </Text>
      </Flex>
    );
  }
  return (
    <Flex
      p={4}
      gridGap="4"
      boxShadow="1px 1px 8px 0px rgba(31, 51, 203, 0.09)"
      borderRadius="lg"
      bg="white"
      w="fit-content"
      ref={boxRef}
    >
      <Box
        borderRadius="lg"
        bg="white"
        cursor="pointer"
        onClick={() => {
          addConditions(pathArray, type, {
            eq: [
              {
                const: true,
              },
              {
                const: '',
              },
            ],
          });
          setShowConditionDropdown(false);
        }}
      >
        <Box
          px={4}
          py={2}
          fontSize="xs"
          borderWidth={1}
          borderRadius="lg"
          borderColor={conditionType === 'or' ? 'terracotta.100' : 'blueGray.100'}
          bg={conditionType === 'or' ? 'terracotta.50' : 'blue.100'}
          color={conditionType === 'or' ? 'terracotta.500' : 'blue.400'}
          textTransform="uppercase"
          _hover={{ bg: conditionType === 'or' ? 'terracotta.100' : 'blueGray.100' }}
        >
          {conditionType}
        </Box>
      </Box>
      <Box
        px={2}
        py="6px"
        borderRadius="lg"
        bg="white"
        _hover={{ bg: 'white.50' }}
        cursor="pointer"
        onClick={() => {
          addConditions(pathArray, type, conditionType === 'and' ? OR_CONDITION : AND_CONDITION);
          setShowConditionDropdown(false);
        }}
      >
        <Flex gridGap="1" alignItems="center" fontSize="sm" color="blue.400">
          <Plus />
          Add Group
        </Flex>
      </Box>
    </Flex>
  );
}

export default ConditionDropDown;
