import React, { Dispatch, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { CaretLeft, PaperPlaneTilt } from 'phosphor-react';
import { Flex, Box, Button, useToast, Text, Input, Image } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { colors } from '@/utils/colors';
import AlertMessageModal from '@/components/AlertMessageModal';
import SaveCapabilityModal from '@/screens/capabilitiesV2/workflow-creation/components/SaveCapabilityModal';
import { WorkflowModified } from '../states/workflowChart';
import { Workflow, ToastMessage, GetCapabilityResponse } from '../types';
import { defaultWorkflowName } from '../../constants';
import WorkflowPublishModal from '../../components/publish-popup/WorkflowPublishPopup';
import { initialTryOutDetails, initialWorkflowDetails } from '../../CapabilitiesList';
import TryOutModal from '../../components/try-out-modal/TryOutModal';
import { Parameter } from '../../components/try-out-modal/types';
import { useTryOut } from '../../queries/useTryOut';
import { Capability, CapabilityDataTypes } from '../../types';
import useCapabilityCreation from '../../queries/useCapabilityCreation';
import useCapabilityUpdation from '../queries/useCapabilityUpdation';

const CapabilitiesCreationHeader = ({
  capability,
  json,
  refetch,
  showSuccessPopup,
  setShowSuccessPopup,
}: {
  capability?: CapabilityDataTypes;
  json: Workflow;
  showSuccessPopup: boolean;
  setShowSuccessPopup: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}): React.ReactElement => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const [workflowModified, setWorkflowModified] = useRecoilState(WorkflowModified);

  const [workflowName, setWorkflowName] = useState(capability?.name ?? '');
  const [nameExistsError, setNameExistsError] = useState(false);
  const [showSaveCapabilityModal, setShowSaveCapabilityModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [workflowDetails, setWorkflowDetails] = useState<Capability>(initialWorkflowDetails);
  const [tryOutResponse, setTryOutResponse] = useState('');
  const [tryOutDetails, setTryOutDetails] = useState(initialTryOutDetails);
  const [transactionId, setTransactionId] = useState('');
  const [capabilityId, setCapabilityId] = useState('');

  // const { data: capability } = useCapabilityDetails(workflowDetails.id, false);

  const { mutateAsync: updateCapability, isLoading: updateCapabilityLoading } = useCapabilityUpdation({
    onSuccess: data => {
      handleSuccess(data);
    },
    onError: error => {
      handleModifyWorkflowError(error);
    },
  });

  const { mutateAsync: createCapability, isLoading: createCapabilityLoading } = useCapabilityCreation({
    onSuccess: data => {
      handleSuccess(data);
    },
    onError: error => {
      handleModifyWorkflowError(error);
    },
  });

  const handleModifyWorkflowError = (updateError: AxiosError): void => {
    // if (updateError?.response?.status === CustomErrorCode.RECORD_EXISTS) {
    //   setNameExistsError(true);
    //   setShowSaveCapabilityModal(true);
    // } else {
    //   handleErrorToast(updateError);
    // }
    handleErrorToast(updateError);
    // setIsLoading(false);
  };

  const handleErrorToast = (error: AxiosError): void => {
    toast({
      description: ToastMessage.ERROR_MESSAGE,
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleSuccess = (data: CapabilityDataTypes): void => {
    setWorkflowModified(false);
    if (pathname.includes('create')) {
      setCapabilityId(data.id ?? '');
      setShowSuccessPopup(true);
      history.replace(`${data.id}/edit`);
    } else {
      toast({
        render: () => <WorkflowPublishToast setShowSuccessPopup={setShowSuccessPopup} />,
      });
    }
    // setIsLoading(false);
    refetch();
  };

  const navigateBack = (): void => {
    history.push('/capabilities');
  };

  const handleSaveWorkflow = (): void => {
    if (json.columns.length <= 1) {
      toast({
        description: 'Add columns to create capability',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (pathname.includes('create')) {
      setShowSaveCapabilityModal(true);
    } else if (pathname.includes('edit') || workflowModified) {
      if (workflowName.length > 2) {
        updateCapability({ ...capability, id, name: workflowName, object: json });
      } else {
        toast({
          description: 'Capability name cannot be blank!',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const onSaveCapability = (details: Capability): void => {
    createCapability(details);
    // setIsLoading(true);
  };

  const getResponseContent = useMemo(
    (): ReactElement => (
      <Flex direction="column" alignItems="center">
        <Image src={`/assets/icons/${tryOutResponse}_response.svg`} mb={4} />
        <Box textAlign="center" mt={2}>
          {tryOutResponse === 'success'
            ? 'Transaction successfully executed'
            : 'Some error occurred while executing the transaction. Please try again.'}
        </Box>
      </Flex>
    ),
    [tryOutResponse],
  );

  const { mutateAsync: tryOutWorkflow } = useTryOut({
    onSuccess: response => {
      setTryOutResponse('success');
      setTransactionId(response?.transactionId);
      setSelectedAction('');
      setTryOutDetails(initialTryOutDetails);
    },
    onError: () => {
      setTryOutResponse('error');
      setSelectedAction('');
    },
  });

  const getTryOutDetails = (): { [key: string]: string } => {
    let response = {};
    tryOutDetails.forEach((parameter: Parameter) => {
      response = { ...response, [parameter.parameter]: parameter.value };
    });
    return response;
  };

  const onTryOutWorkflow = (): void => {
    const request = {
      workflowId: capability?.id,
      data: { ...getTryOutDetails() },
    };
    tryOutWorkflow(request);
  };

  const onActionPopupCancel = (): void => {
    setSelectedAction('');
    setWorkflowDetails(initialWorkflowDetails);
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        minW="fit-content"
        px={6}
        py={4}
        borderWidth="1px"
        borderColor="blue.100"
        borderRadius="8rem"
        boxShadow="0px 4px 8px rgba(17, 25, 99, 0.04)"
        mx={6}
        my={4}
      >
        <Flex direction="column">
          <Flex direction="row" alignItems="center">
            <CaretLeft
              onClick={navigateBack}
              size={20}
              style={{ marginTop: '2px', cursor: 'pointer', color: colors.gray[500] }}
            />
            <Box fontSize="md" color="gray.800" w={350}>
              <Input
                autoFocus
                value={workflowName}
                onChange={evt => {
                  setWorkflowName(evt.target.value);
                  if (evt.target.value !== capability?.name) setWorkflowModified(true);
                }}
                fontSize="lg"
                _hover={{ bg: 'blue.100', cursor: 'pointer' }}
                _focus={{ outline: 'none' }}
                textAlign="left"
                border="none"
                fontWeight="500"
                aria-label="Edit Workflow Name"
              />
            </Box>
          </Flex>
          {/* <Flex mt="-2" ml="2" alignItems="center">
            <Text fontWeight="light" fontSize="sm" color="gray.600" isTruncated>
              {id}
            </Text>
            <CopyButton text={id} id="copy-workflow" />
          </Flex> */}
        </Flex>

        <Flex flex={1} justifyContent="flex-end" gridGap="4">
          {/* <Button
            bg="white.50"
            fontSize="sm"
            fontWeight="normal"
            borderRadius="lg"
            p="4"
            onClick={() => setSelectedAction('Tryout')}
            id="tryout-workflow"
          >
            <Play size={18} style={{ paddingLeft: '4', marginRight: '8' }} weight="fill" color={colors.gray[800]} />
            Tryout
          </Button> */}
          <Button
            type="submit"
            colorScheme="blue"
            isLoading={createCapabilityLoading || updateCapabilityLoading}
            fontSize="sm"
            fontWeight="normal"
            borderRadius="lg"
            p="4"
            mr="4"
            disabled={workflowName.length < 3 || (workflowName.indexOf(defaultWorkflowName) !== 0 && !workflowModified)}
            onClick={handleSaveWorkflow}
            id="publish-workflow"
          >
            <PaperPlaneTilt size={22} style={{ paddingLeft: '4', marginRight: '8' }} color="white" />{' '}
            {pathname.includes('edit') ? 'Update' : 'Publish'} Capability
          </Button>
        </Flex>
      </Flex>

      {showSaveCapabilityModal && (
        <SaveCapabilityModal
          isOpen={showSaveCapabilityModal}
          setIsOpen={setShowSaveCapabilityModal}
          details={{ ...capability, name: workflowName, object: json } as GetCapabilityResponse}
          isLoading={createCapabilityLoading}
          onSaveCapability={onSaveCapability}
          nameExistsError={nameExistsError}
          setNameExistsError={setNameExistsError}
        />
      )}

      {showSuccessPopup && <WorkflowPublishModal onClose={() => setShowSuccessPopup(false)} id={id ?? capabilityId} />}
      {selectedAction === 'Tryout' && (
        <TryOutModal
          onCancel={() => {
            setTryOutDetails(initialTryOutDetails);
            onActionPopupCancel();
          }}
          onClose={onTryOutWorkflow}
          workflowId={workflowDetails.id}
          tryOutDetails={tryOutDetails}
          setTryOutDetails={setTryOutDetails}
        />
      )}
      {tryOutResponse && (
        <AlertMessageModal
          alertContent={getResponseContent}
          onCancel={() => setTryOutResponse('')}
          onClose={() => {
            if (tryOutResponse === 'success') {
              history.push(`/reports/${transactionId}`);
            } else setTryOutResponse('');
          }}
          {...(tryOutResponse === 'success' && { buttonLabel: 'View Transaction' })}
        />
      )}
    </>
  );
};

const WorkflowPublishToast = ({
  setShowSuccessPopup,
}: {
  setShowSuccessPopup: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const toast = useToast();
  return (
    <Box color="white" w="fit-content" bg="white" boxShadow="lg" borderRadius="lg" h="56px" pl={5} px={4}>
      <Flex h="full" alignItems="center">
        <Image src="/assets/icons/toast_tick.svg" />
        <Text fontSize="sm" fontWeight="normal" pl={3} color="gray.700">
          Capability has been published Successfully
        </Text>
        <Text
          fontSize="xs"
          cursor="pointer"
          pl={6}
          fontWeight="normal"
          color="blue.900"
          onClick={() => {
            setShowSuccessPopup(true);
            toast.closeAll();
          }}
        >
          View Details
        </Text>
      </Flex>
    </Box>
  );
};

export default CapabilitiesCreationHeader;
