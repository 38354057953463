import React from 'react';
import { Cell } from 'react-table';
import { Flex, Text, Avatar, Icon, IconProps } from '@chakra-ui/react';
import { format } from 'date-fns';
import { DATE_FORMAT_WITHOUT_SECONDS } from '@/constants/constants';
import { timeSince } from '@/screens/workflows/helpers/dateHelper';

export const UserIcon = (props: IconProps): React.ReactElement => (
  <Icon viewBox="0 0 14 14" {...props}>
    <path
      d="M7.00167 7.14279C8.89596 7.14279 10.4302 5.6085 10.4302 3.71422C10.4302 1.81993 8.89596 0.285645 7.00167 0.285645C5.10739 0.285645 3.5731 1.81993 3.5731 3.71422C3.5731 5.6085 5.10739 7.14279 7.00167 7.14279ZM7.00167 8.85707C4.7131 8.85707 0.144531 10.0056 0.144531 12.2856V13.9999H13.8588V12.2856C13.8588 10.0056 9.29025 8.85707 7.00167 8.85707Z"
      fill="#727C8D"
    />
  </Icon>
);

export const EntityNameCell = ({ value }: Cell): React.ReactElement => {
  const { userDetails, entityId } = value;

  return (
    <Flex direction="row">
      {!userDetails?.name ? (
        <Flex mr={2} justify="center" align="center" bg="gray.200" h={10} w={10} borderRadius="6rem">
          <UserIcon w={4} h={4} />
        </Flex>
      ) : (
        <Avatar
          bg="gray.200"
          borderRadius="6rem"
          w={10}
          h={10}
          mr={2}
          color="gray.600"
          fontWeight="normal"
          name={userDetails?.name}
          fontSize="md"
          lineHeight={1}
        />
      )}
      <Flex direction="column">
        <Text fontSize="sm" pl={1} textTransform="capitalize" fontWeight={400} color="gray.800">
          {!userDetails?.name ? '...' : userDetails?.name}
        </Text>
        <Text fontSize="xs" pl={1} fontWeight={300} color="gray.500">
          {entityId}
        </Text>
      </Flex>
    </Flex>
  );
};
export const EntityDateCell = ({ value }: Cell): React.ReactElement => {
  if (value) {
    const date = new Date(value);
    const formattedDate = format(date, DATE_FORMAT_WITHOUT_SECONDS);

    return (
      <Flex direction="column">
        <Text color="gray.700" fontWeight="light" textTransform="capitalize" fontSize="xs">
          {formattedDate}
        </Text>
        <Text color="gray.500" fontSize="xs">
          {timeSince(value)} ago
        </Text>
      </Flex>
    );
  }
  return <></>;
};
