import React, { ReactElement } from 'react';
import { Flex, Text, Tooltip, TooltipProps } from '@chakra-ui/react';

function PlatformTooltip({
  children,
  title = '',
  description = '',
  tooltipProps,
}: {
  children: ReactElement;
  title?: string;
  description: string;
  tooltipProps?: TooltipProps | {};
}): ReactElement {
  return (
    <Tooltip
      label={
        <Flex p="3" direction="column" gridGap="2" color="gray.50">
          {title && (
            <Text fontSize="xs" fontWeight="400" lineHeight="normal">
              {title}
            </Text>
          )}
          <Text fontSize="xs" fontWeight="300" lineHeight="130%" whiteSpace="pre-line">
            {description}
          </Text>
        </Flex>
      }
      hasArrow
      arrowPadding={16}
      offset={[-16, 10]}
      placement="right-start"
      openDelay={200}
      closeDelay={300}
      p="0"
      borderRadius="lg"
      bg="gray.800"
      w="278px"
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  );
}

export default PlatformTooltip;
