import React from 'react';
import { HeaderWithTabs, Header, Heading, RHSContent, ProfileDropdown } from '@/layout/Header';
import CustomTabs from '@/components/tabs/CustomTabs';
import WorkflowList from './WorkflowList';

const workflowTabList = [
  {
    label: 'Templates',
    id: 'workflows/templates',
    content: <WorkflowList workflowType="templates" />,
  },
  {
    label: 'My Workflows',
    id: 'workflows/my-workflows',
    content: <WorkflowList />,
  },
];

const WorkflowsTabs = (): React.ReactElement => {
  return (
    <HeaderWithTabs>
      <Header>
        <Heading>
          <Heading.Title>Workflows</Heading.Title>
        </Heading>
        <RHSContent>
          <ProfileDropdown />
        </RHSContent>
      </Header>
      <CustomTabs tabs={workflowTabList} />
    </HeaderWithTabs>
  );
};

export default WorkflowsTabs;
