import React, { CSSProperties, ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Files } from 'phosphor-react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { colors } from '@/utils/colors';
import { DRAG_ITEM_TYPE } from '../types';
import AsyncTag from './AsyncTag';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
): {
  display?: string;
  transform?: string;
  WebkitTransform?: string;
  background?: string;
  width?: string;
} {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const CustomDragLayer = (): ReactElement => {
  const { item, itemType, isDragging, initialFileOffset, currentFileOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging && itemType !== DRAG_ITEM_TYPE.CAPABILITY) {
    return <></>;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialFileOffset, currentFileOffset)}>
        <Flex
          direction="column"
          bg="white"
          borderWidth={1}
          borderColor="blue.400"
          borderRadius="lg"
          w="328px"
          cursor="pointer"
          boxShadow={`2px 6px 8px 0px rgba(17, 25, 99, 0.04), 0px 0px 0px 3px ${colors.shadows.blue}`}
          zIndex="11"
          transform="rotate(5deg)"
        >
          <Flex m="2" justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" flexGrow={1}>
              <Flex bg="white.50" borderRadius="full" p="2" mr="1">
                <Files size="16" />
              </Flex>
              <Text fontSize="sm" maxW="52" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {item.name}
              </Text>
            </Flex>
            {item.isAsync && <AsyncTag />}
          </Flex>
          <Text fontSize="xs" color="gray.600" noOfLines={[1, 2]} m="2" fontWeight="300">
            {item.description}
          </Text>
        </Flex>
      </div>
    </div>
  );
};
