import { SortField } from '@/types/common';
import { format } from 'date-fns';
import { FilterFields } from './types';

export const getHexColor = (number: number): string => {
  return `#${(number * 10000).toString(16).slice(-6)}`;
};

export const getSearchTerm = (searchTerm?: string): string => {
  const searchItem = {
    value: searchTerm,
    fields: [{ name: 'name' }, { name: 'applicationId' }],
  };
  return searchTerm ? JSON.stringify(searchItem) : JSON.stringify({});
};

export const getSortField = (sortField?: SortField): string => {
  const sortValue = {
    field: sortField?.field || 'createdAt',
    order: sortField?.order || 'desc',
  };
  return `${JSON.stringify([sortValue])}`;
};

export const getFilterField = (filterFields?: FilterFields): string => {
  const { createdAt } = filterFields || { createdAt: [] };
  let date;
  const createdDateCount = createdAt?.length || 0;
  if (createdDateCount >= 1) {
    date = {
      value: createdAt?.[0],
      operator: 'between',
      value2: createdAt?.[1],
    };
  }
  const isOutcomeFilter = filterFields?.status && filterFields?.status.length;
  const allOutcome = filterFields?.status?.includes('*');
  const filterValues = {
    ...(isOutcomeFilter && !allOutcome && { active: filterFields?.status }),
    ...(date && createdAt?.[0] !== createdAt?.[1] && { createdAt: date }),
  };
  return JSON.stringify(filterValues);
};

export const getFormattedDateAndTime = (time: number): { formattedDate: string; formattedTime: string } => {
  const date = new Date(time);

  const formattedDate = format(date, 'dd-MM-yyyy');
  const formattedTime = format(date, `h':'mm aa`);

  return { formattedDate, formattedTime };
};

export const getDateAndTime = (dateValue?: number): string => {
  if (dateValue) {
    const { formattedDate, formattedTime } = getFormattedDateAndTime(dateValue);

    return `${formattedDate} | ${formattedTime}`;
  }
  return '-';
};

const fileSizeInMB = (size: number): number => Number((size / 1024 / 1024).toFixed(1));
const fileSizeInKB = (size: number): number => Number((size / 1024).toFixed(1));
export const renderFileSize = (size: number): string =>
  fileSizeInMB(size) > 1 ? `${fileSizeInMB(size)}MB` : `${fileSizeInKB(size)}KB`;
