import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { Flex, Text } from '@chakra-ui/react';

export const TransactionsCell = ({ value, row, column }: Cell): ReactElement => {
  const { transactionCount, transactionLimit } = row.values;
  return (
    <Flex direction="column">
      <Text>
        {transactionCount}/{transactionLimit}
      </Text>
      <Text fontSize="xs" fontWeight="300" color="gray.600">
        {transactionLimit - transactionCount} Transactions Remaining
      </Text>
    </Flex>
  );
};
