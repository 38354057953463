import React, { ReactElement, useState } from 'react';
import { Box, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ThemeAtom } from './states/themeAtom';
import { getValueAtPath, updateValueAtPath } from './utils';

const FontSizeSelector = ({ path }: { path: string }): ReactElement => {
  const themeState = useRecoilValue(ThemeAtom);
  const [fontSize, setFontSize] = useState<string>(() => {
    const currentValue = getValueAtPath(path.split('.'), themeState.themes[themeState.currentIndex]);
    return currentValue;
  });
  const setThemeState = useSetRecoilState(ThemeAtom);
  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setThemeState(currentTheme => {
      const lastThemeState = currentTheme.themes[currentTheme.currentIndex];
      const updatedNewForwardTheme = updateValueAtPath(
        path.split('.'),
        JSON.parse(JSON.stringify(lastThemeState)),
        +event.target.value,
      );
      const newThemeState = [...currentTheme.themes, updatedNewForwardTheme];
      return {
        themes: newThemeState,
        currentIndex: currentTheme.currentIndex + 1,
      };
    });
    setFontSize(event.target.value);
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="font-size" color="gray.900" fontSize="sm" fontWeight="normal">
          Font Size
        </FormLabel>
        <Input type="number" id="font-size" value={fontSize} onChange={handleSizeChange} min="0" step="1" />
      </FormControl>
    </Box>
  );
};

export default FontSizeSelector;
