import React, { ReactElement } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import history from '@/utils/history';
import VerifiedPage from '@/screens/login/VerifiedPage';
import { Login } from '@/screens/login/Login';
import ProtectedRoute from './ProtectedRoute';
import { AppLanding } from './AppLanding';

const AppRoutes = (): ReactElement => {
  const queryClient = new QueryClient();
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route exact path="/verified" component={VerifiedPage} />
        <QueryClientProvider client={queryClient}>
          <ProtectedRoute path="/" component={AppLanding} />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Switch>
    </Router>
  );
};

export default AppRoutes;
