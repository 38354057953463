import React, { ReactElement } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react';

export const ConfirmationModal = ({
  headerText = '',
  bodyText = '',
  primaryButtonText = 'Yes',
  secondaryButtonText = 'No',
  primaryButtonBg = 'blue',
  width = 124,
  onClose,
  onCancel,
  onCloseButtonClick,
  isLoading = false,
}: {
  headerText: string;
  bodyText: string | ReactElement;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonBg?: string;
  width?: number;
  onClose: () => void;
  onCancel: () => void;
  onCloseButtonClick?: () => void;
  isLoading?: boolean;
}): ReactElement => {
  return (
    <Flex>
      <Modal isOpen onClose={onCloseButtonClick || onCancel} isCentered size="lg">
        <ModalOverlay bg="transparentGray.100" />
        <ModalContent borderRadius="lg">
          <ModalHeader fontSize="md" fontWeight="500" px="6" pt="6" pb="4">
            {headerText}
          </ModalHeader>
          <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
          <ModalBody fontSize="sm" fontWeight="light" px="6" pt="0" pb="6">
            {bodyText}
          </ModalBody>
          <ModalFooter p={4}>
            {secondaryButtonText && (
              <Button
                h={38}
                mr="3"
                onClick={onCancel}
                bg="white.50"
                borderColor="gray.200"
                borderWidth={1}
                minW={`${width}px`}
                maxW="fit-content"
                _hover={{ opacity: 0.85 }}
                fontSize="sm"
              >
                {secondaryButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <Button
                isLoading={isLoading}
                h={38}
                type="button"
                borderRadius="lg"
                onClick={onClose}
                bg={`${primaryButtonBg}.500`}
                px="8"
                color="white"
                fontSize="sm"
                fontWeight="light"
                minW={`${width}px`}
                maxW="fit-content"
                _hover={{ opacity: 0.85 }}
              >
                {primaryButtonText}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ConfirmationModal;
