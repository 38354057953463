import { colors } from '@/utils/colors';
import { Box, Flex } from '@chakra-ui/layout';
import { Button, Text, useToast } from '@chakra-ui/react';
import { ArrowArcLeft, ArrowArcRight, ArrowCounterClockwise, CheckCircle, Files, Palette } from 'phosphor-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useFetchConfig from './queries/useFetchConfig';
import useSaveConfig from './queries/useSaveConfig';
import useUpdateConfig from './queries/useUpdateConfig';
import { InitialThemeData, ThemeAtom } from './states/themeAtom';

function ThemeEventHandlers({
  openCustomiseTheme,
  setOpenCustomiseTheme,
}: {
  openCustomiseTheme: boolean;
  setOpenCustomiseTheme: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  const toast = useToast();
  const setThemeState = useSetRecoilState(ThemeAtom);
  const themeData = useRecoilValue(ThemeAtom);
  const { data: config } = useFetchConfig();
  const { mutateAsync: saveConfig, isLoading: saveConfigLoading } = useSaveConfig({
    onSuccess: () => {
      toast({
        title: 'Successfully saved config',
        status: 'info',
        duration: 1000,
        isClosable: true,
      });
    },
    onError: error => {
      toast({
        title: 'Failed to save config',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    },
  });

  const { mutateAsync: updateConfig, isLoading: updateConfigLoading } = useUpdateConfig({
    onSuccess: () => {
      toast({
        title: '"The configuration has been updated successfully. Your changes have been saved."',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: error => {
      toast({
        title: 'Failed to save workflow',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    },
  });

  function handleUndoTheme(): void {
    setThemeState(themeState => {
      if (themeState.themes.length === 0) {
        return themeState;
      }
      return {
        ...themeState,
        currentIndex: themeState.currentIndex - 1,
      };
    });
  }

  function handleRedoTheme(): void {
    setThemeState(themeState => {
      if (themeState.themes.length === themeState.currentIndex) {
        return themeState;
      }
      return {
        ...themeState,
        currentIndex: themeState.currentIndex + 1,
      };
    });
  }

  function handleResetTheme(): void {
    setThemeState({
      themes: [config ?? InitialThemeData],
      currentIndex: 0,
    });
  }

  function handleSaveConfig(): void {
    const isConfigAvailable = config && Object.keys(config).length > 0;

    if (isConfigAvailable) {
      updateConfig(themeData.themes[themeData.currentIndex]);
    } else {
      saveConfig(themeData.themes[themeData.currentIndex]);
    }
  }

  const actionButtons = [
    {
      text: 'Undo',
      icon: <ArrowArcLeft weight="bold" size={16} />,
      onClick: handleUndoTheme,
      isDisabled: () => {
        return themeData.currentIndex === 0;
      },
    },
    {
      text: 'Redo',
      icon: <ArrowArcRight weight="bold" size={16} />,
      onClick: handleRedoTheme,
      isDisabled: () => {
        return themeData.currentIndex === themeData.themes.length - 1;
      },
    },
    {
      text: 'Reset Theme',
      icon: <ArrowCounterClockwise weight="bold" size={16} />,
      onClick: handleResetTheme,
      isDisabled: () => {
        return themeData.currentIndex === 0;
      },
    },
    {
      text: 'Save & Apply',
      icon: <CheckCircle weight="bold" size={16} />,
      onClick: handleSaveConfig,
      isDisabled: () => {
        return saveConfigLoading || updateConfigLoading || themeData.currentIndex === 0;
      },
    },
  ];

  return (
    <Flex as="span" flex="1" textAlign="left" gridGap="2" alignItems="center">
      <Flex gridGap="2" alignItems="center" flexGrow={1}>
        <Box p="2" bg="white.50" borderRadius="50%">
          <Files size={16} color={colors.gray[800]} weight="thin" />
        </Box>
        <Flex gridGap="2" alignItems="center" flexGrow={1}>
          <Text fontSize="lg" fontWeight="medium" color="gray.800" flexGrow={1}>
            Preview Frontend
          </Text>
        </Flex>
      </Flex>
      {openCustomiseTheme ? (
        <Flex mr="8" gridGap="4">
          {actionButtons.map(({ text, icon, onClick, isDisabled }) => (
            <Button
              key={text}
              variant="link"
              color="blue.400"
              leftIcon={icon}
              fontSize="sm"
              fontWeight="normal"
              _hover={{
                textDecoration: 'none',
              }}
              onClick={onClick}
              disabled={isDisabled()}
            >
              {text}
            </Button>
          ))}
        </Flex>
      ) : (
        <Button
          mr="8"
          variant="link"
          color="blue.400"
          leftIcon={<Palette weight="fill" size={16} />}
          fontSize="sm"
          fontWeight="normal"
          _hover={{
            textDecoration: 'none',
          }}
          onClick={() => setOpenCustomiseTheme(true)}
        >
          Customise Theme
        </Button>
      )}
    </Flex>
  );
}

export default ThemeEventHandlers;
