import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Button, Flex, IconButton, Image } from '@chakra-ui/react';
import { Palette, TextT } from 'phosphor-react';

interface Props {
  selectedButton: string;
  setSelectedButton: React.Dispatch<React.SetStateAction<string>>;
}
const AdvanceCustomizationButtonGroup = ({ selectedButton, setSelectedButton }: Props): ReactElement => {
  const handleButtonClick = (buttonName: string): void => {
    setSelectedButton(buttonName);
  };

  const buttonContent = (buttonName: string): ReactElement => {
    return <>{selectedButton === buttonName && <span style={{ textTransform: 'capitalize' }}>{buttonName}</span>}</>;
  };

  const buttons = [
    {
      text: 'branding',
      icon: <Palette size={16} weight="fill" />,
    },
    {
      text: 'text',
      icon: <TextT size={16} />,
    },
    {
      text: 'buttons',
      icon: <Image src="/assets/icons/buttonIcon.svg" w="4" h="4" />,
    },
    // {
    //   text: 'background',
    //   icon: <SelectionBackground size={16} />,
    // },
  ];

  return (
    <Flex gridGap="3" mb="8">
      {buttons.map(({ text, icon }) =>
        selectedButton === text ? (
          <Button
            key={text}
            colorScheme="blue"
            leftIcon={icon}
            borderRadius="3xl"
            bgColor="blue.700"
            _focus={{ boxShadow: 'none' }}
            onClick={() => handleButtonClick(text)}
          >
            {buttonContent(text)}
          </Button>
        ) : (
          <IconButton
            key={text}
            colorScheme="blue"
            aria-label="icon"
            icon={icon}
            borderRadius="full"
            bgColor="blue.700"
            opacity={selectedButton === text ? 1 : 0.2}
            onClick={() => handleButtonClick(text)}
          />
        ),
      )}
    </Flex>
  );
};

export default AdvanceCustomizationButtonGroup;
