import { mode } from '@chakra-ui/theme-tools';

export const ButtonStyles = {
  baseStyle: {
    fontWeight: 'semibold',
  },
  variants: {
    solid: (props: Record<string, string>) => {
      if (props.colorScheme === 'blue') {
        const bg = mode(`blue.500`, `blue.500`)(props);

        return {
          bg,
          color: 'white',
          _hover: {
            bg: mode(`blue.500`, `blue.500`)(props),
            _disabled: {
              bg,
            },
          },
          _active: { bg: mode(`blue.700`, `blue.700`)(props) },
        };
      }
      return {};
    },
  },
};
