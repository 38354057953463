/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputLeftElement, Box, FormLabel } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ThemeAtom } from './states/themeAtom';
import { getValueAtPath, updateValueAtPath } from './utils';
import { Config } from './types';

const ColorInput = ({
  title = 'Brand Color',
  path,
  type,
}: {
  path: string;
  title?: string;
  type?: string;
}): React.ReactElement => {
  const setThemeState = useSetRecoilState(ThemeAtom);
  const themeState = useRecoilValue(ThemeAtom);
  const [hexCode, setHexCode] = useState<string>(() => {
    const currentValue = getValueAtPath(path.split('.'), themeState.themes[themeState.currentIndex]);
    return currentValue;
  });
  const handleHexCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHexCode(event.target.value?.toUpperCase());
  };

  const handleThemeState = (): void => {
    setThemeState(prevThemeState => {
      const lastThemeState = prevThemeState.themes[prevThemeState.currentIndex];
      const updatedNewForwardTheme = updateValueAtPath(
        path.split('.'),
        JSON.parse(JSON.stringify(lastThemeState)),
        hexCode,
      ) as Config;
      if (type === 'quick_customiaztion') {
        updatedNewForwardTheme.text.objects.title.color = hexCode;
      }
      const newThemeState = [...prevThemeState.themes, updatedNewForwardTheme];
      return {
        themes: newThemeState,
        currentIndex: themeState.currentIndex + 1,
      };
    });
  };

  useEffect(() => {
    const currentValue = getValueAtPath(path.split('.'), themeState.themes[themeState.currentIndex]);
    if (currentValue !== hexCode) {
      setHexCode(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeState.currentIndex]);

  return (
    <Box w="full">
      <FormLabel htmlFor="color-input" color="gray.900" fontSize="sm" fontWeight="normal">
        {title}
      </FormLabel>
      <InputGroup>
        <InputLeftElement
          // zIndex="1"
          children={
            <Input
              type="color"
              value={hexCode || '#4462EC'}
              onChange={handleHexCodeChange}
              onBlur={handleThemeState}
              border="none"
              _focus={{ boxShadow: 'none' }}
              p="2"
              cursor="pointer"
            />
          }
        />
        <Input
          type="text"
          placeholder="Hex code"
          value={hexCode || '#4462EC'}
          color="gray.900"
          fontSize="sm"
          fontWeight="thin"
          borderRadius="lg"
          isReadOnly
        />
      </InputGroup>
    </Box>
  );
};

export default ColorInput;
