import { Dispatch, SetStateAction } from 'react';
import { AxiosError } from 'axios';
import { TransactionDetail } from '../transaction-details/types';

export const UNKNOWN = 'unknown';
export const FAILED = 'failed';
export const MANUAL_REVIEW = 'MANUAL_REVIEW';

export interface TransactionFilterField {
  createdAt?: string[];
  outcome?: string[];
  entityId?: string;
  workflowId?: string;
}

export interface TransactionListContentProps {
  data: TransactionDetail[];
  isLoading: boolean;
  isFetching: boolean;
  error: AxiosError | null;
  setSortField: Dispatch<SetStateAction<{ field: string; order: string }>>;
  setOffset?: (offset: number) => void;
  offset?: number;
  count?: number;
  limit?: string;
  setLimit?: (limit: string) => void;
}

export enum ToastMessage {
  EMPTY_SELECTED_LIST_MESSAGE = 'No Transactions selected',
}
