import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { ConditionsType } from './types';
import { NestedCondition } from '../workflows/workflow-creation/components/workflow-properties/types';
import IndividualCondition from './IndividualCondition';
import ConditionDropDown from './ConditionDropDown';

function ConditionsRightBlock({
  type,
  pathArray,
  conditions,
}: {
  type: ConditionsType;
  pathArray: (string | number)[];
  conditions: NestedCondition;
}): ReactElement {
  const conditionType = pathArray[pathArray.length - 1];
  const individualConditions = conditions[conditionType];
  return (
    <Flex flex="1" w="full" flexDirection="column" justifyContent="space-between">
      {Array.isArray(individualConditions) ? (
        <Flex flexDirection="column">
          {individualConditions.slice(1).map((condition, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={index}>
              <IndividualCondition type={type} condition={condition} pathArray={[...pathArray, index + 1]} />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex>No conditions</Flex>
      )}
      <ConditionDropDown conditionType={conditionType} pathArray={pathArray} type={type} />
    </Flex>
  );
}

export default ConditionsRightBlock;
