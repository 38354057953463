import React, { ReactElement, ReactNode } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { bannerHeight } from '@/constants/constants';

const Layout = ({
  children,
  hasSider,
  hasBanner,
  styles,
}: {
  children: ReactElement | ReactNode;
  hasSider?: boolean;
  hasBanner?: boolean;
  styles?: FlexProps;
}): ReactElement => {
  //   //@ts-ignore
  //   const hasBanner = Children.map(children, child => child?.type.name === 'TrialBanner');
  //   console.log({ hasBanner });

  return (
    <Flex
      h={hasBanner ? `calc(100% - ${bannerHeight}px)` : 'full'}
      w="full"
      flexDirection={hasSider ? 'row' : 'column'}
      {...styles}
    >
      {children}
    </Flex>
  );
};

export default Layout;
