import { createClient } from 'contentful';

const contentfulApi = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
  host: process.env.REACT_APP_ENV === 'development' ? 'preview.contentful.com' : 'cdn.contentful.com',
  environment: 'dev',
});

export { contentfulApi };
