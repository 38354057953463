import { AndOrCondition, NestedCondition, InnerCondition } from '../components/workflow-properties/types';
import { ConditionItem } from '../types';

const getObjItem = (kind: string, id: string, type?: string, rawInput?: string): object => {
  const getRawInputString = (): string => {
    return rawInput ? `${rawInput}` : '';
  };
  return {
    obj: `${kind}${id ? `.${id}` : ''}${type ? `.${type}${getRawInputString()}` : ''}`,
  };
};

const parseConstValue = (value: string | number | boolean): string | number | boolean =>
  typeof value === 'number' && +value % 1 === 0 ? Number(`${(+value).toFixed(5)}1`) : value;

const jsonModalToJson = (conditions?: ConditionItem): NestedCondition => {
  let nodeCondition: NestedCondition = {};

  if (conditions?.type === AndOrCondition.AND || conditions?.type === AndOrCondition.OR) {
    nodeCondition = { [conditions?.type]: [] };
    conditions.items.forEach(item => {
      if (item.group.type) {
        (nodeCondition?.[conditions?.type] as NestedCondition[]).push(jsonModalToJson(item.group));
      } else if (item.isFunctionOperator) {
        const functionArray: (InnerCondition | string)[] = [`functions.${item.functionOperator}`];
        item.functionItems.forEach(functionElement => {
          const temp =
            functionElement.kind === 'static'
              ? {
                  const: parseConstValue(functionElement.value),
                }
              : getObjItem(functionElement.kind, functionElement.id, functionElement?.type, item?.rawInput);
          functionArray.push(temp);
        });
        (nodeCondition?.[conditions?.type] as NestedCondition[]).push({
          eq: [{ call: functionArray as NestedCondition[] }, { const: true }],
        });
      } else if (item.kind) {
        (nodeCondition?.[conditions?.type] as NestedCondition[]).push({
          [item.operator]: [
            getObjItem(item.kind, item.id, item.kind.toLowerCase() === 'tag' ? 'status' : item?.type, item?.rawInput),
            ...(item.isDynamic
              ? [
                  getObjItem(
                    item.dynamicItem.kind,
                    item.dynamicItem.id,
                    item.kind.toLowerCase() === 'tag' ? 'status' : item.dynamicItem.type,
                    item.rawInput,
                  ),
                ]
              : [
                  {
                    const: parseConstValue(item.value),
                  },
                ]),
          ],
        } as NestedCondition);
      } else {
        (nodeCondition?.[conditions?.type] as NestedCondition[]).push({
          eq: [{ const: true }, { const: conditions?.type?.toLowerCase() === 'and' }],
        });
      }
    });
  }
  return nodeCondition;
};

export default jsonModalToJson;
