import React, { useState } from 'react';
import { Cell } from 'react-table';
import { Eye, EyeSlash, Copy } from 'phosphor-react';
import { Flex, Text, Tooltip, Box, useClipboard, useToast } from '@chakra-ui/react';
import { ToastMessage } from '@/components/copy-button/types';

export const PasswordCell = ({ value }: Cell): React.ReactElement => {
  const [showPassword, setShowPassword] = useState(false);
  const { onCopy } = useClipboard(value);
  const toast = useToast();
  return (
    <Flex>
      <Tooltip
        hasArrow
        bg="white"
        color="gray.700"
        placement="top"
        px={4}
        py={3}
        borderRadius="lg"
        fontWeight="light"
        fontSize="xs"
        label={showPassword && value}
      >
        <Text isTruncated fontSize="xs">
          {showPassword ? value : '**********************'}
        </Text>
      </Tooltip>
      <Box onClick={() => setShowPassword(!showPassword)} ml={2} cursor="pointer">
        {showPassword ? <EyeSlash height="16px" width="16px" /> : <Eye height="16px" width="16px" />}
      </Box>
      <Box w={4} mx="1">
        <Copy
          onClick={e => {
            e.stopPropagation();
            onCopy();
            toast({
              title: ToastMessage.COPIED_TO_CLIPBOARD,
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
          cursor="pointer"
        />
      </Box>
    </Flex>
  );
};
