import React from 'react';
import { Cell } from 'react-table';
import { Link as LinkIcon } from 'phosphor-react';
import { Flex, Text, Tooltip, Box, Link } from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { Original } from '../../utils/types';

export const LinkCell = ({ value, row, column }: Cell): React.ReactElement => {
  const { original }: Original = row;
  const { Header } = column;

  return (
    <Flex alignItems="center" color="blue.500">
      <Tooltip
        label={value}
        hasArrow
        bg="white"
        color="gray.700"
        placement="top"
        px={4}
        py={3}
        borderRadius="lg"
        fontWeight="light"
        fontSize="xs"
      >
        <Text pr={2} color="gray.700" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" maxWidth="15em">
          {value}
        </Text>
      </Tooltip>
      <Box w={2}>
        {value ? (
          <Link
            href={
              Header === row.cells[1].column.Header
                ? `/entities/${original?.entityId}`
                : `/capabilities/${original?.workflowId}/edit`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon size={16} color={colors.blue[500]} />
          </Link>
        ) : (
          <></>
        )}
      </Box>
    </Flex>
  );
};
