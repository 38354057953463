import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState, useRecoilState } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { Node, Elements } from 'react-flow-renderer';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import { RouterPrompt } from '@/components/RouterPrompt';
import WorkflowChart from '@/screens/capabilitiesV2/workflow-creation/components/workflow-chart/WorkflowChart';

import FactsLoader from '@/components/loader/Loader';
import { formatCurrentTime } from '@/screens/capabilitiesV2/helpers';
import { defaultWorkflowName } from '@/screens/capabilitiesV2/constants';
import { parseOutputs } from './helpers';
import {
  WorkflowJson,
  NewNodePosition,
  ModifyNodePositions,
  NodeDetails,
  AddedServiceIds,
  AddedTags,
  ServicesToTheLeft,
  TagsToTheLeft,
  ShowInputAttributes,
  SelectedPosition,
  WorkflowModified,
  ActiveService,
} from './states/workflowChart';
import {
  SupplierDescriptions,
  ServicesDescriptions,
  ConfigureServicesDescriptions,
} from './states/serviceDescriptions';
import {
  Workflow,
  Service,
  StepDetails,
  ConditionsData,
  Position,
  ModifyNodeDetails,
  NodeKind,
  Tag,
  WorkflowNode,
  ConditionItem,
  ToastMessage,
  Inputs,
  ServiceDescriptions,
  ContentfulService,
  ContentfulConfigureService,
} from './types';
import AddStepModal from './components/AddStepModal';
import WorkflowCreationHeader from './components/WorkflowCreationHeader';
import WorkflowConditions from './components/WorkflowConditions';
import InputAttributes from './components/InputAttributes';
import useCapabilityDetails from './queries/useCapabilityDetails';
import { newJson } from './constants';
import useWorkflowStepsHandler from './hooks/useWorkflowStepsHandler';
import useWorkflowJsonHandler from './hooks/useWorkflowJsonHandler';
import { NestedCondition, InnerCondition } from './components/workflow-properties/types';
import { useGetInputAttributes } from './queries/useGetInputAttributes';
import useDeleteNodeHandler from './hooks/useDeleteNodeHandler';
import helperFunction from './helpers/jsonToJsonModal';
import helperJsonFunction from './helpers/jsonModalToJson';
import useGetStartEndNodeDescriptions from './queries/useGetStartEndNodeDescriptions';
import useGetServiceDescriptions from './queries/useGetServiceDescriptions';
import useConfigureServiceDescriptions from './queries/useGetConfigureServiceDescriptions';
import AwaitAttributesModal from './components/AwaitAttributesModal';

export const CREATE_CAPABILITY_DATA = {
  name: `${defaultWorkflowName}-${formatCurrentTime()}`,
  object: null,
  active: true,
};

export const CapabilityCreation = (): ReactElement => {
  const { pathname } = useLocation();
  const [showAddStepModal, setShowAddStepModal] = useState(false);
  const [showConditionsFlag, setShowConditionsFlag] = useState(false);
  const [conditionsData, setConditionsData] = useState<ConditionsData>({
    id: '',
    name: '',
    type: '',
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [nodeConditions, setNodeConditions] = useState<ConditionItem>();

  const modifyNodePosition = useRecoilValue<Position>(ModifyNodePositions);
  const nodeDetails = useRecoilValue<ModifyNodeDetails>(NodeDetails);
  const setWorkflowJson = useSetRecoilState(WorkflowJson);
  const resetNodePosition = useResetRecoilState(NewNodePosition);
  const setServiceIds = useSetRecoilState(AddedServiceIds);
  const resetServiceIds = useResetRecoilState(AddedServiceIds);
  const setTags = useSetRecoilState(AddedTags);
  const resetModifyNodeDetails = useResetRecoilState(NodeDetails);
  const setServicesToLeft = useSetRecoilState(ServicesToTheLeft);
  const setTagsToLeft = useSetRecoilState(TagsToTheLeft);
  const [showAttributesFlag, setShowAttributesFlag] = useRecoilState(ShowInputAttributes);
  const resetAttributesFlag = useResetRecoilState(ShowInputAttributes);
  const selectedPosition = useRecoilValue(SelectedPosition);
  const resetSelectedPosition = useResetRecoilState(SelectedPosition);
  const [workflowModified, setWorkflowModified] = useRecoilState(WorkflowModified);
  const setActiveService = useSetRecoilState(ActiveService);
  const setServiceDescriptions = useSetRecoilState(SupplierDescriptions);
  const setServicesDescriptions = useSetRecoilState(ServicesDescriptions);
  const setConfigureServicesDescriptions = useSetRecoilState(ConfigureServicesDescriptions);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showAwaitAttributes, setShowAwaitAttributes] = useState(false);

  const { nodes, json, setNodes } = useWorkflowJsonHandler();
  const { setDeleteNodePosition } = useDeleteNodeHandler(json, setWorkflowJson);
  const { addStepType, setAddStepType, onAddStep } = useWorkflowStepsHandler(json);
  const { data: inputAttributes } = useGetInputAttributes();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error, isFetching, refetch } = useCapabilityDetails(id, pathname.includes('edit'));
  const capabilityData = pathname.includes('edit') ? data : CREATE_CAPABILITY_DATA;
  const { getStartEndNodeDescriptions } = useGetStartEndNodeDescriptions();
  const { getServiceDescriptions } = useGetServiceDescriptions();
  const { getConfigureServiceDescriptions } = useConfigureServiceDescriptions();

  const toast = useToast();

  useEffect(() => {
    getStartEndNodeDescriptions().then(response => {
      setServiceDescriptions(response?.items[0]?.fields as ServiceDescriptions);
    });
    getServiceDescriptions().then(response => setServicesDescriptions(response?.items as ContentfulService[]));
    getConfigureServiceDescriptions().then(response =>
      setConfigureServicesDescriptions(response?.items as ContentfulConfigureService[]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      resetNodePosition();
      resetAttributesFlag();
      resetSelectedPosition();
      setNodes([]);
      setWorkflowModified(false);
      resetServiceIds();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addStepType) setShowAddStepModal(true);
  }, [addStepType]);

  const addStartNodeToObject = useMemo((): Workflow => {
    const object = capabilityData?.object ?? { columns: [] };
    const startNode = newJson.columns;
    const findStartNode = object?.columns?.findIndex(column => column.id === 'column_0');
    if (findStartNode === -1) {
      return { ...object, columns: [...startNode, ...object?.columns] };
    }
    return object;
  }, [capabilityData]);

  useEffect(() => {
    if (capabilityData && id) {
      // Edit capability
      const workflowObj: Workflow =
        capabilityData?.object && capabilityData.object?.columns ? cloneDeep(addStartNodeToObject) : cloneDeep(newJson);
      if (
        workflowObj?.columns?.[0]?.nodes[0].outputs &&
        !workflowObj?.columns?.[0]?.nodes[0].outputs.length &&
        inputAttributes
      ) {
        workflowObj.columns[0].nodes[0].outputs.push(
          ...parseOutputs(inputAttributes, workflowObj?.columns?.[0]?.nodes[0]?.id),
        );
      }
      setWorkflowJson(workflowObj);
      const serviceIds = new Set([] as string[]);
      const tags = new Set([] as string[]);
      // stores already added service ids
      workflowObj.columns?.forEach(col => {
        col.nodes.forEach(node => {
          if (node.kind.toLowerCase() === NodeKind.service) {
            serviceIds.add(node.supplierId ?? '');
          } else if (node.kind.toLowerCase() === NodeKind.tag) {
            tags.add(node.name);
          }
        });
      });
      setServiceIds(Array.from(serviceIds));
      setTags(Array.from(tags));
    } else {
      // create capability
      setWorkflowJson(newJson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capabilityData, inputAttributes]);

  useEffect(() => {
    setShowConditionsFlag(false);
    if (nodeDetails.type && (nodeDetails.action === 'edit' || nodeDetails.action === 'replace')) {
      if (nodeDetails.type === NodeKind.await) {
        setShowAwaitAttributes(true);
      } else {
        // on configure click
        setServicesToLeft(getServicesToTheLeft(modifyNodePosition.columnPosition));
        setShowAddStepModal(true);
        setAddStepType(nodeDetails?.type?.toLowerCase());
      }
    } else if (nodeDetails.type && nodeDetails.action === 'delete') {
      setShowConfirmationModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeDetails]);

  const getServicesToTheLeft = (columnPosition: number): Service[] => {
    const services: Service[] = [];
    json?.columns?.slice(1, columnPosition).forEach(item => {
      item?.nodes.forEach((node: WorkflowNode) => {
        if (node.kind.toLowerCase() === NodeKind.service) {
          const cloneOutputParams = node?.outputs?.map(output => {
            const cloneOutput = { ...output };
            cloneOutput.label = output.displayName;
            cloneOutput.value = output.key;
            return cloneOutput;
          });
          services.push({
            value: node.id,
            label: node.name,
            id: node.id,
            name: node.name,
            outputParameters: cloneOutputParams || [],
          });
        }
      });
    });
    return services;
  };

  const getTagsToTheLeft = (columnPosition: number): Tag[] => {
    const tags: Tag[] = [];
    json?.columns.slice(1, columnPosition).forEach(item => {
      item?.nodes.forEach((node: WorkflowNode) => {
        if (node.kind.toLowerCase() === NodeKind.tag) {
          tags.push({
            value: node.id,
            label: node.name,
            outputs: node.outputs,
          });
        }
      });
    });
    return tags;
  };

  const handleModalClose = (): void => {
    setShowAddStepModal(false);
    setAddStepType('');
    resetModifyNodeDetails();
  };

  const handleAddStep = (type: string, details: Service | StepDetails, action: string): void => {
    setShowAddStepModal(false);
    onAddStep(type, details, action);
    resetModifyNodeDetails();
  };

  const onNodeClick = (node: Node): void => {
    setServicesToLeft(getServicesToTheLeft(node?.data?.columnPosition));
    setTagsToLeft(getTagsToTheLeft(node?.data?.columnPosition));
    setShowAttributesFlag(false);
    setShowConditionsFlag(false);
    if (node?.data?.kind.toLowerCase() === NodeKind.input) {
      setShowAttributesFlag(true);
    } else if (node?.data?.kind.toUpperCase() === NodeKind.await) {
      setShowAwaitAttributes(true);
    } else {
      if (node?.id) {
        setActiveService({ id: node?.id });
        setShowConditionsFlag(true);
      } else setShowConditionsFlag(false);
      setConditionsData({
        id: node?.id,
        name: node?.data?.name,
        type: node?.data?.kind?.toLowerCase(),
        columnPosition: node?.data?.columnPosition,
        nodePosition: node?.data?.nodePosition,
      });
      const formattedJson = helperFunction(node?.data?.conditions?.[0]?.when);
      setNodeConditions(formattedJson);
    }
  };

  const loopConditions = (conditionsArr: NestedCondition[] | InnerCondition[], dependsArr: string[]): void => {
    conditionsArr.forEach((val: NestedCondition | InnerCondition) => {
      const key = Object.keys(val)[0];
      if (key && key !== 'obj' && key !== 'const' && key !== 'type' && key !== 'call')
        loopConditions((val as NestedCondition)[key] as NestedCondition[], dependsArr);
      if (key && (key === 'obj' || key === 'call')) {
        const obj =
          key === 'call' ? ((val as InnerCondition)?.call?.[1] as InnerCondition)?.obj : (val as InnerCondition)?.obj;
        if (obj) {
          const nodeId = obj?.split('.')[1];
          if (dependsArr.indexOf(nodeId) < 0 && nodeId) dependsArr.push(obj.split('.')[1]);
        }
      }
    });
  };

  const getDependIds = (details: NestedCondition, dependsArr: string[]): void => {
    if (details.and) {
      loopConditions(details.and as NestedCondition[], dependsArr);
    } else if (details.or) {
      loopConditions(details.or as NestedCondition[], dependsArr);
    }
  };

  const onConditionSave = (): void => {
    const convertedJson = helperJsonFunction(nodeConditions);
    const cloneJson = cloneDeep(json);
    const currentNode = cloneJson.columns[conditionsData.columnPosition || 0].nodes[conditionsData.nodePosition || 0];
    currentNode.conditions = Object.keys(convertedJson).length
      ? [
          {
            when: convertedJson as NestedCondition,
          },
        ]
      : [];
    const dependsArr: string[] = [];
    getDependIds(convertedJson as NestedCondition, dependsArr);
    currentNode.dependencies = dependsArr;
    setWorkflowJson({ ...cloneJson });
  };

  const resetConditionsVisibility = (): void => {
    setShowAttributesFlag(false);
    setShowConditionsFlag(false);
    if (selectedPosition.columnPosition || selectedPosition.nodePosition) resetSelectedPosition();
  };

  const onDeleteModalClose = (): void => {
    setDeleteNodePosition(modifyNodePosition);
    resetModifyNodeDetails();
    setShowConfirmationModal(false);
    resetSelectedPosition();
  };

  const onDeleteModalCancel = (): void => {
    resetModifyNodeDetails();
    setShowConfirmationModal(false);
  };

  const getKind = (): string => {
    switch (nodeDetails.type.toLowerCase()) {
      case NodeKind.tag: {
        return 'tag';
      }
      case NodeKind.service: {
        return 'service';
      }
      case NodeKind.accept:
      case NodeKind.reject:
      case NodeKind.manualReview: {
        return 'end';
      }
      default: {
        return 'node';
      }
    }
  };

  const refetchDetails = (): void => {
    refetch();
    setShowConditionsFlag(false);
  };

  const onSaveButtonClick = (): void => {
    onConditionSave();
    setWorkflowModified(true);
    toast({
      description: ToastMessage.CONDITION_SAVED_MESSAGE,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const getDeleteConfirmationBody = (): ReactElement => {
    return (
      <Text>
        Are you sure you want to delete this {getKind()}? This will remove <b>&apos;{nodeDetails.oldName}&apos;</b>
        &nbsp;{getKind()} from all the conditions that have been defined in the capability.
      </Text>
    );
  };

  const onAddAwaitParams = (params: string[]): void => {
    onAddStep('AWAIT', { name: 'Awaits', params }, 'edit');
    setShowAwaitAttributes(false);
    resetModifyNodeDetails();
  };

  const onAddServiceParams = (inputs: Inputs[]): void => {
    onAddStep('source', inputs, 'edit');
    handleModalClose();
    resetModifyNodeDetails();
  };

  if (isLoading || isFetching) {
    return (
      <Flex justifyContent="center" height="40vh" width="100%">
        <FactsLoader />
      </Flex>
    );
  }

  if (error) {
    return (
      <Text textAlign="center" fontSize="xl" m="5">
        Something went wrong..
      </Text>
    );
  }

  return (
    <Flex w="full" overflowX="auto" position="relative">
      <Flex w="full" direction="column">
        <WorkflowCreationHeader
          capability={capabilityData}
          json={json}
          refetch={refetchDetails}
          showSuccessPopup={showSuccessPopup}
          setShowSuccessPopup={setShowSuccessPopup}
        />
        <Flex flexDirection="column" h="100%" w="100%" pb={6} pl={6} justifyContent="space-between">
          <Box h="full" width="full" position="fixed">
            <WorkflowChart
              elements={nodes}
              saveElements={(cb: (prevElements: Elements) => Elements) => setNodes(cb(nodes))}
              onNodeClick={onNodeClick}
              onPanelClick={resetConditionsVisibility}
              columnsLength={json?.columns?.length}
            />
          </Box>
          {showAwaitAttributes && (
            <AwaitAttributesModal
              parameters={nodeDetails?.awaits?.[0]?.values}
              onCancel={() => {
                resetModifyNodeDetails();
                setShowAwaitAttributes(false);
              }}
              onAdd={onAddAwaitParams}
            />
          )}
          {showAddStepModal && (
            <AddStepModal
              addStepType={addStepType}
              onCloseModal={handleModalClose}
              onAddStep={(type, details, action) => {
                if (type === NodeKind.service && action === 'edit') {
                  onAddServiceParams(details as Inputs[]);
                }
                handleAddStep(type, details as Service | StepDetails, action || '');
              }}
              nodeDetails={nodeDetails}
            />
          )}
          {showConfirmationModal && (
            <ConfirmationModal
              headerText={`Delete ${getKind()}`}
              bodyText={getDeleteConfirmationBody()}
              onClose={onDeleteModalClose}
              onCancel={onDeleteModalCancel}
              primaryButtonText={`Delete ${getKind()}`}
              secondaryButtonText="Cancel"
              primaryButtonBg="red"
            />
          )}
        </Flex>
      </Flex>
      {showAttributesFlag && <InputAttributes onDoneClick={setShowAttributesFlag} />}

      <RouterPrompt
        when={workflowModified}
        title="Leaving the Page?"
        body="You have unsaved changes. You will lose the progress if you close this page."
        cancelText="Close Without Saving"
        okText="Keep Working"
      />
      {showConditionsFlag && (
        <WorkflowConditions
          formattedCondition={nodeConditions}
          setFormattedCondition={setNodeConditions}
          details={conditionsData}
          onSave={onSaveButtonClick}
          onClose={() => {
            setShowConditionsFlag(false);
            resetSelectedPosition();
          }}
        />
      )}
    </Flex>
  );
};

export default CapabilityCreation;
