import React, { Dispatch, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { CheckCircle, DotsThree, Files, ShieldWarning } from 'phosphor-react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { colors } from '@/utils/colors';
import { Menu, MenuButton, MenuItem, MenuList, Flex, Text, useToast } from '@chakra-ui/react';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { Conditions, ConditionsType, WorkflowRenderObj } from './types';
import { WorkflowAtom } from './states/workflowAtom';
import { ConditionsAtom } from './states/conditionsAtom';
import AsyncTag from './components/AsyncTag';

const CapabilityNode = ({
  id,
  handleCapabilityNodeClick,
  nodeData,
  isCapabilityListOpen,
  onConfigureModalClose,
  setIsCapabilityListOpen,
}: {
  id: string;
  handleCapabilityNodeClick: (id: string) => void;
  nodeData: WorkflowRenderObj;
  isCapabilityListOpen: boolean;
  onConfigureModalClose: () => void;
  setIsCapabilityListOpen: Dispatch<SetStateAction<boolean>>;
}): ReactElement => {
  const toast = useToast();
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const workflows = useRecoilValue(WorkflowAtom);
  const setWorkflow = useSetRecoilState(WorkflowAtom);
  const resetNode = useResetRecoilState(SelectedNodeAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const setConditions = useSetRecoilState(ConditionsAtom);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const isAlertsAdded = useMemo(() => {
    const processedWorkflows = workflows
      .filter(workflow => workflow.metadata.capabilityId === id)
      .flatMap(workflow => workflow.nodes)
      .flatMap(workflow => workflow.inputs)
      .filter(input => input?.location === '..' && !input?.isOptional);
    return !!processedWorkflows.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(workflows)]);

  const onDelete = (): void => {
    setWorkflow(workflow =>
      workflow.filter(column => !(column.metadata.capabilityId === id || column.metadata.parentID === id)),
    );
    toast({
      title: 'Node successfully deleted',
      status: 'success',
      duration: 1000,
      isClosable: true,
    });
    resetConditions();
    setShowConfirmationModal(false);
    onConfigureModalClose();
    setIsCapabilityListOpen(true);
  };

  const getDeleteConfirmationBody = (): ReactElement => {
    return (
      <Text>
        This will delete <b>&apos;{nodeData.name}&apos;</b> capability from the workflow and remove all the conditions
        that have been defined in the capability.
      </Text>
    );
  };

  const isNodeSelected = selectedNode.id === id;
  return (
    <>
      <Flex
        direction="column"
        bg="white"
        borderWidth={1}
        borderColor={isNodeSelected ? 'blue.400' : 'gray.200'}
        borderRadius="lg"
        w="80"
        h="24"
        p="2"
        gridGap="2"
        boxShadow={
          isNodeSelected ? `2px 6px 8px 0px rgba(17, 25, 99, 0.04), 0px 0px 0px 3px ${colors.shadows.blue}` : 'none'
        }
        onClick={() => {
          resetNode();
          resetConditions();
          const columns = workflows.filter(workflow => workflow.metadata.capabilityId === id);
          const conditionsArray = workflows.filter(workflow => workflow.metadata.parentID === id) || [];
          if (conditionsArray.length) {
            const flattenedArray = conditionsArray.flatMap(condition => condition.nodes);
            const conditionsObject = {
              [ConditionsType.accept]: [],
              [ConditionsType.reject]: [],
              [ConditionsType.manual_review]: [],
            } as Conditions;
            flattenedArray.forEach(arr => {
              if (arr.kind === 'end_accepted' && arr.conditions) {
                const acceptedCondition = arr.conditions;
                conditionsObject[ConditionsType.accept] = acceptedCondition;
              }
              if (arr.kind === 'end_rejected' && arr.conditions) {
                const rejectedCondition = arr.conditions;
                conditionsObject[ConditionsType.reject] = rejectedCondition;
              }
              if (arr.kind === 'end_manual_review' && arr.conditions) {
                const manualReviewCondition = arr.conditions;
                conditionsObject[ConditionsType.manual_review] = manualReviewCondition;
              }
            });
            setConditions(conditionsObject);
          }
          setSelectedNode(() => ({ id, type: 'source', columns }));
          handleCapabilityNodeClick(id);
        }}
        cursor="pointer"
      >
        <Flex gridGap="2" alignItems="center" justifyContent="space-between">
          <Flex gridGap="2" alignItems="center">
            <Flex bg="white.50" borderRadius="full" p="2" mr="1">
              <Files size="16" />
            </Flex>
            <Text
              fontSize="sm"
              flexGrow={1}
              maxW={nodeData.isAsync ? '36' : '52'}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              title={nodeData.name}
            >
              {nodeData.name}
            </Text>
          </Flex>
          <Flex gridGap="2" alignItems="center">
            {nodeData.isAsync && <AsyncTag />}
            {isAlertsAdded ? (
              <ShieldWarning size={16} color={colors.orange[500]} weight="fill" />
            ) : (
              <CheckCircle color="green" weight="fill" />
            )}

            <Menu>
              <MenuButton onClick={event => event.stopPropagation()}>
                <DotsThree size={16} color={colors.gray[900]} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  fontWeight={300}
                  fontSize="sm"
                  color="red.500"
                  onClick={event => {
                    event.stopPropagation();
                    setShowConfirmationModal(true);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Text fontSize="xs" color="gray.600" noOfLines={[1, 2]} fontWeight="300">
          {nodeData.description}
        </Text>
      </Flex>
      {showConfirmationModal && (
        <ConfirmationModal
          headerText="Confirm Delete Node?"
          bodyText={getDeleteConfirmationBody()}
          onClose={onDelete}
          onCancel={() => setShowConfirmationModal(false)}
          primaryButtonText="Confirm Delete"
          secondaryButtonText="Cancel"
          primaryButtonBg="red"
        />
      )}
    </>
  );
};

export default CapabilityNode;
