import React, { ReactElement } from 'react';
import { Flex, Spacer, Button, Text } from '@chakra-ui/react';
import { Circle, Files } from 'phosphor-react';
import { useHistory, useLocation } from 'react-router-dom';

import { TransactionDetail } from '@/screens/transactions/transaction-details/types';
import { transactionStatusStyles, transactionStatus } from '../../utils/helper';

const TryoutResultHeader = ({ tryoutResponse }: { tryoutResponse: TransactionDetail }): ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isTemplate = pathname.includes('templates');

  const transactionId = tryoutResponse?.transactionId ?? '';

  const handleViewFullReport = (): void => {
    history.push(isTemplate ? `/reports/templates/${transactionId}` : `/reports/workflows/${transactionId}`);
  };

  return (
    <Flex gridGap="2" alignItems="center">
      <Text fontSize="sm">Results</Text>
      <Flex
        p="5px 10px"
        {...transactionStatusStyles(tryoutResponse)}
        borderRadius="md"
        justifyContent="space-between"
        alignItems="center"
      >
        <Circle size={8} weight="fill" />
        <Text paddingLeft="5px" textTransform="capitalize" fontSize="xs" fontWeight="light">
          {transactionStatus(tryoutResponse?.outcome.toLowerCase()) || ''}
        </Text>
      </Flex>
      <Spacer />
      <Button
        variant="link"
        fontSize="sm"
        fontWeight="normal"
        color="blue.400"
        leftIcon={<Files size={16} weight="fill" />}
        onClick={handleViewFullReport}
      >
        View Full Report
      </Button>
    </Flex>
  );
};

export default TryoutResultHeader;
