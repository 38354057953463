/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

export const useTestWebhook = (
  options?: UseMutationOptions<any, AxiosError, any>,
): UseMutationResult<any, AxiosError, any> => useMutation(getTestWebhook, options);

const getTestWebhook = async (): Promise<any> => {
  const res = await bureauPlatformApi.post(`/platform/webhooks/triggertestevent`, {});
  return res.data;
};
