import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { QuickLinkData, QuickLinkResponse } from '../workflowv2/types';

type UpdateQuickLinkVariables = {
  data: QuickLinkData;
  id: string;
};

const updateQuickLink = async ({ data, id }: UpdateQuickLinkVariables): Promise<QuickLinkResponse> => {
  const { data: response } = await bureauPlatformApi.patch<QuickLinkResponse>(`/workflows/quicklink/${id}`, data);
  return response;
};

const useUpdateQuickLink = (
  options?: UseMutationOptions<QuickLinkResponse, AxiosError, UpdateQuickLinkVariables>,
): UseMutationResult<QuickLinkResponse, AxiosError, UpdateQuickLinkVariables> => {
  return useMutation(updateQuickLink, options);
};

export default useUpdateQuickLink;
