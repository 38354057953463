import React, { ChangeEvent, ReactElement } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  Input,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { TrashSimple } from 'phosphor-react';
import { Grid } from '@chakra-ui/layout';
import { ChildItems, ConditionsType, OutputV2, ParentMenuItem } from './types';
import useConditions from './hooks/useConditions';
import { NestedCondition } from '../workflows/workflow-creation/components/workflow-properties/types';
import InputDropdown from './InputDropdown';
import useFetchSupplierInputOutput from './queries/useFetchSupplierInputOutput';
import useGetOperators from '../workflows/workflow-creation/queries/useGetOperators';
import { WorkflowAtom } from './states/workflowAtom';
import { STATUS_INPUT, STATUS_OUTPUTS } from './constant';

function ConditionsRow({
  type,
  pathArray,
  conditionKey,
  conditions,
}: {
  type: ConditionsType;
  pathArray: (string | number)[];
  conditionKey: string;
  conditions: NestedCondition;
}): ReactElement {
  const workflows = useRecoilValue(WorkflowAtom);
  const { updateConditions, getCurrentConditions, deleteConditions } = useConditions();
  const getSupplierIds = (): string[] => {
    const supplierIds = [
      ...new Set(workflows.flatMap(workflowNode => workflowNode.nodes).flatMap(nodes => nodes.supplierId)),
    ] as string[];
    return supplierIds;
  };
  const { data: inputAttributes = { inputs: [], outputs: [] } } = useFetchSupplierInputOutput(getSupplierIds());
  const { data: operators = { operators: [] } } = useGetOperators();
  const userInput = {
    id: 'user-input',
    header: 'User Input',
    subHeader: 'Inputs entered by user',
  };
  const userInputChild: ChildItems = {
    'user-input': inputAttributes.inputs.map(attributes => ({
      id: attributes.key || 'random-id',
      header: attributes.displayName ? attributes.displayName : '',
      subHeader: attributes.description ? attributes.description : '',
      type: 'user-input',
    })),
  };

  const functionalOperators: ParentMenuItem[] = operators.operators
    .filter(operator => operator.type === 'functional')
    .map(operator => ({
      id: operator.symbol,
      header: operator.name,
      subHeader: operator.description,
      type: 'functional',
      lablel: operator.label,
    }));
  const relationalOperator: ParentMenuItem[] = operators.operators
    .filter(operator => operator.type === 'relational')
    .map(operator => ({
      id: operator.symbol,
      header: operator.name,
      subHeader: operator.description,
      type: 'relational',
      lablel: operator.label,
    }));

  const operatorParent = [
    { id: 'relational', header: 'Operator', subHeader: '' },
    { id: 'functional', header: 'Custom Function', subHeader: '' },
  ];
  const operatorChild = {
    relational: relationalOperator,
    functional: functionalOperators,
  };

  function handleChange(key: string, value: ParentMenuItem, output: string): void {
    const pathKey = pathArray[pathArray.length - 1];
    if (key === 'service-input-dropdown') {
      const currentObject = getCurrentConditions(pathArray, type);
      if (Array.isArray(currentObject)) {
        const result = [...currentObject];
        const index = pathKey === 'call' ? 1 : 0;
        result[index] = {
          obj: output,
        };
        const updatedCondition = {
          [pathArray[pathArray.length - 1]]: result,
        };
        updateConditions(pathArray.slice(0, pathArray.length - 1), type, updatedCondition);
        return;
      }
    }
    if (key === 'operator-dropdown') {
      const currentObject = getCurrentConditions(pathArray.slice(0, pathArray.length - 1), type);
      if (value.type === 'functional') {
        const updatedValue = currentObject[pathKey];
        const result = {
          call: [`functions.${value.header}`, ...updatedValue],
        };
        updateConditions(pathArray.slice(0, pathArray.length - 1), type, result);
        return;
      }

      if (typeof currentObject === 'object' && currentObject !== null && pathKey in currentObject) {
        let updatedValue = currentObject[pathKey];
        if (updatedValue.length > 2) {
          updatedValue = updatedValue.slice(1);
        }
        const updatedKey = value.lablel || 'none';
        const result = {
          [updatedKey]: updatedValue,
        };
        updateConditions(pathArray.slice(0, pathArray.length - 1), type, result);
        return;
      }
    }
    if (key === 'outputs') {
      const index = pathKey === 'call' ? 2 : 1;
      if (value.id === 'const') {
        const currentObject = getCurrentConditions(pathArray, type);
        if (Array.isArray(currentObject)) {
          const result = [...currentObject];
          result[index] = {
            const: '',
          };
          const updatedCondition = {
            [pathArray[pathArray.length - 1]]: result,
          };
          updateConditions(pathArray.slice(0, pathArray.length - 1), type, updatedCondition);
        }
      } else {
        const currentObject = getCurrentConditions(pathArray, type);

        if (Array.isArray(currentObject)) {
          const result = [...currentObject];
          const resultKey = value.type === 'status' ? 'const' : 'obj';
          result[index] = {
            [resultKey]: output,
          };
          const updatedCondition = {
            [pathArray[pathArray.length - 1]]: result,
          };

          updateConditions(pathArray.slice(0, pathArray.length - 1), type, updatedCondition);
        }
      }
    }
  }
  const flattenedNode = workflows
    .filter(workflow => !(workflow.metadata.kind === 'tag' || workflow.metadata.kind === 'condition'))
    .map(column => {
      return {
        ...column,
        nodes: column.nodes.map(node => ({ ...node, capabilityName: column.metadata.capabilityName })),
      };
    })
    .flatMap(column => column.nodes)
    .filter(nodes => nodes.kind !== 'AWAIT')
    .filter(node => node.kind !== 'tag');
  const parentServiceMenuItems = flattenedNode.map(node => ({
    id: node.id,
    header: node.name,
    subHeader: node.capabilityName,
    outputs: node.outputs,
  })) as ParentMenuItem[];
  const childServiceNodeItems = flattenedNode.reduce((acc, node) => {
    const nodeOutputs = node.outputs?.map(output => ({ id: output.key || '', header: output.displayName || '' }));
    return { ...acc, [node.id]: nodeOutputs };
  }, {});
  const secondItem = conditions[conditionKey];
  let isString = false;
  let isStatus = false;
  const stringIndex = conditionKey === 'call' ? 2 : 1;
  if (Array.isArray(secondItem)) {
    const index = conditionKey === 'call' ? 2 : 1;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const firstElement = secondItem[index - 1] as any;
    isString = Object.keys(secondItem[index])[0] === 'const';
    if (Object.keys(firstElement)[0] === 'obj') {
      const firstElementArray = firstElement.obj.split('.');
      isStatus = firstElementArray[2] === 'status';
    }
  }
  function getServiceInputDropdownLabel(): string {
    const conditionsArray = conditions[conditionKey] as NestedCondition[];
    const pathKey = pathArray[pathArray.length - 1];
    const index = pathKey === 'call' ? 1 : 0;
    const firstElemnt = conditionsArray[index];
    const firstKey = Object.keys(firstElemnt)[0];
    if (firstKey === 'const') {
      return 'Service Parameter';
    }
    if (firstKey === 'obj') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const output = firstElemnt.obj as any;
      const outputArray = output.split('.');
      if (outputArray[0] === 'source') {
        const selectedSeviceNode = flattenedNode.find(node => node.id === outputArray[1]);
        return `${selectedSeviceNode?.name} - ${outputArray[2]}`;
      }
      const selectedInput = inputAttributes.inputs.find(attr => attr.key === outputArray[2]);
      return `User Input - ${selectedInput?.displayName}`;
    }
    return 'Service Parameter';
  }

  function getOperatorDropdownLabel(): string {
    const pathKey = pathArray[pathArray.length - 1];
    if (pathKey === 'call') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const functionalOperator = conditions[pathKey] as any;
      return functionalOperator[0].split('.')[1] || 'Operator';
    }
    const selectedOperator = operators.operators.find(operator => operator.label === conditionKey);
    return `${selectedOperator?.name}` || 'Operator';
  }

  function getValueDropdownLabel(): string {
    const conditionsArray = conditions[conditionKey] as NestedCondition[];
    const pathKey = pathArray[pathArray.length - 1];
    const index = pathKey === 'call' ? 2 : 1;
    const secondElement = conditionsArray[index];
    const secondKey = Object.keys(secondElement)[0];
    const firstElement = conditionsArray[index - 1];
    const firstKey = Object.keys(firstElement)[0];

    if (firstKey === 'obj' && secondKey === 'const') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const firstArray = firstElement.obj as any;
      const sourceArray = firstArray.split('.');
      if (sourceArray[2] === 'status') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const output = secondElement[secondKey] as any;
        return output;
      }
    }

    if (secondKey === 'obj') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const output = secondElement.obj as any;
      const outputArray = output.split('.');
      if (outputArray[0] === 'source') {
        const selectedSeviceNode = flattenedNode.find(node => node.id === outputArray[1]);
        return `${selectedSeviceNode?.name} - ${outputArray[2]}`;
      }
      const selectedInput = inputAttributes.inputs.find(attr => attr.key === outputArray[2]);
      return `User Input - ${selectedInput?.displayName}`;
    }
    if (isString) {
      return 'Values';
    }
    return 'Value';
  }

  function getConditionName(): string {
    const index = conditionKey === 'call' ? 2 : 1;
    let conditionName =
      conditionKey === 'call' ? 'Parameter of Service is Equal  Value' : 'Parameter of Service is Equal to the Value';
    if (conditionKey === 'call') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const firstCondition = conditions[conditionKey] as any;
      const operatorValue = firstCondition[0].split('.')[1];
      conditionName = conditionName.replace('is Equal', operatorValue || 'Equal');
    } else {
      const operatorValue = operators.operators.find(o => o.label === conditionKey)?.name;
      conditionName = conditionName.replace('Equal', operatorValue || 'Equal');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const conditionsArray = conditions[conditionKey] as any;

    if (Array.isArray(conditionsArray) && Object.keys(conditionsArray[index - 1])[0] === 'obj') {
      const splitConditionsArray = conditionsArray[index - 1].obj.split('.');
      if (splitConditionsArray[0] === 'input') {
        const inputValueLabel = userInputChild['user-input'].find(input => input.id === splitConditionsArray[2]);
        conditionName = conditionName.replace('Parameter of Service', inputValueLabel?.header || '');
      } else {
        const serviceInputName = parentServiceMenuItems.find(menuItem => menuItem.id === splitConditionsArray[1]);
        conditionName = conditionName.replace(
          'Parameter of Service',
          `${splitConditionsArray[2]} of ${serviceInputName?.header} ` || '',
        );
      }
    }
    if (Array.isArray(conditionsArray) && Object.keys(conditionsArray[index])[0] === 'obj') {
      const splitConditionsArray = conditionsArray[index].obj.split('.');
      conditionName = conditionName.replace('Value', splitConditionsArray[2] || '');
    }
    if (isString) {
      conditionName = conditionName.replace('Value', conditionsArray[index].const || '');
    }
    return conditionName;
  }

  const getValuesParentItem = (): ParentMenuItem[] => {
    let menuItems = [...parentServiceMenuItems, userInput, { id: 'const', header: 'Static Values' }];
    const conditionsArray = conditions[conditionKey] as NestedCondition[];
    const index = conditionKey === 'call' ? 1 : 0;
    if (Array.isArray(conditionsArray) && Object.keys(conditionsArray[index])[0] === 'obj') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const firstElement = conditionsArray[index] as any;
      const source = firstElement.obj.split('.');
      if (source[0] === 'source') {
        menuItems = menuItems.filter(item => item.id !== source[1]);
        if (source[2] === 'status') {
          menuItems = menuItems.filter(item => item.id !== 'const');
          menuItems = [...menuItems, STATUS_INPUT];
        }
      }
    }
    return menuItems;
  };
  function handleStaticValues(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    const index = conditionKey === 'call' ? 2 : 1;
    const currentObject = getCurrentConditions(pathArray, type);
    if (Array.isArray(currentObject)) {
      const result = [...currentObject];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const firstElement = currentObject[index - 1] as any;
      const source = firstElement.obj.split('.');
      let isNumberType = false;
      if (source[0] === 'source') {
        const service = parentServiceMenuItems.find(item => item.id === source[1]);
        const outputs = service?.outputs as OutputV2[];
        const attributesType = outputs.find(attr => attr.key === source[2]);
        isNumberType = attributesType?.type === 'NUMBER';
      } else {
        const attributesType = inputAttributes.inputs.find(attr => attr.key === source[2]);
        isNumberType = attributesType?.type === 'NUMBER';
      }
      result[index] = {
        const: isNumberType ? +value : value,
      };
      const updatedCondition = {
        [pathArray[pathArray.length - 1]]: result,
      };
      updateConditions(pathArray.slice(0, pathArray.length - 1), type, updatedCondition);
    }
  }

  function getStringValue(): string {
    if (Array.isArray(secondItem)) {
      const staticValue = secondItem?.[stringIndex];
      return String(staticValue.const || '');
    }
    return '';
  }

  return (
    <Flex w="full">
      <Accordion allowToggle w="inherit">
        <AccordionItem borderWidth={1} mt="2" borderColor="gray.300" borderRadius="md">
          <h2>
            <AccordionButton border="none" _focus={{ boxShadow: 'none' }} p="4">
              <Box as="span" flex="1" textAlign="left" fontSize="sm" fontWeight="medium">
                {getConditionName()}
              </Box>
              <IconButton
                aria-label="Delete Condition"
                icon={<TrashSimple size={20} />}
                color="gray.500"
                bg="transparent"
                mr="2"
                h="5"
                minW="5"
                onClick={event => {
                  event.preventDefault();
                  deleteConditions(pathArray, type);
                }}
              />

              <AccordionIcon color="gray.400" />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Grid gridTemplateColumns="1fr 1fr 1fr" gridGap="1">
              <InputDropdown
                key="service-input-dropdown"
                type="service-input-dropdown"
                label={getServiceInputDropdownLabel()}
                parentMenuItems={[...parentServiceMenuItems, userInput]}
                childItems={{ ...childServiceNodeItems, ...userInputChild }}
                handleChange={handleChange}
                styles={{ maxW: '52' }}
              />
              <InputDropdown
                key="operator-dropdown"
                type="operator-dropdown"
                label={getOperatorDropdownLabel()}
                parentMenuItems={operatorParent}
                childItems={operatorChild}
                handleChange={handleChange}
                styles={{ maxW: '52' }}
              />
              <InputDropdown
                key="outputs"
                type="outputs"
                label={getValueDropdownLabel()}
                parentMenuItems={getValuesParentItem()}
                childItems={{ ...childServiceNodeItems, ...userInputChild, ...STATUS_OUTPUTS }}
                handleChange={handleChange}
                styles={{ maxW: '52' }}
              />
            </Grid>
            <Flex mt="3">
              {isString && !isStatus ? (
                <Input placeholder="Enter static value" onChange={handleStaticValues} value={getStringValue()} />
              ) : null}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}

export default ConditionsRow;
