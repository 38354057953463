/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnV2, WorkflowRenderObj } from '../types';

// Creates the workflow object required to render the workflow layout
export function createWorkflowRenderData(data: Array<ColumnV2>): WorkflowRenderObj[] {
  const initialData: WorkflowRenderObj[] = [
    {
      kind: 'START',
      id: 'START',
      columns: [],
    },
  ];
  const resultData = data.reduce((result, currentObject) => {
    const existingObj = result.find(obj => obj.id === currentObject.metadata.capabilityId);
    if (existingObj) {
      existingObj.columns = [...existingObj.columns, ...currentObject.nodes];
    } else {
      const columns = currentObject.nodes;
      result.push({
        kind: currentObject.metadata.kind || '',
        id: currentObject.metadata.capabilityId || '',
        columns,
        name: currentObject.metadata.capabilityName,
        description: currentObject.metadata.description,
        isAsync: currentObject.metadata.isAsync,
      });
    }
    return result;
  }, initialData);
  resultData.push({
    kind: 'DROPZONE',
    id: 'DROPZONE',
    columns: [],
  });
  resultData.push({
    kind: 'END',
    id: 'END',
    columns: [],
  });
  return resultData;
}

export function generateUUID(): string {
  const characters = '0123456789abcdef';
  const sections = [8, 4, 4, 4, 12];
  let uuid = '';
  // eslint-disable-next-line no-plusplus
  for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
    const sectionLength = sections[sectionIndex];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sectionLength; i++) {
      uuid += characters[Math.floor(Math.random() * characters.length)];
    }
    if (sectionIndex !== sections.length - 1) {
      uuid += '';
    }
  }
  return uuid;
}

export function updateValueAtPath(pathArray: string[], obj: any, value: any): any {
  let current: any = obj;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pathArray.length - 1; i++) {
    current = current[pathArray[i]];
    if (current === undefined) {
      return obj; // Path does not exist, return original object
    }
  }

  current[pathArray[pathArray.length - 1]] = value;
  return obj;
}

export function getValueAtPath(pathArray: string[], obj: any): any {
  let current: any = obj;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pathArray.length - 1; i++) {
    current = current[pathArray[i]];
    if (current === undefined) {
      return ''; // Path does not exist, return original object
    }
  }

  return current[pathArray[pathArray.length - 1]];
}
