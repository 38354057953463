import { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { v4 } from 'uuid';
import produce from 'immer';

import { generateUUID } from '@/screens/workflowv2/utils';
import {
  WorkflowJson,
  ModifyNodePositions,
  NewNodePosition,
  SelectedService,
  NewEdgeButtonNode,
  WorkflowModified,
} from '../states/workflowChart';
import {
  ColumnPosition,
  Service,
  StepDetails,
  Ids,
  Workflow,
  Position,
  NodeKind,
  WorkflowNode,
  Column,
  AwaitData,
  Inputs,
} from '../types';
import { replaceCharacter, parseOutputs, parseInputs } from '../helpers';
import { awaitsNodeName, defaultOutput } from '../constants';
import useDeleteNodeHandler from './useDeleteNodeHandler';

const useWorkflowStepsHandler = (
  json: Workflow,
): {
  addStepType: string;
  setAddStepType: (val: string) => void;
  onAddStep: (type: string, details: Service | StepDetails | AwaitData | Inputs[], action?: string) => void;
} => {
  const [addStepType, setAddStepType] = useState('');

  const newNodePosition = useRecoilValue<ColumnPosition>(NewNodePosition);
  const selectedService = useRecoilValue<Service>(SelectedService);
  const setWorkflowJson = useSetRecoilState(WorkflowJson);
  const modifyNodePositions = useRecoilValue<Position>(ModifyNodePositions);
  const setNewEdgeButtonNode = useSetRecoilState(NewEdgeButtonNode);
  const setWorkflowModified = useSetRecoilState(WorkflowModified);

  const { getUpdatedColumnsAfter } = useDeleteNodeHandler(json, setWorkflowJson);

  useEffect(() => {
    if (newNodePosition.type === NodeKind.service) onAddStep('source', selectedService);
    else if (newNodePosition.type === NodeKind.await) onAddStep('AWAIT', { name: 'Awaits', params: [] });
    else setAddStepType(newNodePosition.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNodePosition, selectedService]);

  const getNewNodeName = (kind: string, name: string): string => {
    switch (kind.toLowerCase()) {
      case NodeKind.accept:
      case NodeKind.reject:
      case NodeKind.manualReview: {
        return `End ${name}`;
      }
      default: {
        return name;
      }
    }
  };

  const getLastNodeId = (prevColumns: Array<Column>): string => {
    return prevColumns[prevColumns?.length - 1]?.nodes[0].id;
  };

  const getNewColumn = (
    details: StepDetails,
    kind: string,
    id: string,
    cloneColumns: Array<Column>,
    service?: Service,
  ): Array<Column> => {
    return [
      // new column with one node which needs to be inserted
      {
        id: `column_${replaceCharacter(v4(), '-')}_${generateUUID()}`,
        nodes: [
          {
            id,
            dependencies: [getLastNodeId(cloneColumns)],
            description: service?.description,
            kind,
            name: details.name,
            supplierId: service?.supplierId,
            color: details?.color || '',
            inputs: service?.inputs ? parseInputs(service?.inputs) : [],
            outputs: parseOutputs([defaultOutput, ...(service?.outputParameters || [])], id),
            open: false,
            columnPosition: newNodePosition.columnPosition || 0,
            outputDependencies: [
              {
                columnPosition: newNodePosition.columnPosition ? newNodePosition.columnPosition + 1 : 0,
                nodePosition: 0,
              },
            ],
          },
        ],
      },
    ];
  };

  const addNewNode = (details: StepDetails, kind: string, service?: Service): void => {
    const cloneColumns = [...json.columns];
    const dependId =
      kind.toLowerCase() === NodeKind.service && service?.id
        ? service.id
        : `${replaceCharacter(details.name, ' ')}_${replaceCharacter(v4(), '-')}`;
    let columnsAfter = cloneColumns.splice(newNodePosition.columnPosition || 0); // to get columns after the new column
    if (newNodePosition?.newColumn) {
      // node added via button on edge
      columnsAfter = columnsAfter.map((cols, index) => {
        const cloneCols = { ...cols };
        cloneCols.nodes = cloneCols.nodes.map((nd: WorkflowNode, ndIndex: number) => {
          const cloneNd = { ...nd };
          // sets dependency of node succeeding the new column to the id of newly created node
          if (index === 0) {
            if (ndIndex === 0) cloneNd.dependencies = [dependId];
            else cloneNd.dependencies = [];
            cloneNd.conditions = [];
          }
          if (cloneNd.columnPosition) cloneNd.columnPosition += 1;
          return cloneNd;
        });
        return cloneCols;
      });

      const newColumn = getNewColumn(details, kind, dependId, cloneColumns, service);
      setWorkflowJson({ columns: [...cloneColumns, ...newColumn, ...columnsAfter] }); // combines columns
      setNewEdgeButtonNode({ insertColumnId: 0 });
    } else {
      setWorkflowJson(
        produce(json, draft => {
          const customNd: WorkflowNode = {
            id: dependId,
            kind,
            name: details.name,
            color: details?.color || '',
            open: false,
            description: service?.description,
            supplierId: service?.supplierId,
            outputs: parseOutputs([defaultOutput, ...(service?.outputParameters || [])], dependId),
            inputs: service?.inputs ? parseInputs(service?.inputs) : [],
            columnPosition: newNodePosition.columnPosition || 0,
            nodePosition: newNodePosition.nodePosition || 0,
            dependencies: [],
          };
          if (draft.columns[newNodePosition.columnPosition || 0]) {
            draft.columns[newNodePosition.columnPosition || 0].nodes.push(customNd);
          } else {
            setNewEdgeButtonNode({ insertColumnId: newNodePosition.columnPosition || 0 });
          }
        }),
      );
    }
  };

  const updateCustomNode = (
    name: string,
    kind: string,
    color?: string,
    service?: Service,
    awaitParams?: string[],
    action?: string,
    inputs?: Inputs[],
  ): void => {
    setWorkflowModified(true);
    // updates the new node with selected service, tag or end-state and changes input and output dependencies of next and prev nodes
    if (action === 'edit' || action === 'replace') {
      const cloneJson = json ? cloneDeep(json) : null;
      if (cloneJson) {
        const nodeId =
          cloneJson?.columns?.[modifyNodePositions?.columnPosition || 0]?.nodes?.[
            modifyNodePositions?.nodePosition || 0
          ]?.id;
        const generatedRandomId = nodeId?.split('_')[1];
        const newNodeName = `${
          kind.toLowerCase() === NodeKind.accept ||
          kind.toLowerCase() === NodeKind.reject ||
          kind.toLowerCase() === NodeKind.manualReview
            ? 'end'
            : replaceCharacter(name, ' ')
        }`;
        const newNodeId =
          kind.toLowerCase() === NodeKind.service && service?.id ? service.id : `${newNodeName}_${generatedRandomId}`;
        cloneJson.columns[modifyNodePositions.columnPosition || 0].nodes[modifyNodePositions.nodePosition || 0] = {
          ...cloneJson.columns[modifyNodePositions.columnPosition || 0].nodes[modifyNodePositions.nodePosition || 0],
          ...(!(kind === NodeKind.service && action === 'edit')
            ? {
                id: newNodeId,
                name: getNewNodeName(kind, name),
                kind,
                color,
                outputs: parseOutputs([defaultOutput, ...(service?.outputParameters || [])], newNodeId),
              }
            : {}),
          conditions:
            action === 'replace'
              ? cloneJson.columns[modifyNodePositions.columnPosition || 0].nodes[modifyNodePositions.nodePosition || 0]
                  ?.conditions
              : [],
          ...(kind === NodeKind.await && action === 'edit'
            ? {
                awaits: [
                  {
                    nodeName: awaitsNodeName, // for now only one name is added which is the input node
                    values: awaitParams,
                  },
                ],
              }
            : {}),
          ...(kind === NodeKind.service && action === 'edit'
            ? {
                inputs: inputs ? parseInputs(inputs) : [],
              }
            : {}),
        };
        if (
          modifyNodePositions.columnPosition !== cloneJson?.columns.length - 1 &&
          !(kind === NodeKind.service && action === 'edit')
        ) {
          const outputDependencies =
            cloneJson?.columns[modifyNodePositions.columnPosition || 0]?.nodes[modifyNodePositions.nodePosition || 0]
              ?.outputDependencies;
          if (outputDependencies) {
            outputDependencies.forEach((dep: Ids) => {
              const index = cloneJson.columns[dep.columnPosition].nodes[dep.nodePosition].dependencies.indexOf(nodeId);
              if (index > -1)
                cloneJson.columns[dep.columnPosition].nodes[dep.nodePosition].dependencies[index] = newNodeId;
            });
          }
        }
        if (action === 'replace' && kind !== NodeKind.await) {
          removeConditionsOnEdit(cloneJson, modifyNodePositions as Position, nodeId);
        } else setWorkflowJson({ ...cloneJson });
      }
    } else {
      addNewNode({ name, color }, kind, service);
    }
  };

  const removeConditionsOnEdit = (workflowJson: Workflow, position: Position, nodeId: string): void => {
    const cloneColumns = cloneDeep(workflowJson.columns);
    const columnsAfter = getUpdatedColumnsAfter(
      cloneColumns,
      position?.columnPosition || 0,
      position?.nodePosition || 0,
      'edit',
      nodeId,
    );
    if (position?.columnPosition) {
      cloneColumns.splice(position?.columnPosition + 1);
      setWorkflowJson({ columns: [...cloneColumns, ...columnsAfter] });
    }
  };

  const onAddStep = (type: string, details: Service | StepDetails | AwaitData | Inputs[], action?: string): void => {
    setAddStepType('');
    if (type === NodeKind.service && action === 'edit') onEditService(details as Inputs[]);
    else if (type === NodeKind.service) onAddService(details as Service, action);
    else if (type === NodeKind.tag) onAddTag(details as StepDetails, action);
    else if (type === NodeKind.await) onAddAwait(details as AwaitData, action);
    else onAddEndState(details as StepDetails, action);
  };

  const onAddService = (val: Service, action?: string): void => {
    updateCustomNode(val?.label || '', NodeKind.service, undefined, val, undefined, action);
  };

  const onEditService = (details: Inputs[]): void => {
    updateCustomNode('', NodeKind.service, undefined, undefined, undefined, 'edit', details);
  };

  const onAddTag = (details: StepDetails, action?: string): void => {
    updateCustomNode(details.name || '', NodeKind.tag, details.color, undefined, undefined, action);
  };

  const onAddEndState = (details: StepDetails, action?: string): void => {
    let endStateName;
    if (details.name === 'accept') endStateName = NodeKind.accept;
    else if (details.name === 'reject') endStateName = NodeKind.reject;
    else endStateName = NodeKind.manualReview;
    updateCustomNode(details.name || '', endStateName, undefined, undefined, undefined, action);
  };

  const onAddAwait = (details: AwaitData, action?: string): void => {
    updateCustomNode(details.name || '', NodeKind.await, undefined, undefined, details.params, action);
  };

  return {
    addStepType,
    setAddStepType,
    onAddStep,
  };
};

export default useWorkflowStepsHandler;
