import React, { ReactElement, useState } from 'react';
import { Box, FormLabel, Flex, Button } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { ThemeAtom } from '../../states/themeAtom';

const ButtonSelector = (): ReactElement => {
  const [selectedBorderRadius, setSelectedBorderRadius] = useState(32);
  const setThemeState = useSetRecoilState(ThemeAtom);

  const buttons = [
    { type: 'Rectangle', borderRadius: 1 },
    { type: 'Rounded', borderRadius: 8 },
    { type: 'Circular', borderRadius: 32 },
  ] as {
    type: string;
    borderRadius: number;
  }[];

  return (
    <Box>
      <FormLabel color="gray.900" fontSize="sm" fontWeight="normal">
        Button Type
      </FormLabel>
      <Flex gridGap="4">
        {buttons.map(({ type, borderRadius }) => (
          <Button
            key={type}
            fontSize="sm"
            fontWeight="normal"
            borderRadius={borderRadius}
            bgColor={selectedBorderRadius === borderRadius ? 'blue.400' : 'blueGray.50'}
            color={selectedBorderRadius === borderRadius ? 'white' : 'black'}
            _hover={{
              bgColor: selectedBorderRadius === borderRadius ? 'blue.400' : 'blueGray.50',
              color: selectedBorderRadius === borderRadius ? 'white' : 'black',
            }}
            _focus={{ boxShadow: 'none' }}
            onClick={() => {
              setSelectedBorderRadius(borderRadius);
              setThemeState(themeState => {
                const lastThemeState = themeState.themes[themeState.currentIndex];
                const newThemeState = [
                  ...themeState.themes,
                  {
                    ...lastThemeState,
                    buttons: {
                      ...lastThemeState.buttons,
                      borderRadius,
                    },
                  },
                ];
                return {
                  themes: newThemeState,
                  currentIndex: themeState.currentIndex + 1,
                };
              });
            }}
          >
            {type}
          </Button>
        ))}
      </Flex>
    </Box>
  );
};

export default ButtonSelector;
