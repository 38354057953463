import React, { ReactElement, useState, useEffect } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';

import FactsLoader from '@/components/loader/Loader';
import { AddedServiceIds } from '../states/workflowChart';
import { Service, ModifyNodeDetails } from '../types';
import ServicesList from './ServicesList';

export const AddServiceModal = ({
  onCancel,
  onClose,
  nodeDetails,
}: {
  onCancel: () => void;
  onClose: (service: Service, action: string) => void;
  nodeDetails: ModifyNodeDetails;
}): ReactElement => {
  const [selectedId, setSelectedId] = useState('');
  const [serviceIds, setServiceIds] = useRecoilState(AddedServiceIds);
  const [isLoading, setIsLoading] = useState(false);

  const onModifyService = (data: Service): void => {
    setSelectedId(data?.supplierId || '');
    const cloneIds = [...serviceIds];
    if (data.supplierId && cloneIds.indexOf(data.supplierId) < 0) cloneIds.push(data.supplierId);
    setServiceIds(cloneIds);
    onClose(data, nodeDetails.action);
  };

  useEffect(() => {
    setSelectedId(nodeDetails?.oldId);
  }, [nodeDetails]);

  return (
    <Modal isOpen onClose={onCancel} isCentered>
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent>
        <ModalHeader fontSize="md" fontWeight="500" px={6} pt={6}>
          {nodeDetails.action === 'edit' ? 'Edit' : 'New'} Service
          <Text color="gray.700" fontSize="xs" fontWeight="light">
            A Data sources that provide rich information about the input attributes.
          </Text>
        </ModalHeader>
        <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
        <ModalBody minH="140px" maxH="280px" display="flex" flexDirection="column" justifyContent="space-around">
          {isLoading ? (
            <FactsLoader />
          ) : (
            <ServicesList onAddService={onModifyService} selectedId={selectedId} setNodePanelLoading={setIsLoading} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddServiceModal;
