import React, { ReactElement, useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Radio,
  Box,
  Text,
  Switch,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { statusValues } from '@/types/common';
import { SelectInput, OptionType } from '@bureau/components';
import { ModifyWorkflowDetails } from '../types';

export const AddEditWorkflowModal = ({
  details = { name: '', workflowId: '' },
  editFromList = false,
  nameExistsError,
  autoFocus,
  publish,
  setNameExistsError,
  onCancel,
  onClose,
  isLoading = false,
}: {
  details: ModifyWorkflowDetails;
  editFromList?: boolean;
  nameExistsError: boolean;
  autoFocus?: boolean;
  publish?: boolean;
  setNameExistsError: (val: boolean) => void;
  onCancel: () => void;
  onClose: (details: ModifyWorkflowDetails) => void;
  isLoading?: boolean;
}): ReactElement => {
  const [workflowName, setWorkflowName] = useState(details?.name);
  const [status, setStatus] = useState('false');
  const [isAsyncExecution, setIsAsyncExecution] = useState(details?.isAsyncExecution ?? false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Option = (props: any): React.ReactElement => {
    const { isSelected, children, innerProps } = props;

    return (
      <Flex {...innerProps} bg="white" p={1} py={2} cursor="pointer" _hover={{ bg: 'gray.100' }}>
        <Radio size="md" name="1" colorScheme="white" isChecked={isSelected} ml={1}>
          <Box fontSize="sm">{children}</Box>
        </Radio>
      </Flex>
    );
  };

  const overrideComponents = { Option };

  const customStyles = {
    control: () => ({
      fontSize: '14px',
    }),
  };

  useEffect(() => {
    setStatus(details?.active?.toString() || 'false');
  }, [details]);

  return (
    <Modal isOpen onClose={onCancel} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent py={2}>
        <ModalHeader fontSize="md" fontWeight="500">
          {publish ? 'Publish Your' : 'Edit'} Workflow
        </ModalHeader>
        <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ email: '' }}
          onSubmit={() => onClose({ ...details, name: workflowName, active: status === 'true', isAsyncExecution })}
        >
          {() => {
            return (
              <Form>
                <ModalBody>
                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="400">
                      Workflow Name
                    </FormLabel>
                    <Input
                      fontSize="sm"
                      placeholder="Enter workflow name"
                      fontWeight="light"
                      value={workflowName}
                      onChange={evt => {
                        setWorkflowName(evt.target.value);
                        setNameExistsError(false);
                      }}
                      borderColor={nameExistsError ? 'red.500' : 'gray.300'}
                      borderRadius="lg"
                      autoFocus={autoFocus}
                    />
                    {nameExistsError && (
                      <Text fontSize="xs" color="red.500">
                        A workflow with the given name already exists
                      </Text>
                    )}
                  </FormControl>
                  {editFromList && (
                    <>
                      <FormControl mt={4}>
                        <FormLabel fontSize="sm" fontWeight="400">
                          Workflow ID
                        </FormLabel>
                        <Input fontSize="sm" value={details?.workflowId} disabled />
                      </FormControl>
                      <FormControl mt={4}>
                        <FormLabel fontSize="sm" fontWeight="400">
                          Status
                        </FormLabel>
                        <SelectInput
                          onChange={data => {
                            setStatus((data as OptionType).value);
                          }}
                          value={status}
                          options={statusValues}
                          size="small"
                          components={overrideComponents}
                          isClearable={false}
                          styles={customStyles}
                        />
                      </FormControl>
                      <FormControl mt={4}>
                        <FormLabel fontSize="sm" fontWeight="400">
                          Enable Async Execution
                        </FormLabel>
                        <Switch
                          id="async-execution"
                          onChange={event => setIsAsyncExecution(event.target.checked)}
                          isChecked={isAsyncExecution}
                        />
                      </FormControl>
                    </>
                  )}
                </ModalBody>
                <ModalFooter pt={6}>
                  <Button
                    h="38px"
                    w="124px"
                    color="blue.800"
                    bg="#E7EBFD"
                    borderRadius="lg"
                    fontSize="sm"
                    fontWeight="300"
                    _hover={{ opacity: '0.8' }}
                    mr={3}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    h="38px"
                    w={publish ? '138px' : '124px'}
                    color="white"
                    bg="blue.500"
                    disabled={!workflowName}
                    fontSize="sm"
                    borderRadius="lg"
                    fontWeight="300"
                    _hover={workflowName ? { opacity: '0.8', background: 'blue.500' } : {}}
                    _disabled={{ color: 'gray.600', bg: 'gray.200', cursor: 'not-allowed' }}
                  >
                    {publish ? 'Publish Workflow' : 'Save'}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AddEditWorkflowModal;
