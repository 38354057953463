import React, { ReactElement } from 'react';
import { Flex, Image, Text, Button, FlexProps } from '@chakra-ui/react';
import { ArrowClockwise } from 'phosphor-react';
import { colors } from '@/utils/colors';

function ErrorStateComponent({
  heading,
  text,
  imageSrc,
  imageWidth,
  isRetryEnabled,
  onRetry,
  styles,
}: {
  heading: string;
  text: string;
  imageSrc: string;
  imageWidth: string;
  isRetryEnabled?: boolean;
  onRetry?: () => void;
  styles: FlexProps;
}): ReactElement {
  return (
    <Flex direction="column" alignItems="center" gridGap="4" justifyContent="center" {...styles}>
      <Image src={imageSrc} w={imageWidth} />
      <Flex direction="column" gridGap="2" alignItems="center">
        <Text fontSize="16px" fontWeight="500">
          {heading}
        </Text>
        <Text fontSize="14px" fontWeight="300" textAlign="center" lineHeight="normal" color="gray.600">
          {text}
        </Text>
        {isRetryEnabled && (
          <Button
            onClick={onRetry}
            colorScheme="none"
            color="blue.700"
            fontSize="md"
            fontWeight="400"
            leftIcon={<ArrowClockwise size={18} color={colors.blue[700]} />}
          >
            Retry
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default ErrorStateComponent;
