import { OptionType } from '@bureau/components';

export enum DropdownVariant {
  GHOST = 'ghost',
  OUTLINE = 'outline',
}

export interface SelectBoxEvent {
  action: string;
  name: string;
  option: OptionType;
}

export enum EventAction {
  SELECTED = 'select-option',
  DESELECTED = 'deselect-option',
}
