import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import ConditionsRightBlock from './ConditionsRightBlock';
import { ConditionsType } from './types';
import ConditionsLeftBlock from './ConditionsLeftBlock';
import { NestedCondition } from '../workflows/workflow-creation/components/workflow-properties/types';

function Conditions({
  type,
  conditions,
  pathArray,
}: {
  type: ConditionsType;
  conditions: NestedCondition;
  pathArray: (string | number)[];
}): ReactElement {
  return (
    <Flex w="inherit">
      <ConditionsLeftBlock type={type} pathArray={[...pathArray]} />
      <ConditionsRightBlock type={type} pathArray={[...pathArray]} conditions={conditions} />
    </Flex>
  );
}

export default Conditions;
