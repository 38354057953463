import { cloneDeep } from 'lodash-es';
import {
  AndOrCondition,
  NestedCondition,
  ConditionData,
  InnerCondition,
} from '../components/workflow-properties/types';
import { ConditionItem, ConditionGroupItem } from '../types';
import { initialCondition, initialItem, functionItem } from '../constants';

const objSplitFunction = (item?: string): { kind: string; id: string; type: string } => {
  const propertiesArray = item?.split('.');
  const kind = propertiesArray?.[0] || '';
  const id = propertiesArray?.[1] || '';
  const type = propertiesArray?.[2] || '';
  return { kind, id, type };
};

const getConstValue = (value: string | number | boolean | undefined): string | number | boolean => {
  const val = value?.toString().split('.');
  if (typeof value === 'number' && val?.[1] === '000001') {
    return val[0];
  }
  return value || '';
};

const formatObj = (item: ConditionData, objItem: ConditionGroupItem, innerKey: string): ConditionGroupItem => {
  // split the objects { obj: 'kind.id.type' } and { const: 'value' }
  const itemsObject = objSplitFunction(item[innerKey][0].obj);
  const conditionItem = { ...objItem, ...itemsObject };
  conditionItem.operator = innerKey;
  if ('obj' in item?.[innerKey]?.[1]) {
    conditionItem.isDynamic = true;
    const dynamicItemObject = objSplitFunction(item[innerKey][1].obj);
    conditionItem.dynamicItem = { ...dynamicItemObject };
  } else if ('const' in item?.[innerKey]?.[1]) {
    conditionItem.value = getConstValue(item[innerKey][1].const || '');
  }
  return conditionItem;
};

const formatFunctionObj = (item: ConditionData, objItem: ConditionGroupItem, innerKey: string): ConditionGroupItem => {
  const conditionItem = { ...objItem };
  conditionItem.isFunctionOperator = true;
  // the operator comes along with the word functions as the first element of the array with call as key.
  [, conditionItem.functionOperator] = (item?.[innerKey]?.[0]?.call?.[0] as string).split('.');
  item?.[innerKey]?.[0]?.call?.slice(1).forEach((functionArg: InnerCondition | string, functionIndex: number) => {
    conditionItem.functionItems.push(cloneDeep(functionItem));
    if ('obj' in (functionArg as InnerCondition)) {
      const functionItemObject = objSplitFunction((functionArg as InnerCondition)?.obj);
      conditionItem.functionItems[functionIndex] = { ...functionItemObject, value: '' };
    } else if ('const' in (functionArg as InnerCondition)) {
      conditionItem.functionItems[functionIndex].kind = 'static';
      conditionItem.functionItems[functionIndex].value = getConstValue((functionArg as InnerCondition)?.const || '');
    }
  });

  return conditionItem;
};

const jsonToJsonModal = (jsonData: NestedCondition): ConditionItem => {
  // formats the conditions json in the workflow object to key value pairs.
  const nodeCondition: ConditionItem = cloneDeep(initialCondition);
  const key = jsonData ? Object?.keys(jsonData)?.[0] : '';

  nodeCondition.type = key === AndOrCondition.AND ? AndOrCondition.AND : jsonData && AndOrCondition.OR;
  if (key) {
    (jsonData?.[key] as NestedCondition[])?.forEach((item: NestedCondition | ConditionData, index) => {
      nodeCondition.items.push(cloneDeep(initialItem));
      const innerKey = Object.keys(item)[0];
      if (innerKey && (innerKey === AndOrCondition.AND || innerKey === AndOrCondition.OR)) {
        nodeCondition.items[index].group = jsonToJsonModal(item as NestedCondition);
      } else if (innerKey && 'obj' in (item as ConditionData)?.[innerKey]?.[0]) {
        nodeCondition.items[index] = formatObj(item as ConditionData, nodeCondition.items[index], innerKey);
      } else if (innerKey && 'call' in (item as ConditionData)?.[innerKey]?.[0]) {
        nodeCondition.items[index] = formatFunctionObj(item as ConditionData, nodeCondition.items[index], innerKey);
      }
    });
  }

  return nodeCondition;
};

export default jsonToJsonModal;
