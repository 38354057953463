import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { Flex } from '@chakra-ui/react';
import { Trash } from 'phosphor-react';
import { Box } from '@chakra-ui/layout';
import { ActionButtonColumnProps } from '@/components/paginated-table/types';

export const DeleteQuickLinkCell = ({ value, row, column }: Cell): ReactElement => {
  const { getProps } = column as ActionButtonColumnProps;
  return (
    <Flex px="1">
      <Box
        px="1"
        onClick={() => {
          getProps?.onActionIconClick?.(row, 'DELETE');
        }}
        cursor="pointer"
      >
        <Trash size="24" />
      </Box>
    </Flex>
  );
};
