import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

import { CreateWorkflowRequest, WorkflowResponse } from '../types';

const useCreateWorkflow = (
  options?: UseMutationOptions<WorkflowResponse, AxiosError, CreateWorkflowRequest>,
): UseMutationResult<WorkflowResponse, AxiosError, CreateWorkflowRequest> => {
  return useMutation(createWorkflow, options);
};

const createWorkflow = async (data: CreateWorkflowRequest): Promise<WorkflowResponse> => {
  const { data: response } = await bureauPlatformApi.post<WorkflowResponse>(`/workflows`, data);
  return response;
};

export default useCreateWorkflow;
