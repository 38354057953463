import React, { useState, useEffect } from 'react';
import { Flex, Heading, Text, Box } from '@chakra-ui/react';
import { X } from 'phosphor-react';

import SearchInput from '@/components/search-input/SearchInput';
import PaginatedTable from '@/components/paginated-table/PaginatedTable';
import useDebounce from '@/hooks/useDebounce';
import { inputAttributeColumns } from '../../columns';
import useGetInputAttributes from '../queries/useGetInputAttributes';
import { Output } from '../types';

const InputAttributes = ({ onDoneClick }: { onDoneClick: (item: boolean) => void }): React.ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState<Output[]>([]);
  const { data: inputAttributes, isLoading } = useGetInputAttributes();

  const searchItem = useDebounce(searchTerm);

  useEffect(() => {
    if (searchItem && inputAttributes) {
      const filteredData = inputAttributes?.filter(
        item =>
          item.key?.toLowerCase().includes(searchItem.toLowerCase()) ||
          item.displayName?.toLowerCase().includes(searchItem.toLowerCase()),
      );
      setTableData(filteredData);
    } else if (inputAttributes) setTableData(inputAttributes);
  }, [searchItem, inputAttributes]);

  return (
    <Flex
      direction="column"
      w="400px"
      minW="400px"
      borderWidth={1}
      borderColor="blue.100"
      borderRadius="lg"
      py={6}
      px={4}
      h="full"
      bg="white"
      position="relative"
      zIndex={99}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Heading fontSize="md" fontWeight="normal" color="gray.800">
          Input step
        </Heading>
        <X onClick={() => onDoneClick(false)} cursor="pointer" color="#727C8D" />
      </Flex>
      <Flex direction="column" py={2} justifyContent="space-between" h="full">
        <Flex direction="column" h="full">
          <Text fontSize="xs" fontWeight="light" color="gray.500">
            Input added by customers on the website or the app.
          </Text>
          <Box my={6}>
            <SearchInput setSearchTerm={setSearchTerm} />
          </Box>
          <PaginatedTable
            data={tableData}
            isManualSort={false}
            columns={inputAttributeColumns}
            isLoading={isLoading}
            hidePagination
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InputAttributes;
