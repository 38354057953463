import { camelCase } from 'lodash-es';
import { WorkflowNode } from '../types';

export const renderDataTestId = (data: WorkflowNode, fileName: string, workFlowType?: string): string => {
  const colPosition = data?.columnPosition || data?.insertColId || 0;
  const rowPosition = data?.nodePosition || 0;

  const dataTestId = `${camelCase(
    workFlowType || renderNodeType(data?.kind),
  )}-level${colPosition}-subLevel${rowPosition}-${fileName}`;
  return dataTestId;
};

const renderNodeType = (workFlowKind: string): string => {
  if (workFlowKind === 'source') return 'service';
  return workFlowKind;
};
