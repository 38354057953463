import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Input, HStack, Image, Flex, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { OptionType } from '@bureau/components';
import Dropdown from '@/components/dropdown/Dropdown';
import { colors } from '@/utils/colors';
import { WorkflowJson } from '../../../states/workflowChart';
import { FunctionItem } from '../../../types';
import { conditionTypes, functionItem } from '../../../constants';
import useFunctionConditionHandler from '../../../hooks/useFunctionConditionHandler';

const dropdownStyle = { fontWeight: '300', border: `1px solid ${colors.gray[300]}` };

const FunctionCondition = ({
  item,
  setItem,
  index,
  functionItemsCount,
  description,
  isVariadic,
  attributeType,
  onItemDelete,
}: {
  item: FunctionItem;
  setItem: (el: FunctionItem, index: number) => void;
  index: number;
  attributeType: string;
  description?: string;
  isVariadic?: boolean;
  functionItemsCount: number;
  onItemDelete: (val: number) => void;
}): ReactElement => {
  const { kind, id, type, value } = item;

  const [inputValue, setInputValue] = useState<string | number | boolean>();

  const { handleDropdownData, getIds, getTypes } = useFunctionConditionHandler();

  const workflowJson = useRecoilValue(WorkflowJson);
  const getInputNodeId = (): string => workflowJson?.columns?.[0]?.nodes?.[0]?.id;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    handleDropdownData(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const renderKindField = (): ReactElement => (
    <Dropdown
      options={[...conditionTypes, { value: 'static', label: 'Static' }]}
      {...(!kind && { controlWidth: '150px' })}
      value={kind}
      isDisabled={index === 0}
      onChange={field => {
        setItem({ ...functionItem, kind: (field as OptionType).value }, index);
      }}
      placeholder="Select"
    />
  );

  const renderIdField = (): ReactElement => (
    <Dropdown
      options={getIds(kind)}
      isDisabled={index === 0}
      placeholder="Select"
      {...(!id && { controlWidth: '150px' })}
      value={id}
      onChange={field => {
        setItem({ ...functionItem, kind, id: (field as OptionType).value }, index);
      }}
      style={dropdownStyle}
    />
  );

  const renderTypeField = (): ReactElement => (
    <Dropdown
      options={getTypes(kind)}
      isDisabled={index === 0}
      placeholder="Select"
      {...(!type && { controlWidth: '150px' })}
      value={type}
      onChange={field => {
        setItem(
          {
            ...functionItem,
            kind,
            id: kind === 'input' ? getInputNodeId() : id,
            type: (field as OptionType).value,
          },
          index,
        );
      }}
      style={dropdownStyle}
    />
  );

  const renderValueField = (): ReactElement => {
    if (attributeType === 'NUMBER') {
      return (
        <input
          type="number"
          className="input-box"
          placeholder="eg: 50.00"
          onChange={val => setInputValue(+val.target.value || '')}
          value={inputValue as number}
          onBlur={() => {
            setItem({ ...functionItem, kind, value: inputValue ? +inputValue : '' }, index);
          }}
        />
      );
    }
    return (
      <Input
        bg="white"
        w="150px"
        fontSize="xs"
        value={inputValue?.toString()}
        onChange={e => setInputValue(e.target.value)}
        onBlur={() => {
          setItem({ ...functionItem, kind, value: inputValue || '' }, index);
        }}
      />
    );
  };

  return (
    <Box mt={4}>
      <Box ml={30} fontSize="sm" fontWeight="medium" pb={4}>
        <Flex alignItems="center">
          <Text>Argument {index + 1}</Text>
          {attributeType && (
            <Box
              ml={2}
              borderRadius="lg"
              textTransform="capitalize"
              bg="gray.200"
              px={2}
              py={1}
              fontSize="xs"
              fontWeight="normal"
            >
              {attributeType?.toLowerCase()}
            </Box>
          )}
        </Flex>
        <Text pt={2} fontSize="xs" fontWeight="normal" color="gray.600">
          {description}
        </Text>
      </Box>
      <HStack spacing={4}>
        <Box w={30} borderWidth={0.5} borderColor="gray.400" bg="gray.400" mr={-4} />
        {renderKindField()} {kind && kind !== 'input' && kind !== 'static' && renderIdField()}
        {((id && kind !== 'tag' && kind !== 'static') || kind === 'input') && renderTypeField()}
        {(kind === 'static' || value) && renderValueField()}
        {functionItemsCount > 2 && isVariadic && index !== 0 && (
          <Image
            mt={2}
            mr={6}
            w={5}
            h={5}
            src="/assets/icons/delete.svg"
            cursor="pointer"
            onClick={() => {
              onItemDelete(index);
            }}
          />
        )}
      </HStack>
    </Box>
  );
};

export default FunctionCondition;
