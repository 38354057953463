import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { Flex, Switch, Tooltip } from '@chakra-ui/react';
import { ActionButtonColumnProps } from '@/components/paginated-table/types';

export const ToggleQuickLinkCell = ({ value, row, column }: Cell): ReactElement => {
  const { getProps } = column as ActionButtonColumnProps;
  const { transactionLimit, transactionCount } = row.values;
  const isChecked = (): boolean => {
    if (transactionLimit <= transactionCount) {
      return false;
    }
    return value;
  };

  const getTooltipLabel = (): string => {
    if (transactionLimit <= transactionCount) {
      return `Transaction limit reached `;
    }
    return `Available transactions: ${transactionLimit - transactionCount}`;
  };
  return (
    <Tooltip label={getTooltipLabel()} hasArrow placement="top" bg="gray.700" color="white">
      <Flex
        w={10}
        cursor="pointer"
        onClick={() => {
          if (transactionLimit <= transactionCount) {
            return;
          }
          getProps?.onApiKeyToggle?.(row.values.id);
        }}
      >
        <Switch isChecked={isChecked()} colorScheme="blue" />
      </Flex>
    </Tooltip>
  );
};
