/* eslint-disable react/no-children-prop */
import React, { ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/layout';
import ColorInput from './ColorInput';
import FontSizeSelector from './FontSizeSelector';

const TextThemeCustomization = (): ReactElement => {
  return (
    <Flex direction="column" gridGap="5" maxH="37vh" overflowY="auto" mb="4">
      <Box>
        <Text
          textTransform="uppercase"
          fontWeight="400"
          color="gray.900"
          mb="2"
          fontStyle="normal"
          fontSize="14px"
          lineHeight="normal"
        >
          Title
        </Text>
        <Flex>
          <Box flexBasis="50%">
            <FontSizeSelector path="text.objects.title.fontSize" />
          </Box>
          <Box flexBasis="50%" pl="2">
            <ColorInput path="text.objects.title.color" />
          </Box>
        </Flex>
      </Box>

      <Box>
        <Text
          textTransform="uppercase"
          fontWeight="400"
          color="gray.900"
          mb="2"
          fontStyle="normal"
          fontSize="14px"
          lineHeight="normal"
        >
          Sub Title
        </Text>
        <Flex>
          <Box flexBasis="50%">
            <FontSizeSelector path="text.objects.subtitle.fontSize" />
          </Box>
          <Box flexBasis="50%" pl="2">
            <ColorInput path="text.objects.subtitle.color" />
          </Box>
        </Flex>
      </Box>

      <Box>
        <Text
          textTransform="uppercase"
          fontWeight="400"
          color="gray.900"
          mb="2"
          fontStyle="normal"
          fontSize="14px"
          lineHeight="normal"
        >
          Body Title
        </Text>
        <Flex>
          <Box flexBasis="50%">
            <FontSizeSelector path="text.objects.bodyTitle.fontSize" />
          </Box>
          <Box flexBasis="50%" pl="2">
            <ColorInput path="text.objects.bodyTitle.color" />
          </Box>
        </Flex>
      </Box>

      <Box>
        <Text
          textTransform="uppercase"
          fontWeight="400"
          color="gray.900"
          mb="2"
          fontStyle="normal"
          fontSize="14px"
          lineHeight="normal"
        >
          Content
        </Text>
        <Flex>
          <Box flexBasis="50%">
            <FontSizeSelector path="text.objects.content.fontSize" />
          </Box>
          <Box flexBasis="50%" pl="2">
            <ColorInput path="text.objects.content.color" />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default TextThemeCustomization;
