import React, { useEffect, ReactElement, forwardRef, useRef, MutableRefObject } from 'react';
import { Row, TableToggleAllRowsSelectedProps } from 'react-table';
import { Checkbox } from '@chakra-ui/react';

const CheckboxHeader = ({
  getToggleAllRowsSelectedProps,
}: {
  getToggleAllRowsSelectedProps: (
    props?: Partial<TableToggleAllRowsSelectedProps> | undefined,
  ) => TableToggleAllRowsSelectedProps;
}): ReactElement => <CheckboxComponent {...getToggleAllRowsSelectedProps()} />;
const CheckboxCell = ({ row }: { row: Row }): ReactElement => (
  <CheckboxComponent {...row.getToggleRowSelectedProps()} />
);
const CheckboxComponent = forwardRef(({ indeterminate, ...rest }: TableToggleAllRowsSelectedProps, ref) => {
  const defaultRef = useRef<HTMLInputElement>();
  const resolvedRef = (ref || defaultRef) as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (indeterminate) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox isChecked={rest.checked} ref={resolvedRef} {...rest} />;
});
CheckboxComponent.displayName = 'CheckboxComponent';

export { CheckboxHeader, CheckboxCell };
