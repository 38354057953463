import React, { ReactElement, SetStateAction } from 'react';
import { Input, Image, Flex, Text } from '@chakra-ui/react';
import TagsInput, { TagProps, InputProps } from 'react-tagsinput';

const TagInput = ({
  placeholder,
  tagList,
  setTagList,
  inputWidth,
}: {
  placeholder?: string;
  tagList: SetStateAction<string>[];
  setTagList: Function;
  inputWidth?: string;
}): ReactElement => {
  const renderTag = (props: TagProps): ReactElement => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...rest } = props;
    return (
      <Flex key={key} m={1} {...rest} bg="blue.100" borderRadius="md" px={3} py={1} justifyContent="space-between">
        <Text maxW="300px" minW="70px" isTruncated fontSize="xs" fontWeight="light" color="blue.500">
          {getTagDisplayValue(tag)}
        </Text>
        {!disabled && (
          <Image
            src="/assets/icons/tagClose.svg"
            className={classNameRemove}
            cursor="pointer"
            ml={2}
            onClick={() => onRemove(key)}
          />
        )}
      </Flex>
    );
  };
  const renderTagInputLayout = (tagComponents: ReactElement[], inputComponents: ReactElement): ReactElement => {
    return (
      <Flex borderRadius="lg" px={2} py={1} borderWidth={1} borderColor="gray.200" flexWrap="wrap" maxW="430px">
        {tagComponents}
        {inputComponents}
      </Flex>
    );
  };

  const renderTagInput = (props: InputProps): ReactElement => {
    const { onChange, value, addTag, ...rest } = props;
    return (
      <Input
        onChange={onChange}
        value={value}
        {...rest}
        border="none"
        _focusWithin={{ boxShadow: 'none' }}
        mt={1}
        h={6}
        w={inputWidth || 'full'}
        placeholder={placeholder}
        fontSize="sm"
        fontWeight="light"
      />
    );
  };

  return (
    <TagsInput
      value={tagList}
      onChange={value => setTagList(value)}
      renderLayout={renderTagInputLayout}
      renderTag={renderTag}
      renderInput={renderTagInput}
      addOnBlur
      addKeys={[',', 'Enter']}
    />
  );
};

export default TagInput;
