import React, { ReactElement, useState } from 'react';
import { Flex, Text, Tag, Button } from '@chakra-ui/react';
import { InputSectionPropTypes, OutputSectionPropTypes } from '../types';
import { NO_OF_TAGS_VISIBLE } from '../constant';

function InputSection({ sectionName, data = [] }: InputSectionPropTypes): ReactElement {
  const [showMore, setShowMore] = useState(false);

  const inputDisplayNamesList = data
    .filter(input => !input.location?.includes('source'))
    .map(input => input?.displayName || '');
  const uniqueInputNamesList = [...new Set(inputDisplayNamesList)];

  return (
    <Flex direction="column" gridGap="2">
      <Text fontSize="xs" textTransform="capitalize">
        {sectionName}
      </Text>
      <Flex wrap="wrap" gridRowGap="2" gridColumnGap="2">
        {uniqueInputNamesList
          ?.filter((_, index) => (showMore ? true : index <= NO_OF_TAGS_VISIBLE))
          .map(inputName => (
            <Tag
              key={inputName}
              py="1"
              borderWidth={1}
              borderRadius="lg"
              bg="white.50"
              borderColor="gray.200"
              fontSize="xs"
              fontWeight="300"
            >
              {inputName}
            </Tag>
          ))}
        {uniqueInputNamesList.length > NO_OF_TAGS_VISIBLE && (
          <Button
            variant="link"
            colorScheme="blue"
            fontSize="xs"
            fontWeight="normal"
            _focus={{ boxShadow: 'none' }}
            _hover={{ textDecoration: 'none' }}
            onClick={() => setShowMore(show => !show)}
          >
            {showMore ? 'show less' : 'show more...'}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

function OutputSection({ sectionName, data }: OutputSectionPropTypes): ReactElement {
  const [showMore, setShowMore] = useState(false);

  const outputDisplayNamesList = data.map(output => output?.displayName || '');
  const uniqueOutputNamesList = [...new Set(outputDisplayNamesList)];

  return (
    <Flex direction="column" gridGap="2">
      <Text fontSize="xs" textTransform="capitalize">
        {sectionName}
      </Text>
      <Flex wrap="wrap" gridRowGap="2" gridColumnGap="2">
        {uniqueOutputNamesList
          ?.filter((_, index) => (showMore ? true : index <= NO_OF_TAGS_VISIBLE))
          .map(outputName => (
            <Tag
              key={outputName}
              py="1"
              borderWidth={1}
              borderRadius="lg"
              bg="white.50"
              borderColor="gray.200"
              fontSize="xs"
              fontWeight="300"
            >
              {outputName}
            </Tag>
          ))}
        {uniqueOutputNamesList.length > NO_OF_TAGS_VISIBLE && (
          <Button
            variant="link"
            colorScheme="blue"
            fontSize="xs"
            fontWeight="normal"
            _focus={{ boxShadow: 'none' }}
            _hover={{ textDecoration: 'none' }}
            onClick={() => setShowMore(show => !show)}
          >
            {showMore ? 'show less' : 'show more...'}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export { InputSection, OutputSection };
