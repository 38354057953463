import React, { ReactElement } from 'react';
import { Controls, ControlButton, useZoomPanHelper } from 'react-flow-renderer';
import { CornersOut, Minus, Plus } from 'phosphor-react';

export const WorkflowsActionButtons = (): ReactElement => {
  const { zoomIn, zoomOut, fitView } = useZoomPanHelper();

  return (
    <Controls showZoom={false} showInteractive={false} showFitView={false}>
      <ControlButton onClick={() => zoomIn()} id="zoom-in">
        <Plus size={22} />
      </ControlButton>
      <ControlButton onClick={() => zoomOut()} id="zoom-out">
        <Minus size={22} />
      </ControlButton>
      <ControlButton onClick={() => fitView()} id="reset">
        <CornersOut size={22} />
      </ControlButton>
    </Controls>
  );
};
