import React from 'react';
import { Node, Handle, Position } from 'react-flow-renderer';
import { Flex, Box, Image, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { ShowInputAttributes } from '../states/workflowChart';
import { SupplierDescriptions } from '../states/serviceDescriptions';
import './workflow-chart/styles.css';
import { renderDataTestId } from '../helpers';

const InputNode = ({ data }: Node): React.ReactElement => {
  const showAttributesFlag = useRecoilValue(ShowInputAttributes);
  const serviceDescriptions = useRecoilValue(SupplierDescriptions);

  return (
    <>
      <Box
        borderWidth={2}
        borderRadius="lg"
        height="fit-content"
        w="209px"
        borderColor="white.50"
        data-test-id={renderDataTestId(data, 'inputNode')}
        bg={showAttributesFlag ? 'blue.100' : 'white'}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          h="100%"
          p={3}
          top="0"
          width="full"
          borderBottomWidth={1}
          borderColor="white.50"
        >
          <Flex borderRadius="full" bg="green.50" justifyContent="center" alignItems="center" h={6} w={6} mr="3">
            <Image src="/assets/icons/nodePencil.svg" w={4} h={4} />
          </Flex>
          <Box fontSize="sm" fontWeight="normal">
            Start
          </Box>
        </Flex>
        <Text fontSize="xs" fontWeight="light" color="gray.600" p={2} px={3}>
          {serviceDescriptions.startNode}
        </Text>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ width: '8px', height: '8px', background: 'white', border: '2px solid #ADC5F9' }}
      />
    </>
  );
};

export default InputNode;
