import React, { ReactElement, useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { OptionType } from '@bureau/components';
import Dropdown from '@/components/dropdown';

import { endTypes } from '../constants';
import { ModifyNodeDetails } from '../types';

export const AddEndStateModal = ({
  onCancel,
  onClose,
  nodeDetails,
}: {
  onCancel: () => void;
  onClose: (val: string, action: string) => void;
  nodeDetails: ModifyNodeDetails;
}): ReactElement => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (nodeDetails.action !== 'create') {
      setValue(nodeDetails?.oldId);
    }
  }, [nodeDetails]);

  return (
    <Modal isOpen onClose={onCancel} isCentered>
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent>
        <ModalHeader fontSize="md" fontWeight="500" p={6}>
          {nodeDetails.action === 'replace' ? 'Edit' : 'Add an'} End state
        </ModalHeader>
        <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ email: '' }}
          onSubmit={() => onClose(value, nodeDetails.action)}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <ModalBody>
                  <FormControl fontSize="sm">
                    <FormLabel fontSize="sm" color="gray.800">
                      End state
                    </FormLabel>
                    <Dropdown
                      onChange={data => {
                        setValue((data as OptionType)?.value);
                      }}
                      value={value}
                      options={endTypes}
                      size="small"
                      placeholder="Select..."
                      controlWidth="full"
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    h="38px"
                    w="124px"
                    color="blue.800"
                    bg="#E7EBFD"
                    fontSize="sm"
                    fontWeight="normal"
                    _hover={{ opacity: '0.8' }}
                    mr={3}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    h="38px"
                    w="124px"
                    color={!value ? 'gray.900' : 'white'}
                    bg={!value ? 'gray.100' : 'blue.500'}
                    disabled={!value}
                    fontSize="sm"
                    fontWeight="normal"
                    _hover={value ? { opacity: '0.8', background: 'blue.500' } : {}}
                  >
                    {nodeDetails.action === 'replace' ? 'Save' : 'Create'}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AddEndStateModal;
