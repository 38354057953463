import React, { ReactElement, useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Box } from '@chakra-ui/react';
import FileUpload from '@/components/file-upload/FileUpload';
import { isJSON } from './helpers';

const ImportFileModal = ({
  onCancel,
  onClose,
  errorMessage,
}: {
  onCancel: () => void;
  onClose: (name: string, json: string | ArrayBuffer) => void;
  errorMessage: string;
}): ReactElement => {
  const initialFile = new File([''], 'workflow');
  const [selectedFile, setSelectedFile] = useState(initialFile);
  const [invalidJson, setInvalidJson] = useState(false);

  useEffect(() => {
    setInvalidJson(false);
    if (selectedFile?.size) {
      const fileReader = new FileReader();
      fileReader.readAsText(selectedFile, 'UTF-8');
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (isJSON(e?.target?.result || '')) {
          onClose(selectedFile?.name.replace(/\.[^/.]+$/, ''), e?.target?.result || ''); // removes extension from name
        } else {
          setInvalidJson(true);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <Modal isOpen onClose={onCancel} isCentered>
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent borderRadius="lg">
        <ModalHeader fontSize="md" fontWeight="500" p={6}>
          Import a file
        </ModalHeader>
        <ModalCloseButton top={4} _focus={{ border: 'none' }} size="sm" />
        <ModalBody pt={0} pb={4}>
          <FileUpload
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            validTypes={['text/plain', 'application/json']}
            message="Upload .txt or .json file"
            title=""
            errorMessage={errorMessage}
            showUpload
          />
          {invalidJson && (
            <Box color="red.500" fontSize="xs">
              Please provide a valid JSON{' '}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ImportFileModal;
