import React, { ReactElement } from 'react';
import { Button, Text, Flex, IconButton } from '@chakra-ui/react';
import { CheckCircle, CodeSimple, TrashSimple } from 'phosphor-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { colors } from '@/utils/colors';
import { ConditionsType } from './types';
import Conditions from './Conditions';
import { ConditionsAtom } from './states/conditionsAtom';
import { SelectedNodeAtom } from './states/selectedNodeAtom';

function ConditionsBlock({ type }: { type: ConditionsType }): ReactElement {
  const conditionsBlock = useRecoilValue(ConditionsAtom);
  const firstKey = Object.keys(conditionsBlock[type][0].when)[0];
  const setConditionsBlock = useSetRecoilState(ConditionsAtom);
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  function getBackgroundColor(): string {
    if (type === ConditionsType.accept) {
      return 'green.50';
    }
    if (type === ConditionsType.reject) {
      return 'red.200';
    }
    return 'blue.100';
  }
  function getColor(): string {
    if (type === ConditionsType.accept) {
      return 'green.500';
    }
    if (type === ConditionsType.reject) {
      return 'red.500';
    }
    return 'blue.500';
  }

  function getBorderColor(): string {
    if (type === ConditionsType.accept) {
      return 'green.200';
    }
    if (type === ConditionsType.reject) {
      return 'red.50';
    }
    return 'blue.200';
  }

  const conditionDescription = {
    [ConditionsType.accept]: 'Workflow transactions that match the below conditions will be Accepted',
    [ConditionsType.reject]: 'Workflow transactions that match the below conditions will be Rejected',
    [ConditionsType.manual_review]:
      'Workflow transactions that match the below conditions will be put under Manual Review',
  };

  return (
    <Flex direction="column">
      <Flex
        w="full"
        p="2"
        gridGap="2"
        bg={getBackgroundColor()}
        borderColor="gray.200"
        borderWidth={1}
        borderRadius="lg"
        borderBottomRadius="none"
        align="center"
        display={selectedNode.type === 'branch' ? 'none' : 'flex'}
      >
        <Button
          variant="outline"
          leftIcon={<CheckCircle size={20} weight="fill" />}
          rightIcon={<CodeSimple size={12} style={{ transform: 'rotate(90deg)' }} />}
          p="2"
          borderRadius="lg"
          borderColor={getBorderColor()}
          color={getColor()}
          bg={getBackgroundColor()}
          fontSize="sm"
          fontWeight="medium"
          textTransform="capitalize"
          // _hover={{ bg: getBorderColor() }}
          _disabled={{ opacity: 1, cursor: 'default' }}
          isDisabled
        >
          {type.toLowerCase()}
        </Button>
        <Text color="gray.600" fontSize="xs" flexGrow={1}>
          {conditionDescription[type]}
        </Text>
        <IconButton
          aria-label="Delete condition"
          icon={<TrashSimple size={20} color={colors.gray[500]} />}
          bg="transparent"
          minW="5"
          h="5"
          mr="4"
          onClick={() => {
            setConditionsBlock(prevConditions => ({
              ...prevConditions,
              [type]: [],
            }));
          }}
        />
      </Flex>
      <Flex
        border="1px solid"
        borderColor="gray.200"
        borderBottomRadius="lg"
        borderTop="none"
        p="4"
        bg="white"
        overflow="auto"
      >
        <Conditions type={type} conditions={conditionsBlock[type][0].when} pathArray={[firstKey]} />
      </Flex>
    </Flex>
  );
}

export default ConditionsBlock;
