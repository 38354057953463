import React from 'react';
import { Cell } from 'react-table';
import {
  format,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  differenceInMinutes,
} from 'date-fns';
import { Flex, Text } from '@chakra-ui/react';

const getTimeDifferenceString = (dateNow: Date, date: Date): string => {
  const yearsDifference = differenceInYears(dateNow, date);
  const monthsDifference = differenceInMonths(dateNow, date);
  const daysDifference = differenceInDays(dateNow, date);
  const hoursDifference = differenceInHours(dateNow, date);
  const minutesDifference = differenceInMinutes(dateNow, date);

  if (yearsDifference >= 1) {
    return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
  }
  if (monthsDifference >= 1) {
    return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
  }
  if (daysDifference >= 1) {
    return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
  }
  if (hoursDifference >= 1) {
    return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
  }
  if (minutesDifference >= 1) {
    return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
  }

  return `< 1 minute ago`;
};

export const BulkVerificationDateCell = ({ value }: Cell): React.ReactElement => {
  if (value) {
    const dateNow = new Date();
    const date = new Date(value);

    const formattedDate = format(date, 'dd-MM-yyyy');
    const formattedTime = format(date, `h:mm:ss a`);
    const timeDifferenceString = getTimeDifferenceString(dateNow, date);

    return (
      <Flex direction="column">
        <Text fontSize="xs" color="gray.700">
          {formattedDate} {formattedTime}
        </Text>
        <Text fontSize="xs" fontWeight="light" color="gray.500">
          {timeDifferenceString}
        </Text>
      </Flex>
    );
  }

  return <></>;
};
