import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormControl,
  FormLabel,
  Circle,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './workflow-chart/styles.css';

import { ModifyNodeDetails } from '../types';
import { AddedTags } from '../states/workflowChart';

export const AddTagModal = ({
  onCancel,
  onClose,
  nodeDetails,
}: {
  onCancel: () => void;
  onClose: (name: string, color: string, action: string) => void;
  nodeDetails: ModifyNodeDetails;
}): ReactElement => {
  const [tagName, setTagName] = useState('');
  const [selectedColor, setSelectedColor] = useState('gray');
  const [tagExistsFlag, setTagExistsFlag] = useState(false);
  const tagColors = ['gray', 'orange', 'green', 'red'];

  const [tags, setTags] = useRecoilState(AddedTags);

  const onSubmit = (values: { tagName: string }, { setSubmitting }: { setSubmitting: Function }): void => {
    const cloneTags = [...tags];
    if (tagName && (tagName === nodeDetails.oldName || cloneTags.indexOf(tagName) < 0)) {
      if (nodeDetails.action === 'edit') {
        const index = cloneTags.findIndex(tg => tg === nodeDetails.oldName);
        cloneTags.splice(index, 1);
      }
      cloneTags.push(tagName);
      setTags(cloneTags);
      setTagExistsFlag(false);
      onClose(tagName, selectedColor, nodeDetails.action);
    } else setTagExistsFlag(true);
    setSubmitting(false);
  };

  useEffect(() => {
    if (nodeDetails.action !== 'create') {
      setTagName(nodeDetails?.oldName);
      setSelectedColor(nodeDetails?.color || 'gray');
    }
  }, [nodeDetails]);

  return (
    <Modal isOpen onClose={onCancel} isCentered>
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent borderRadius="lg">
        <ModalHeader fontSize="md" fontWeight="500" p={6}>
          {nodeDetails.action === 'edit' ? 'Edit' : 'New'} Tag
        </ModalHeader>
        <ModalCloseButton top={4} _focus={{ border: 'none' }} />
        <Formik
          initialValues={{ tagName: '' }}
          validationSchema={Yup.object().shape({
            tagName: Yup.string().min(3, 'Tag Name must be at least 3 characters long.'),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, errors, touched, handleBlur, handleChange }) => {
            return (
              <Form>
                <ModalBody>
                  <FormControl isInvalid={!!errors.tagName && !!touched.tagName}>
                    <FormLabel fontSize="sm" color="gray.800" fontWeight="400">
                      Tag
                    </FormLabel>
                    <Input
                      name="tagName"
                      placeholder="Enter tag name"
                      value={tagName}
                      onChange={evt => {
                        handleChange(evt);
                        setTagName(evt.target.value);
                        setTagExistsFlag(false);
                      }}
                      onBlur={handleBlur}
                      fontSize="sm"
                      borderColor={tagExistsFlag ? 'red.600' : 'gray.300'}
                    />
                    {tagExistsFlag && (
                      <Text color="red.600" fontSize="xs">
                        A tag with the same name already exists in the capability
                      </Text>
                    )}
                    {errors.tagName && touched.tagName && (
                      <Text color="red.600" fontSize="xs">
                        {errors.tagName}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl mt="6">
                    <FormLabel fontSize="sm" color="gray.800">
                      Tag Color
                    </FormLabel>
                    <Flex>
                      {tagColors.map(color => (
                        <Circle
                          size={color === selectedColor ? '27px' : '40px'}
                          bg={`${color}.500`}
                          color="white"
                          border="3px solid #FFF"
                          top={color === selectedColor ? '7px' : '0'}
                          ml={color === selectedColor ? '6px' : '0'}
                          mr={color === selectedColor ? '4' : '2'}
                          key={color}
                          onClick={() => setSelectedColor(color)}
                          cursor="pointer"
                          position="relative"
                          className={color === selectedColor ? 'selectedColor' : ''}
                          _after={
                            color === selectedColor
                              ? {
                                  content: '""',
                                  position: 'absolute',
                                  borderWidth: '3px',
                                  borderColor: `${color}.500`,
                                  borderRadius: '50%',
                                  width: 'calc(100% + 14px)',
                                  height: 'calc(100% + 14px)',
                                  left: '-7px',
                                  top: -'7px',
                                }
                              : {}
                          }
                        />
                      ))}
                    </Flex>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    h="38px"
                    w="124px"
                    color="blue.800"
                    bg="#E7EBFD"
                    fontSize="sm"
                    fontWeight="300"
                    _hover={{ opacity: '0.8' }}
                    mr={3}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    h="38px"
                    w="124px"
                    disabled={tagName.length < 3}
                    fontSize="sm"
                    fontWeight="300"
                    colorScheme="blue"
                  >
                    {nodeDetails.action === 'edit' ? 'Save' : 'Create'}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AddTagModal;
