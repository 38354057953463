import React, { useMemo } from 'react';
import ReactSelect, {
  GroupTypeBase,
  NamedProps,
  StylesConfig,
  components as ReactSelectComponents,
  IndicatorProps,
  Styles,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useFormControlProps, useToken } from '@chakra-ui/react';
import { MdArrowDropDown, MdClear } from 'react-icons/md';

export interface OptionType<T = string> {
  label: string;
  value: T;
}

type ReactSelectInputProps = NamedProps<OptionType, boolean>;
type SizeType = 'small' | 'default';
export type SelectInputProps = Omit<ReactSelectInputProps, 'value'> & {
  value?: string | string[] | null;
  options: OptionType[];
  isInvalid?: boolean;
  controlWidth?: string;
  maxWidth?: string;
  menuHeading?: string;
  variant?: string;
  staticContainer?: boolean;
  width?: string;
  menuWidth?: string;
  size?: SizeType;
  filterOption?: (option: OptionType, inputValue: string) => boolean;
  style?: object;
  createNewLabel?: string;
  onCreateOption?: (value: string) => void;
};

type Options = OptionType[];

const getFinalValue = (
  isMulti: boolean | undefined,
  options: OptionType[],
  value?: string | string[] | null,
): OptionType | OptionType[] | null => {
  if (isMulti) {
    return findOptions(getValues(value as string[]), options);
  }
  return findOption(getValue(value as string), options);
};

const getValues = (value: string[] | null | undefined): string[] => {
  if (value === null || value === undefined) {
    return [];
  }

  return value;
};
const getValue = (value: string | null | undefined): string => {
  if (value === null || value === undefined) {
    return '';
  }

  return value;
};

const findOptions = (selectedValues: Array<string>, options: Options): Options => {
  if (options.length === 0) {
    return [];
  }

  if (selectedValues.length === 0) {
    return [];
  }

  return selectedValues.map(selectedValue => {
    const result = options.find(option => option.value === selectedValue);

    if (result === undefined) {
      return { label: selectedValue as string, value: selectedValue };
    }

    return result;
  });
};

const findOption = (selectedValue: unknown, options: Options): OptionType | null => {
  if (selectedValue === null) {
    return null;
  }

  if (selectedValue === '') {
    return null;
  }

  if (typeof selectedValue === 'object') {
    throw new Error(`FormSelect: incorrect value type`);
  }

  return (
    options?.find(option => {
      if (typeof option.value === 'object') {
        throw new Error(`FormSelect: incorrect value type`);
      }
      return option.value === selectedValue;
    }) || { label: selectedValue as string, value: selectedValue as string }
  );
};

const getReadableValue = (value: string | string[], options: Options, isMulti: boolean): string => {
  if (isMulti) {
    return findOptions(getValues(value as string[]), options)
      .map(opt => opt.label)
      .join(', ');
  }
  const option = findOption(getValue(value as string), options) || { label: '' };
  return option.label;
};

const DropdownIndicator = (props: IndicatorProps<OptionType, boolean>): React.ReactElement => {
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <MdArrowDropDown />
    </ReactSelectComponents.DropdownIndicator>
  );
};
const ClearIndicator = (props: IndicatorProps<OptionType, boolean>): React.ReactElement => {
  return (
    <ReactSelectComponents.ClearIndicator {...props}>
      <MdClear />
    </ReactSelectComponents.ClearIndicator>
  );
};
const IndicatorSeparator = (): null => null;

const useSelectStyles = (
  size: SizeType,
  isInvalid?: boolean,
  styles?: Partial<Styles<OptionType, boolean>>,
): StylesConfig<OptionType, boolean, GroupTypeBase<OptionType>> => {
  const textColor = useToken('colors', 'gray.700');
  const boxShadowColor = useToken('colors', 'gray.200');
  const listBgColor = useToken('colors', 'white');
  const selectedBgColor = useToken('colors', 'blue.50');
  const placeholderColor = useToken('colors', 'gray.400');
  const inputColor = useToken('colors', 'gray.900');
  const focusColor = useToken('colors', 'blue.500');
  const focusedBackgroundColor = useToken('colors', 'gray.100');
  const invalidColor = useToken('colors', 'red.500');
  const getFinalBoxShadowColor = (isFocused: boolean): string => {
    if (isFocused) {
      return focusColor;
    }
    if (isInvalid) {
      return invalidColor;
    }
    return boxShadowColor;
  };
  return {
    container: (base, { selectProps }) => ({
      position: selectProps.staticContainer ? 'static' : 'relative',
    }),
    control: (base, { isFocused, isDisabled, selectProps, ...rest }) => {
      const finalBoxShadowColor = getFinalBoxShadowColor(isFocused);
      return {
        ...base,
        width: selectProps.width,
        opacity: isDisabled ? '.55' : '1',
        outline: 'none',
        border: `1px solid ${finalBoxShadowColor}`,
        boxShadow: `0 0 0 ${isFocused || isInvalid ? '1px' : '0'} ${finalBoxShadowColor}`,
        ...styles?.control?.(base, { isFocused, isDisabled, selectProps, ...rest }),
      };
    },
    singleValue: base => ({
      ...base,
      color: textColor,
    }),
    input: base => ({
      ...base,
      color: inputColor,
    }),
    indicatorsContainer: base => ({
      ...base,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 0,
      fontSize: '24px',
    }),
    clearIndicator: base => ({
      ...base,
      fontSize: '16px',
    }),
    option: (base, { isSelected, isFocused, ...rest }) => {
      let backgroundColor = listBgColor;

      if (isSelected) backgroundColor = selectedBgColor;
      if (isFocused) backgroundColor = focusedBackgroundColor;

      return {
        ...base,
        backgroundColor,
        color: 'gray.800',
        ...styles?.option?.(base, { isSelected, isFocused, ...rest }),
      };
    },
    menu: (base, { selectProps }) => ({
      ...base,
      backgroundColor: listBgColor,
    }),
    placeholder: base => ({
      ...base,
      color: placeholderColor,
    }),
  };
};

const baseComponents = { DropdownIndicator, ClearIndicator, IndicatorSeparator };
const SelectInput = ({
  id,
  isClearable = true,
  noOptionsMessage = () => 'No options',
  loadingMessage = () => 'Loading...',
  value,
  isMulti = false,
  options,
  size = 'default',
  components,
  styles,
  placeholder = '',
  createNewLabel = '',
  filterOption = (option: OptionType, inputValue: string): boolean => {
    return (
      !inputValue ||
      (typeof option?.label === 'string' && option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())) ||
      typeof option?.label !== 'string'
    );
  },
  onCreateOption,
  ...props
}: SelectInputProps): React.ReactElement => {
  const { isInvalid } = useFormControlProps({ id, ...props });
  const finalStyles = useSelectStyles(size, isInvalid, styles);

  const finalValue = getFinalValue(isMulti, options, value);
  const selectComponents = useMemo(() => {
    return { ...baseComponents, ...components };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {createNewLabel ? (
        <CreatableSelect
          createOptionPosition="first"
          placeholder={placeholder}
          isClearable={isClearable}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          className="react-select-container"
          classNamePrefix="react-select"
          inputId={id}
          isMulti={isMulti}
          options={options}
          value={finalValue}
          {...props}
          components={selectComponents}
          styles={finalStyles}
          filterOption={filterOption}
          menuPlacement="auto"
          onCreateOption={(newValue: string) => onCreateOption?.(newValue)}
          formatCreateLabel={(inputValue: string) => (
            <div>
              {createNewLabel} <b>{inputValue}</b>
            </div>
          )}
        />
      ) : (
        <ReactSelect
          placeholder={placeholder}
          isClearable={isClearable}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          className="react-select-container"
          classNamePrefix="react-select"
          inputId={id}
          isMulti={isMulti}
          options={options}
          value={finalValue}
          {...props}
          components={selectComponents}
          styles={finalStyles}
          filterOption={filterOption}
          menuPlacement="auto"
        />
      )}
    </>
  );
};

export { SelectInput, getValue, getReadableValue, findOption, findOptions };
