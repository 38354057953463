import UserOnboarding from '@/screens/user-onboarding/UserOnboarding';
import { bureauPlatformApi } from '@/utils/api';
import React, { ReactElement } from 'react';
import { AppLandingContent } from './AppLandingContent';

export const AppLanding = (): ReactElement => {
  if (bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'] || window.location.href.indexOf('stg') > -1) {
    return <AppLandingContent />;
  }

  return <UserOnboarding />;
};

export default AppLanding;
