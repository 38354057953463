import React, { ReactElement, useEffect } from 'react';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import { NotePencil } from 'phosphor-react';
import Draggable from 'react-draggable';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { showLeftNav } from '@/states/showLeftNav';
import './styles.css';

const nodes = [
  {
    bg: 'green.100',
    icon: <NotePencil />,
    heading: 'URL on browser',
    description: 'When user redirect valid or invalid URL in browser.',
    hasBorder: true,
  },
  {
    bg: 'blue.100',
    icon: <Image src="/assets/icons/verifyUrl.svg" h={4} w={4} />,
    heading: 'Verify URL',
    description: 'Verify URL redirect valid or invalid URL in browser.',
    hasBorder: false,
  },
  {
    bg: 'blue.100',
    icon: <Image src="/assets/icons/accept.svg" h={6} w={6} />,
    heading: 'Accept',
    description: 'Lorem ipus service description will appear here.',
    hasBorder: false,
  },
];

const ErrorScreen = (): ReactElement => {
  const isShowLeftNav = useRecoilValue(showLeftNav);
  const setShowLeftNav = useSetRecoilState(showLeftNav);

  useEffect(() => {
    const showLeftNavDefault = isShowLeftNav;
    setShowLeftNav(false);

    return () => {
      setShowLeftNav(showLeftNavDefault);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const logo = window.location.href.includes('enformion') ? '/assets/Enformion_LOGO.png' : '/assets/bureau-logo.svg';
  return (
    <Flex w="full" h="full" overflow="auto">
      <Image src={logo} w={28} h={25} m={6} />
      <Draggable>
        <Flex alignItems="center" justifyContent="center" direction="column" w="full" position="relative">
          <Flex>
            {nodes.map(item => (
              <Node item={item} key={item.heading} />
            ))}
          </Flex>
          <Box borderWidth={2} borderColor="gray.200" borderRadius="lg" w={607} ml={424} mt={82} bg="white">
            <Flex alignItems="center" px={2} pt={3} pb={2} borderBottomWidth={1} borderColor="gray.200">
              <Image src="/assets/icons/reject.svg" h={6} w={6} />
              <Text fontSize="sm" fontWeight="normal" pl={2}>
                Reject
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="center" direction="column" py={10} px={84}>
              <Image src="/assets/icons/error.svg" />
              <Text pt={4} fontSize="24px" fontWeight="bold">
                Oh No! Page not found...
              </Text>
              <Text pt={2} fontSize="sm" fontWeight="light" textAlign="center" maxW={300} color="gray.600">
                Sorry, the page you’re looking for doesn’t exist or has been moved.
              </Text>
              <Link to="/">
                <Button
                  mt={6}
                  minW="180px"
                  colorScheme="blue"
                  borderRadius="lg"
                  py="10px"
                  pl={7}
                  pr={8}
                  size="sm"
                  h="44px"
                  fontWeight="light"
                  fontSize="sm"
                  onClick={() => setShowLeftNav(true)}
                >
                  Back to home
                </Button>
              </Link>
            </Flex>
          </Box>
          <Image src="/assets/icons/connector.svg" position="absolute" ml={-308} mt={-500} />
          <Image src="/assets/icons/connectorGray.svg" position="absolute" ml={324} mt={-500} />
          <Image src="/assets/icons/connectorRed.svg" position="absolute" ml={5} mt={-190} />
          <Box
            h={5}
            w={5}
            borderRadius="full"
            bg="green.500"
            position="absolute"
            className="motion-div"
            ml={-370}
            mt={-482}
          />
          <Box
            h={5}
            w={5}
            borderRadius="full"
            bg="red.600"
            position="absolute"
            className="motion-div2"
            ml={-230}
            mt={-484}
          />
        </Flex>
      </Draggable>
    </Flex>
  );
};

const Node = ({
  item,
}: {
  item: { bg: string; description: string; hasBorder: boolean; icon: ReactElement; heading: string };
}): ReactElement => {
  return (
    <Box
      borderWidth={2}
      borderColor={item.hasBorder ? 'green' : 'gray.200'}
      borderRadius="lg"
      ml={item.hasBorder ? '' : '88px'}
      bg="white"
    >
      <Flex alignItems="center" px={2} pt={3} pb={2} borderBottomWidth={1} borderColor="gray.200">
        <Flex
          h={6}
          w={6}
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          bg={item.bg}
          color="green.500"
        >
          {item.icon}
        </Flex>
        <Text fontSize="sm" fontWeight="normal" pl={2}>
          {item.heading}
        </Text>
      </Flex>
      <Text p={2} pb={3} fontSize="xs" fontWeight="light" color="gray.600" w={224}>
        {item.description}
      </Text>
    </Box>
  );
};

export default ErrorScreen;
