import React from 'react';
import { Text } from '@chakra-ui/react';
import { Cell } from 'react-table';

export const BulkQueryCell = ({ value }: Cell): React.ReactElement => {
  return (
    <Text fontSize="xs" color="gray.500">
      {value}
    </Text>
  );
};
