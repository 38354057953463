import React, { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SelectedNodeAtom } from '../states/selectedNodeAtom';
import { ConditionsAtom } from '../states/conditionsAtom';
import { Conditions, ConditionsType, WorkflowRenderObj } from '../types';
import { EndnodeAtom } from '../states/endnodeAtom';

const BoundaryNode = ({
  title,
  description,
  startIcon,
  endIcon,
  id,
  nodeData,
  handleCapabilityNodeClick,
}: {
  title: string;
  description: string;
  startIcon: ReactElement;
  endIcon: ReactElement;
  nodeData?: WorkflowRenderObj;
  id?: string;
  handleCapabilityNodeClick?: (id: string) => void;
}): ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const resetNode = useResetRecoilState(SelectedNodeAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const setConditions = useSetRecoilState(ConditionsAtom);
  const endColumn = useRecoilValue(EndnodeAtom);
  return (
    <Flex
      bg="white"
      border="1"
      borderColor="gray.200"
      borderRadius="lg"
      w="328px"
      px="2"
      py="2"
      alignItems="center"
      gridGap="2"
      onClick={() => {
        if (title !== 'End Workflow') {
          return;
        }
        resetNode();
        resetConditions();
        const conditionsArray = endColumn.nodes;

        if (conditionsArray.length) {
          const conditionsObject = {
            [ConditionsType.accept]: [],
            [ConditionsType.reject]: [],
            [ConditionsType.manual_review]: [],
          } as Conditions;
          conditionsArray.forEach(arr => {
            if (arr.kind === 'end_accepted' && arr.conditions) {
              const acceptedCondition = arr.conditions;
              conditionsObject[ConditionsType.accept] = acceptedCondition;
            }
            if (arr.kind === 'end_rejected' && arr.conditions) {
              const rejectedCondition = arr.conditions;
              conditionsObject[ConditionsType.reject] = rejectedCondition;
            }
            if (arr.kind === 'end_manual_review' && arr.conditions) {
              const manualReviewCondition = arr.conditions;
              conditionsObject[ConditionsType.manual_review] = manualReviewCondition;
            }
          });
          setConditions(conditionsObject);
        }
        setSelectedNode(() => ({ id, type: 'end', columns: [endColumn] }));
        handleCapabilityNodeClick && handleCapabilityNodeClick(id || '');
      }}
    >
      <Flex p="2" bg="green.50" borderRadius="lg" alignSelf="flex-start">
        {startIcon}
      </Flex>
      <Flex direction="column" gridGap="0.5">
        <Text fontSize="sm">{title}</Text>
        <Text fontSize="xs" color="gray.600" noOfLines={[1, 2]} fontWeight="300">
          {description}
        </Text>
      </Flex>
      {endIcon}
    </Flex>
  );
};

export default BoundaryNode;
