import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { GetWorkflowResponse } from '../types';

export const useGetWorkflow = (id: string): UseQueryResult<GetWorkflowResponse, AxiosError> => {
  return useQuery('workflowById', getDetails(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!id,
  });
};

const getDetails = (id: string) => {
  return async () => {
    const res = await bureauPlatformApi.get<GetWorkflowResponse>(`/workflows/${id}`);
    return res.data;
  };
};

export default useGetWorkflow;
