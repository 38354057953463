import { Flex } from '@chakra-ui/layout';
import React, { ReactElement, useState } from 'react';
import AdvanceCustomizationButtonGroup from './AdvanceCustomizationButtonGroup';
import BackgroundCustomization from './BackgroundCustomization';
import BrandingCustomization from './BrandingCustomization';
import ButtonThemeCustomization from './ButtonThemeCustomization';
import TextThemeCustomization from './TextThemeCustomization';

function AdvancedCustomization(): ReactElement {
  const [selectedButton, setSelectedButton] = useState<string>('branding');

  const renderCustomizationDetails = (): ReactElement => {
    if (selectedButton === 'branding') {
      return <BrandingCustomization />;
    }
    if (selectedButton === 'text') {
      return <TextThemeCustomization />;
    }
    if (selectedButton === 'buttons') {
      return <ButtonThemeCustomization />;
    }
    if (selectedButton === 'background') {
      return <BackgroundCustomization />;
    }
    return <></>;
  };
  return (
    <Flex direction="column">
      <AdvanceCustomizationButtonGroup selectedButton={selectedButton} setSelectedButton={setSelectedButton} />
      {renderCustomizationDetails()}
    </Flex>
  );
}

export default AdvancedCustomization;
