import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

interface TryOutRequest {
  workflowId?: string;
  data: {
    [key: string]: string;
  };
}

interface TryOutResponse {
  transactionId: string;
}

export const useTryOut = (
  options?: UseMutationOptions<TryOutResponse, AxiosError, TryOutRequest>,
): UseMutationResult<TryOutResponse, AxiosError, TryOutRequest> => useMutation(tryOut, options);

const tryOut = async (data: TryOutRequest): Promise<TryOutResponse> => {
  const url = process.env.REACT_APP_STG1_URL ? `${process.env.REACT_APP_STG1_URL}/transactions` : '/transactions';
  const { data: response } = await bureauPlatformApi.post<TryOutResponse>(url, data);
  return response;
};
