import React, { ReactElement, useEffect, forwardRef } from 'react';
import { Flex, Text, HStack, Image, Box, Input } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash-es';
import Dropdown from '@/components/dropdown/Dropdown';
import { OptionType } from '@bureau/components';
import TagInput from '@/components/tag-input/TagInput';
import useConditionFieldHandler from '../../../hooks/useConditonFieldHandler';
import { conditionTypes, staticDynamicFieldOptions, initialItem, functionItem } from '../../../constants';
import { WorkflowJson, ActiveService } from '../../../states/workflowChart';
import { AndOrCondition, FormatType, ConstantType, FunctionalOperator } from '../types';
import { ConditionGroupItem, FunctionItem } from '../../../types';
import CustomFunctionBlock from './CustomFunctionBlock';
import './styles.css';
import OperatorBox from './OperatorBox';

const dropdownStyle = { fontWeight: '300', border: '1px solid #DDE1EC' };

const BlocklistTag = ({ isBlocklist }: { isBlocklist: boolean }): ReactElement => (
  <Box
    borderRadius="lg"
    bg={isBlocklist ? 'red.50' : 'green.50'}
    color={isBlocklist ? 'red.500' : 'green.500'}
    py={1}
    px={2}
    fontSize="xs"
    fontWeight="light"
  >
    {isBlocklist ? 'Blocklist' : 'Allowlist'}
  </Box>
);

const ConditionField = ({
  onMainItemDelete,
  setConditionData,
  conditionsArrayCount,
  position,
  prefixWord,
  group,
  isClearable,
  condition,
}: {
  onMainItemDelete: (arrayIndex: number) => void;
  setConditionData: (data: ConditionGroupItem, arrayPosition: number) => void;
  prefixWord?: string;
  group: string;
  position: number;
  isClearable: boolean;
  condition: ConditionGroupItem;
  conditionsArrayCount: number;
}): ReactElement => {
  const {
    kind,
    id,
    type,
    value,
    operator,
    isDynamic,
    isFunctionOperator,
    functionItems,
    dynamicItem,
    functionOperator,
  } = condition;
  const workflowJson = useRecoilValue(WorkflowJson);
  const activeService = useRecoilValue(ActiveService);

  const DateInput = forwardRef(({ value: inputValue, ...props }: { value?: string }, ref) => (
    <Input bg="white" value={inputValue} {...props} />
  ));

  DateInput.displayName = 'DateInput';

  const {
    handleDropdownData,
    getIdsDropdownData,
    getDynamicTypesDropdownData,
    getTypesDropdownData,
    getOperators,
    allowedValues,
    valueType,
    valueFormat,
    showOperator,
    setShowOperator,
    getBlocklist,
    getOperatorData,
  } = useConditionFieldHandler();

  useEffect(() => {
    if (condition) handleDropdownData(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, activeService]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Option = (props: any): React.ReactElement => {
    const { children, innerProps, data } = props;
    const isBlocklist = data?.type === 'blocklist';
    return (
      <Flex
        {...innerProps}
        bg="white"
        p={2}
        alignItems="center"
        cursor="pointer"
        justifyContent="space-between"
        borderRadius="lg"
        _hover={{ bg: 'white.50' }}
      >
        <Text color="gray.900" fontSize="sm" fontWeight="light" w={220} isTruncated>
          {children}
        </Text>
        <BlocklistTag isBlocklist={isBlocklist} />
      </Flex>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SingleValue = (props: any): React.ReactElement => {
    const { children, innerProps, data } = props;
    const isBlocklist = data?.type === 'blocklist';
    return (
      <Flex {...innerProps} minW="50px" alignItems="center">
        <Text color="gray.900" fontWeight="light" fontSize="xs" mr={3}>
          {children}
        </Text>
        <BlocklistTag isBlocklist={isBlocklist} />
      </Flex>
    );
  };

  const getInputNodeId = (): string => workflowJson?.columns?.[0]?.nodes?.[0]?.id;

  const onIdsDropdownChange = (val: OptionType): void => {
    if (functionOperator)
      setConditionData(
        { ...initialItem, ...condition, kind: functionItems[0]?.kind, id: val.value, operator: 'eq' },
        position,
      );
    else setConditionData({ ...initialItem, ...condition, kind, id: val.value, operator: 'eq' }, position);
  };

  const onTypesDropdownChange = (val: OptionType): void => {
    if (functionOperator)
      setConditionData(
        {
          ...initialItem,
          ...condition,
          kind: functionItems[0]?.kind,
          id: functionItems[0]?.kind === 'input' ? getInputNodeId() : functionItems[0]?.id,
          type: val.value,
          operator: 'eq',
        },
        position,
      );
    else {
      setConditionData(
        {
          ...initialItem,
          ...condition,
          kind,
          id: kind === 'input' ? getInputNodeId() : id,
          type: val.value,
          operator: 'eq',
        },
        position,
      );
    }
  };

  const onOperatorChange = (val: OptionType): void => {
    setShowOperator(true);
    const operatorData = cloneDeep(getOperatorData(val.value));
    const { kind: functionKind, type: functionType, id: functionId } = functionItems[0]
      ? functionItems[0]
      : { kind: '', id: '', type: '' };
    if (operatorData?.type === 'functional') {
      const tempCondition = {
        ...initialItem,
        isFunctionOperator: true,
        functionOperator: val?.value,
        functionItems: [
          {
            kind: functionOperator ? functionKind : kind,
            type: functionOperator ? functionType : type,
            id: functionOperator ? functionId : id,
            value: '',
          },
        ],
      };
      if (operatorData?.label === FunctionalOperator.IS_BLOCKED) {
        tempCondition.functionItems.push({ ...functionItem, kind: 'static' });
      } else if (operatorData?.isVariadic) {
        tempCondition.functionItems.push(functionItem);
      } else {
        operatorData?.arguments?.splice(0, 1);
        operatorData?.arguments?.forEach(item => {
          tempCondition.functionItems.push(functionItem);
        });
      }
      setConditionData(tempCondition, position);
    } else {
      setConditionData(
        {
          ...initialItem,
          ...condition,
          kind: functionOperator ? functionKind : kind,
          id: functionOperator ? functionId : id,
          type: functionOperator ? functionType : type,
          operator: val?.value,
        },
        position,
      );
    }
  };

  const renderKindField = (): ReactElement => (
    <Dropdown
      options={conditionTypes}
      value={functionOperator ? functionItems[0].kind : kind}
      {...(!kind && !functionItems?.[0]?.kind && { controlWidth: '150px' })}
      placeholder="Select"
      onChange={field => {
        setConditionData({ ...initialItem, ...condition, kind: (field as OptionType).value, operator: 'eq' }, position);
      }}
      style={dropdownStyle}
    />
  );

  const renderIdField = (): ReactElement => (
    <Dropdown
      options={getIdsDropdownData(kind || functionItems[0]?.kind || '') as OptionType[]}
      placeholder="Select"
      {...(!id && !functionItems?.[0]?.id && { controlWidth: '150px' })}
      value={functionOperator ? functionItems?.[0]?.id : id}
      onChange={field => onIdsDropdownChange(field as OptionType)}
      style={dropdownStyle}
    />
  );

  const renderTypeField = (): ReactElement => (
    <Dropdown
      options={getTypesDropdownData(kind || functionItems[0]?.kind || '') as OptionType[]}
      value={functionOperator ? functionItems[0].type : type}
      placeholder="Select"
      {...(!type && !functionItems?.[0]?.type && { controlWidth: '150px' })}
      isSearchable
      onChange={field => onTypesDropdownChange(field as OptionType)}
      style={dropdownStyle}
    />
  );

  const renderOperatorField = (): ReactElement => {
    return (
      <OperatorBox
        options={getOperators(type || functionItems[0]?.type, kind || functionItems[0]?.kind)}
        value={showOperator ? operator || functionOperator : ''}
        onChange={onOperatorChange}
      />
    );
  };

  const renderStaticDynamicField = (): ReactElement => {
    return (
      <Dropdown
        options={staticDynamicFieldOptions}
        value={isDynamic ? 'dynamic' : 'static'}
        onChange={field => {
          setConditionData(
            {
              ...initialItem,
              ...condition,
              kind,
              id,
              type,
              operator,
              isDynamic: (field as OptionType).value === 'dynamic',
            },
            position,
          );
        }}
        placeholder="Select"
        style={dropdownStyle}
      />
    );
  };

  const renderValueField = (): ReactElement => {
    const valueTags = value.toString()?.split(',') || [];

    const onValueUpdate = (inputValue: string | boolean | number): void => {
      setConditionData(
        {
          ...initialItem,
          ...condition,
          kind,
          id,
          type,
          operator,
          isDynamic,
          value: inputValue,
        },
        position,
      );
    };

    if (valueFormat === FormatType.DATE) {
      return (
        <Box w="150px" className="condition-date-picker">
          <DatePicker
            selected={value ? new Date(+value * 1000) : null} // converts seconds to milliseconds
            onChange={(date: Date) => {
              onValueUpdate(date.getTime() / 1000); // converts milliseconds to seconds
            }}
            customInput={<DateInput />}
            dateFormat="dd/MM/yy"
            showYearDropdown
          />
        </Box>
      );
    }

    const onTagInputChange = (inputValue: string[]): void => {
      const filteredValue = inputValue.filter(item => (item[0] === ',' && item.length > 1) || item[0] !== ',');
      const valueList = filteredValue.map(item => {
        if (item[0] === ',') {
          return item.substring(1);
        }
        return item;
      });
      setConditionData(
        {
          ...initialItem,
          ...condition,
          kind,
          id,
          type,
          operator,
          isDynamic,
          value: valueList.toString(),
        },
        position,
      );
    };

    return (
      <>
        {valueType === ConstantType.STRING && !allowedValues.length && (
          <TagInput
            tagList={valueTags?.length > 0 && valueTags[0] !== '' ? valueTags : []}
            setTagList={(inputValue: string[]) => onTagInputChange(inputValue)}
            inputWidth="150px"
            placeholder="eg: OK"
          />
        )}
        {valueType === ConstantType.BOOLEAN && !allowedValues.length && (
          <Dropdown
            options={
              [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ] as OptionType[]
            }
            placeholder="Select"
            value={value.toString()}
            {...(!value && { controlWidth: '150px' })}
            onChange={field => onValueUpdate(Boolean((field as OptionType).value))}
            style={dropdownStyle}
          />
        )}
        {valueType === ConstantType.NUMBER && (
          <input
            type="number"
            className="input-box"
            placeholder="eg: 50.00"
            onChange={inputValue => {
              onValueUpdate(+inputValue.target.value);
            }}
            value={value as number}
          />
        )}
        {valueType === ConstantType.INTERFACE && (
          <input
            className="input-box"
            placeholder="eg: 50.00"
            onChange={inputValue => {
              onValueUpdate(inputValue.target.value);
            }}
            value={value as string}
          />
        )}
        {allowedValues.length > 0 && (
          <Dropdown
            options={allowedValues}
            placeholder="Select"
            value={value.toString()}
            {...(!value && { controlWidth: '150px' })}
            onChange={field => onValueUpdate((field as OptionType).value)}
            style={dropdownStyle}
          />
        )}
      </>
    );
  };

  const renderDynamicKindField = (): ReactElement => (
    <Dropdown
      options={conditionTypes}
      value={dynamicItem?.kind}
      {...(!dynamicItem?.kind && { controlWidth: '150px' })}
      placeholder="Select"
      onChange={field => {
        setConditionData(
          {
            ...initialItem,
            ...condition,
            kind,
            id,
            type,
            operator,
            isDynamic,
            dynamicItem: {
              kind: (field as OptionType).value,
              type: '',
              id: '',
            },
          },
          position,
        );
      }}
      style={dropdownStyle}
    />
  );

  const renderDynamicIdField = (): ReactElement => (
    <Dropdown
      options={getIdsDropdownData(dynamicItem?.kind as string) as OptionType[]}
      placeholder="Select"
      {...(!dynamicItem?.id && { controlWidth: '150px' })}
      value={dynamicItem?.id}
      onChange={field => {
        setConditionData(
          {
            ...initialItem,
            ...condition,
            kind,
            id,
            type,
            operator,
            isDynamic,
            dynamicItem: {
              kind: dynamicItem?.kind,
              type: '',
              id: (field as OptionType).value,
            },
          },
          position,
        );
      }}
      style={dropdownStyle}
    />
  );

  const renderNestedOperatorBox = (): ReactElement => {
    return (
      <Box>
        <Input
          placeholder="Enter raw input"
          onChange={e => {
            e.preventDefault();
            setConditionData(
              {
                ...condition,
                rawInput: e.target.value,
              },
              position,
            );
          }}
        />
      </Box>
    );
  };

  const renderDynamicTypeField = (): ReactElement => (
    <Dropdown
      options={getDynamicTypesDropdownData(dynamicItem?.kind) as OptionType[]}
      value={dynamicItem?.type}
      placeholder="Select"
      {...(!dynamicItem?.type && { controlWidth: '150px' })}
      isSearchable
      onChange={field => {
        setConditionData(
          {
            ...initialItem,
            ...condition,
            kind,
            id,
            type,
            operator,
            isDynamic,
            dynamicItem: {
              kind: dynamicItem?.kind,
              type: (field as OptionType).value,
              id: dynamicItem?.id,
            },
          },
          position,
        );
      }}
      style={dropdownStyle}
    />
  );

  const renderBlocklistField = (): ReactElement => (
    <Dropdown
      options={getBlocklist()}
      value={functionItems?.[1]?.value.toString()}
      placeholder="Select"
      isSearchable
      controlWidth="340px"
      onChange={field => updateBlocklistCondition((field as OptionType).value)}
      style={dropdownStyle}
      components={{ Option, SingleValue }}
    />
  );

  const updateBlocklistCondition = (val: string): void => {
    const tempFunctionItems = functionItems;
    tempFunctionItems[1].value = val;
    setConditionData(
      {
        ...initialItem,
        ...condition,
        functionItems: tempFunctionItems,
        functionOperator,
        isFunctionOperator: true,
      },
      position,
    );
  };

  const updateFunctionCondition = (data: FunctionItem, index: number): void => {
    const tempFunctionItems = functionItems;
    tempFunctionItems[index] = data;
    setConditionData(
      {
        ...initialItem,
        ...condition,
        functionItems: tempFunctionItems,
        functionOperator,
        isFunctionOperator: true,
      },
      position,
    );
  };

  const insertNewFunctionCondition = (): void => {
    const tempFunctionItems = functionItems;
    tempFunctionItems.push(functionItem);
    setConditionData(
      {
        ...initialItem,
        ...condition,
        functionItems: tempFunctionItems,
        functionOperator,
        isFunctionOperator: true,
      },
      position,
    );
  };

  const onItemDelete = (pos: number): void => {
    const tempFunctionItems = functionItems;
    tempFunctionItems.splice(pos, 1);
    setConditionData(
      {
        ...initialItem,
        ...condition,
        functionItems: tempFunctionItems,
        functionOperator,
        isFunctionOperator: true,
      },
      position,
    );
  };
  const isRenderIdField = (kind || functionItems[0]?.kind) && kind !== 'input' && functionItems[0]?.kind !== 'input';
  const isRenderTypeField =
    (id || functionItems[0]?.id || kind === 'input' || functionItems[0]?.kind === 'input') && kind !== 'tag';
  const isRenderOperatorField = type || functionItems[0]?.type || (kind === 'tag' && (id || functionItems[0]?.id));
  const isRenderStaticDynamicField =
    operator &&
    (kind || functionItems[0]?.kind) &&
    (type || functionItems[0]?.type || (kind === 'tag' && (id || functionItems[0]?.id))) &&
    !functionOperator;
  const isRenderValueField = operator && !isDynamic && !functionOperator;
  const isRenderDynamicKind = operator && isDynamic;
  const isNestedField = valueFormat === FormatType.JSON || valueFormat === FormatType.LIST;
  const isRenderDynamicId = dynamicItem.kind && dynamicItem.kind !== 'input';
  const isRenderDynamicType =
    (dynamicItem.id || (dynamicItem.kind === 'input' && dynamicItem.kind)) && isDynamic && dynamicItem.kind !== 'tag';

  if (conditionsArrayCount > 1 && !kind && !functionItems?.[0]?.kind && position === 0) {
    return <></>;
  }
  return (
    <>
      <Box w="full">
        <Flex alignItems="center" pt={4} w="full">
          <Text
            mr={5}
            px={3}
            py={1}
            bg={group === AndOrCondition.OR ? 'terracotta.100' : 'purple.50'}
            textColor={group === AndOrCondition.OR ? 'terracotta.500' : 'purple.100'}
            textAlign="center"
            borderRadius="lg"
            fontSize="xs"
            textTransform="capitalize"
          >
            {prefixWord}
          </Text>
          <HStack spacing={4}>
            {renderKindField()}
            {isRenderIdField && renderIdField()}
            {isRenderTypeField && renderTypeField()}
            {isNestedField && renderNestedOperatorBox()}
            {isRenderOperatorField && renderOperatorField()}
            {isRenderStaticDynamicField && renderStaticDynamicField()}
            {isRenderValueField && renderValueField()}
            {isRenderDynamicKind && renderDynamicKindField()}
            {isRenderDynamicId && renderDynamicIdField()}
            {isRenderDynamicType && renderDynamicTypeField()}
            {functionOperator === FunctionalOperator.IS_BLOCKED && renderBlocklistField()}
            {isClearable && (
              <Image
                mt={2}
                mr={6}
                w={5}
                h={5}
                src="/assets/icons/delete.svg"
                cursor="pointer"
                onClick={() => {
                  onMainItemDelete(position);
                }}
              />
            )}
          </HStack>
        </Flex>
        {isFunctionOperator && functionOperator !== FunctionalOperator.IS_BLOCKED && (
          <CustomFunctionBlock
            functionItems={functionItems}
            functionOperator={functionOperator}
            onItemDelete={onItemDelete}
            insertNewFunctionCondition={insertNewFunctionCondition}
            updateFunctionCondition={updateFunctionCondition}
            getOperatorData={getOperatorData}
          />
        )}
      </Box>
    </>
  );
};

export default ConditionField;
