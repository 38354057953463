import React, { useEffect, useState, ReactElement } from 'react';
import { Flex, Text, Box, Image } from '@chakra-ui/react';
import { Plus } from 'phosphor-react';
import { cloneDeep } from 'lodash-es';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal';
import { ConditionItem, ConditionGroupItem } from '../../types';
import { initialItem, newConditionItems } from '../../constants';
import ConditionField from './components/ConditionField';
import { AndOrCondition } from './types';

const WorkflowProperties = ({
  group,
  index,
  innerGroupColorIndex,
  onMainItemDelete,
  columnPosition,
  formattedCondition,
  setFormattedCondition,
}: {
  group: AndOrCondition;
  onMainItemDelete?: (arrayIndex: number) => void;
  innerGroupColorIndex?: boolean;
  index: number;
  columnPosition: number;
  formattedCondition?: ConditionItem;
  setFormattedCondition: (data: ConditionItem, position: number) => void;
}): ReactElement => {
  const [orGroup, switchOrGroup] = useState(group !== AndOrCondition.AND);
  const [conditionDropdown, showConditionDropdown] = useState(false);
  const [deleteGroupPopup, setDeleteGroupPopup] = useState(false);
  const innerGroupColorFlag = innerGroupColorIndex;

  const onItemInsert = (data: ConditionGroupItem): void => {
    const newTemp = cloneDeep(formattedCondition);
    if (newTemp) {
      newTemp.items.push(data);
      setFormattedCondition(newTemp, index);
    }
  };

  const onItemUpdate = (data: ConditionItem | ConditionGroupItem, arrayIndex: number): void => {
    const newTemp = cloneDeep(formattedCondition);
    if (newTemp && (data.type === AndOrCondition.AND || data.type === AndOrCondition.OR)) {
      newTemp.items[arrayIndex].group = data as ConditionItem;
    } else if (newTemp) {
      newTemp.items[arrayIndex] = data as ConditionGroupItem;
    }
    if (newTemp) setFormattedCondition(newTemp, index);
  };

  const onItemDelete = (arrayIndex: number): void => {
    const newTemp = cloneDeep(formattedCondition);
    newTemp?.items.splice(arrayIndex, 1);
    if (newTemp) setFormattedCondition(newTemp, index);
  };

  const onGroupSwitch = (): void => {
    const newTemp = cloneDeep(formattedCondition);
    if (newTemp) {
      newTemp.type = newTemp?.type === AndOrCondition.AND ? AndOrCondition.OR : AndOrCondition.AND;
      setFormattedCondition(newTemp, index);
    }
  };

  const renderProperties = (
    item: ConditionGroupItem,
    arrayIndex: number,
    conditionsArray: ConditionGroupItem[],
  ): ReactElement => {
    if (item.group.type !== AndOrCondition.OR && item.group.type !== AndOrCondition.AND) {
      const prefixWord = conditionsArray.length === 1 || arrayIndex === 1 ? 'If' : formattedCondition?.type;
      return (
        <Flex key={arrayIndex}>
          <ConditionField
            condition={item}
            setConditionData={(data: ConditionGroupItem, arrayPosition: number) => onItemUpdate(data, arrayPosition)}
            prefixWord={prefixWord}
            conditionsArrayCount={conditionsArray.length}
            group={formattedCondition?.type || AndOrCondition.AND}
            position={arrayIndex}
            isClearable={conditionsArray.length > 2}
            onMainItemDelete={(itemIndex: number) => onItemDelete(itemIndex)}
          />
        </Flex>
      );
    }
    return (
      <Flex mt={4} borderWidth={2} borderRadius="lg" borderColor="blue.100" key={arrayIndex}>
        <WorkflowProperties
          formattedCondition={item.group}
          setFormattedCondition={(data: ConditionItem, arrayPosition) => onItemUpdate(data, arrayPosition)}
          group={item.group.type}
          innerGroupColorIndex={!innerGroupColorFlag}
          index={arrayIndex}
          columnPosition={columnPosition}
          onMainItemDelete={(itemIndex: number) => onItemDelete(itemIndex)}
        />
      </Flex>
    );
  };

  useEffect(() => {
    switchOrGroup(group !== AndOrCondition.AND);
  }, [group]);

  return (
    <>
      <Flex width="full" pt={4} direction="column" bg={!innerGroupColorFlag ? 'blue.50' : 'white'}>
        <Flex borderBottomWidth={2} borderColor="blue.100" direction="column">
          <Flex justifyContent="space-between" pb={2}>
            <Flex>
              {formattedCondition?.type === AndOrCondition.AND ? (
                <Box pl={6} justifyContent="center" onClick={onGroupSwitch} cursor="pointer">
                  <Text color="purple.100" fontSize="sm" fontWeight={500} alignSelf="center" textAlign="center">
                    AND Group
                  </Text>
                </Box>
              ) : (
                <Box pl={6} justifyContent="center" onClick={onGroupSwitch} cursor="pointer">
                  <Text color="red.400" fontSize="sm" fontWeight={500} alignSelf="center" textAlign="center">
                    OR Group
                  </Text>
                </Box>
              )}
            </Flex>
            <Image
              mt={2}
              mr={6}
              w={5}
              h={5}
              src="/assets/icons/delete.svg"
              cursor="pointer "
              onClick={() => {
                setDeleteGroupPopup(true);
              }}
            />
          </Flex>
          <Box
            ml={6}
            width={10}
            borderWidth={3}
            bg={orGroup ? 'red.400' : 'purple.100'}
            borderColor={orGroup ? 'red.400' : 'purple.100'}
            borderRadius="lg"
          />
        </Flex>
        <Box mt={1} py={3} pr={1} pl={6} width="full">
          {formattedCondition?.items.map((item: ConditionGroupItem, arrayIndex: number, arr) =>
            renderProperties(item, arrayIndex, arr),
          )}
          <Flex paddingTop={7} alignItems="center" color={conditionDropdown ? 'blue.400' : 'blue.500'}>
            <Plus size={20} weight="fill" />
            <Text
              pl="10px"
              fontSize="md"
              fontWeight="normal"
              cursor="pointer"
              onClick={() => showConditionDropdown(!conditionDropdown)}
            >
              Add Condition
            </Text>
          </Flex>
          {conditionDropdown && (
            <Box p={2} boxShadow="lg" borderRadius="lg" bg="white" maxW="130px">
              <Flex direction="column">
                <Text textColor="gray.400" fontSize="xs" fontWeight="light" pt={2} px={2}>
                  Select Condition
                </Text>
                <Box
                  px={2}
                  mt="10px"
                  py="6px"
                  borderRadius="lg"
                  bg="white"
                  _hover={{ bg: 'white.50' }}
                  cursor="pointer"
                  onClick={() => {
                    onItemInsert(initialItem);
                    showConditionDropdown(false);
                  }}
                >
                  <Box
                    fontWeight={300}
                    width="fit-content"
                    height="fit-content"
                    borderRadius="lg"
                    py={1}
                    fontSize="xs"
                    px={3}
                    bg="blue.100"
                    color="gray.600"
                  >
                    {orGroup ? 'OR' : 'AND'}
                  </Box>
                </Box>
                <Box
                  px={2}
                  py="6px"
                  borderRadius="lg"
                  bg="white"
                  _hover={{ bg: 'white.50' }}
                  cursor="pointer"
                  onClick={() => {
                    onItemInsert(
                      orGroup
                        ? { ...initialItem, group: { type: 'or', items: newConditionItems } }
                        : { ...initialItem, group: { type: 'and', items: newConditionItems } },
                    );
                    showConditionDropdown(false);
                  }}
                >
                  <Box
                    fontWeight={300}
                    width="fit-content"
                    height="fit-content"
                    borderRadius="lg"
                    py={1}
                    px={3}
                    fontSize="xs"
                    bg={orGroup ? 'terracotta.100' : 'purple.50'}
                    color={orGroup ? 'terracotta.500' : 'purple.100'}
                  >
                    {orGroup ? 'OR Group' : 'AND Group'}
                  </Box>
                </Box>
                <Box
                  px={2}
                  py="6px"
                  borderRadius="lg"
                  bg="white"
                  _hover={{ bg: 'white.50' }}
                  cursor="pointer"
                  onClick={() => {
                    onItemInsert(
                      orGroup
                        ? { ...initialItem, group: { type: 'and', items: newConditionItems } }
                        : { ...initialItem, group: { type: 'or', items: newConditionItems } },
                    );
                    showConditionDropdown(false);
                  }}
                >
                  <Box
                    fontWeight={300}
                    width="fit-content"
                    height="fit-content"
                    borderRadius="lg"
                    py={1}
                    fontSize="xs"
                    px={3}
                    bg={!orGroup ? 'terracotta.100' : 'purple.50'}
                    color={!orGroup ? 'terracotta.500' : 'purple.100'}
                  >
                    {!orGroup ? 'OR Group' : 'AND Group'}
                  </Box>
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>
      {deleteGroupPopup && onMainItemDelete && (
        <ConfirmationModal
          headerText={`Delete ${orGroup ? 'Or' : 'And'} Group`}
          bodyText={`Do you really want to delete ${orGroup ? 'Or' : 'And'} Group?`}
          onClose={() => onMainItemDelete(index)}
          onCancel={() => setDeleteGroupPopup(false)}
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
          primaryButtonBg="red"
        />
      )}
    </>
  );
};

export default WorkflowProperties;
