import React from 'react';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Card } from '@bureau/components';

export const Login = (): React.ReactElement => {
  const { loginWithRedirect } = useAuth0();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleLogin = async (): Promise<void> => {
    setIsSubmitting(true);
    await loginWithRedirect({
      // screen_hint: 'noSignup',
    });
  };

  return (
    <Flex width="full" justifyContent="center">
      <Card width="360px" marginTop="200px">
        <Stack direction="column" spacing={8} flexGrow={1} alignItems="center">
          <Stack direction="column" spacing={4} alignSelf="stretch">
            <Button isLoading={isSubmitting} onClick={handleLogin} colorScheme="blue">
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Flex>
  );
};
