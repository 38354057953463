export interface JsonDataType {
  if: AndOrData;
}

export enum AndOrCondition {
  AND = 'and',
  OR = 'or',
}

export enum FunctionalOperator {
  IS_BLOCKED = 'IsBlocked',
}

export interface AndOrData {
  and?: (ConditionData | AndOrData)[];
  or?: (ConditionData | AndOrData)[];
}

export interface ConditionObject {
  kind: string;
  id: string;
  type: string;
  operator: string;
  value: string | boolean | number;
  functionOperator: string;
  dynamicKind?: string;
  dynamicType?: string;
  dynamicId?: string;
}

export interface ConditionData {
  [key: string]: InnerCondition[];
}

export interface InnerCondition {
  obj?: string;
  const?: string | boolean | number;
  call?: (InnerCondition | string)[];
}

export interface NestedCondition {
  [key: string]: NestedCondition[] | boolean | string;
}

export interface NestedConditionData {
  [key: string]: ConditionData[];
}

export interface WhenCondition {
  [key: string]: NestedCondition;
}

export enum ConstantType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  INTERFACE = 'INTERFACE',
}

export enum FormatType {
  DATE = 'DATE',
  JSON = 'JSON',
  LIST = 'LIST',
}
