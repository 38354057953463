export const platformFAQs = [
  {
    id: 1,
    question: 'Can I preview how a workflow will look and work on my application?',
    answer: `Absolutely! We offer two features to help you preview your workflow's look and functionality within your application:

Preview: This feature lets you tailor the workflow's user interface (UI) to match your brand guidelines. Choose between quick and advanced customization options to achieve the desired look and feel.

Tryout: This functionality allows you to simulate how the workflow would operate on your application's frontend. You can test different scenarios with the Tryout feature to ensure your workflow functions as intended. 

Simply click "Tryout" and enter the required information. Our system will then process the information, make necessary API calls, evaluate your workflow's conditions, and provide a clear decision (accept, reject, manual review) based on your workflow logic.
`,
  },
  {
    id: 2,
    question: 'How do I test my workflow?',
    answer: `There are two ways you can test your workflows:\n

Tryout feature: This feature lets you simulate how the workflow would function on your application frontend. You can also use Tryout to test different scenarios and ensure your workflow functions as intended. Simply click "Tryout" and enter the required information. Our system processes the information, makes the necessary API calls, checks the conditions you have set up, and provides a clear decision based on your workflow logic (accept, reject, manual review).

Postman (for advanced testing):  For more advanced testing needs, you can leverage Postman to run multiple test cases efficiently. This allows you to automate testing processes or create complex test scenarios. To use Postman, click "Integrate" to access the workflow API. Then, copy the provided cURL command and import it into Postman for testing.`,
  },
  {
    id: 3,
    question: 'Can I test workflows using Postman?',
    answer: `Yes, and it's efficient! You can use Postman to run multiple test cases efficiently or automate testing entirely with Postman. To get started, click "Integrate" within your workflow and copy the provided cURL command. Simply import this cURL into Postman to begin testing.`,
  },
  {
    id: 4,
    question: 'How can I identify and handle errors in my workflow?',
    answer: `When you test your workflow, either using the Tryout feature or through Postman, the system provides you with a response code and detailed error information. This information helps you identify and address any issues that might arise during the workflow execution.

Here's how to access error details:
Error Parameter: Look for an "error" parameter at the root level of the response. This parameter will contain information about any errors encountered during the entire workflow execution.
API Error Objects: The response may also include error objects for each API call made within the workflow. These objects provide specific details about any errors that occurred during those individual API calls.

By examining these error details, you can diagnose the root cause of the issue and make necessary adjustments to your workflow logic.`,
  },
];
