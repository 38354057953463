import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { Config } from '../types';

export const useFetchConfig = (): UseQueryResult<Config, AxiosError> => {
  return useQuery('fetch-hostedConfig', fetchConfig(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const fetchConfig = () => {
  return async () => {
    const res = await bureauPlatformApi.get<Config>(`/workflows/hostedconfig`);
    return res.data;
  };
};

export default useFetchConfig;
