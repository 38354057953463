import React from 'react';
import { Icon, Text } from '@chakra-ui/react';
import { IconProps } from 'phosphor-react';
import { Flex } from '@chakra-ui/layout';
import { colors } from '@/utils/colors';

type PhosphorIconType = {
  icon: React.FC<IconProps & React.RefAttributes<SVGSVGElement>>;
  color?: string;
  size: number;
  fontWeight?: IconProps['weight'];
  onClick?: () => void;
  cursor?: string;
  children?: React.ReactNode;
  text?: string;
  textColor?: string;
};
export const PhosphorIcon = ({
  icon,
  color,
  size,
  fontWeight,
  text,
  children,
  textColor = colors.gray[800],
  ...rest
}: PhosphorIconType): JSX.Element => {
  if (!text && !children) {
    return <Icon as={icon} fontSize={size} weight={fontWeight} color={color} {...rest} />;
  }
  if (text) {
    return (
      <Flex alignItems="center">
        <Icon as={icon} fontSize={size + 2} weight={fontWeight} color={color} {...rest} mr={1} />
        <Text fontSize={size} color={textColor} {...rest}>
          {text}
        </Text>
      </Flex>
    );
  }
  if (children) {
    return (
      <Icon as={icon} fontSize={size} weight={fontWeight} color={color} {...rest}>
        {children}
      </Icon>
    );
  }
  return (
    <Flex alignItems="center">
      <Icon as={icon} fontSize={size} weight={fontWeight} color={color} {...rest}>
        {children}
      </Icon>
      <Text fontSize={size} color={textColor} {...rest}>
        {text}
      </Text>
    </Flex>
  );
};
