import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Box, Accordion, AccordionButton, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';
import { CaretDown, CaretUp, Info } from 'phosphor-react';
import { colors } from '@/utils/colors';
import PlatformTooltip from '@/components/tooltip/PlatformTooltip';
import FactsLoader from '@/components/loader/Loader';
import { useAuthDetails } from '@/queries/useAuth';
import { getAuthDetails } from '@/helpers/getAuthDetails';
import { bannerHeight } from '@/constants/constants';
import Capability from './Capability';
import { USE_CASE_TOOLTIP_DESCRIPTION } from './constants';
import { useInfiniteCapabilityList } from '../capabilitiesV2/queries/useCapabilityList';
import { ListData } from '../capabilitiesV2/types';
import { CapabilityFilterItem } from '../user-onboarding/types';
import { CapabilityPropTypes } from './types';
import useFetchActiveSubscription from '../billing/queries/useFetchActiveSubscription';
import WorkflowErrorState from './components/WorkflowErrorState';

function Capabilities({
  isCapabilityListOpen,
  setIsCapabilityListOpen,
  useCase,
}: {
  isCapabilityListOpen: boolean;
  setIsCapabilityListOpen: Dispatch<SetStateAction<boolean>>;
  useCase: CapabilityFilterItem;
}): ReactElement {
  const { data: meDetails, isLoading: isMeDatailsLoading } = useAuthDetails();
  const authDetails = getAuthDetails();
  const {
    data: activeSubscription = {
      active: false,
      balanceLeft: 0,
      planId: 'free',
      endTimestamp: 0,
    },
  } = useFetchActiveSubscription();
  const filterFields = {
    useCase: useCase.value,
    industry: '',
    geography: meDetails?.geography || authDetails?.geography,
  };
  const {
    data,
    isError: isCapabilityListError,
    refetch: refetchCapabilityList,
    isFetching: isCapabilityListLoading,
  } = useInfiniteCapabilityList(!!useCase.value, filterFields);

  const { listData } = data ? (data as ListData) : { listData: [] };
  const filteredCapabilityList: CapabilityPropTypes[] = listData
    .filter(capability => capability.active)
    .filter(capability => capability.plansWithAccess.toString().indexOf(activeSubscription?.planId || 'free') > -1);
  return (
    <Accordion
      index={isCapabilityListOpen ? 0 : -1}
      allowToggle
      w="22.5rem"
      bg="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      boxShadow={`2px 6px 8px 0px ${colors.shadows.blue600}`}
      position="fixed"
      bottom="6"
      ml="6"
      zIndex="docked"
      onChange={expandedIndex => setIsCapabilityListOpen(expandedIndex === 0)}
    >
      <AccordionItem border="none">
        {({ isExpanded, isDisabled }) => (
          <>
            <Box as="h2">
              <AccordionButton
                p="4"
                borderRadius="lg"
                _expanded={{ borderBottomRadius: 'none', bg: 'white.50' }}
                _focus={{ boxShadow: 'none' }}
              >
                <Box as="span" flex="1" textAlign="left" color="gray.800" fontWeight="500" lineHeight="130%">
                  Select a Capability
                </Box>
                {isExpanded ? (
                  <CaretDown size={16} color={colors.gray[500]} />
                ) : (
                  <CaretUp size={16} color={colors.gray[500]} />
                )}
              </AccordionButton>
            </Box>

            <AccordionPanel
              p="4"
              display="flex"
              flexDirection="column"
              gridGap="4"
              h={`calc(100vh - ${bannerHeight + 188}px)`}
              overflowY="auto"
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              {isCapabilityListLoading || isMeDatailsLoading ? (
                <FactsLoader />
              ) : (
                <>
                  <Flex alignItems="center" gridGap="1">
                    <Text fontSize="xs" color="gray.600">
                      Digital Lifecycle
                    </Text>
                    <Text fontSize="xs" color="gray.800">
                      {useCase.displayText}
                    </Text>

                    <PlatformTooltip title={useCase.description ?? ''} description={USE_CASE_TOOLTIP_DESCRIPTION}>
                      <Info size={16} color={colors.gray[600]} weight="thin" />
                    </PlatformTooltip>
                  </Flex>
                  <Accordion allowToggle allowMultiple display="flex" flexDirection="column" gridGap="2">
                    {isCapabilityListError && !filteredCapabilityList.length ? (
                      <Flex align="center" direction="column" justify="center" h="calc(100vh - 254px)">
                        <WorkflowErrorState
                          errorText="It seems there is a technical issue that is preventing data from loading."
                          onRetry={() => {
                            refetchCapabilityList();
                          }}
                          styles={{ w: '64' }}
                          isRetryEnabled
                        />
                      </Flex>
                    ) : (
                      filteredCapabilityList.map(capability => (
                        <Capability key={capability.id} capability={capability} />
                      ))
                    )}
                  </Accordion>
                </>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export default Capabilities;
