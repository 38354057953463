import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Flex, Box, Text, HStack } from '@chakra-ui/react';
import { CaretUp, CaretDown, Calendar } from 'phosphor-react';
import { format, subDays } from 'date-fns';
import { OptionType } from '@bureau/components';
import { dateFilterAllOptions, customDateOption, DATE_FORMAT_WITHOUT_TIME } from '@/constants/constants';
import DateRangePicker from '../date-range-picker/DateRangePicker';
import { CustomDateType } from '../date-range-picker/types';

const currentDate = new Date().toISOString();
const allDateRange = subDays(new Date(), 1095).toISOString();
const oneDayRange = subDays(new Date(), 1).toISOString();

const SelectBoxOption = ({
  item,
  onClick,
  isSelected,
}: {
  item: OptionType;
  onClick: () => void;
  isSelected: boolean;
}): ReactElement => {
  return (
    <Flex
      key={item.value}
      bg="white"
      p={2}
      alignItems="center"
      cursor="pointer"
      borderRadius="lg"
      _hover={{ bg: 'white.50' }}
      onClick={onClick}
    >
      <Flex>
        <Flex
          w={5}
          h={5}
          mr={2}
          borderRadius="full"
          borderWidth={1}
          justifyContent="center"
          alignItems="center"
          borderColor={isSelected ? 'blue.500' : 'gray.300'}
        >
          {isSelected && <Box w={2.5} borderRadius="full" h={2.5} bg="blue.500" />}
        </Flex>
        <Text fontSize="xs" fontWeight="light">
          {item.label === customDateOption.label ? 'Custom Date' : item.label}
        </Text>
      </Flex>
    </Flex>
  );
};

const DateSelectBox = ({
  menuHeading,
  selectedDates,
  onChange,
  inputType,
  children,
  customFilterOptions = dateFilterAllOptions,
}: {
  menuHeading: string;
  selectedDates: string[];
  onChange: (startDate: string, endDate: string) => void;
  inputType?: boolean;
  children?: ReactElement;
  customFilterOptions?: OptionType[];
}): ReactElement => {
  const [startDate, setStartDate] = useState(oneDayRange);
  const [endDate, setEndDate] = useState(currentDate);
  const [selectedValue, setSelectedValue] = useState(
    customFilterOptions ? customFilterOptions[1].label : dateFilterAllOptions[1].label,
  );
  const [openMenu, setOpenMenu] = useState(false);
  const dateSelectBoxRef = useRef<HTMLDivElement | null>(null);

  const handleOnClick = (value: string): void => {
    if (value === CustomDateType.CUSTOM_DATE) {
      setStartDate(currentDate);
      setEndDate(currentDate);
    } else if (value === 'all') {
      setStartDate(allDateRange);
      setEndDate(currentDate);
    } else {
      setStartDate(value);
      setEndDate(currentDate);
    }
  };

  useEffect(() => {
    if (selectedDates) {
      if (!selectedDates.length) {
        setStartDate(allDateRange);
        setEndDate(currentDate);
      }
      if (selectedDates.length > 0 && selectedDates[0] !== '') setStartDate(selectedDates[0]);
      if (selectedDates.length > 1 && selectedDates[1] !== '') setEndDate(selectedDates[1]);
    }
  }, [selectedDates]);

  const getDateRangeText = (): string => {
    const formattedStartDate = format(new Date(startDate), DATE_FORMAT_WITHOUT_TIME);
    const formattedEndDate = format(new Date(endDate), DATE_FORMAT_WITHOUT_TIME);
    return `${formattedStartDate} to ${formattedEndDate}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (dateSelectBoxRef.current && !dateSelectBoxRef.current.contains(event.target as Node)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Flex
      direction={inputType ? 'row' : 'column'}
      m={inputType ? '' : 2}
      w={inputType ? '100%' : 'auto'}
      position="relative"
    >
      {inputType ? (
        <Flex
          w="full"
          fontSize="xs"
          color="gray.900"
          borderRadius="lg"
          bg="white"
          borderWidth={1}
          borderColor="gray.300"
          _hover={{ borderColor: 'black' }}
          cursor="pointer"
          py={2.5}
          px={4}
          alignItems="center"
          justifyContent="space-between"
          onClick={() => setOpenMenu(!openMenu)}
        >
          {selectedValue === CustomDateType.CUSTOM_DATE ? getDateRangeText() : selectedValue}
          <Calendar />
        </Flex>
      ) : (
        <Box onClick={() => setOpenMenu(!openMenu)} cursor="pointer">
          <Flex alignItems="center" justifyContent="space-between">
            {children}
            <HStack>
              <Text fontSize="xs" color="gray.900">
                {selectedValue === CustomDateType.CUSTOM_DATE ? 'Custom Date' : selectedValue}
              </Text>
              {openMenu ? <CaretUp size={16} /> : <CaretDown size={16} />}
            </HStack>
          </Flex>
        </Box>
      )}
      {openMenu && (
        <Flex
          position="absolute"
          top={inputType ? -150 : 12}
          right={inputType ? -500 : 0}
          bg="white"
          zIndex={2}
          maxW={600}
          minH={426}
          maxH={500}
          overflow="hidden"
          borderRadius="lg"
          boxShadow="1px 1px 8px rgb(31, 51, 203, 0.09)"
          ref={dateSelectBoxRef}
        >
          <Box bg="white" mt={2} p={2} minW={150} w={150} borderRightWidth={1} zIndex={1}>
            {menuHeading && (
              <Text fontWeight="light" fontSize="xs" p={2} color="gray.600">
                {menuHeading}
              </Text>
            )}
            <Box>
              <Flex direction="column">
                {[...customFilterOptions, customDateOption].map(item => (
                  <SelectBoxOption
                    key={item.value}
                    item={item}
                    onClick={() => {
                      setSelectedValue(item.label);
                      handleOnClick(item.value);
                    }}
                    isSelected={item.label === selectedValue}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
          <Box w={340} minH="460px">
            <DateRangePicker
              selectedDates={[startDate, endDate]}
              onChange={(customStartDate, customEndDate) => {
                if (selectedValue === CustomDateType.CUSTOM_DATE) onChange(customStartDate, customEndDate);
                else if (selectedValue === 'All') {
                  onChange('', '');
                } else onChange(startDate, endDate);
                setOpenMenu(false);
              }}
              onCancel={() => {
                setOpenMenu(false);
                if (!customFilterOptions) {
                  handleOnClick(dateFilterAllOptions[1].value);
                } else {
                  handleOnClick(customFilterOptions[1].value);
                }
              }}
              disable={selectedValue !== CustomDateType.CUSTOM_DATE}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default DateSelectBox;
