import { atom } from 'recoil';
import { TransactionDetail } from '@/screens/transactions/transaction-details/types';

interface TryoutStateTypes {
  tryoutResponse: TransactionDetail;
  showTryOutResult: boolean;
}

export const TryoutStateAtom = atom({
  key: 'tryout-response',
  default: { tryoutResponse: {}, showTryOutResult: false } as TryoutStateTypes,
});
