import React, { ReactElement } from 'react';
import { Flex, Image, Text, Button, FlexProps } from '@chakra-ui/react';
import { ArrowClockwise } from 'phosphor-react';
import { colors } from '@/utils/colors';

function WorkflowErrorState({
  errorText,
  isRetryEnabled,
  onRetry,
  styles,
}: {
  errorText: string;
  isRetryEnabled?: boolean;
  onRetry?: () => void;
  styles: FlexProps;
}): ReactElement {
  return (
    <Flex direction="column" alignItems="center" gridGap="4" justifyContent="center" {...styles}>
      <Image src="/assets/workflow_error_state.svg" w="14" />
      <Flex direction="column" gridGap="2" alignItems="center">
        <Text fontSize="sm">Something went wrong!</Text>
        <Text fontSize="xs" fontWeight="light" textAlign="center" lineHeight="normal" color="gray.600">
          {errorText}
        </Text>
        {isRetryEnabled && (
          <Button
            onClick={onRetry}
            colorScheme="none"
            color="blue.700"
            fontSize="xs"
            fontWeight="400"
            leftIcon={<ArrowClockwise size={16} color={colors.blue[700]} />}
          >
            Retry
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default WorkflowErrorState;
