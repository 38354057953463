import React, { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

const MainContent = ({ children }: { children: ReactNode }): React.ReactElement => {
  return (
    <Flex w="full" overflow="auto">
      {children}
    </Flex>
  );
};

export default MainContent;
