import React, { ReactElement } from 'react';
import { Flex, Heading, Image, Button } from '@chakra-ui/react';
import { Link as LinkIcon } from 'phosphor-react';
import { formatDate } from '@/utils/utils';
import { bannerHeight } from '@/constants/constants';
import useGetSubscription from './queries/useGetSubscriptio';
import useGetUsageMetrics from './queries/useGetUsageMetrics';

export const TrialBanner = (): ReactElement => {
  const { data } = useGetSubscription();
  const { data: usageData, isLoading } = useGetUsageMetrics();

  //! stripe check removed because we are not redirecting to billing page and billing is not being used s
  // const checkStripeKey = (authenticationDetails: AuthDetails): boolean => {
  //   return !(authenticationDetails?.customerId && authenticationDetails?.customerId.stripe);
  // };

  // if (checkStripeKey(meDetails || authDetails)) {
  //   return <></>;
  // }

  function getHeadingText(): string {
    if (window.location.href.includes('stg')) {
      const allocated = usageData?.lifeTimeAllocated || 0;
      const usage = usageData?.lifeTimeUsage || 0;
      if (usageData?.usageExceed) {
        return `You have already used ${allocated}  of your allocated Testing Credits.`;
      }
      return `You have ${allocated - usage} Testing Credits remaining from ${
        usageData?.lifeTimeAllocated
      } Testing Credits allocated to you.`;
    }
    return `You are currently in a trial till ${formatDate(data?.endTimestamp)} and ${
      data?.balanceLeft
    } calls remaining`;
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <Flex align="center" justify="center" h={`${bannerHeight}px`} w="full" bg="#262951">
      <Image src="/assets/Star.svg" w={10} h={7} />
      <Heading color="white" textAlign="center" fontSize="sm" fontWeight="normal">
        {getHeadingText()}
      </Heading>
      <Flex>
        <Button
          as="a"
          target="_blank"
          variant="link"
          href="https://www.bureau.id/contact-us"
          color="purple.200"
          ml="2"
          fontSize="sm"
          fontWeight="medium"
          rightIcon={<LinkIcon size={18} />}
          _focus={{ boxShadow: 'none' }}
          mr="1"
        >
          Contact Us
        </Button>
        <Heading color="white" textAlign="center" fontSize="sm" fontWeight="normal">
          additional credits
        </Heading>
      </Flex>
    </Flex>
  );
};
