import React, { ReactElement, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CaretDown, CaretUp, Plus } from 'phosphor-react';
import { OptionType, SelectInput } from '@bureau/components';
import CopyButton from '@/components/copy-button/CopyButton';
import { colors } from '@/utils/colors';

import { Parameter } from './types';
import useGetInputAttributes from '../../workflow-creation/queries/useGetInputAttributes';
import { getCurl } from './helper';
import './styles.css';

const TryOutModal = ({
  onCancel,
  onClose,
  tryOutDetails,
  workflowId,
  setTryOutDetails,
}: {
  onCancel: () => void;
  onClose: () => void;
  tryOutDetails: Parameter[];
  workflowId?: string;
  setTryOutDetails: Dispatch<SetStateAction<Parameter[]>>;
}): ReactElement => {
  const [selectedParameters, setSelectedParameters] = useState<string[]>([]);
  const { data: parameters } = useGetInputAttributes();

  const customStyles = {
    control: () => ({
      borderRadius: '8px',
    }),
    option: (styles: object, { isDisabled }: { isDisabled: boolean }) => {
      return {
        ...styles,
        ...(isDisabled && { backgroundColor: '#FAFBFC', color: '#727C8D', cursor: 'not-allowed' }),
      };
    },
  };

  const updatedParameters = (parameter: string): string[] => {
    const parametersSelected = [...selectedParameters];
    if (parameter) {
      const index = selectedParameters.findIndex(param => param === parameter);
      parametersSelected.splice(index, 1);
    }
    return parametersSelected;
  };

  const onRemoveParameter = (index: number): void => {
    const details = [...tryOutDetails];
    setSelectedParameters(updatedParameters(details[index].parameter));
    details.splice(index, 1);
    setTryOutDetails(details);
  };

  const onParameterChange = (data: { label: string; value: string; type: string }, index: number): void => {
    const details = [...tryOutDetails];
    setSelectedParameters([...updatedParameters(details[index].parameter), data.value]);
    details[index] = { ...details[index], parameter: data.value, displayName: data.label, type: data.type };
    setTryOutDetails(details);
  };

  const onValueChange = (value: string | boolean, index: number): void => {
    const details = [...tryOutDetails];
    details[index] = { ...details[index], value };
    setTryOutDetails(details);
  };

  const enableAction = useMemo((): boolean => {
    return tryOutDetails.some(details => details.parameter && details.value.toString().length);
  }, [tryOutDetails]);

  const getCopyUrl = useMemo((): string => {
    return getCurl(tryOutDetails, workflowId);
  }, [tryOutDetails, workflowId]);

  const getParameters = useMemo((): OptionType[] => {
    const response = parameters?.map(parameter => {
      let cloneParam = { ...parameter };
      if (parameter.value && selectedParameters.indexOf(parameter.value) >= 0)
        cloneParam = { ...cloneParam, isDisabled: true };
      return cloneParam;
    });
    return response as OptionType[];
  }, [selectedParameters, parameters]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DropdownIndicator = (props: any): React.ReactElement => {
    const { selectProps } = props;
    if (selectProps.menuIsOpen) {
      return (
        <Box mr={2}>
          <CaretUp size={16} color={colors.gray[600]} />
        </Box>
      );
    }
    return (
      <Box mr={2}>
        <CaretDown size={16} color={colors.gray[600]} />
      </Box>
    );
  };

  const renderValue = (item: Parameter, index: number): ReactElement => {
    if (item.parameter === 'phoneNumber') {
      return (
        <>
          <FormLabel mt={4} fontSize="sm" fontWeight="400">
            {item.displayName}
          </FormLabel>
          <PhoneInput
            country="us"
            value={item.value as string}
            onChange={phone => onValueChange(phone, index)}
            inputStyle={{ borderColor: '#E8EBF1', width: '100%', borderRadius: '8px' }}
            buttonStyle={{ borderColor: '#E8EBF1', borderRadius: '8px 0 0 8px' }}
          />
        </>
      );
    }
    if (item.type === 'BOOLEAN') {
      return (
        <>
          <FormLabel mt={4} fontSize="sm" fontWeight="400">
            {item.displayName}
          </FormLabel>
          <SelectInput
            onChange={field => onValueChange((field as OptionType)?.value === 'true', index)}
            value={item.value?.toString()}
            options={[
              { label: 'True', value: 'true' },
              { label: 'False', value: 'false' },
            ]}
            size="small"
            isClearable={false}
            placeholder="Select a value"
            styles={customStyles}
            components={{
              DropdownIndicator,
            }}
          />
        </>
      );
    }
    return (
      <>
        <FormLabel mt={4} fontSize="sm" fontWeight="400">
          {item.displayName}
        </FormLabel>
        <Input
          borderRadius="lg"
          fontSize="sm"
          placeholder="Enter value"
          _focus={{ borderColor: 'blue.200' }}
          value={item.value as string}
          h="38px"
          onChange={e => onValueChange(e.target.value, index)}
        />
      </>
    );
  };

  const renderParameterBlock = (): ReactElement => (
    <Box>
      {tryOutDetails.map((item: Parameter, index: number) => (
        <Box key={item.parameter || Math.random()} mb={6} color="gray.900">
          {tryOutDetails.length > 1 && (
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Box fontWeight="500">Parameter {index + 1}</Box>
              <Image
                w="14px"
                h="14px"
                src="/assets/icons/delete_red.svg"
                cursor="pointer"
                onClick={() => onRemoveParameter(index)}
              />
            </Flex>
          )}
          <FormLabel fontSize="sm" fontWeight="400">
            Parameter
          </FormLabel>
          <SelectInput
            onChange={data => onParameterChange(data as { label: string; value: string; type: string }, index)}
            value={item.parameter}
            options={getParameters}
            size="small"
            isClearable={false}
            placeholder="Select a parameter"
            styles={customStyles}
            components={{
              DropdownIndicator,
            }}
          />
          {item.parameter && renderValue(item, index)}
        </Box>
      ))}
      <Flex alignItems="center">
        <Plus size={16} weight="bold" color={colors.blue[500]} />
        <Text
          pl="10px"
          textColor="#23368C"
          fontSize="sm"
          fontWeight="normal"
          cursor="pointer"
          onClick={() => {
            setTryOutDetails([...tryOutDetails, { parameter: '', value: '', type: '' }]);
          }}
        >
          Add Parameter
        </Text>
      </Flex>
    </Box>
  );

  return (
    <Modal isOpen onClose={onCancel} isCentered>
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent borderRadius="lg">
        <ModalHeader fontSize="md" fontWeight="500" pt={5} pb={4}>
          Tryout Capability
        </ModalHeader>
        <ModalCloseButton
          top={4}
          w="9px"
          h="9px"
          color="gray.600"
          mt={3}
          mr={3}
          _focus={{ border: 'none' }}
          _hover={{ background: 'none', opacity: '0.85' }}
        />
        <Formik validateOnBlur={false} validateOnChange={false} initialValues={{ email: '' }} onSubmit={onClose}>
          {({ isSubmitting }) => {
            return (
              <Form>
                <ModalBody className="try-out-modal" overflow="auto" maxH={440} pb={4}>
                  <FormControl fontSize="sm">{renderParameterBlock()}</FormControl>
                </ModalBody>
                <ModalFooter py={6} justifyContent="flex-start">
                  <Flex w="full" justifyContent="space-between" alignItems="center">
                    <Box fontSize="sm" fontWeight={400} color="blue.500" ml="-10px">
                      <CopyButton text={getCopyUrl} label="Copy cURL" disabled={!enableAction} />
                    </Box>
                    <Flex>
                      <Button
                        h="38px"
                        w="124px"
                        color="blue.800"
                        bg="purple.300"
                        fontSize="sm"
                        fontWeight="300"
                        _hover={{ opacity: '0.8' }}
                        mr={3}
                        onClick={onCancel}
                      >
                        Cancel{' '}
                      </Button>
                      <Button
                        isLoading={isSubmitting}
                        type="submit"
                        h="38px"
                        w="124px"
                        color={!tryOutDetails?.length ? 'gray.900' : 'white'}
                        bg={!tryOutDetails?.length ? 'gray.100' : 'blue.500'}
                        disabled={!tryOutDetails?.length || !enableAction}
                        fontSize="sm"
                        fontWeight="300"
                        _hover={tryOutDetails?.length ? { opacity: '0.8', background: 'blue.500' } : {}}
                      >
                        Tryout{' '}
                      </Button>
                    </Flex>
                  </Flex>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default TryOutModal;
