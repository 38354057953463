/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CaretDown } from 'phosphor-react';
import { colors } from '@/utils/colors';
import ConditionsWrapper from './ConditionsWrapper';
import { BranchAtom } from './states/branchAtom';

const BranchConfig = (): ReactElement => {
  const selectedBranch = useRecoilValue(BranchAtom);
  const setSelectedBranch = useSetRecoilState(BranchAtom);
  const { onOpen } = useDisclosure();
  return (
    <Box p="4">
      <Flex flexDirection="column" borderColor="gray.300" mb="4">
        <Text fontWeight="500">If</Text>
        <Text fontSize="xs" color="gray.600" mb="4">
          Checks if a certain condition is met.
        </Text>
        <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            rightIcon={<CaretDown size="16" color={colors.gray[600]} />}
            fontSize="sm"
            fontWeight="light"
            color="gray.900"
            borderRadius="lg"
            onClick={() => onOpen()}
            w="50%"
            textAlign="left"
          >
            {selectedBranch === 'branch A' ? 'Branch A' : selectedBranch === 'branch B' ? 'Branch B' : 'Select  Branch'}
          </MenuButton>
          <MenuList minW="sm">
            <MenuItem
              onClick={() => {
                setSelectedBranch('branch A');
              }}
            >
              Branch A
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedBranch('branch B');
              }}
            >
              Branch B
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <ConditionsWrapper />
      <Box bg="gray.300" w="full" h="1px" mt="3" mb="4" />
      <Text fontWeight="500">Else</Text>
      <Text fontSize="xs" color="gray.600" mb="4">
        Checks if a certain condition is not met.
      </Text>
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<CaretDown size="16" color={colors.gray[600]} />}
          fontSize="sm"
          fontWeight="light"
          color="gray.900"
          borderRadius="lg"
          onClick={() => onOpen()}
          w="50%"
          textAlign="left"
        >
          {selectedBranch === 'branch A' ? 'Branch B' : selectedBranch === 'branch B' ? 'Branch A' : 'Select Branch'}
        </MenuButton>
        <MenuList minW="sm">
          <MenuItem
            onClick={() => {
              setSelectedBranch('branch B');
            }}
          >
            Branch A
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedBranch('branch A');
            }}
          >
            Branch B
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default BranchConfig;
