/* eslint-disable react/no-children-prop */
import React from 'react';
import { Box } from '@chakra-ui/react';
import ButtonSelector from './sdk-preview/components/ButtonSelector';

const ButtonThemeCustomization: React.FC = () => {
  return (
    <Box>
      <ButtonSelector />
    </Box>
  );
};

export default ButtonThemeCustomization;
