import { useEffect, useCallback, useRef } from 'react';

interface IdleTimerProps {
  onIdle: () => void; // Callback when user is idle
  timeout?: number; // Idle timeout duration in milliseconds (optional, default is 30 minutes)
}

// Custom hook to detect user inactivity and trigger a callback
const useIdleTimer = ({ onIdle, timeout = 30 * 60 * 1000 }: IdleTimerProps): void => {
  const idleTimerRef = useRef<number | null>(null); // Holds the reference to the timeout

  // Function to clear and reset the idle timer
  const resetIdleTimer = useCallback(() => {
    // Clear the existing idle timer
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }

    // Set a new idle timer
    idleTimerRef.current = window.setTimeout(() => {
      // Trigger the onIdle callback when the user is idle
      onIdle();
    }, timeout);
  }, [onIdle, timeout]);

  useEffect(() => {
    // Events to track user activity
    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    // Function to handle events and reset the idle timer
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const eventHandler = () => resetIdleTimer();

    // Attach event listeners to track user activity
    events.forEach(event => window.addEventListener(event, eventHandler));

    // Initialize the idle timer
    resetIdleTimer();

    // Clean up event listeners and timer on unmount
    return () => {
      events.forEach(event => window.removeEventListener(event, eventHandler));
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
    };
  }, [resetIdleTimer]);
};

export default useIdleTimer;
