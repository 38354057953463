import React, { ReactElement } from 'react';
import { HStack, Image } from '@chakra-ui/react';

export const Footer = (): ReactElement => {
  return (
    <HStack alignItems="end" justifyContent="start" marginBottom="7" position="fixed" bottom="0">
      <Image src="/assets/footer.png" />
    </HStack>
  );
};
