import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  Box,
  UseModalProps,
  HStack,
  Link,
} from '@chakra-ui/react';
import { CopyButton } from '@bureau/components/src';
import QrCode from '../workflowv2/quick-link/QrCode';

interface QRCodeModalProps extends Omit<UseModalProps, 'children'> {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ url, isOpen, onClose }) => {
  const trimUrl = (qrCodeLink: string): string => {
    return qrCodeLink.length > 40 ? `${qrCodeLink.substring(0, 37)}...` : url;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent mx={4} bg="white" borderRadius="xl" maxW="400px">
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Box p={4} bg="white" borderRadius="lg" boxShadow="sm">
              <QrCode url={url} />
            </Box>
            <HStack spacing={1}>
              <Link href={url} color="blue.500" isExternal>
                <Text>{trimUrl(url)}</Text>
              </Link>
              <CopyButton text={url} />
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QRCodeModal;
