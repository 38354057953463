import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import { CaretDown, CaretRight } from 'phosphor-react';
import { useRecoilValue } from 'recoil';
import { ContentfulCustomFunctions } from '../../../states/contentfulCustomFunctions';
import useGetOperatorDescriptions from '../../../queries/useGetOperatorDescriptions';

interface OptionType<T = string> {
  label: string;
  value: T;
  name?: string;
  description?: string;
  type?: string;
}

const operatorOptions = [
  {
    name: 'Operator',
    hasArrow: true,
    key: 'operator',
  },
  {
    name: 'Custom Functions',
    hasArrow: true,
    key: 'customFunctions',
  },
  {
    name: 'Allow List or Block List',
    hasArrow: false,
    key: 'list',
  },
];

const OperatorBox = ({
  options,
  onChange,
  value,
}: {
  options: OptionType[];
  onChange: (val: OptionType) => void;
  value?: string;
}): React.ReactElement => {
  const [filteredOptions, setFilterdOptions] = useState(options);
  const [hoveredOption, setHoveredOption] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [operatorTypeDetails, setOperatorTypeDetails] = useState<{ fields: { id: string; description: string } }[]>();
  const selectedValue = options.find(opt => opt.value === value);

  const contentfulCustomFunctions = useRecoilValue(ContentfulCustomFunctions);

  const { getOperatorDescriptions } = useGetOperatorDescriptions();

  useEffect(() => {
    if (hoveredOption === 'operator') {
      const tempOptions = options.filter(opt => {
        if (!opt.type) {
          return opt;
        }
        return opt.type === 'relational';
      });
      setFilterdOptions(tempOptions);
    } else if (hoveredOption === 'customFunctions') {
      const tempOptions = options.filter(opt => {
        if (!opt.type) {
          return opt;
        }
        return opt.type === 'functional';
      });
      setFilterdOptions(tempOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredOption]);

  useEffect(() => {
    getOperatorDescriptions()
      .then(response => {
        setOperatorTypeDetails(response?.items as { fields: { id: string; description: string } }[]);
      })
      // eslint-disable-next-line no-console
      .catch(() => console.log(`Descriptions for operators not added in contentful`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Button
        rightIcon={<CaretDown />}
        colorScheme="blue"
        variant="outline"
        fontSize="small"
        fontWeight="400"
        onClick={() => setShowOptions(!showOptions)}
      >
        {selectedValue?.label || 'Select'}
      </Button>
      {showOptions && (
        <Flex onMouseLeave={() => setHoveredOption('')} position="absolute" top={0} zIndex={1} ml={24}>
          <Box bg="white" borderRadius="lg" overflow="hidden" w={300} h={303} boxShadow="lg">
            <Box>
              {operatorOptions.map(item => (
                <Box
                  key={item.name}
                  onMouseEnter={() => setHoveredOption(item.key)}
                  onClick={() => {
                    if (item.key === 'list') {
                      onChange(options[10]);
                      setShowOptions(false);
                    }
                  }}
                >
                  <Option
                    item={item}
                    description={operatorTypeDetails?.find(op => op.fields.id === item.key)?.fields?.description}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          {(hoveredOption === 'operator' || hoveredOption === 'customFunctions') && (
            <Box
              ml="30px"
              bg="white"
              borderRadius="lg"
              overflow="auto"
              className="scrollable-div"
              w={270}
              boxShadow="lg"
              maxH={303}
            >
              {filteredOptions.map(item => (
                <Operator
                  item={item}
                  description={
                    contentfulCustomFunctions?.find(op => op.fields.id === item.description)?.fields?.description
                  }
                  key={item.value}
                  onChange={() => {
                    onChange(item);
                    setShowOptions(false);
                  }}
                />
              ))}
            </Box>
          )}
        </Flex>
      )}
    </Box>
  );
};

const Option = ({
  item,
  description,
}: {
  item: {
    name: string;
    hasArrow: boolean;
  };
  description?: string;
}): ReactElement => {
  return (
    <Flex alignItems="center" justifyContent="space-between" px={4} py={2} cursor="pointer" _hover={{ bg: 'gray.200' }}>
      <Box maxW={252}>
        <Text fontSize="sm" fontWeight="normal">
          {item.name}
        </Text>
        <Text fontSize="xs" fontWeight="light" color="gray.600" pt={1} noOfLines={4}>
          {description}
        </Text>
      </Box>
      {item.hasArrow && <CaretRight size={12} />}
    </Flex>
  );
};

const Operator = ({
  item,
  onChange,
  description,
}: {
  item: OptionType;
  onChange: () => void;
  description?: string;
}): ReactElement => {
  return (
    <Flex alignItems="center" px={4} py={2} cursor="pointer" _hover={{ bg: 'gray.200' }} onClick={onChange}>
      <Box maxW={252}>
        <Text fontSize="sm" fontWeight="normal">
          {item.label}
        </Text>
        <Text fontSize="xs" fontWeight="light" color="gray.600" pt={1} noOfLines={3}>
          {description || item.name}
        </Text>
      </Box>
    </Flex>
  );
};

export default OperatorBox;
