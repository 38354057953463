import React from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { TabList, Tabs, Tab, TabPanels, TabPanel, Text } from '@chakra-ui/react';
import AdvancedCustomization from './AdvancedCustomization';
import QuickCustimizationTheme from './QuickCustimizationTheme';

function ThemeCustomizationWrapper(): React.ReactElement {
  const tabSelectedStyles = { color: 'blue.500', borderColor: 'blue.500', fontWeight: 'medium' };

  return (
    <Box height="100%" borderWidth="1px" borderRadius="lg" overflow="hidden" w="360px">
      <Flex alignItems="center" bg="gray.50" p={4} mb={4}>
        <Text fontWeight="medium">Customize Theme</Text>
      </Flex>
      <Box>
        <Tabs color="gray.600" height="88%" mt="4">
          <TabList fontSize="sm">
            <Tab fontSize="sm" fontWeight="medium" _selected={{ ...tabSelectedStyles }} _focus={{ boxShadow: 'none' }}>
              Quick Customisation
            </Tab>
            <Tab fontSize="sm" fontWeight="medium" _selected={{ ...tabSelectedStyles }} _focus={{ boxShadow: 'none' }}>
              Advanced
            </Tab>
          </TabList>

          <TabPanels h="calc(100% - 42px)">
            <TabPanel h="100%">
              <Box w="full" h="auto" overflow="auto">
                <QuickCustimizationTheme />
              </Box>
            </TabPanel>
            <TabPanel h="100%" position="relative">
              <Box w="full" h="auto" overflow="auto">
                <AdvancedCustomization />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default ThemeCustomizationWrapper;
