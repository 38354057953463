import React, { ReactElement } from 'react';
import { Flex, Text, Image, FlexProps } from '@chakra-ui/react';

const EmptySearchResult = ({ text, styles }: { text?: string; styles?: FlexProps }): ReactElement => (
  <Flex direction="column" justifyContent="center" alignItems="center" {...styles}>
    <Image src="/assets/not-found-2.svg" h="130px" w="110px" />
    <Text color="gray.600" fontWeight="light" fontSize="sm">
      {text}
    </Text>
  </Flex>
);

export default EmptySearchResult;
