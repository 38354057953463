import React, { ReactElement } from 'react';
import { Box, Button, Text, Tooltip } from '@chakra-ui/react';
import { Play } from 'phosphor-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { SelectedNodeAtom } from '../../states/selectedNodeAtom';
import { AlertsAtom } from '../../states/alertsAtom';

const TryoutButton = ({
  openConfigureModal,
  workflowId,
}: {
  openConfigureModal: () => void;
  workflowId: string;
}): ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const alertsList = useRecoilValue(AlertsAtom);

  return (
    <Tooltip
      label={
        <Text fontSize="xs" fontWeight="normal" color="gray.50">
          {// eslint-disable-next-line no-nested-ternary
          !workflowId ? 'To test the workflow please publish first.' : alertsList.length ? 'Resolve Alerts first.' : ''}
        </Text>
      }
      aria-label="A tooltip for Tryout button"
      isDisabled={!!workflowId && !alertsList.length}
      hasArrow
      placement="bottom-start"
      openDelay={200}
      closeDelay={300}
      p="3"
      borderRadius="lg"
      bg="gray.800"
      maxW="52"
    >
      {/* Added Box as wrapper to make tooltip work as expected */}
      <Box>
        <Button
          borderRadius="lg"
          fontSize="sm"
          fontWeight="normal"
          bg="white.50"
          borderColor="gray.200"
          onClick={() => {
            if (workflowId) {
              openConfigureModal();
              setSelectedNode({ id: 'tryout', type: 'tryout', columns: [] });
            }
          }}
          leftIcon={<Play weight="fill" />}
          _disabled={{ cursor: 'pointer', opacity: 0.5 }}
          isDisabled={!workflowId || !!alertsList.length}
        >
          Tryout
        </Button>
      </Box>
    </Tooltip>
  );
};

export default TryoutButton;
