import { TransactionDetail } from '@/screens/transactions/transaction-details/types';

export const getResponseTime = (initialEpochTime: number, finalEpochTime: number): string => {
  const timeDifference = Math.abs(finalEpochTime - initialEpochTime);

  const minutes = Math.floor(timeDifference / 60000);
  const remaining = timeDifference % 60000;
  const seconds = Math.floor(remaining / 1000);
  const milliseconds = remaining % 1000;

  const formatTimeUnit = (unit: number): string => {
    return unit < 10 ? `0${unit}` : `${unit}`;
  };

  return `${formatTimeUnit(minutes)}:${formatTimeUnit(seconds)}:${formatTimeUnit(milliseconds)}`;
};

export const transactionStatusStyles = (
  transactionData: TransactionDetail | undefined,
): { color: string; bg: string } => {
  if (transactionData?.outcome.toLowerCase() === 'accepted') {
    return { color: 'green.500', bg: 'green.50' };
  }
  if (transactionData?.outcome.toLowerCase() === 'rejected') {
    return { color: 'red.500', bg: 'red.50' };
  }
  return { color: 'gray.600', bg: 'white.50' };
};

export const transactionStatus = (status: string): string => {
  if (status === 'awaiting') return 'In Progress';

  return status;
};
