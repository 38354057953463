import React, { ReactElement, useMemo, useState } from 'react';
import { Box, Flex, Grid, Text, Icon } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { CaretDown } from 'phosphor-react';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { WorkflowAtom } from './states/workflowAtom';
import { CategorizedInputs, ChildItems, ColumnV2, OutputV2, ParentMenuItem, ResponseInputsV2 } from './types';
import ConditionsWrapper from './ConditionsWrapper';
import ServiceConfiguration from './ServiceConfiguration';
import { InputSection, OutputSection } from './components/InputOutputSection';
import { NO_OF_INPUT_FIELDS_VISIBLE } from './constant';
import useFetchSupplierInputOutput from './queries/useFetchSupplierInputOutput';

const SourceConfiguration = (): ReactElement => {
  const [showAllInputDropdown, setShowAllInputDropdown] = useState(false);
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const workflows = useRecoilValue(WorkflowAtom);
  const getSupplierIds = (): string[] => {
    const supplierIds = [
      ...new Set(workflows.flatMap(workflow => workflow.nodes).flatMap(nodes => nodes.supplierId)),
    ] as string[];
    return supplierIds;
  };
  const { data: inputAttributes = { inputs: [], outputs: [] } } = useFetchSupplierInputOutput(getSupplierIds());
  const getInput = (): { parentMenuItems: ParentMenuItem[]; childItems: ChildItems } => {
    // const inputs = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < workflows.length; i++) {
      if (workflows[i].metadata.capabilityId === selectedNode.id) {
        break;
      }
    }
    const userInput = {
      id: 'user-input',
      header: 'User Input',
      subHeader: 'Inputs entered by user',
    };
    const userInputChild: ChildItems = {
      'user-input': inputAttributes.inputs.map(attributes => ({
        id: attributes.key || 'random-id',
        header: attributes.displayName ? attributes.displayName : '',
        subHeader: attributes.description ? attributes.description : '',
        type: 'user-input',
      })),
    };
    return { parentMenuItems: [userInput], childItems: { ...userInputChild } };
  };

  const getParentItems = (): ColumnV2[] => {
    const currentIndex = workflows.findIndex(workflow => workflow.metadata.capabilityId === selectedNode.id);

    const parentItems = [...workflows.slice(0, currentIndex)];
    return parentItems.filter(
      workflow => !(workflow.metadata.kind === 'tag' || workflow.metadata.kind === 'condition'),
    );
  };

  const parentItems = getParentItems();

  const getInputs = (columns: ColumnV2[]): ResponseInputsV2[] =>
    columns
      .map((column, columnIndex) => {
        return { ...column, nodes: column.nodes.map((node, rowIndex) => ({ ...node, columnIndex, rowIndex })) };
      })
      .flatMap(column => column.nodes)
      .filter(nodes => nodes.kind !== 'AWAIT')
      .map(node => {
        return {
          ...node,
          inputs: node.inputs?.map((input, elementIndex) => ({
            ...input,
            columnIndex: node.columnIndex,
            rowIndex: node.rowIndex,
            elementIndex,
          })),
        };
      })
      .flatMap(nodes => nodes.inputs)
      .filter(input => !input?.isStatic)
      .filter(item => item !== undefined) as ResponseInputsV2[];

  const allInputs = getInputs(selectedNode.columns);

  const savedInputs = getInputs(workflows.filter(object => object.metadata.capabilityId === selectedNode.id));

  const outputs = selectedNode.columns.flatMap(column => column.nodes).flatMap(node => node.outputs) as OutputV2[];

  const limitedInputs = useMemo(() => {
    const newInputs = [...(allInputs ?? [])];

    const initialCategorizedInputs: CategorizedInputs = {
      notConfiguredMandatory: [],
      configuredMandatory: [],
      notConfiguredOptional: [],
      configuredOptional: [],
    };
    const categorizedInputsObject = newInputs.reduce((result, input, index) => {
      const isConfigured = savedInputs[index]?.location !== '..';
      if (input.isOptional) {
        if (isConfigured) {
          // Configured-Optional
          result.configuredOptional.push(input);
        } else {
          // NOT-Configured-Optional
          result.notConfiguredOptional.push(input);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isConfigured) {
          // Configured-Mandatory
          result.configuredMandatory.push(input);
        } else {
          // NOT-Configured-Mandatory
          result.notConfiguredMandatory.push(input);
        }
      }

      return result;
    }, initialCategorizedInputs);

    const {
      notConfiguredMandatory,
      configuredMandatory,
      notConfiguredOptional,
      configuredOptional,
    } = categorizedInputsObject;

    const rearrangedInputs = [
      ...notConfiguredMandatory,
      ...configuredMandatory,
      ...notConfiguredOptional,
      ...configuredOptional,
    ];

    if (!showAllInputDropdown) return rearrangedInputs.slice(0, NO_OF_INPUT_FIELDS_VISIBLE);

    return rearrangedInputs || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInputs, showAllInputDropdown]);

  return (
    <>
      <Flex flexDirection="column" gridGap="4" p="4" borderColor="gray.300" borderBottomWidth={1} w="100%">
        <Text fontWeight="500">Configure</Text>
        <Grid gridTemplateColumns="1fr 1fr" gridGap="10" w="100%">
          <Flex direction="column" gridGap="4">
            {limitedInputs.map((input, index) => (
              <ServiceConfiguration
                key={input.columnIndex ? `${input.columnIndex}-${input.rowIndex}- ${input.elementIndex}` : index}
                currentElement={input}
                parentItems={parentItems}
                index={index}
                userInputs={getInput()}
              />
            ))}
            {!showAllInputDropdown && allInputs?.length > NO_OF_INPUT_FIELDS_VISIBLE && (
              <Text
                color="blue.500"
                fontSize="xs"
                fontWeight="400"
                cursor="pointer"
                w="fit-content"
                py={2}
                px={3}
                mt={2}
                _hover={{ bg: 'blue.100', borderRadius: 'lg' }}
                onClick={() => setShowAllInputDropdown(true)}
              >
                {`Configure More (${allInputs.length - NO_OF_INPUT_FIELDS_VISIBLE})`}
                <Icon boxSize={3} as={CaretDown} weight="bold" ml={1} />
              </Text>
            )}
          </Flex>
          <Flex
            p="4"
            direction="column"
            bg="gray.50"
            borderWidth={1}
            borderColor="gray.200"
            borderRadius="lg"
            h="fit-content"
            flexDirection="column"
            gridGap="4"
          >
            {savedInputs.length ? <InputSection sectionName="input" data={savedInputs} /> : null}
            <OutputSection sectionName="output" data={outputs} />
          </Flex>
        </Grid>
      </Flex>
      <Box>
        <ConditionsWrapper />
      </Box>
    </>
  );
};

export default SourceConfiguration;
