/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { CreateWebhookResponse } from '../types';

export const useCreateWebhook = (
  options?: UseMutationOptions<CreateWebhookResponse, AxiosError, {}>,
): UseMutationResult<CreateWebhookResponse, AxiosError, CreateWebhookResponse> => useMutation(createWebhook, options);

const createWebhook = async (webhookDetails: CreateWebhookResponse): Promise<CreateWebhookResponse> => {
  const res = await bureauPlatformApi.post(`/platform/webhooks`, webhookDetails);
  return res.data;
};
