import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';

interface UserDetailsType {
  email: string;
  nickname: string;
  picture: string;
  user_id: string;
  user_name: string;
}

interface DecodedTokenType {
  [key: string]: string | UserDetailsType | string[] | number | boolean;
}
interface DecodedTokenReturnTypes {
  decodedToken: DecodedTokenType;
  isLoading: boolean;
  userDetails: UserDetailsType;
}

function useDecodedToken(): DecodedTokenReturnTypes {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [decodedToken, setDecodedToken] = useState<DecodedTokenType>({});
  const urlKey = 'https://overwatch.bureau.id';

  React.useEffect(() => {
    if (isAuthenticated) {
      const getToken = async (): Promise<void> => {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        const decodeToken: DecodedTokenType = jwt_decode(token);
        setDecodedToken(decodeToken);
        setIsLoading(false);
      };
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return { decodedToken, isLoading, userDetails: decodedToken[urlKey] as UserDetailsType };
}

export default useDecodedToken;
