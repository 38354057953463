import { format } from 'date-fns';

export function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return randomString;
}

export function isUrl(str: string): boolean {
  if (str.length > 10000) {
    return true;
  }
  const urlRegex = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
  return urlRegex.test(str);
}

export function capitalizeFirstLetter(string: string): string {
  return string.replace(/^\w/, match => match.toUpperCase());
}

export const formatDate = (value: number | string | undefined): Date | string => {
  if (value) {
    if (typeof value === 'string') {
      const formatedValue = new Date(value);
      const formattedDate = format(formatedValue, 'MMM dd, yyyy');
      return formattedDate;
    }
    const valueAsString = value as number;
    const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    const formatedValue = date.setUTCSeconds(valueAsString);
    const formattedDate = format(formatedValue, 'MMM dd, yyyy');
    return formattedDate;
  }
  return '';
};

export function convertCamelCaseToWords(input: string): string {
  const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}

export const isValidEmail = (email: string): boolean => {
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test if the email matches the pattern
  return emailPattern.test(email);
};
