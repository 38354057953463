import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

import { WorkflowNode } from '../types';

const useDeleteWorkflow = (
  options?: UseMutationOptions<WorkflowNode, AxiosError, { id: string }>,
): UseMutationResult<WorkflowNode, AxiosError, { id: string }> => {
  return useMutation(deleteWorkflow, options);
};

const deleteWorkflow = async (data: { id: string }): Promise<WorkflowNode> => {
  const { data: response } = await bureauPlatformApi.delete<WorkflowNode>(`/workflows/${data?.id}`);
  return response;
};

export default useDeleteWorkflow;
