import { MouseEventHandler, ReactElement } from 'react';

export interface OnboardingInputData {
  displayName: string;
  industry: string;
  geography: string;
}

export enum Pages {
  CreateOrganisation,
  Industries,
  Geographies,
  WelcomeMessage,
}

export interface CreateOrgPropTypes {
  handleNextPage: Function;
  selectionData: OnboardingInputData;
  setSelectionData: Function;
}

export interface QuestionPagePropTypes {
  page: Pages;
  selectionData: OnboardingInputData;
  setSelectionData: Function;
  options: CapabilityFilterItem[];
  handleNextPage: MouseEventHandler<HTMLButtonElement>;
  handlePrevPage: MouseEventHandler<HTMLButtonElement>;
}

export interface MessagePagePropTypes {
  isLoading: boolean;
  createOrganisationHandler: MouseEventHandler<HTMLButtonElement>;
  handlePrevPage: MouseEventHandler<HTMLButtonElement>;
}

export interface CapabilityFilterItem {
  value: string;
  displayText: string;
  comingSoon?: boolean;
  description?: string;
  tags?: string[];
  icon?: ReactElement;
}

export interface CapabilityFilters {
  industries: CapabilityFilterItem[];
  geographies: CapabilityFilterItem[];
  useCases?: CapabilityFilterItem[];
}
