import React, { ReactElement } from 'react';
import { Flex, Text, Image, Button } from '@chakra-ui/react';
import { useDrop } from 'react-dnd';
import { useSetRecoilState } from 'recoil';
import { CloudArrowDown } from 'phosphor-react';
import { colors } from '@/utils/colors';
import { DRAG_ITEM_TYPE } from './types';
import { WorkflowAtom } from './states/workflowAtom';
import { generateUUID } from './utils';
import { OUTPUTS } from './constant';

const CapabilityDrop = ({ type, parentId }: { type: string; parentId: string }): ReactElement => {
  const setWorkflow = useSetRecoilState(WorkflowAtom);

  const handleClick = (): void => {
    setWorkflow(prevWorkflow => {
      const tempCapabilityID = `column_${generateUUID()}`;
      const outputs = JSON.parse(JSON.stringify(OUTPUTS).replace('namespace_id', tempCapabilityID));
      const tagIndex = prevWorkflow.filter(column => column.metadata.kind === 'tag').length;
      const newTagObject = {
        metadata: {
          capabilityId: tempCapabilityID,
          capabilityName: '',
          kind: 'tag',
        },
        nodes: [{ id: tempCapabilityID, kind: 'tag', name: `Condition ${tagIndex + 1}`, dependencies: [], outputs }],
        id: tempCapabilityID,
      };
      return [...prevWorkflow, newTagObject];
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DRAG_ITEM_TYPE.CAPABILITY,
    drop: () => ({ type, parentId }),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }));
  return (
    <Flex
      direction="column"
      bg={isOver ? 'blue.100' : 'blue.50'}
      border="1px dashed"
      borderColor="blue.200"
      borderRadius="lg"
      w="328px"
      h="24"
      alignItems="center"
      justifyContent="center"
      gridGap="5"
      ref={drop}
    >
      <Flex alignItems="center" gridGap="2" opacity="0.5">
        <CloudArrowDown size={16} color={colors.blue[300]} />
        <Text color="blue.300" fontSize="xs">
          Drop a capability
        </Text>
      </Flex>
      {type === 'source' ? (
        <Button
          leftIcon={<Image src="/assets/icons/Conditions.svg" alt="tree icon" />}
          variant="outline"
          borderColor="blueGray.100"
          bg="blue.100"
          color="blue.300"
          h="unset"
          px="2"
          py="1"
          opacity="0.5"
          borderRadius="lg"
          fontSize="xs"
          fontWeight="normal"
          _hover={{ color: 'blue.400', opacity: 1, bg: 'blue.100' }}
          onClick={handleClick}
        >
          Add conditional branching
        </Button>
      ) : null}
    </Flex>
  );
};

export default CapabilityDrop;
