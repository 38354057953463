import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { WorkflowInputParams } from '../types';

export const useGetInputDetails = (id: string): UseQueryResult<WorkflowInputParams, AxiosError> => {
  return useQuery('workflow-input-details', getDetails(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const getDetails = (id: string) => {
  return async () => {
    const res = await bureauPlatformApi.get<WorkflowInputParams>(`/input-details/${id}`);
    return res.data;
  };
};

export default useGetInputDetails;
