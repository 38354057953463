import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { Capability } from '../types';

const useCapabilityCreation = (
  options?: UseMutationOptions<Capability, AxiosError, Capability>,
): UseMutationResult<Capability, AxiosError, Capability> => useMutation(createCapability, options);

const createCapability = async (capabilityDetails: Capability): Promise<Capability> => {
  const { object } = capabilityDetails;
  // remove start node
  const objectWithoutStartNode = object
    ? {
        ...object,
        columns: object.columns.filter(column => column.id.indexOf('column_0') === -1),
      }
    : null;

  const payloadData = {
    ...capabilityDetails,
    object: objectWithoutStartNode?.columns.length ? objectWithoutStartNode : null,
  };

  const { data: response } = await bureauPlatformApi.post<Capability>('/capabilities', payloadData);
  return response;
};

export default useCapabilityCreation;
