import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CapabilityCreation from './workflow-creation/CapabilitiesCreation';
import CapabilitiesList from './CapabilitiesList';

const CapabilitiesV2Wrapper = (): React.ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CapabilitiesList />
      </Route>
      <Route exact path={`${path}/create`}>
        <CapabilityCreation />
      </Route>
      <Route exact path={`${path}/:id/edit`}>
        <CapabilityCreation />
      </Route>
    </Switch>
  );
};

export default CapabilitiesV2Wrapper;
