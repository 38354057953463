import React, { ReactElement, useEffect, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation, useParams } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { useQueryClient } from 'react-query';
import CapabilityAccordion from './Capabilities';
import WorkflowLayout from './WorkflowLayout';
import { CapabilityFilterItem } from '../user-onboarding/types';
import { CustomDragLayer } from './components/CustomDragLayer';
import InputConfiguration from './InputConfig';
import WorkflowHeader from './WorkflowHeader';
import WorkflowOnboardingModal from './WorkflowOnboardingModal';
import { TryoutStateAtom } from './states/tryoutStateAtom';
import { WorkflowNameAtom } from './states/workflowName';

export const initialUseCaseValues = {
  value: '',
  displayText: '',
  comingSoon: false,
};

function Workflow(): ReactElement {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const resetTryoutResponse = useResetRecoilState(TryoutStateAtom);
  const resetWorkflowName = useResetRecoilState(WorkflowNameAtom);
  const queryClient = useQueryClient();

  const [workflowId, setWorkflowId] = useState<string>(() => {
    if (pathname.indexOf('duplicate') > -1) {
      return '';
    }
    return id || '';
  });
  const [selectedCapability, setSelectedCapability] = useState<string>('');
  const [selectedUseCase, setSelectedUseCase] = useState<CapabilityFilterItem>(initialUseCaseValues);
  const [isCapabilityListOpen, setIsCapabilityListOpen] = useState(true);
  const [showTryoutModal, setShowTryoutModal] = useState(false);
  const { isOpen: isConfigureModalOpen, onOpen: openConfigureModal, onClose: onConfigureModalClose } = useDisclosure();
  const isTemplate = pathname.includes('templates');

  useEffect(() => {
    return () => {
      resetTryoutResponse();
      resetWorkflowName();
      queryClient.removeQueries({ queryKey: 'workflowById-v2', exact: true });
      queryClient.removeQueries({ queryKey: 'capabilityList' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCapabilityListOpen) {
      onConfigureModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCapabilityListOpen]);

  useEffect(() => {
    if (isConfigureModalOpen || showTryoutModal) {
      setIsCapabilityListOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigureModalOpen, showTryoutModal]);

  return (
    <Flex direction="column" w="full">
      <WorkflowHeader
        name=""
        workflowId={workflowId}
        setWorkflowId={setWorkflowId}
        selectedUseCase={selectedUseCase}
        setShowTryoutModal={setShowTryoutModal}
        openConfigureModal={openConfigureModal}
      />

      <DndProvider backend={HTML5Backend}>
        <CustomDragLayer />
        <Flex>
          {!isTemplate && (
            <CapabilityAccordion
              isCapabilityListOpen={isCapabilityListOpen}
              setIsCapabilityListOpen={setIsCapabilityListOpen}
              useCase={selectedUseCase}
            />
          )}
          <WorkflowLayout
            isConfigureModalOpen={isConfigureModalOpen}
            isCapabilityListOpen={isCapabilityListOpen}
            openConfigureModal={openConfigureModal}
            setSelectedCapability={setSelectedCapability}
            setSelectedUseCase={setSelectedUseCase}
            onConfigureModalClose={onConfigureModalClose}
            setIsCapabilityListOpen={setIsCapabilityListOpen}
          />
          <InputConfiguration
            setIsCapabilityListOpen={setIsCapabilityListOpen}
            isOpen={isConfigureModalOpen}
            onClose={onConfigureModalClose}
            selectedCapability={selectedCapability}
            workflowId={workflowId}
          />
        </Flex>
      </DndProvider>

      {pathname.includes('create') && <WorkflowOnboardingModal setSelectedUseCase={setSelectedUseCase} />}
    </Flex>
  );
}

export default Workflow;
