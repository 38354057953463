import React, { ReactElement, useMemo, useState } from 'react';
import { Node, Handle, Position } from 'react-flow-renderer';
import { Icon, Flex, Box, Tooltip, Image, Text } from '@chakra-ui/react';
import { ClockClockwise, Warning, GearSix, TrashSimple, ArrowsClockwise, Tag } from 'phosphor-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { colors } from '@/utils/colors';
import { ModifyNodePositions, NodeDetails, SelectedPosition, WorkflowJson } from '../states/workflowChart';
import { ServicesDescriptions } from '../states/serviceDescriptions';
import { renderDataTestId } from '../helpers/utilityMethods';
import { AwaitJson, NodeKind, ResponseInputs, Position as NodePositionType } from '../types';

const NodeIcon = ({
  nodeData,
  isAwaitNode,
}: {
  nodeData: { color: string; name: string; awaits: AwaitJson[] };
  isAwaitNode: boolean;
}): ReactElement => {
  if (nodeData?.color) {
    return (
      <Box
        borderRadius="50%"
        background="white"
        w={6}
        h={6}
        mr={2}
        ml={1}
        borderWidth={1}
        borderColor={`${nodeData.color}.400`}
      >
        <Icon size={4} mr="2" ml="1" color={`${nodeData.color}.500`} as={Tag} />
      </Box>
    );
  }
  if (isAwaitNode) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        bg={colors.green[50]}
        p={1}
        borderRadius="full"
        w={6}
        h={6}
        mr={3}
      >
        <ClockClockwise size={12} color={colors.green[500]} />
      </Flex>
    );
  }
  return <Image mr="2" ml="1" src="/assets/icons/serviceIcon.svg" alt="" h={6} w={6} />;
};

const WorkflowNode = ({
  nodeData,
  isAwaitNode,
}: {
  nodeData: { color: string; name: string; awaits: AwaitJson[]; id: string; kind: string };
  isAwaitNode: boolean;
}): ReactElement => {
  const servicesDescriptions = useRecoilValue(ServicesDescriptions);
  const serviceDescription = servicesDescriptions.find(item => item.fields.capabilityIDs?.includes(nodeData.id));

  return (
    <Tooltip
      label={nodeData?.name}
      bg="white"
      color="gray.700"
      placement="bottom"
      px={4}
      py={3}
      borderRadius="lg"
      fontWeight="light"
      fontSize="xs"
    >
      <Flex direction="column" width="100%">
        <Flex
          alignItems="center"
          {...(isAwaitNode ? { mb: '30px' } : {})}
          borderBottomWidth={nodeData?.kind === 'tag' ? '' : 1}
          borderColor="white.50"
          pb={2}
        >
          <NodeIcon nodeData={nodeData} isAwaitNode={isAwaitNode} />
          <Box width="140px" fontWeight="light" isTruncated>
            {nodeData?.name}
          </Box>
        </Flex>
        {isAwaitNode && (
          <Box fontWeight="light" fontSize="xs" pos="relative">
            {nodeData?.awaits?.[0]?.values?.length ? (
              <Box pos="absolute" bottom="1px" color="gray.600">
                {`${nodeData.awaits[0].values.length} Value${nodeData.awaits[0].values.length === 1 ? '' : 's'} Added`}
              </Box>
            ) : (
              <Flex alignItems="center" pos="absolute" bottom="1px" color="orange.500">
                <Warning size={18} />
                <Text ml={1}>Add Values to process</Text>
              </Flex>
            )}
          </Box>
        )}
        {!isAwaitNode && (
          <Text fontSize="xs" fontWeight="light" color="gray.600" px={2} pt={2} noOfLines={2}>
            {serviceDescription?.fields?.capabilityDescription}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
};

const SourceTagNode = ({ data }: Node): React.ReactElement => {
  const [showPopup, setShowPopup] = useState(false);

  const setModifyNodePosition = useSetRecoilState(ModifyNodePositions);
  const setEditNodeDetails = useSetRecoilState(NodeDetails);
  const selectedPosition = useRecoilValue(SelectedPosition);
  const workflowJson = useRecoilValue(WorkflowJson);

  const isAwaitNode = data.kind === NodeKind.await;

  const initialNodePosition = {
    columnPosition: data.columnPosition || 0,
    nodePosition: data.nodePosition || 0,
  };

  const modifyNodePosition: NodePositionType = useMemo(
    () =>
      workflowJson.columns.reduce((nodePos, column, columnPosition) => {
        const nodePosition = column.nodes.findIndex(node => node.id === data.id);

        if (nodePosition !== -1) {
          return { columnPosition, nodePosition };
        }
        return nodePos;
      }, initialNodePosition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, workflowJson],
  );

  const onActionClick = (type: string): void => {
    setEditNodeDetails({
      type: data?.kind || '',
      oldName: data?.name || '',
      oldId: data?.id || '',
      action: type,
      description: data.description,
      color: data?.color || '',
      ...(isAwaitNode ? { awaits: data?.awaits } : {}),
      inputs: data?.inputs?.map((item: ResponseInputs) => {
        const locationArray = item?.location?.split('.');
        const inputItem = {
          ...item,
          locationKind: locationArray?.[0],
          locationId: locationArray?.[1],
          locationType: locationArray?.[2],
          isOptional: item.isOptional ? item.isOptional : item.optionality === 'OPTIONAL',
        };
        return inputItem;
      }),
    });

    setModifyNodePosition(modifyNodePosition);
  };

  return (
    <Flex
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
      data-test-id={renderDataTestId(data, 'sourceTagNode')}
      p="2px 0 2px 5px"
      borderRadius="lg"
      bg={
        selectedPosition.columnPosition === modifyNodePosition.columnPosition &&
        selectedPosition.nodePosition === modifyNodePosition.nodePosition
          ? 'blue.100'
          : ''
      }
      h="full"
    >
      <Box w="205px" h="100%" p="2px 0 2px 5px" role="group" id={data?.id}>
        <Handle
          type="target"
          position={Position.Left}
          style={{
            borderRadius: 0,
            width: 0,
            background: 'none',
          }}
        />
        <Flex flexDirection="row" alignItems="center" h="100%" pt={3}>
          <WorkflowNode nodeData={data} isAwaitNode={isAwaitNode} />
        </Flex>
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          style={{
            width: '8px',
            height: '8px',
            background: 'white',
            border: '2px solid #ADC5F9',
            right: '-5px',
          }}
        />
      </Box>
      <Box
        position="absolute"
        bg="transparent"
        h={6}
        w="185px"
        bottom={110}
        left={data?.kind === 'tag' ? '35px' : '10px'}
      />
      {showPopup && (
        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          borderRadius={19}
          width="fit-content"
          height={46}
          left={data?.kind !== 'source' ? '35px' : '10px'}
          bottom={110}
          overflow="hidden"
        >
          {data?.kind === 'source' && (
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              h="full"
              w={71}
              color="gray.700"
              bg="blue.300"
              _hover={{ color: 'gray.900', bg: 'blue.500' }}
              onClick={e => {
                e.stopPropagation();
                onActionClick('edit');
              }}
              cursor="pointer"
            >
              <GearSix color="white" size="16" />
              <Text fontSize="8px" pt={1} fontWeight="normal" color="white">
                Configure
              </Text>
            </Flex>
          )}
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            h="full"
            w={data.kind !== 'source' ? 71 : 43}
            color="gray.700"
            bg="blue.300"
            _hover={{ color: 'gray.900', bg: 'blue.500' }}
            onClick={e => {
              e.stopPropagation();
              onActionClick('replace');
            }}
            cursor="pointer"
          >
            <ArrowsClockwise color="white" size="16" />
            <Text fontSize="8px" pt={1} fontWeight="normal" color="white">
              Replace
            </Text>
          </Flex>
          <Flex
            color="red.400"
            direction="column"
            alignItems="center"
            justifyContent="center"
            bg="blue.300"
            h="full"
            w={71}
            _hover={{ color: 'red.500', bg: 'blue.500' }}
            onClick={e => {
              e.stopPropagation();
              onActionClick('delete');
            }}
            cursor="pointer"
          >
            <TrashSimple color="white" size="16" />
            <Text fontSize="8px" pt={1} fontWeight="normal" color="white">
              Delete
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SourceTagNode;
