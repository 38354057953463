import React, { ReactElement, useState } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { Info, Circle } from 'phosphor-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { colors } from '@/utils/colors';
import { ParameterObject } from './types';

const Values = ({
  text,
  received,
  values,
}: {
  text: string;
  received: boolean;
  values?: ParameterObject;
}): ReactElement => {
  const parameters = values?.data?.filter(item => item.set === received) || [];
  return (
    <>
      <Flex alignItems="center" color="gray.600" fontWeight="light" fontSize="sm" mx={4} mt={4}>
        <Box color={received ? 'green.600' : 'gray.600'}>
          <Circle size={8} weight="fill" />
        </Box>
        <Text ml={2}>{text}</Text>
      </Flex>
      {parameters?.length > 0 ? (
        parameters.map(parameter => (
          <Flex
            key={parameter?.key}
            color="gray.800"
            justifyContent="space-between"
            alignItems="center"
            fontSize="xs"
            my={1}
            py={2}
            px={4}
            _odd={{ bg: 'gray.50' }}
          >
            <Box key={parameter?.key}>{parameter?.displayName}</Box>
            {parameter?.key && (
              <Box px={2} py={1} bg="gray.200" borderRadius={4} fontWeight="light">
                {parameter.key}
              </Box>
            )}
          </Flex>
        ))
      ) : (
        <Box fontSize="xs" color="gray.500" textAlign="center" fontWeight="light" p={4}>
          No Values
        </Box>
      )}
    </>
  );
};

const AwaitingParameters = ({ awaitDetails }: { awaitDetails?: ParameterObject }): ReactElement => (
  <Flex direction="column">
    <Values text="Awaiting for Values" received={false} values={awaitDetails} />
    <Values text=" Received Values" received values={awaitDetails} />
  </Flex>
);

const AwaitStatus = ({ awaitData }: { awaitData?: ParameterObject }): ReactElement => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setShowTooltip(false) });
  return (
    <Box ref={ref}>
      <Popover isOpen={showTooltip}>
        <PopoverTrigger>
          <Box ml={2} onClick={() => setShowTooltip(!showTooltip)}>
            <Info weight="bold" size={16} color={colors.gray[600]} cursor="pointer" />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent border="none" _focus={{ boxShadow: 'none', outline: 'none' }}>
            <PopoverArrow bg="white" />
            <PopoverBody
              maxH="300px"
              overflow="auto"
              bg="white"
              boxShadow="0px 4px 10px rgba(17, 25, 99, 0.12)"
              borderRadius="lg"
              p={0}
            >
              <AwaitingParameters awaitDetails={awaitData} />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default AwaitStatus;
