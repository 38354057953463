import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

import { Service, GetServicesResponse, Output, Operator } from '../types';

export const useGetServices = (refetch: boolean): UseQueryResult<Service[], AxiosError> => {
  return useQuery('suppliers', getServices(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: refetch,
    select: data => {
      const servicesData: Service[] =
        data.suppliers?.map((item: Service) => ({
          ...item,
          label: item?.displayName,
          value: item?.id,
          outputParameters: item.outputParameters?.map((params: Output) => ({
            ...params,
            label: params.displayName,
            value: params.key,
            operators: params?.operators?.map((op: Operator) => ({
              ...op,
              label: op.key,
            })),
          })),
        })) ?? [];
      const services =
        process?.env?.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development' ? [...servicesData] : servicesData;
      return services?.sort((serviceA, serviceB) => {
        if (serviceA.displayName && serviceB.displayName) return serviceA.displayName > serviceB.displayName ? 1 : -1;
        return 1;
      });
    },
  });
};

const getServices = () => {
  return async () => {
    const url = window.location.href.includes('stg1') ? `${process.env.REACT_APP_STG1_URL}/suppliers` : '/suppliers';
    const res = await bureauPlatformApi.get<GetServicesResponse>(url);
    return res.data;
  };
};

export default useGetServices;
