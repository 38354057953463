import React, { ReactElement } from 'react';
import { Cell } from 'react-table';
import { Text, Box } from '@chakra-ui/react';

export const GrayTagCell = ({ value }: Cell): ReactElement => {
  return (
    <Box px={2} py={1} bg="gray.200" borderRadius="sm" w="fit-content">
      <Text fontSize="xs" fontWeight="light">
        {value}
      </Text>
    </Box>
  );
};
