import { atom } from 'recoil';
import { ColumnV2 } from '../types';

export type SelectedNodeType = {
  id?: string;
  type: string;
  columns: ColumnV2[];
};

export const SelectedNodeAtom = atom({
  key: 'selected-node',
  default: {} as SelectedNodeType,
});
