import React from 'react';
import { getSmoothStepPath, getMarkerEnd, EdgeProps, ArrowHeadType } from 'react-flow-renderer';
import { Icon, Flex } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { PlusCircle } from 'phosphor-react';

import { NewEdgeButtonNode } from '../states/workflowChart';
import './workflow-chart/styles.css';
import { renderDataTestId } from '../helpers';

const foreignObjectWidth = 22;
const foreignObjectHeight = 24;
const firstAddNodeX = 244;

const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType = ArrowHeadType.Arrow,
  markerEndId,
}: EdgeProps): React.ReactElement => {
  const setNewEdgeButtonNode = useSetRecoilState(NewEdgeButtonNode);

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const onEdgeClick = (evt: React.MouseEvent): void => {
    setNewEdgeButtonNode({ sourceX, insertColumnId: data?.insertColId });
  };

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={foreignObjectWidth}
        height={foreignObjectHeight}
        x={firstAddNodeX + (data.insertColId - 1) * 300}
        y={40} // as this button is at a fixed vertical distance
        data-test-id={renderDataTestId(data, 'buttonEdge', 'plusButton')}
      >
        <Flex bg="white" height="20px" borderRadius={10} alignItems="center" justifyContent="center" mt="2px">
          <button
            type="button"
            onClick={event => {
              onEdgeClick(event);
            }}
          >
            <Icon w={6} h={6} color="#5A75F0" _hover={{ color: '#233CAA' }} as={PlusCircle} weight="fill" />
          </button>
        </Flex>
      </foreignObject>
    </>
  );
};

export default ButtonEdge;
