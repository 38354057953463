import { Box, Image } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

function Header(): ReactElement {
  const logo = window.location.href.includes('enformion') ? '/assets/Enformion_LOGO.png' : '/assets/bureau-logo.svg';
  return (
    <Box position="fixed">
      <Image src={logo} w="32" ml="16" mt="12" p="1.5" />
      <Image src="/assets/top_right.svg" position="fixed" right="-20" top="-12" />
    </Box>
  );
}

export default Header;
