import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

import { UpdateWorkflowData } from '../types';

const useUpdateWorkflow = (
  options?: UseMutationOptions<UpdateWorkflowData, AxiosError, UpdateWorkflowData>,
): UseMutationResult<UpdateWorkflowData, AxiosError, UpdateWorkflowData> => {
  return useMutation(updateWorkflow, options);
};

const updateWorkflow = async (data: UpdateWorkflowData): Promise<UpdateWorkflowData> => {
  const { data: response } = await bureauPlatformApi.patch<UpdateWorkflowData>(`/workflows/${data.id}`, data.workflow);
  return response || data;
};

export default useUpdateWorkflow;
