/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Button } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Globe } from 'phosphor-react';
import { SelectedNodeAtom } from '../states/selectedNodeAtom';

const QuickLinkButton = ({
  openConfigureModal,
  workflowId,
}: {
  openConfigureModal: () => void;
  workflowId: string;
}): ReactElement => {
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);

  return (
    <Button
      type="submit"
      borderRadius="lg"
      fontWeight="normal"
      gridGap="2"
      bg="white.50"
      borderWidth={1}
      borderColor="gray.200"
      color="gray.800"
      id="integration-workflow"
      onClick={() => {
        openConfigureModal();
        setSelectedNode({ id: 'quicklink', type: 'quicklink', columns: [] });
      }}
      isDisabled={!workflowId}
    >
      <Globe size={18} weight="light" color="black" /> Quicklink
    </Button>
  );
};

export default QuickLinkButton;
