import React from 'react';
import { Cell } from 'react-table';
import { Flex, Text } from '@chakra-ui/react';

export const BlueColorTextCell = ({ value }: Cell): React.ReactElement => (
  <Flex>
    <Text color="blue.500" textDecoration="underline" fontSize="xs" fontWeight="bold">
      {value}
    </Text>
  </Flex>
);
