import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { OptionType } from '@bureau/components';
import useGetInputAttributes from '../queries/useGetInputAttributes';
import { ServicesToTheLeft, TagsToTheLeft } from '../states/workflowChart';
import { Service, Output, FunctionItem } from '../types';

const useFunctionConditionHandler = (): {
  handleDropdownData: (condition: FunctionItem) => void;
  getIds: (key: string) => OptionType[];
  getTypes: (key: string) => OptionType[];
} => {
  const [functionAttributes, setFunctionAttributes] = useState<Output[]>([]);

  const { data: inputAttributes } = useGetInputAttributes();

  const servicesToLeft = useRecoilValue(ServicesToTheLeft);
  const tagsToLeft = useRecoilValue(TagsToTheLeft);

  const handleDropdownData = (condition: FunctionItem): void => {
    switch (condition?.kind) {
      case 'source': {
        setFunctionServiceRelatedData(condition);
        break;
      }
      default: {
        break;
      }
    }
  };

  const setFunctionServiceRelatedData = (condition: FunctionItem): void => {
    const services = servicesToLeft;
    if (condition?.id) {
      const attrs = services?.find((ser: Service) => ser.id === condition.id);
      setFunctionAttributes(attrs?.outputParameters || []);
    }
  };

  const getIds = (key: string): OptionType[] => {
    switch (key) {
      case 'source': {
        return (servicesToLeft as OptionType[]) || [];
      }
      default: {
        return tagsToLeft || [];
      }
    }
  };

  const getTypes = (key: string): OptionType[] => {
    switch (key) {
      case 'source': {
        return (functionAttributes as OptionType[]) || [];
      }
      default: {
        return (inputAttributes as OptionType[]) || [];
      }
    }
  };

  return {
    getIds,
    handleDropdownData,
    getTypes,
  };
};

export default useFunctionConditionHandler;
