import { ChakraProvider } from '@chakra-ui/react';
import React, { ReactElement, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import AuthenticationProvider from './authentication/AuthenticationProvider';
import { AppRoutes } from './routes';
import { getTheme } from './utils/theme';
import { initGA } from './analytics';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const App = (): ReactElement => {
  const theme = getTheme();

  useEffect(() => {
    if (process.env.REACT_APP_GA_TRACKING_ID) initGA(process.env.REACT_APP_GA_TRACKING_ID);
  }, []);

  return (
    <React.StrictMode>
      <RecoilRoot>
        <AuthenticationProvider>
          <ChakraProvider theme={theme}>
            <AppRoutes />
          </ChakraProvider>
        </AuthenticationProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default Sentry.withProfiler(App);
