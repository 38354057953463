import React, { useMemo, useEffect } from 'react';
import { Node } from 'react-flow-renderer';
import { Icon, Flex, Box } from '@chakra-ui/react';
import { PlusCircle } from 'phosphor-react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { NewNodePosition, SelectedPosition } from '../states/workflowChart';
import AddNodePanel from './AddNodePanel';
import { renderDataTestId } from '../helpers/utilityMethods';

const AddToColumnNode = ({ data }: Node): React.ReactElement => {
  const setNewNodePosition = useSetRecoilState(NewNodePosition);
  const selectedPosition = useRecoilValue(SelectedPosition);

  const getRightOffset = useMemo((): string => {
    if (data.lastColumn) {
      if (data?.columnPosition === 1 && data.nodePosition === 0) return '295px';
      return '360px';
    }
    return '8px';
  }, [data]);

  const addStep = (type: string): void => {
    setNewNodePosition({
      type,
      columnPosition: data.columnPosition,
      nodePosition: data.nodePosition,
      newColumn: data?.lastColumn,
    });
  };

  useEffect(() => {
    if (
      selectedPosition.columnPosition === data?.columnPosition &&
      selectedPosition.nodePosition === data?.nodePosition
    ) {
      const element = document.getElementById('steps-menu');
      element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition]);

  return (
    <Flex
      alignItems={!data?.lastColumn ? 'center' : 'start'}
      w={data?.lastColumn ? '40px' : '24px'}
      pt="10"
      direction="column"
      data-test-id={renderDataTestId(data, 'newColumnNode', 'addNode')}
    >
      <button type="button">
        <Icon w={6} h={6} color="#5A75F0" _hover={{ color: '#233CAA' }} as={PlusCircle} weight="fill" />
      </button>
      {selectedPosition.columnPosition === data?.columnPosition &&
        selectedPosition.nodePosition === data?.nodePosition && (
          <Box
            id="steps-menu"
            className="steps-menu"
            bg="white"
            mt={4}
            h="425px"
            w="730px"
            borderRadius="lg"
            position="relative"
            right={getRightOffset}
            _after={{
              content: '""',
              position: 'absolute',
              bottom: '100%',
              left: data?.columnPosition === 1 && data.nodePosition === 0 ? '41%' : '50%',
              borderBottom: 'solid 12px #E8EBF1',
              borderLeft: 'solid 8px transparent',
              borderRight: 'solid 8px transparent',
            }}
          >
            <AddNodePanel addStep={addStep} />
          </Box>
        )}
    </Flex>
  );
};

export default AddToColumnNode;
