import { AuthDetails } from '@/types/common';
import { bureauPlatformApi } from '@/utils/api';

export function getAuthDetails(): AuthDetails {
  const authLocalData = window.localStorage.getItem('authDetails');
  const authDetails = authLocalData !== null ? JSON.parse(authLocalData) : ({} as AuthDetails);
  if (authDetails.region === 'us') {
    bureauPlatformApi.defaults.baseURL = process.env.REACT_APP_US_ENDPOINT;
  }
  return authDetails;
}
