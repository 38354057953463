import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Elements } from 'react-flow-renderer';

import { WorkflowJson } from '../states/workflowChart';
import { parseWorkflowJson } from '../helpers';
import { Workflow } from '../types';

export const useWorkflowJsonHandler = (): {
  nodes: Elements;
  json: Workflow;
  setNodes: (nodes: Elements) => void;
  setJson: (json: Workflow) => void;
} => {
  const workflowJson = useRecoilValue(WorkflowJson);

  const [cloneJson, setCloneJson] = useState(workflowJson);
  const [cloneNodes, setCloneNodes] = useState(parseWorkflowJson(workflowJson));

  useEffect(() => {
    setCloneJson(workflowJson);
    setCloneNodes(parseWorkflowJson(workflowJson));
  }, [workflowJson]);
  return {
    nodes: cloneNodes,
    json: cloneJson,
    setNodes: setCloneNodes,
    setJson: setCloneJson,
  };
};

export default useWorkflowJsonHandler;
