export const prettifyJsonString = (jsonString: string): string => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, '\t');
  } catch (err) {
    return jsonString;
  }
};

export const isJSON = (string: string | ArrayBuffer): boolean => {
  try {
    return JSON.parse(string as string) && !!string;
  } catch (e) {
    return false;
  }
};
