import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { WebhooksApiResponse } from '../types';

export const useGetWebhookList = (): UseQueryResult<WebhooksApiResponse, AxiosError> => {
  return useQuery(['webhooks'], getWebhooks(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const getWebhooks = () => {
  return async () => {
    const res = await bureauPlatformApi.get<WebhooksApiResponse>(`/platform/webhooks`);
    return res?.data;
  };
};
