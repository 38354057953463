import { SortBy, SortOrder } from '@/screens/bulk-verification/types';
import { FeatureFlagDetail } from '@/types/common';
import { AxiosError } from 'axios';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Cell, CellValue, Column, ColumnInstance, Row } from 'react-table';

export interface PaginatedTableColumn {
  Header: string;
  accessor: string;
  className?: string;
  Cell?: React.ElementType;
}

export enum StatusValue {
  REJECTED = 'Rejected',
  ERROR = 'Error',
  MANUAL_REVIEW = 'Manual Review',
  MANUAL_REVIEW_RESPONSE = 'manual_review',
  SERVER_ERROR = 'Internal Server Error',
  UNKNOWN = 'Unknown',
  SUCCESS = 'Success',
  ACCEPTED = 'Accepted',
  CANCELLED = 'Cancelled',
  DONE = 'Done',
  OK = 'OK',
  UPLOADING = 'Uploading',
  IN_QUEUE = 'In Queue',
  IN_QUEUE_RESPONSE = 'IN_QUEUE',
  PROCESSING = 'Processing',
  AWAITING = 'AWAITING',
  CANCELLING = 'Cancelling',
  PAUSED = 'Paused',
  FAILED = 'Failed',
  PENDING_DELIVERY_RESPONSE = 'Pending_Delivery',
  PENDING_DELIVERY = 'Pending Delivery',
}

export enum UserRoles {
  ADMIN = 'admin',
  OWNER = 'owner',
  MEMBER = 'member',
  SALES = 'sales',
  UNASSIGNED = 'unassigned',
}

export interface PaginatedTableProps {
  data: readonly object[];
  columns: readonly Column<object>[];
  onCellClick?: (e: React.MouseEvent, cell: Cell, index: number) => void;
  onRowClick?: (e: React.MouseEvent, row: Row, index: number) => void;
  setSortField?:
    | Dispatch<SetStateAction<{ field: string; order: string }>>
    | Dispatch<SetStateAction<{ sortBy: SortBy; sortOrder: SortOrder }>>;
  error?: AxiosError | null;
  isLoading?: boolean;
  setOffset?: (offset: number) => void;
  offset?: number;
  count?: number;
  limit?: string;
  setLimit?: (limit: string) => void;
  title?: string;
  customEmptyTableBody?: ReactElement;
  showCustomEmptyTableBody?: boolean;
  setSelectedList?: (val: Row<object>[]) => void;
  isManualSort?: boolean;
  hidePagination?: boolean;
  isDeviceDashboard?: boolean;
  deviceDataCount?: number;
  hiddenColumns?: string[];
}

export interface ActionButtonColumnProps {
  getProps?: {
    onEditButtonClick?: (e: Row) => void;
    onDeleteButtonClick?: (e: Row) => void;
    onActionIconClick?: (e: Row, type: string) => void;
    onApiKeyToggle?: (val: string) => void;
    onWebhookToggle?: (val: string) => void;
    refetch?: () => void;
  };
  permissions?: PaginatedTableUserPermissions;
  options?: Action[];
  type?: string;
  getFeatureFlagPageWise?(access: keyof FeatureFlagDetail): boolean;
}

export interface ActionButtonTableProps {
  row?: Row;
  column: ActionButtonColumnProps;
  value?: string;
}

interface PaginatedTableUserPermissions {
  edit: boolean;
  delete: boolean;
}

export interface PaginatedTableColumnsWithProps extends ActionButtonColumnProps {
  Header: string;
  Cell?: CellValue;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: string | ((data: any) => any);
  disableSortBy?: boolean;
  permissions?: PaginatedTableUserPermissions;
  options?: Action[];
  id: string;
}

export interface Action {
  name: string;
  type: string;
}

export interface Original {
  original: {
    entityId?: string;
    workflowId?: string;
    entityType?: string;
    archived?: boolean;
  };
}

export interface RowState {
  hover?: boolean;
  cellState?: {};
}

export enum UserInvitationStatus {
  INVITED = 'invited',
  ACCEPTED = 'accepted',
}

export interface BulkQueryOriginal {
  original: {
    applicationId?: string;
  };
}

export interface AwaitParameterResponse {
  awaits?: { Awaits?: { response?: ParameterObject } };
}

export interface ParameterObject {
  data: {
    displayName: string;
    key: string;
    set: boolean;
  }[];
}

export interface StatusColumnProps extends ColumnInstance {
  type: string;
}
