import { Flex } from '@chakra-ui/react';
import React, { ReactChild } from 'react';

const Sider = ({ children }: { children: ReactChild }): React.ReactElement => {
  return (
    <Flex as="aside" h="full">
      {children}
    </Flex>
  );
};

export default Sider;
