import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { CapabilityDataTypes } from '../../types';

const useCapabilityUpdation = (
  options?: UseMutationOptions<CapabilityDataTypes, AxiosError, CapabilityDataTypes>,
): UseMutationResult<CapabilityDataTypes, AxiosError, CapabilityDataTypes> => {
  return useMutation(updateCapability, options);
};

const updateCapability = async (capabilityDetails: CapabilityDataTypes): Promise<CapabilityDataTypes> => {
  const { id, object } = capabilityDetails;
  // remove start node
  const objectWithoutStartNode = object
    ? {
        ...object,
        columns: object.columns.filter(column => column.id.indexOf('column_0') === -1),
      }
    : null;

  const payloadData = {
    ...capabilityDetails,
    object: objectWithoutStartNode?.columns.length ? objectWithoutStartNode : null,
  };

  const { data: response } = await bureauPlatformApi.patch<CapabilityDataTypes>(`/capabilities/${id}`, payloadData);
  return response;
};

export default useCapabilityUpdation;
