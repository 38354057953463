import { getAuthDetails } from '@/helpers/getAuthDetails';
import { getBaseUrl, bureauPlatformApi } from '@/utils/api';
import { WorkflowInputParams } from '../../workflow-creation/types';

const parseParameter = (parameterData?: WorkflowInputParams): { [key: string]: string } => {
  let response = {};
  parameterData?.inputParameters?.forEach((parameter: { key: string }) => {
    if (parameter?.key) {
      response = { ...response, [parameter.key]: '' };
    }
  });
  return response;
};

export const getCurl = (workflowId = '', parameterData?: WorkflowInputParams): string => {
  const data = parseParameter(parameterData);
  const token = bureauPlatformApi.defaults.headers.common.Authorization;
  const authDetails = getAuthDetails();
  const url = authDetails.region === 'us' ? process.env.REACT_APP_US_ENDPOINT : getBaseUrl();
  return `curl --location --request POST '${url}/transactions' \
  --header 'Authorization: ${token}' \
  --header 'Content-Type: application/json' \
  --header 'x-bureau-auth-org-id: ${bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID']}' \
  --data-raw '{
      "workflowId": "${workflowId}",
      "data": ${JSON.stringify(data)}
  }'`;
};
