const getTimeString = (interval: number, timeUnit: string): string => {
  const time = Math.floor(interval);
  return `${time} ${time > 1 ? `${timeUnit}s` : timeUnit} `;
};

export const timeSince = (date: number): string => {
  const seconds: number = Math.floor((new Date().getTime() - date) / 1000);
  let interval = seconds / 31536000; // 31536000 is seconds in an year

  if (interval > 1) {
    return getTimeString(interval, 'year');
  }
  interval = seconds / 2592000; // 2592000 is seconds in a month
  if (interval > 1) {
    return getTimeString(interval, 'month');
  }
  interval = seconds / 86400; // 86400 is seconds in a day
  if (interval > 1) {
    return getTimeString(interval, 'day');
  }
  interval = seconds / 3600; // 3600 is seconds in an hour
  if (interval > 1) {
    return getTimeString(interval, 'hour');
  }
  interval = seconds / 60;
  if (interval > 1) {
    return getTimeString(interval, 'minute');
  }
  return getTimeString(interval, 'second');
};
