import { atom } from 'recoil';
import { ServiceDescriptions, ContentfulService, ContentfulConfigureService } from '../types';

export const SupplierDescriptions = atom({
  key: 'supplierDescriptions',
  default: {} as ServiceDescriptions,
});

export const ServicesDescriptions = atom({
  key: 'servicesDescriptions',
  default: [] as ContentfulService[],
});

export const ConfigureServicesDescriptions = atom({
  key: 'configureServicesDescriptions',
  default: [] as ContentfulConfigureService[],
});
