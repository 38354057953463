// import React, { ReactElement } from 'react';
// import { colors } from '@/utils/colors';
// import { CheckCircle, Circle, CircleNotch, Clock, StopCircle, XCircle } from 'phosphor-react';
import { StatusValue } from './types';

export const getOutcome = (statusText?: string): string => {
  switch (statusText?.toLowerCase()) {
    case StatusValue.ERROR.toLowerCase():
    case StatusValue.SERVER_ERROR.toLowerCase():
      return StatusValue.ERROR;
    case StatusValue.MANUAL_REVIEW_RESPONSE.toLowerCase():
      return StatusValue.MANUAL_REVIEW;
    case StatusValue.REJECTED.toLowerCase():
      return StatusValue.REJECTED;
    case StatusValue.OK.toLowerCase():
      return StatusValue.SUCCESS;
    case StatusValue.ACCEPTED.toLowerCase():
      return StatusValue.ACCEPTED;
    case StatusValue.CANCELLED.toLowerCase():
      return StatusValue.CANCELLED;
    case StatusValue.DONE.toLowerCase():
      return StatusValue.DONE;
    case StatusValue.UPLOADING.toLowerCase():
      return StatusValue.UPLOADING;
    case StatusValue.IN_QUEUE_RESPONSE.toLowerCase():
      return StatusValue.IN_QUEUE;
    case StatusValue.PROCESSING.toLowerCase():
      return StatusValue.PROCESSING;
    case StatusValue.AWAITING.toLowerCase():
      return StatusValue.AWAITING;
    case StatusValue.CANCELLING.toLowerCase():
      return StatusValue.CANCELLING;
    case StatusValue.PAUSED.toLowerCase():
      return StatusValue.PAUSED;
    case StatusValue.FAILED.toLowerCase():
      return StatusValue.FAILED;
    case StatusValue.PENDING_DELIVERY_RESPONSE.toLowerCase():
      return StatusValue.PENDING_DELIVERY;
    default:
      return StatusValue.UNKNOWN;
  }
};
