import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Cell } from 'react-table';
import { CaretRight } from 'phosphor-react';
import { Button } from '@chakra-ui/react';

export const RedirectCellWithRow = ({ value }: Cell): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();

  const redirectHandler = (): void => {
    history.push(`${location.pathname}/${value.id}`);
  };

  return (
    <Button color="gray.400" variant="link" p="0" fontSize="xs" onClick={redirectHandler}>
      <CaretRight size={20} />
    </Button>
  );
};
