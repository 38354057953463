/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { RetryWebhookRequest } from '../types';

export const useretryWebhook = (
  options?: UseMutationOptions<RetryWebhookRequest, AxiosError, {}>,
): UseMutationResult<RetryWebhookRequest, AxiosError, RetryWebhookRequest> => useMutation(retryWebhook, options);

const retryWebhook = async (webhookDetails: RetryWebhookRequest): Promise<RetryWebhookRequest> => {
  const { webhookId, messageId, ...rest } = webhookDetails;
  const res = await bureauPlatformApi.post(`/platform/webhooks/${webhookId}/retry/${messageId}`, rest);
  return res.data;
};
