import { SortField } from '@/types/common';
import { WorkflowFilterField } from '../types';

export const getSearchTerm = (searchTerm?: string): string => {
  const searchItem = {
    value: searchTerm,
    fields: [
      { name: 'name' },
      { name: 'workflowId' },
      { name: 'entityId' },
      { name: 'transactionId' },
      { name: 'workflowName' },
      { name: 'entityName' },
    ],
  };
  return searchTerm ? JSON.stringify(searchItem) : JSON.stringify({});
};

export const getSortField = (sortField?: SortField): string => {
  const sortValue = {
    field: sortField?.field || 'updatedAt',
    order: sortField?.order || 'desc',
  };
  return `${JSON.stringify([sortValue])}`;
};

export const getFilterField = (filterFields?: WorkflowFilterField): string => {
  const { createdAt } = filterFields || { createdAt: [] };
  let date;
  const createdDateCount = createdAt?.length || 0;
  if (createdDateCount >= 1) {
    date = {
      value: createdAt?.[0],
      operator: 'between',
      value2: createdAt?.[1],
    };
  }
  const isOutcomeFilter = filterFields?.outcome && filterFields?.outcome.length;
  const allOutcome = filterFields?.outcome?.includes('*');
  const filterValues = {
    ...(isOutcomeFilter && !allOutcome && { active: filterFields?.outcome }),
    ...(date && createdAt?.[0] !== createdAt?.[1] && { createdAt: date }),
  };
  return JSON.stringify(filterValues);
};
