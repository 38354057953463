/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from './confirmation-modal/ConfirmationModal';

export const RouterPrompt = ({
  when,
  title,
  body,
  okText,
  cancelText,
}: {
  when: boolean;
  title: string;
  body: string;
  okText: string;
  cancelText: string;
}): ReactElement | null => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }
    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(() => {
    history.block(() => {});
    history.push(currentPath);
  }, [history, currentPath]);

  const handleCancel = useCallback(() => {
    setShowPrompt(false);
  }, []);

  return showPrompt ? (
    <ConfirmationModal
      headerText={title}
      bodyText={body}
      onClose={handleCancel}
      onCloseButtonClick={handleCancel}
      onCancel={handleOK}
      primaryButtonText={okText}
      secondaryButtonText={cancelText}
    />
  ) : null;
};
