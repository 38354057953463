import { SortField } from '@/types/common';
import { BlocklistFilterField } from '../types';

export const getSearchTerm = (searchTerm?: string): string => {
  const searchItem = searchTerm
    ? {
        value: searchTerm,
        fields: [{ name: 'name' }, { name: 'attributeName' }],
      }
    : {};
  return JSON.stringify(searchItem);
};

export const getSortField = (sortField?: SortField): string => {
  const sortValue = {
    field: sortField?.field || 'createdAt',
    order: sortField?.order || 'desc',
  };
  return `${JSON.stringify([sortValue])}`;
};

export const getFilterField = (filterFields?: BlocklistFilterField): string => {
  const { createdAt } = filterFields || { createdAt: [] };
  let date;
  const createdDateCount = createdAt?.length || 0;
  if (createdDateCount >= 1) {
    date = {
      value: createdAt?.[0],
      operator: 'between',
      value2: createdAt?.[1],
    };
  } else {
    date = {
      value: new Date().toISOString(),
      operator: 'lte',
    };
  }

  const isTypeFilter = filterFields?.outcome && filterFields?.outcome.length;
  const allType = filterFields?.outcome?.includes('*');
  const filterValues = {
    ...(isTypeFilter && !allType && { type: filterFields?.outcome }),
    createdAt: date,
  };
  return JSON.stringify(filterValues);
};
