import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

import { Config } from '../types';

const useSaveConfig = (
  options?: UseMutationOptions<Config, AxiosError, Config>,
): UseMutationResult<Config, AxiosError, Config> => {
  return useMutation(saveConfig, options);
};

const saveConfig = async (data: Config): Promise<Config> => {
  const { data: response } = await bureauPlatformApi.post<Config>(`/workflows/hostedconfig`, data);
  return response || data;
};

export default useSaveConfig;
