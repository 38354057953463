import React, { useEffect, useState, ReactElement, SetStateAction, Dispatch } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { X, Circle } from 'phosphor-react';
import { Resizable } from 're-resizable';
import { useSetRecoilState } from 'recoil';
import WorkflowProperties from '@/screens/workflows/workflow-creation/components/workflow-properties/WorkflowProperties';
import { AndOrCondition } from '@/screens/workflows/workflow-creation/components/workflow-properties/types';
import { ConditionsData, NodeKind, ConditionItem, ContentfulCustomFunction } from '../types';
import { initialCondition, newConditionItems } from '../constants';
import useGetContentfulCustomFunctions from '../queries/useGetContentfulCustomFunctions';
import { ContentfulCustomFunctions } from '../states/contentfulCustomFunctions';

const WorkflowConditions = ({
  details,
  setFormattedCondition,
  formattedCondition,
  onClose,
  onSave,
}: {
  details: ConditionsData;
  onClose: () => void;
  formattedCondition?: ConditionItem;
  setFormattedCondition: Dispatch<SetStateAction<ConditionItem | undefined>>;
  onSave: () => void;
}): ReactElement => {
  const [conditionGroup, setConditionGroup] = useState<AndOrCondition>();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const setContentfulCustomFunctions = useSetRecoilState(ContentfulCustomFunctions);

  const { getContentfulCustomFunctions } = useGetContentfulCustomFunctions();

  const onItemDelete = (arrayIndex: number): void => {
    setConditionGroup(undefined);
    setFormattedCondition(initialCondition);
  };

  useEffect(() => {
    getContentfulCustomFunctions()
      .then(response => setContentfulCustomFunctions(response?.items as ContentfulCustomFunction[]))
      // eslint-disable-next-line no-console
      .catch(() => console.log('Custom functions not found'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formattedCondition?.type === AndOrCondition.AND) {
      setConditionGroup(AndOrCondition.AND);
    } else if (formattedCondition?.type === AndOrCondition.OR) {
      setConditionGroup(AndOrCondition.OR);
    } else setConditionGroup(undefined);
  }, [formattedCondition]);

  const getEndStateText = (type?: string): string => {
    switch (type) {
      case NodeKind.accept:
        return 'Accept';
      case NodeKind.manualReview:
        return 'Manual Review';
      default:
        return 'Reject';
    }
  };

  const onGroupClick = (groupType: AndOrCondition): void => {
    setConditionGroup(groupType);
    setFormattedCondition({
      items: newConditionItems,
      type: groupType,
    });
  };

  const style = {
    borderTop: 'solid 1px #ddd',
    background: 'white',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: '84px',
    flexDirection: 'column',
    bg: 'white',
    overflowX: 'clip',
    zIndex: 10,
  } as const;

  return (
    <Resizable
      style={style}
      defaultSize={{
        width: 'unset',
        height: '65%',
      }}
      minWidth="calc(100vw - 84px)"
      maxHeight="90%"
      className="condition-block"
      enable={{
        top: true,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <Flex justify="center" align="center" mt="2">
        <Box bg="gray.200" h=".4rem" w="7rem" borderRadius="md" />
      </Flex>
      <Flex mt={4} px={6} zIndex={1} justifyContent="flex-end" width="full" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text fontWeight={500} fontSize="md">
            Condition
          </Text>
        </Flex>
        {details?.type === NodeKind.tag || details?.type === NodeKind.service ? (
          <Box bg="blue.100" py={2} color="blue.500" fontSize="xs" px={3} mr={4} borderRadius="md">
            <Flex fontWeight={300} pr={6} alignItems="center">
              <Circle size={10} weight="fill" />
              <Box ml={2}>{details?.type === NodeKind.tag ? 'Tag' : 'Run'}</Box>
              <Box ml="2">{details?.name}</Box>
            </Flex>
          </Box>
        ) : (
          <Box fontWeight={300} bg="blue.100" color="blue.500" fontSize="xs" py={2} mr={4} px={3} borderRadius="md">
            <Flex alignItems="center">
              <Circle size={10} weight="fill" />
              <Box ml={2}>End flow with {getEndStateText(details.type)}</Box>
            </Flex>
          </Box>
        )}
        {showSaveButton && (
          <Button
            mr={2}
            h={8}
            fontSize="xs"
            fontWeight="medium"
            bg="blue.500"
            _hover={{ bg: 'blue.600' }}
            color="white"
            onClick={onSave}
          >
            Save
          </Button>
        )}
        <Flex bg="white" borderRadius="full" color="gray.600" zIndex={1}>
          <X size="20px" onClick={onClose} cursor="pointer" />
        </Flex>
      </Flex>
      <Box overflowY="auto" className="condition-block" h="88%">
        <Box w="full" px={6} pb={3}>
          {conditionGroup === undefined && (
            <Flex mt={6}>
              <Box marginRight={10} onClick={() => onGroupClick(AndOrCondition.AND)} cursor="pointer">
                <Text fontSize="md" color="purple.100" fontWeight={300} alignSelf="center" textAlign="center">
                  AND Group
                </Text>
              </Box>
              <Box onClick={() => onGroupClick(AndOrCondition.OR)} cursor="pointer">
                <Text fontSize="md" color="terracotta.500" fontWeight={300} alignSelf="center" textAlign="center">
                  OR Group
                </Text>
              </Box>
            </Flex>
          )}
        </Box>
        {conditionGroup !== undefined && (
          <Flex width="full" position="relative">
            <WorkflowProperties
              formattedCondition={formattedCondition}
              setFormattedCondition={(data: ConditionItem, position: number) => {
                setFormattedCondition(data);
                setShowSaveButton(true);
              }}
              group={conditionGroup}
              index={0}
              onMainItemDelete={(pos: number) => onItemDelete(pos)}
              columnPosition={details?.columnPosition || 0}
              innerGroupColorIndex
            />
          </Flex>
        )}
      </Box>
    </Resizable>
  );
};

export default WorkflowConditions;
