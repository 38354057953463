import { Button, Flex, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Buildings } from 'phosphor-react';
import { CreateOrgPropTypes, OnboardingInputData } from '../types';

function CreateOrganization({ handleNextPage, selectionData, setSelectionData }: CreateOrgPropTypes): ReactElement {
  const OrgCreateSchema = Yup.object().shape({
    displayName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      displayName: selectionData.displayName ?? '',
    },
    validationSchema: OrgCreateSchema,
    onSubmit: orgName => {
      setSelectionData((data: OnboardingInputData) => ({ ...data, ...orgName }));
      handleNextPage();
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
          <VStack spacing={4}>
            <Flex direction="column" align="center">
              <Text fontSize="3xl" fontWeight="bold" padding="5px">
                Create Organisation
              </Text>
              <Text fontSize="sm" padding="5px">
                Enter a name for organisation
              </Text>
            </Flex>
            <Flex marginTop={8} w="full">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Buildings />
                </InputLeftElement>
                <Input
                  type="text"
                  name="displayName"
                  placeholder="My Organisation"
                  onChange={formik.handleChange}
                  value={formik.values.displayName}
                />
              </InputGroup>
            </Flex>
            <Flex marginTop={4}>
              <Button
                display="flex"
                fontSize="sm"
                borderRadius="lg"
                fontWeight="light"
                height="44px"
                _hover={{ opacity: '0.85' }}
                mr="4"
                mt="4"
                type="submit"
                isDisabled={formik.isSubmitting || !formik.isValid}
                colorScheme="blue"
              >
                Add Organisation Info
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </form>
    </>
  );
}

export default CreateOrganization;
