/* eslint-disable react/no-children-prop */
import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/layout';
import LogoCustomization from './LogoCustomization';
import FontSelector from './FontSelection';
import ColorInput from './ColorInput';

const BrandingCustomization = (): ReactElement => {
  return (
    <Flex direction="column" gridGap="5" maxH="37vh" overflowY="auto" mb="4">
      <LogoCustomization />
      <ColorInput title="Primary Color" path="branding.primaryColor" />
      <ColorInput title="Secondary Color" path="branding.secondaryColor" />
      <FontSelector />
    </Flex>
  );
};

export default BrandingCustomization;
