export const USE_CASE_TOOLTIP_DESCRIPTION =
  'To change the Lifecycle Stage, create a new Workflow and select a different stage.';

export const outcomeOptions = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
];

export const defaultWorkflowName = 'Untitled';

export const refetchDelay = 2000;
