import React, { Dispatch, SetStateAction } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import SearchInput from '@/components/search-input/SearchInput';
import DateSelectBox from '@/components/date-select-box/DateSelectBox';
import { WorkflowFilterField } from '../types';

const WorkflowSearchFilter = ({
  filterFields,
  setSearchTerm,
  setFilterFields,
}: {
  filterFields: WorkflowFilterField;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setFilterFields: Dispatch<SetStateAction<WorkflowFilterField>>;
}): React.ReactElement => {
  const handleDateFilterChange = (startDate: string, endDate: string): void => {
    setFilterFields({ ...filterFields, createdAt: [startDate, endDate] });
  };

  return (
    <Flex
      justifyContent="space-between"
      borderBottom="1px solid"
      px={6}
      py={4}
      borderColor="blue.100"
      alignItems="center"
    >
      <SearchInput setSearchTerm={setSearchTerm} id="search-workflows" />
      <Flex alignItems="center">
        <DateSelectBox
          selectedDates={filterFields.createdAt || []}
          onChange={handleDateFilterChange}
          menuHeading="Select time"
        >
          <Text pl={4} m={2} fontWeight={300} color="gray.600" fontSize="xs" id="workflow-date">
            Created Date:
          </Text>
        </DateSelectBox>
        {/* <Text pl={8} fontWeight={300} color="gray.600" fontSize="xs" id="workflow-status">
          Status
        </Text>
        <Dropdown
          value={filterFields.outcome}
          onChange={(field, event) =>
            setFilterFields(
              onMultiValueChange(
                field as OptionType[],
                event as SelectBoxEvent,
                filterFields,
                outcomeOptions,
                'outcome',
              ) as WorkflowFilterField,
            )
          }
          isMulti
          controlWidth="50px"
          placeholder="All"
          options={[selectAllOption, ...outcomeOptions]}
          variant="ghost"
          style={{ border: 'none' }}
          menuHeading="Select status"
        /> */}
      </Flex>
    </Flex>
  );
};

export default WorkflowSearchFilter;
