/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Files, Warning } from 'phosphor-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from '@chakra-ui/button';
import { colors } from '@/utils/colors';
import { SelectedNodeAtom } from './states/selectedNodeAtom';
import { ColumnV2, Conditions, ConditionsType } from './types';
import { ConditionsAtom } from './states/conditionsAtom';
import { WorkflowAtom } from './states/workflowAtom';

const AlertList = (): ReactElement => {
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  return (
    <Flex as="span" flex="1" textAlign="left" direction="column" gridGap="2" p="2">
      {selectedNode.columns.map(column => (
        <Box key={column.id} mb="1">
          <Flex gridGap="2" alignItems="center">
            <Box p="2" bg="white.50" borderRadius="50%">
              <Files size={16} color="#2c303e" weight="thin" />
            </Box>
            <Text fontSize="sm" color="gray.800">
              {column.metadata.kind === 'tag' ? column.nodes[0].name : column.metadata.capabilityName}
            </Text>
            <Flex flexGrow={1} />
          </Flex>
          <AlertItem column={column} />
        </Box>
      ))}
    </Flex>
  );
};

const AlertItem = ({ column }: { column: ColumnV2 }): ReactElement => {
  const resetNode = useResetRecoilState(SelectedNodeAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const setConditions = useSetRecoilState(ConditionsAtom);
  const workflows = useRecoilValue(WorkflowAtom);
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);
  const handleClick = (): void => {
    resetNode();
    resetConditions();
    const columns = workflows.filter(workflow => workflow.metadata.capabilityId === column.metadata.capabilityId);
    const conditionsArray =
      workflows.filter(workflow => workflow.metadata.parentID === column.metadata.capabilityId) || [];
    if (conditionsArray.length) {
      const flattenedArray = conditionsArray.flatMap(condition => condition.nodes);
      const conditionsObject = {
        [ConditionsType.accept]: [],
        [ConditionsType.reject]: [],
        [ConditionsType.manual_review]: [],
      } as Conditions;
      flattenedArray.forEach(arr => {
        if (arr.kind === 'end_accepted' && arr.conditions) {
          const acceptedCondition = arr.conditions;
          conditionsObject[ConditionsType.accept] = acceptedCondition;
        }
        if (arr.kind === 'end_rejected' && arr.conditions) {
          const rejectedCondition = arr.conditions;
          conditionsObject[ConditionsType.reject] = rejectedCondition;
        }
        if (arr.kind === 'end_manual_review' && arr.conditions) {
          const manualReviewCondition = arr.conditions;
          conditionsObject[ConditionsType.manual_review] = manualReviewCondition;
        }
      });
      setConditions(conditionsObject);
    }
    setSelectedNode(() => ({ id: column.metadata.capabilityId, type: 'source', columns }));
  };

  function getInputListText(): string {
    return column.nodes
      .flatMap(node => node.inputs)
      .filter(input => input?.location === '..' && !input.isOptional)
      .flatMap(input => input?.displayName)
      .join(',');
  }

  function getDescription(): string {
    if (column.metadata.kind !== 'tag') {
      return `Configure the input parameters ${getInputListText()} to map the use case`;
    }
    return 'Add and configure branch nodes';
  }

  return (
    <Flex
      border="1px"
      bg="white"
      borderRadius="lg"
      borderColor="gray.200"
      opacity={1}
      my="1"
      p="1"
      alignItems="center"
      justifyContent="space-between"
      mt="3"
    >
      <Flex direction="column" p="2">
        <Flex alignItems="center">
          <Warning size={16} color={colors.orange[500]} weight="fill" />
          <Text ml="2" fontSize="16">
            Configuration is pending
          </Text>
        </Flex>
        <Text fontSize="xs" color="gray.600" noOfLines={[1, 2]} fontWeight="300" maxW="xl">
          {getDescription()}
        </Text>
      </Flex>
      {column.metadata.kind !== 'tag' ? (
        <Button fontWeight="normal" onClick={handleClick}>
          Take action
        </Button>
      ) : null}
    </Flex>
  );
};

export default AlertList;
