/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

export const useToggleWebhook = (
  options?: UseMutationOptions<any, AxiosError, any>,
): UseMutationResult<any, AxiosError, any> => useMutation(toggleWebhook, options);

const toggleWebhook = async (webhookDetails: any): Promise<any> => {
  const res = await bureauPlatformApi.patch(`/platform/webhooks/${webhookDetails?.id}`, webhookDetails);
  return res.data;
};
