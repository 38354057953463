import React, { ReactElement } from 'react';
import { Tag } from '@chakra-ui/react';

function ComingSoonTag({ size }: { size: 'lg' | 'sm' }): ReactElement {
  if (size === 'lg') return <LargeTag />;

  if (size === 'sm') return <SmallTag />;

  return <></>;
}

function LargeTag(): ReactElement {
  return (
    <Tag
      px="3"
      py="1.5"
      borderRadius="full"
      border="1px"
      borderColor="gray.200"
      bg="white.100"
      fontSize="xs"
      fontWeight="300"
      color="gray.500"
    >
      Coming soon
    </Tag>
  );
}

function SmallTag(): ReactElement {
  return (
    <Tag
      px="2"
      py="1"
      borderRadius="full"
      border="1px"
      borderColor="gray.200"
      bg="white.100"
      fontSize="10"
      fontWeight="300"
      color="gray.500"
    >
      Coming soon
    </Tag>
  );
}

export default ComingSoonTag;
