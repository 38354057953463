import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Workflow from './Workflow';
import WorkflowsTabs from './WorkflowsTabs';

const WorkflowsV2Wrapper = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/create`}>
        <Workflow />
      </Route>
      <Route exact path={`${path}/templates/:id/edit`}>
        <Workflow />
      </Route>
      <Route exact path={`${path}/:id/edit`}>
        <Workflow />
      </Route>
      <Route exact path={`${path}/:id/duplicate`}>
        <Workflow />
      </Route>
      <Route path={path}>
        <WorkflowsTabs />
      </Route>
    </Switch>
  );
};

export default WorkflowsV2Wrapper;
