import { GrayTagCell } from '@/components/paginated-table/table-components/PaginatedTableCells';

export const inputAttributeColumns = [
  {
    Header: 'Display Name',
    accessor: 'displayName',
    id: 'displayName',
  },
  {
    Header: 'Key',
    accessor: 'key',
    id: 'key',
    Cell: GrayTagCell,
  },
];
