import React, { ReactElement } from 'react';
import { Tag } from '@chakra-ui/react';

const RequiredTag = (): ReactElement => {
  return (
    <Tag
      px="6px"
      borderRadius="full"
      borderWidth={1}
      fontSize="10px"
      fontWeight="light"
      lineHeight="normal"
      textTransform="uppercase"
      bg="white.50"
      borderColor="gray.300"
      minH="unset"
      h="5"
    >
      Required
    </Tag>
  );
};

export default RequiredTag;
