import { getAuthDetails } from '@/helpers/getAuthDetails';
import { getBaseUrl, bureauPlatformApi } from '@/utils/api';
import { Parameter } from './types';

const parseParameter = (parameters: Parameter[]): { [key: string]: string } => {
  let response = {};
  parameters.forEach((parameter: Parameter) => {
    if (parameter.parameter && parameter.value) {
      response = { ...response, [parameter.parameter]: parameter.value };
    }
  });
  return response;
};

export const getCurl = (parameters: Parameter[], workflowId = ''): string => {
  const data = parseParameter(parameters);
  const authDetails = getAuthDetails();
  const url = authDetails.region === 'us' ? process.env.REACT_APP_US_ENDPOINT : getBaseUrl();
  return `curl --location --request POST '${url}/transactions' \\
    --header 'Authorization: ${bureauPlatformApi.defaults.headers.common.Authorization}' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
        "workflowId": "${workflowId}",
        "data": ${JSON.stringify(data)}
    }'`;
};
