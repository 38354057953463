import React, { ReactElement } from 'react';
import { Input, InputGroup, InputLeftElement, Stack, Image } from '@chakra-ui/react';

const SearchInput = ({
  setSearchTerm,
  placeholder = 'Search',
  styles = {},
  id,
  width,
  value,
  displaySearchIcon = true,
}: {
  setSearchTerm: Function;
  placeholder?: string;
  styles?: object;
  id?: string;
  width?: string;
  value?: string;
  displaySearchIcon?: boolean;
}): ReactElement => {
  return (
    <Stack id={id} flex="1">
      <InputGroup>
        {displaySearchIcon && (
          <InputLeftElement pointerEvents="none" color="gray.300" fontSize="2xl">
            <Image src="/assets/icons/search.svg" h="16px" w="16px" />
          </InputLeftElement>
        )}
        <Input
          value={value}
          placeholder={placeholder}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          borderColor="gray.300"
          borderWidth={1}
          bg="white"
          maxW={400}
          maxH={10}
          minW={width || '277px'}
          borderRadius="lg"
          color="gray.900"
          fontSize="sm"
          fontWeight="light"
          _placeholder={{ color: 'gray.500' }}
          _focus={{ borderColor: 'blue.200' }}
          {...styles}
          width="full"
        />
      </InputGroup>
    </Stack>
  );
};

export default SearchInput;
