import { Column } from '../capabilitiesV2/workflow-creation/types';
import { ColumnV2, WorkflowNodeV2 } from './types';

export const INITIAL_AND_CONDITION = [
  {
    when: {
      and: [
        {
          eq: [
            {
              const: true,
            },
            {
              const: true,
            },
          ],
        },
        {
          eq: [
            {
              const: true,
            },
            {
              const: '',
            },
          ],
        },
      ],
    },
  },
];

export const INITIAL_OR_CONDITION = {
  or: [
    {
      eq: [
        {
          const: true,
        },
        {
          const: false,
        },
      ],
    },
    {
      eq: [
        {
          const: true,
        },
        {
          const: '',
        },
      ],
    },
  ],
};

export const AND_CONDITION = {
  and: [
    {
      eq: [
        {
          const: true,
        },
        {
          const: true,
        },
      ],
    },
    {
      eq: [
        {
          const: true,
        },
        {
          const: '',
        },
      ],
    },
  ],
};

export const OR_CONDITION = {
  or: [
    {
      eq: [
        {
          const: true,
        },
        {
          const: false,
        },
      ],
    },
    {
      eq: [
        {
          const: true,
        },
        {
          const: '',
        },
      ],
    },
  ],
};

export const TEMP_CONDITION = [
  {
    when: {
      and: [
        {
          eq: [
            {
              const: true,
            },
            {
              const: true,
            },
          ],
        },
        {
          eq: [
            {
              obj: 'input.start_ghdvng.id',
            },
            {
              const: '3',
            },
          ],
        },
        {
          eq: [
            {
              obj: 'source.InvoidNameMatch_13c2bd51_b67e_4be4_9c60_f982dcc2bbd7.similarity',
            },
            {
              const: 3.000001,
            },
          ],
        },
        {
          and: [
            {
              eq: [
                {
                  const: true,
                },
                {
                  const: true,
                },
              ],
            },
            {
              eq: [
                {
                  obj: 'input.start_ghdvng.id',
                },
                {
                  const: '3',
                },
              ],
            },
            {
              or: [
                {
                  eq: [
                    {
                      const: true,
                    },
                    {
                      const: false,
                    },
                  ],
                },
                {
                  eq: [
                    {
                      call: [
                        'functions.IsBlocked',
                        {
                          obj: 'input.start_ghdvng.id',
                        },
                        {
                          const: 'cb2d59a2-238f-4afb-a13a-9b904d0a953b',
                        },
                      ],
                    },
                    {
                      const: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];

export const CONDITIONS_DATA = [
  {
    when: {
      and: [
        {
          eq: [
            {
              const: true,
            },
            {
              const: true,
            },
          ],
        },
        {
          eq: [
            {
              obj: 'tag.gerua_887b25acb9eb401b95e486c3fc123061.status',
            },
            {
              const: 'Success',
            },
          ],
        },
      ],
    },
  },
];

export const OUTPUTS = [
  {
    allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
    constraints: {
      length_max: null,
      length_min: null,
      numerical_max: null,
      numerical_min: null,
    },
    description: '',
    displayName: 'status',
    format: '',
    key: 'status',
    location: '',
    namespace: 'namespace_id',
    parentKey: '',
    regex: '',
    template: '',
    type: 'STRING',
  },
];

export const NO_OF_INPUT_FIELDS_VISIBLE = 4;

export const NO_OF_TAGS_VISIBLE = 5;

export const STATUS_INPUT = {
  id: 'status-input',
  header: 'Status',
  subHeader: 'Status input',
};

export const STATUS_OUTPUTS = {
  [STATUS_INPUT.id]: [
    {
      id: 'OK',
      header: 'OK',
      type: 'status',
    },
    {
      id: 'FAILED',
      header: 'FAILED',
      type: 'status',
    },
    {
      id: 'NO_RECORD_FOUND',
      header: 'NO RECORD FOUND',
      type: 'status',
    },
  ],
};

export const dummyWorkflowObject: ColumnV2[] = [
  {
    id: 'column_c270d6651c26f84e6995c7e307c3c531',
    metadata: {},
    nodes: [
      {
        columnPosition: 0,
        dependencies: [],
        description: '',
        id: 'start_ghdvng',
        kind: 'START',
        name: 'Input',
        nodePosition: 0,
        outputs: [
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'id',
            displayName: 'ID',
            format: 'TEXT',
            key: 'id',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'name',
            displayName: 'Name',
            format: 'TEXT',
            key: 'name',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Full Name',
            displayName: 'Full Name',
            format: 'TEXT',
            key: 'fullName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'first_name',
            displayName: 'First Name',
            format: 'TEXT',
            key: 'firstName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'last_name',
            displayName: 'Last Name',
            format: 'TEXT',
            key: 'lastName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'date_of_birth',
            displayName: 'Date of Birth',
            format: 'DATE',
            key: 'dob',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'phone_number',
            displayName: 'Phone Number',
            format: 'MOBILE',
            key: 'phoneNumber',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'country_code',
            displayName: 'Country Code',
            format: 'TEXT',
            key: 'countryCode',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Enhanced Coverage for Emailage',
            displayName: 'Enhanced Coverage',
            format: 'TEXT',
            key: 'enhancedCoverage',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Derived Signals',
            displayName: 'Derived Signals',
            format: 'TEXT',
            key: 'derivedSignals',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'ipv4_address',
            displayName: 'IP Address',
            format: 'IP ADDRESS',
            key: 'ipAddress',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'email_address',
            displayName: 'Email',
            format: 'EMAIL',
            key: 'email',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'pan_card_number',
            displayName: 'PAN',
            format: 'TEXT',
            key: 'pan',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'city',
            displayName: 'City',
            format: 'TEXT',
            key: 'city',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'state',
            displayName: 'State',
            format: 'TEXT',
            key: 'state',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'country',
            displayName: 'Country',
            format: 'TEXT',
            key: 'country',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'pin_code',
            displayName: 'Pin Code',
            format: 'TEXT',
            key: 'pinCode',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'zip',
            displayName: 'Zip',
            format: 'TEXT',
            key: 'zip',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'address',
            displayName: 'Address',
            format: 'TEXT',
            key: 'address',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'doc_number',
            displayName: 'Doc Number',
            format: 'TEXT',
            key: 'docNumber',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'doc_type',
            displayName: 'Doc Type',
            format: 'TEXT',
            key: 'docType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_front_document',
            displayName: 'Doc Front',
            format: 'TEXT',
            key: 'front',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_back_document',
            displayName: 'Doc Back',
            format: 'TEXT',
            key: 'back',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_selfie_image',
            displayName: 'Selfie',
            format: 'TEXT',
            key: 'selfie',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'bank_account_number',
            displayName: 'Account Number',
            format: 'TEXT',
            key: 'accountNumber',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'bank_ifsc',
            displayName: 'IFSC',
            format: 'TEXT',
            key: 'ifsc',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'vpa_upi_id',
            displayName: 'VPA',
            format: 'TEXT',
            key: 'vpa',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'gender',
            displayName: 'Gender',
            format: 'TEXT',
            key: 'gender',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'age',
            displayName: 'Age',
            format: 'TEXT',
            key: 'age',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'user_id_hash',
            displayName: 'User Id Hash',
            format: 'TEXT',
            key: 'userIdHash',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_key',
            displayName: 'Session Key',
            format: 'TEXT',
            key: 'sessionKey',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'username',
            displayName: 'Username',
            format: 'TEXT',
            key: 'username',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'order_id',
            displayName: 'Order Id',
            format: 'TEXT',
            key: 'orderId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'created_at',
            displayName: 'Created At',
            format: 'DATE',
            key: 'createdAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'updated_at',
            displayName: 'Updated At',
            format: 'DATE',
            key: 'updatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'source',
            displayName: 'Source',
            format: 'TEXT',
            key: 'source',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'is_cod',
            displayName: 'Is Cod',
            format: 'TEXT',
            key: 'isCod',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'payment_instrument',
            displayName: 'Payment Instrument',
            format: 'TEXT',
            key: 'paymentInstrument',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'amount',
            displayName: 'Amount',
            format: 'TEXT',
            key: 'amount',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'promo_code',
            displayName: 'Promo Code',
            format: 'TEXT',
            key: 'promoCode',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_address_id',
            displayName: 'Shipping Address Id',
            format: 'TEXT',
            key: 'shippingAddressId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_created_at',
            displayName: 'Shipping Created At',
            format: 'DATE',
            key: 'shippingCreatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_updated_at',
            displayName: 'Shipping Updated At',
            format: 'DATE',
            key: 'shippingUpdatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_address_type',
            displayName: 'Shipping Address Type',
            format: 'TEXT',
            key: 'shippingAddressType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_address',
            displayName: 'Shipping Address',
            format: 'TEXT',
            key: 'shippingAddress',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_address_1',
            displayName: 'Shipping Address1',
            format: 'TEXT',
            key: 'shippingAddress1',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_landmark',
            displayName: 'Shipping Landmark',
            format: 'TEXT',
            key: 'shippingLandmark',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_pincode',
            displayName: 'Shipping Pincode',
            format: 'TEXT',
            key: 'shippingPincode',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_city',
            displayName: 'Shipping City',
            format: 'TEXT',
            key: 'shippingCity',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_state',
            displayName: 'Shipping State',
            format: 'TEXT',
            key: 'shippingState',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_country',
            displayName: 'Shipping Country',
            format: 'TEXT',
            key: 'shippingCountry',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_customer_id',
            displayName: 'Shipping Customer Id',
            format: 'TEXT',
            key: 'shippingCustomerId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping)_customer_phone',
            displayName: 'Shipping Customer Phone',
            format: 'TEXT',
            key: 'shippingCustomerPhone',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_customer_email',
            displayName: 'Shipping Customer Email',
            format: 'TEXT',
            key: 'shippingCustomerEmail',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_customer_first_name',
            displayName: 'Shipping Customer First Name',
            format: 'TEXT',
            key: 'shippingCustomerFirstName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'shipping_customer_last_name',
            displayName: 'Shipping Customer Last Name',
            format: 'TEXT',
            key: 'shippingCustomerLastName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_address_id',
            displayName: 'Billing Address Id',
            format: 'TEXT',
            key: 'billingAddressId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_created_at',
            displayName: 'Billing Created At',
            format: 'DATE',
            key: 'billingCreatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_updated_at',
            displayName: 'Billing Updated At',
            format: 'DATE',
            key: 'billingUpdatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_address_type',
            displayName: 'Billing Address Type',
            format: 'TEXT',
            key: 'billingAddressType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_address',
            displayName: 'Billing Address',
            format: 'TEXT',
            key: 'billingAddress',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_address_1',
            displayName: 'Billing Address1',
            format: 'TEXT',
            key: 'billingAddress1',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_landmark',
            displayName: 'Billing Landmark',
            format: 'TEXT',
            key: 'billingLandmark',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_pincode',
            displayName: 'Billing Pincode',
            format: 'TEXT',
            key: 'billingPincode',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_city',
            displayName: 'Billing City',
            format: 'TEXT',
            key: 'billingCity',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_state',
            displayName: 'Billing State',
            format: 'TEXT',
            key: 'billingState',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_country',
            displayName: 'Billing Country',
            format: 'TEXT',
            key: 'billingCountry',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_customer_id',
            displayName: 'Billing Customer Id',
            format: 'TEXT',
            key: 'billingCustomerId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_customer_phone',
            displayName: 'Billing Customer Phone',
            format: 'TEXT',
            key: 'billingCustomerPhone',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_customer_email',
            displayName: 'Billing Customer Email',
            format: 'TEXT',
            key: 'billingCustomerEmail',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_customer_first_name',
            displayName: 'Billing Customer First Name',
            format: 'TEXT',
            key: 'billingCustomerFirstName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'billing_customer_last_name',
            displayName: 'Billing Customer Last Name',
            format: 'TEXT',
            key: 'billingCustomerLastName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_id',
            displayName: 'Session Id',
            format: 'TEXT',
            key: 'sessionId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_created_at',
            displayName: 'Session Created At',
            format: 'DATE',
            key: 'sessionCreatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_updated_at',
            displayName: 'Session Updated At',
            format: 'DATE',
            key: 'sessionUpdatedAt',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_ip_address',
            displayName: 'Session Ip Address',
            format: 'TEXT',
            key: 'sessionIpAddress',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_user_agent',
            displayName: 'Session User Agent',
            format: 'TEXT',
            key: 'sessionUserAgent',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_time_spent_on_website',
            displayName: 'Session Time Spent On Website',
            format: 'TEXT',
            key: 'sessionTimeSpentOnWebsite',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_time_spent_on_scrolling',
            displayName: 'Session Time Spent On Scrolling',
            format: 'TEXT',
            key: 'sessionTimeSpentOnScrolling',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_device_type',
            displayName: 'Session Device Type',
            format: 'TEXT',
            key: 'sessionDeviceType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'session_fingerprint_id',
            displayName: 'Session Fingerprint Id',
            format: 'TEXT',
            key: 'sessionFingerprintId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'customer_id',
            displayName: 'Customer Id',
            format: 'TEXT',
            key: 'customerId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'customer_phone',
            displayName: 'Customer Phone',
            format: 'TEXT',
            key: 'customerPhone',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'customer_email',
            displayName: 'Customer Email',
            format: 'TEXT',
            key: 'customerEmail',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'customer_first_name',
            displayName: 'Customer First Name',
            format: 'TEXT',
            key: 'customerFirstName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'customer_last_name',
            displayName: 'Customer Last Name',
            format: 'TEXT',
            key: 'customerLastName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'aggregate_name',
            displayName: 'Aggregate Name',
            format: 'TEXT',
            key: 'aggregateName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'sent_amount',
            displayName: 'Sent Amount',
            format: 'TEXT',
            key: 'sentAmount',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'sent_currency',
            displayName: 'Sent Currency',
            format: 'TEXT',
            key: 'sentCurrency',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'sender_entity_id',
            displayName: 'Sender Entity Id',
            format: 'TEXT',
            key: 'senderEntityId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'sender_entity_type',
            displayName: 'Sender Entity Type',
            format: 'TEXT',
            key: 'senderEntityType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'sender_source',
            displayName: 'Sender Source',
            format: 'TEXT',
            key: 'senderSource',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'sender_device_fingerprint',
            displayName: 'Sender Device Fingerprint',
            format: 'TEXT',
            key: 'senderDeviceFingerprint',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'received_amount',
            displayName: 'Received Amount',
            format: 'TEXT',
            key: 'receivedAmount',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'received_currency',
            displayName: 'Received Currency',
            format: 'TEXT',
            key: 'receivedCurrency',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'receiver_entity_id',
            displayName: 'Receiver Entity Id',
            format: 'TEXT',
            key: 'receiverEntityId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'receiver_entity_type',
            displayName: 'Receiver Entity Type',
            format: 'TEXT',
            key: 'receiverEntityType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'receiver_source',
            displayName: 'Receiver Source',
            format: 'TEXT',
            key: 'receiverSource',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'receiver_device_fingerprint',
            displayName: 'Receiver Device Fingerprint',
            format: 'TEXT',
            key: 'receiverDeviceFingerprint',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'passport_number',
            displayName: 'Passport Number',
            format: 'TEXT',
            key: 'passportNo',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'passport_application_file_number_as_printed_on_last_page_of_the_passport',
            displayName: 'Passport Application File Number',
            format: 'TEXT',
            key: 'fileNo',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'passport_application_file_number_as_printed_on_last_page_of_the_passport',
            displayName: 'Passport Application File Number',
            format: 'TEXT',
            key: 'fileNumber',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'File to be uploaded.',
            displayName: 'File',
            format: 'TEXT',
            key: 'file',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'date_of_issue_as_per_passport',
            displayName: 'Date of Issue',
            format: 'TEXT',
            key: 'doi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'government_check',
            displayName: 'Government Check',
            format: 'TEXT',
            key: 'gc',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'is_success',
            displayName: 'Is Success',
            format: 'TEXT',
            key: 'isSuccess',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'transaction_type',
            displayName: 'Transaction Type',
            format: 'TEXT',
            key: 'transactionType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'transaction_card_name',
            displayName: 'Transaction Card Name',
            format: 'TEXT',
            key: 'transactionCardName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'orders_count',
            displayName: 'Orders Count',
            format: 'TEXT',
            key: 'ordersCount',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'total_spent',
            displayName: 'Total Spent',
            format: 'TEXT',
            key: 'totalSpent',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'is_email_verified',
            displayName: 'Is Email Verified',
            format: 'TEXT',
            key: 'isEmailVerified',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'browser_width',
            displayName: 'Browser Width',
            format: 'TEXT',
            key: 'browserWidth',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'browser_height',
            displayName: 'Browser Height',
            format: 'TEXT',
            key: 'browserHeight',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'source_url',
            displayName: 'Source URL',
            format: 'TEXT',
            key: 'sourceURL',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'line_items',
            displayName: 'Line Items',
            format: 'LIST',
            key: 'lineItems',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'INTERFACE',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Line Items',
            displayName: 'Line Items',
            format: 'LIST',
            key: 'lineItems',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'INTERFACE',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'total_discounts',
            displayName: 'Total Discounts',
            format: 'TEXT',
            key: 'totalDiscounts',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'total_price',
            displayName: 'Total Price',
            format: 'TEXT',
            key: 'totalPrice',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'total_weight',
            displayName: 'Total Weight',
            format: 'TEXT',
            key: 'totalWeight',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'workflowid',
            displayName: 'WorkflowId',
            format: 'TEXT',
            key: 'workflowId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'blaze_merchant',
            displayName: 'Blaze Merchant',
            format: 'TEXT',
            key: 'blazeMerchant',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'latitude',
            displayName: 'Latitude',
            format: 'TEXT',
            key: 'latitude',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'longitude',
            displayName: 'Longitude',
            format: 'TEXT',
            key: 'longitude',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'cin',
            displayName: 'CIN',
            format: 'TEXT',
            key: 'cin',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'active_compliance',
            displayName: 'Active Compliance',
            format: 'TEXT',
            key: 'active_compliance',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'llpin',
            displayName: 'LLPIN',
            format: 'TEXT',
            key: 'llpin',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'fcrn',
            displayName: 'FCRN',
            format: 'TEXT',
            key: 'fcrn',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'fllpin',
            displayName: 'FLLPIN',
            format: 'TEXT',
            key: 'fllpin',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'active_compliance',
            displayName: 'Active Compliance',
            format: 'TEXT',
            key: 'activeCompliance',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'tan',
            displayName: 'TAN',
            format: 'TEXT',
            key: 'tan',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'document_name',
            displayName: 'Document Name',
            format: 'TEXT',
            key: 'documentName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'document',
            displayName: 'Document',
            format: 'TEXT',
            key: 'document',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'voterid_number',
            displayName: 'VoterID Number',
            format: 'TEXT',
            key: 'epicNumber',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'uan',
            displayName: 'UAN',
            format: 'TEXT',
            key: 'uan',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'otp',
            displayName: 'OTP',
            format: 'TEXT',
            key: 'otp',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'token',
            displayName: 'Token',
            format: 'TEXT',
            key: 'token',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'source_account_mid',
            displayName: 'Source Account MID',
            format: 'TEXT',
            key: 'sourceAccountMID',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'source_account_mcc',
            displayName: 'Source Account MCC',
            format: 'TEXT',
            key: 'sourceAccountMCC',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'beneficiary_account_mid',
            displayName: 'Beneficiary Account MID',
            format: 'TEXT',
            key: 'beneficieryAccountMID',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'beneficiary_account_mcc',
            displayName: 'Beneficiary Account MCC',
            format: 'TEXT',
            key: 'beneficieryAccountMCC',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'beneficiary_account_preregistered',
            displayName: 'Beneficiary Account Preregistered',
            format: 'TEXT',
            key: 'isBeneficieryAccountPreRegistered',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            allowedValues: ['card', 'cash'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'instrument',
            displayName: 'Instrument',
            format: 'TEXT',
            key: 'instrument',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['credit', 'debit'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'credit/debit',
            displayName: 'Credit/Debit',
            format: 'TEXT',
            key: 'creditDebit',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'destination_country',
            displayName: 'Destination Country',
            format: 'TEXT',
            key: 'destinationCountry',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'source_country',
            displayName: 'Source Country',
            format: 'TEXT',
            key: 'sourceCountry',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['true', 'false'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'approved_(system)',
            displayName: 'Approved (System)',
            format: 'TEXT',
            key: 'approved',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'behavioural_typing_score',
            displayName: 'Behavioural Typing Score',
            format: 'TEXT',
            key: 'behaviouralTypingScore',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'behavioural_fraud_score',
            displayName: 'Behavioural Fraud Score',
            format: 'TEXT',
            key: 'behaviouralFraudScore',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'device_id',
            displayName: 'Device ID',
            format: 'TEXT',
            key: 'deviceID',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'callback_url',
            displayName: 'Callback URL',
            format: 'TEXT',
            key: 'callbackUrl',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'cancel_url',
            displayName: 'Cancel URL',
            format: 'TEXT',
            key: 'cancelUrl',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'gst',
            displayName: 'GST',
            format: 'TEXT',
            key: 'gst',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'consent_id',
            displayName: 'Consent ID',
            format: 'TEXT',
            key: 'consentId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'raw_input',
            displayName: 'Raw Input',
            format: 'JSON',
            key: 'rawInput',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'INTERFACE',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'aadhaar_xml_token',
            displayName: 'Aadhaar XML Token',
            format: 'TEXT',
            key: 'aadhaarXMLToken',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'street',
            displayName: 'Street',
            format: 'TEXT',
            key: 'street',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'district',
            displayName: 'District',
            format: 'TEXT',
            key: 'district',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'consumer_id',
            displayName: 'Consumer Id',
            format: 'TEXT',
            key: 'consumerId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'service_provider',
            displayName: 'Service Provider',
            format: 'TEXT',
            key: 'serviceProvider',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'business_partner_number',
            displayName: 'Business Partner Number',
            format: 'TEXT',
            key: 'businessPartnerNumber',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'year_of_birth',
            displayName: 'Year of Birth',
            format: 'TEXT',
            key: 'yob',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'addtional_signers',
            displayName: 'Addtional Signers',
            format: 'LIST',
            key: 'additionalSigners',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'INTERFACE',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Reference ID',
            displayName: 'Reference ID',
            format: 'TEXT',
            key: 'referenceId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Report ID',
            displayName: 'Report ID',
            format: 'TEXT',
            key: 'reportId',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_front_document',
            displayName: 'Doc Front Url',
            format: 'TEXT',
            key: 'frontUrl',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_back_document',
            displayName: 'Doc Back Url',
            format: 'TEXT',
            key: 'backUrl',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_selfie',
            displayName: 'Selfie Url',
            format: 'TEXT',
            key: 'image2',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'url_of_back_document',
            displayName: 'Doc Front Image Url',
            format: 'TEXT',
            key: 'image1',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'name1',
            displayName: 'Name 1',
            format: 'TEXT',
            key: 'name1',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'name2',
            displayName: 'Name 2',
            format: 'TEXT',
            key: 'name2',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'name3',
            displayName: 'Name 3',
            format: 'TEXT',
            key: 'name3',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone',
            displayName: 'Phone',
            format: 'TEXT',
            key: 'phone',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Address1',
            displayName: 'Address1',
            format: 'TEXT',
            key: 'address1',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Address2',
            displayName: 'Address2',
            format: 'TEXT',
            key: 'address2',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Current Network Name',
            displayName: 'Current Network Name',
            format: 'TEXT',
            key: 'currentNetworkName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Current Network Billing Type',
            displayName: 'Current Network Billing Type',
            format: 'TEXT',
            key: 'currentNetworkBillingType',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Is Phone Reachable',
            displayName: 'Is Phone Reachable',
            format: 'TEXT',
            key: 'isPhoneReachable',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Ported',
            displayName: 'Ported',
            format: 'TEXT',
            key: 'ported',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Roaming',
            displayName: 'Roaming',
            format: 'TEXT',
            key: 'roaming',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Final Recommendation',
            displayName: 'Email Final Recommendation',
            format: 'TEXT',
            key: 'emailFinalRecommendation',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Domain Exists',
            displayName: 'Domain Exists',
            format: 'TEXT',
            key: 'domainExists',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Exists',
            displayName: 'Email Exists',
            format: 'TEXT',
            key: 'emailExists',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'BOOLEAN',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Digital Age',
            displayName: 'Email Digital Age',
            format: 'TEXT',
            key: 'emailDigitalAge',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Name Match Score',
            displayName: 'Email Name Match Score',
            format: 'TEXT',
            key: 'emailNameMatchScore',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email UNR Score',
            displayName: 'Email UNR Score',
            format: 'TEXT',
            key: 'emailUNRScore',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Digital Age',
            displayName: 'Phone Digital Age',
            format: 'TEXT',
            key: 'phoneDigitalAge',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Name Match Score',
            displayName: 'Phone Name Match Score',
            format: 'TEXT',
            key: 'phoneNameMatchScore',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone UNR Score',
            displayName: 'Phone UNR Score',
            format: 'TEXT',
            key: 'phoneUNRScore',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'NUMBER',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone WhatsApp',
            displayName: 'Phone WhatsApp',
            format: 'TEXT',
            key: 'phoneWhatsApp',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Instagram',
            displayName: 'Phone Instagram',
            format: 'TEXT',
            key: 'phoneInstagram',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Amazon',
            displayName: 'Phone Amazon',
            format: 'TEXT',
            key: 'phoneAmazon',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Paytm',
            displayName: 'Phone Paytm',
            format: 'TEXT',
            key: 'phonePaytm',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Flipkart',
            displayName: 'Phone Flipkart',
            format: 'TEXT',
            key: 'phoneFlipkart',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone WhatsApp Business',
            displayName: 'Phone WhatsApp Business',
            format: 'TEXT',
            key: 'phoneWhatsAppBusiness',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Instagram',
            displayName: 'Email Instagram',
            format: 'TEXT',
            key: 'emailInstagram',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Amazon',
            displayName: 'Email Amazon',
            format: 'TEXT',
            key: 'emailAmazon',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Paytm',
            displayName: 'Email Paytm',
            format: 'TEXT',
            key: 'emailPaytm',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Flipkart',
            displayName: 'Email Flipkart',
            format: 'TEXT',
            key: 'emailFlipkart',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Contact',
            displayName: 'Contact',
            format: 'TEXT',
            key: 'contact',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Number Deactivation',
            displayName: 'Number Deactivation',
            format: 'TEXT',
            key: 'numberDeactivation',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Subscriber Status',
            displayName: 'Subscriber Status',
            format: 'TEXT',
            key: 'subscriberStatus',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Contact Match',
            displayName: 'Contact Match',
            format: 'TEXT',
            key: 'contactMatch',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'URL',
            displayName: 'URL',
            format: 'TEXT',
            key: 'url',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'QR',
            displayName: 'QR',
            format: 'TEXT',
            key: 'qr',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Mode',
            displayName: 'Mode',
            format: 'TEXT',
            key: 'mode',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Swiggy',
            displayName: 'Phone Swiggy',
            format: 'TEXT',
            key: 'phoneSwiggy',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Indiamart',
            displayName: 'Phone Indiamart',
            format: 'TEXT',
            key: 'phoneIndiamart',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Jiomart',
            displayName: 'Phone Jiomart',
            format: 'TEXT',
            key: 'phoneJiomart',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Yatra',
            displayName: 'Phone Yatra',
            format: 'TEXT',
            key: 'phoneYatra',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Zoho',
            displayName: 'Phone Zoho',
            format: 'TEXT',
            key: 'phoneZoho',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Toi',
            displayName: 'Phone Toi',
            format: 'TEXT',
            key: 'phoneToi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Shaadi',
            displayName: 'Phone Shaadi',
            format: 'TEXT',
            key: 'phoneShaadi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Jeevansaathi',
            displayName: 'Phone Jeevansaathi',
            format: 'TEXT',
            key: 'phoneJeevansaathi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Housing',
            displayName: 'Phone Housing',
            format: 'TEXT',
            key: 'phoneHousing',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Skype',
            displayName: 'Phone Skype',
            format: 'TEXT',
            key: 'phoneSkype',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Phone Microsoft',
            displayName: 'Phone Microsoft',
            format: 'TEXT',
            key: 'phoneMicrosoft',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Yatra',
            displayName: 'Email Yatra',
            format: 'TEXT',
            key: 'emailYatra',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Zoho',
            displayName: 'Email Zoho',
            format: 'TEXT',
            key: 'emailZoho',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Toi',
            displayName: 'Email Toi',
            format: 'TEXT',
            key: 'emailToi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Shaadi',
            displayName: 'Email Shaadi',
            format: 'TEXT',
            key: 'emailShaadi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Jeevansaathi',
            displayName: 'Email Jeevansaathi',
            format: 'TEXT',
            key: 'emailJeevansaathi',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Housing',
            displayName: 'Email Housing',
            format: 'TEXT',
            key: 'emailHousing',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Quora',
            displayName: 'Email Quora',
            format: 'TEXT',
            key: 'emailQuora',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Skype',
            displayName: 'Email Skype',
            format: 'TEXT',
            key: 'emailSkype',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Microsoft',
            displayName: 'Email Microsoft',
            format: 'TEXT',
            key: 'emailMicrosoft',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Pinterest',
            displayName: 'Email Pinterest',
            format: 'TEXT',
            key: 'emailPinterest',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Pinterest',
            displayName: 'Email Pinterest',
            format: 'TEXT',
            key: 'emailPinterest',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Spotify',
            displayName: 'Email Spotify',
            format: 'TEXT',
            key: 'emailSpotify',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Booking',
            displayName: 'Email Booking',
            format: 'TEXT',
            key: 'emailBooking',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Flickr',
            displayName: 'Email Flickr',
            format: 'TEXT',
            key: 'emailFlickr',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Email Wordpress',
            displayName: 'Email Wordpress',
            format: 'TEXT',
            key: 'emailWordpress',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            allowedValues: ['basic', 'advance'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Type',
            displayName: 'Type',
            format: 'TEXT',
            key: 'type',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'SSN',
            displayName: 'SSN',
            format: 'TEXT',
            key: 'ssn',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Address Line 1',
            displayName: 'Address Line 1',
            format: 'TEXT',
            key: 'addressLine1',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Address Line 2',
            displayName: 'Address Line 2',
            format: 'TEXT',
            key: 'addressLine2',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Middle Name',
            displayName: 'Middle Name',
            format: 'TEXT',
            key: 'middleName',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Image url',
            displayName: 'Image Url',
            format: 'TEXT',
            key: 'image_url',
            location: '',
            namespace: 'start_ghdvng',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
        ],
      },
    ],
  },
  {
    id: 'column_e2245ccbdde84590bb6eb50b9fb0dd60',
    metadata: {
      branch: 'source',
      capabilityId: '37e06e7d-5566-4272-a470-115cf8bb73be',
      capabilityName: '*PAN Verification',
      description:
        'Check an Indian PAN document for signs of tampering and read its data, enabling efficient data capture and processing. Verify the authenticity of the PAN card against official records, ensuring it is authentic and accurate. ',
      kind: 'source',
      parentID: 'DROPZONE',
    },
    nodes: [
      {
        color: '',
        columnPosition: 1,
        conditions: [],
        dependencies: ['start_ghdvng'],
        description: 'Synchronous Document Verification v2',
        id: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
        inputs: [
          {
            description: 'docType_0',
            displayName: 'Doc Type',
            format: 'TEXT',
            key: 'docType',
            location: 'input.start_ghdvng.documentName',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            type: 'STRING',
          },
          {
            description: 'countryCode_1',
            displayName: 'Country Code',
            format: 'TEXT',
            key: 'countryCode',
            location: 'static.start_ghdvng.',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            type: 'STRING',
          },
          {
            description: 'frontUrl_2',
            displayName: 'Doc Front Url',
            format: 'TEXT',
            key: 'frontUrl',
            location: 'input.start_ghdvng.frontUrl',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            type: 'STRING',
          },
          {
            description: 'backUrl_3',
            displayName: 'Doc Back Url',
            format: 'TEXT',
            key: 'backUrl',
            location: 'input.start_ghdvng.backUrl',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            type: 'STRING',
          },
        ],
        kind: 'source',
        name: 'Synchronous Document Verification v2',
        nodePosition: 0,
        open: false,
        outputDependencies: [
          {
            columnPosition: 2,
            nodePosition: 0,
          },
        ],
        outputs: [
          {
            allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: '',
            displayName: 'status',
            format: '',
            key: 'status',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Doc Type',
            displayName: 'Doc Type',
            format: 'TEXT',
            key: 'docType',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Doc Sub Type',
            displayName: 'Doc Sub Type',
            format: 'TEXT',
            key: 'docSubType',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Doc Number',
            displayName: 'Doc Number',
            format: 'TEXT',
            key: 'docNumber',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'File Number',
            displayName: 'File Number',
            format: 'TEXT',
            key: 'fileNumber',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'File No',
            displayName: 'File No',
            format: 'TEXT',
            key: 'fileNo',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'First Name',
            displayName: 'First Name',
            format: 'TEXT',
            key: 'firstName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Last Name',
            displayName: 'Last Name',
            format: 'TEXT',
            key: 'lastName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Full Name',
            displayName: 'Full Name',
            format: 'TEXT',
            key: 'fullName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Father Name',
            displayName: 'Father Name',
            format: 'TEXT',
            key: 'fatherName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Mother Name',
            displayName: 'Mother Name',
            format: 'TEXT',
            key: 'motherName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Husband Name',
            displayName: 'Husband Name',
            format: 'TEXT',
            key: 'husbandName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Spouse Name',
            displayName: 'Spouse Name',
            format: 'TEXT',
            key: 'spouseName',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Issue Date',
            displayName: 'Issue Date',
            format: 'TEXT',
            key: 'issueDate',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Date of Birth',
            displayName: 'Date of Birth',
            format: 'TEXT',
            key: 'dateOfBirth',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Expiry Date',
            displayName: 'Expiry Date',
            format: 'TEXT',
            key: 'expiryDate',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Gender',
            displayName: 'Gender',
            format: 'TEXT',
            key: 'gender',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Age',
            displayName: 'Age',
            format: 'TEXT',
            key: 'age',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Nationality',
            displayName: 'Nationality',
            format: 'TEXT',
            key: 'nationality',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Resident',
            displayName: 'Resident',
            format: 'TEXT',
            key: 'resident',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Issue Place',
            displayName: 'Issue Place',
            format: 'TEXT',
            key: 'issuePlace',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Issue Country',
            displayName: 'Issue Country',
            format: 'TEXT',
            key: 'issueCountry',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Mrz Line1',
            displayName: 'Mrz Line1',
            format: 'TEXT',
            key: 'mrzLine1',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Mrz Line2',
            displayName: 'Mrz Line2',
            format: 'TEXT',
            key: 'mrzLine2',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'QR',
            displayName: 'QR',
            format: 'TEXT',
            key: 'qr',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Full Address',
            displayName: 'Full Address',
            format: 'TEXT',
            key: 'fullAddress',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'House No',
            displayName: 'House No',
            format: 'TEXT',
            key: 'addressHouseNo',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Street',
            displayName: 'Street',
            format: 'TEXT',
            key: 'addressStreet',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Locality',
            displayName: 'Locality',
            format: 'TEXT',
            key: 'addressLocality',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'City',
            displayName: 'City',
            format: 'TEXT',
            key: 'addressCity',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'State',
            displayName: 'State',
            format: 'TEXT',
            key: 'addressState',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Pin',
            displayName: 'Pin',
            format: 'TEXT',
            key: 'addressPin',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Country',
            displayName: 'Country',
            format: 'TEXT',
            key: 'addressCountry',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'DOB TimeStamp',
            displayName: 'DOB TimeStamp',
            format: 'DATE',
            key: 'dobTimeStamp',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'Meta',
            displayName: 'Meta',
            format: 'JSON',
            key: 'meta',
            location: '',
            namespace: 'IDOCRnode_idf8e720a84d384f577b8f6daa6f645675',
            parentKey: '',
            regex: '',
            template: '',
            type: 'INTERFACE',
          },
        ],
        supplierId: 'IDOCR',
      },
    ],
  },
  {
    id: 'column_ae77231dd18e42adb817a1809a46e8c2',
    metadata: {
      branch: 'source',
      capabilityId: '37e06e7d-5566-4272-a470-115cf8bb73be',
      capabilityName: '*PAN Verification',
      description:
        'Check an Indian PAN document for signs of tampering and read its data, enabling efficient data capture and processing. Verify the authenticity of the PAN card against official records, ensuring it is authentic and accurate. ',
      kind: 'source',
      parentID: 'DROPZONE',
    },
    nodes: [
      {
        color: '',
        columnPosition: 2,
        conditions: [],
        dependencies: ['IDOCRnode_idf8e720a84d384f577b8f6daa6f645675'],
        description: 'PAN Govt Check',
        id: 'KarzaPANGovtCheck_99a4fbe8_ef7a_4ca7_8e40_5b2bd8fae6e1node_idb91e16e1452d237780155ce32de37ffd',
        inputs: [
          {
            description: 'pan_0',
            displayName: 'PAN',
            format: 'TEXT',
            isStatic: false,
            key: 'pan',
            location: 'source.IDOCRnode_idf8e720a84d384f577b8f6daa6f645675.docNumber',
            namespace: 'KarzaPANGovtCheck_99a4fbe8_ef7a_4ca7_8e40_5b2bd8fae6e1node_idb91e16e1452d237780155ce32de37ffd',
            type: 'STRING',
          },
        ],
        kind: 'source',
        name: 'Pan Basic',
        nodePosition: 0,
        open: false,
        outputDependencies: [
          {
            columnPosition: 3,
            nodePosition: 0,
          },
        ],
        outputs: [
          {
            allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: '',
            displayName: 'status',
            format: '',
            key: 'status',
            location: '',
            namespace: 'KarzaPANGovtCheck_99a4fbe8_ef7a_4ca7_8e40_5b2bd8fae6e1node_idb91e16e1452d237780155ce32de37ffd',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
          {
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: 'PAN Govt Name',
            displayName: 'PAN Govt Name',
            format: 'TEXT',
            key: 'name',
            location: '',
            namespace: 'KarzaPANGovtCheck_99a4fbe8_ef7a_4ca7_8e40_5b2bd8fae6e1node_idb91e16e1452d237780155ce32de37ffd',
            operators: [
              {
                hiddenParams: false,
                key: '==',
                label: '==',
                value: 'eq',
              },
              {
                hiddenParams: false,
                key: '!=',
                label: '!=',
                value: 'not',
              },
              {
                hiddenParams: false,
                key: '>',
                label: '>',
                value: 'gt',
              },
              {
                hiddenParams: false,
                key: '>=',
                label: '>=',
                value: 'gte',
              },
              {
                hiddenParams: false,
                key: '<',
                label: '<',
                value: 'lt',
              },
              {
                hiddenParams: false,
                key: '<=',
                label: '<=',
                value: 'lte',
              },
              {
                hiddenParams: false,
                key: 'contains',
                label: 'contains',
                value: 'Contains',
              },
              {
                hiddenParams: false,
                key: 'in',
                label: 'in',
                value: 'In',
              },
              {
                hiddenParams: false,
                key: 'starts with',
                label: 'starts with',
                value: 'HasPrefix',
              },
              {
                hiddenParams: false,
                key: 'ends with',
                label: 'ends with',
                value: 'HasSuffix',
              },
              {
                hiddenParams: false,
                key: 'regex match',
                label: 'regex match',
                value: 'MatchString',
              },
              {
                hiddenParams: false,
                key: 'is blocked',
                label: 'is blocked',
                value: 'IsBlocked',
              },
            ],
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
        ],
        supplierId: 'KarzaPANGovtCheck_99a4fbe8_ef7a_4ca7_8e40_5b2bd8fae6e1',
      },
    ],
  },
  {
    id: 'accept_997c9bff06d4dd1f663b48f07de8d589',
    metadata: {
      kind: 'end',
    },
    nodes: [
      {
        dependencies: ['start_ghdvng'],
        id: 'accept_997c9bff06d4dd1f663b48f07de8d589',
        inputs: [],
        kind: 'end_accepted',
        name: 'accept',
        outputs: [
          {
            allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
            constraints: {
              length_max: null,
              length_min: null,
              numerical_max: null,
              numerical_min: null,
            },
            description: '',
            displayName: 'status',
            format: '',
            key: 'status',
            location: '',
            namespace: 'accept_997c9bff06d4dd1f663b48f07de8d589',
            parentKey: '',
            regex: '',
            template: '',
            type: 'STRING',
          },
        ],
      },
    ],
  },
];

const NODES = [
  {
    id: 'await_node_id',
    dependencies: ['start_ghdvng'],
    kind: 'AWAIT',
    name: 'Awaits_id',
    inputs: [],
    outputs: [
      {
        key: 'status',
        namespace: 'Awaits_149d1fef08ba4e8e8798b4dd933e9f5a',
        type: 'STRING',
        description: '',
        format: '',
        regex: '',
        constraints: {
          numerical_max: null,
          numerical_min: null,
          length_max: null,
          length_min: null,
        },
        template: '',
        parentKey: '',
        location: '',
        displayName: 'status',
        allowedValues: ['OK', 'FAILED', 'NO_RECORD_FOUND'],
      },
    ],
    open: false,
    columnPosition: 1,
    outputDependencies: [
      {
        columnPosition: 2,
        nodePosition: 0,
      },
    ],
    conditions: [],
    awaits: [
      {
        nodeName: 'start_ghdvng',
        serviceId: 'service_id',
        values: ['await_input'],
      },
    ],
  },
] as WorkflowNodeV2[];

export const AWAIT_NODE = {
  id: 'column_id',
  nodes: NODES,
  metadata: {
    capabilityId: 'item',
    capabilityName: 'item',
    kind: 'source',
    parentID: 'a',
    branch: '',
    description: 'a',
    isAsync: false,
  },
} as Column;
