import React, { useState } from 'react';
import { Cell } from 'react-table';
import { DotsThreeOutlineVertical } from 'phosphor-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Flex, Box } from '@chakra-ui/react';
import { UserRole } from '@/screens/admin/types';
import { UserAction } from '@/types/common';
import { ActionButtonColumnProps, UserRoles } from '../../utils/types';

export const ActionsCell = ({ row, column }: Cell): React.ReactElement | null => {
  const { getProps, options, type, getFeatureFlagPageWise = _ => true } = column as ActionButtonColumnProps;
  const [showMenu, setShowMenu] = useState(false);

  const filteredOptions = options?.filter(opt => {
    if (opt.type === UserAction.DELETE) {
      return getFeatureFlagPageWise('delete');
    }
    if (opt.type === UserAction.EDIT) {
      return getFeatureFlagPageWise('write');
    }
    return true;
  });

  const ref = useDetectClickOutside({ onTriggered: () => setShowMenu(false) });

  const hasDeletePermission =
    type === 'user' &&
    (row.original as { roles: UserRole[] }).roles.findIndex(
      (role: UserRole) => role.name?.toLowerCase() === UserRoles.OWNER,
    ) < 0;

  const isShowActionsIcon = hasDeletePermission || !type;

  if (isShowActionsIcon) {
    return (
      <Flex justifyContent="right" ref={ref}>
        <Flex alignItems="center" w={10}>
          {options?.length ? (
            <DotsThreeOutlineVertical weight="fill" onClick={() => setShowMenu(!showMenu)} cursor="pointer" />
          ) : (
            <></>
          )}
        </Flex>
        {showMenu && (
          <Flex
            direction="column"
            position="absolute"
            right="46px"
            bg="white"
            px={2}
            pb={2}
            pt={1}
            borderRadius="lg"
            mt={6}
            w="128px"
            boxShadow="1px 1px 8px rgba(31, 51, 203, 0.09)"
            fontWeight="light"
            zIndex={1}
          >
            {filteredOptions?.map(opt => (
              <Box
                key={opt.type}
                mt={1}
                py={2}
                pl={2}
                fontSize="sm"
                cursor="pointer"
                _hover={{ bg: ' #F5F6F9', borderRadius: 'lg' }}
                color={opt.type === 'delete' ? 'red.600' : 'initial'}
                onClick={() => {
                  setShowMenu(false);
                  getProps?.onActionIconClick?.(row, opt.type);
                }}
              >
                {opt.name}
              </Box>
            ))}
          </Flex>
        )}
      </Flex>
    );
  }
  return <></>;
};
