import React, { ReactNode, FC, ReactElement } from 'react';
import { Avatar, Box, Button, Flex, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import useDecodedToken from '@/hooks/useDecodedToken';
import { CaretDown, FileCloud, GearSix, GitFork, SignOut } from 'phosphor-react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { DropdownItem, DropdownItemProps } from '@/components/DropdownItem';

export const HeaderWithTabs: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      w="full"
      sx={{
        '& > header': {
          border: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

const Header: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Flex
      as="header"
      py="4"
      px="5"
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      borderBottomWidth={1}
      borderColor="gray.300"
    >
      {children}
    </Flex>
  );
};

// Heading component with nested Title and SubHeader
const Heading: FC<{ children: ReactNode }> & {
  Title: FC<{ children: ReactNode }>;
  SubHeader: FC<{ children: ReactNode }>;
} = ({ children }) => {
  return (
    <Flex gridGap="4" alignItems="center">
      {children}
    </Flex>
  );
};

Heading.Title = ({ children }) => (
  <Text fontSize="xl" fontWeight="medium" lineHeight="normal">
    {children}
  </Text>
);

Heading.SubHeader = ({ children }) => (
  <Text fontSize="sm" fontWeight="light" color="gray.500">
    {children}
  </Text>
);

const RHSContent: FC<{ children: ReactNode }> & {
  Buttons: FC<{ children: ReactNode }>;
} = ({ children }) => (
  <Flex gridGap="8" h="full" alignItems="center">
    {children}
  </Flex>
);

RHSContent.Buttons = ({ children }) => <Flex gridGap="4">{children}</Flex>;

const ProfileDropdown = (): ReactElement => {
  const { userDetails = { user_name: '', nickname: '', picture: '' } } = useDecodedToken();
  const history = useHistory();
  const { logout } = useAuth0();

  const onLogout = (): void => {
    window.localStorage.clear();
    logout({ returnTo: `${window.location.origin}/`, federated: false });
  };

  const menuList: DropdownItemProps[] = [
    {
      leftIcon: <FileCloud size="16" />,
      title: 'My API Keys',
      onClick: () => {
        history.push('/admin/api-keys');
      },
    },
    {
      leftIcon: <GitFork size="16" />,
      title: 'Webhooks',
      onClick: () => {
        history.push('/webhooks');
      },
    },
    {
      leftIcon: <GearSix size="16" />,
      title: 'Settings',
      onClick: () => {
        history.push('/settings');
      },
    },
    { leftIcon: <SignOut size="16" />, title: 'Log Out', onClick: onLogout, color: 'red.500', hoverBgColor: 'red.50' },
  ];

  return (
    <Menu>
      <MenuButton
        as={Button}
        alignItems="center"
        bg="white"
        px="0"
        _hover={{ bg: 'white' }}
        _focus={{ boxShadow: 'none', bg: 'white' }}
        _active={{ bg: 'white' }}
        rightIcon={<CaretDown weight="fill" />}
      >
        <Flex alignItems="center" gridGap="4">
          <Text fontSize="sm" fontWeight="medium" color="gray.700" lineHeight="normal" textTransform="capitalize">
            {userDetails.nickname}
          </Text>
          <Avatar name={userDetails.nickname} size="sm" backgroundColor="gray.200" color="gray.600" />
        </Flex>
      </MenuButton>
      <MenuList px="3" py="4" gridGap="2" zIndex={10}>
        {menuList.map(menuItem => (
          <DropdownItem key={menuItem.title} {...menuItem} />
        ))}
      </MenuList>
    </Menu>
  );
};

export { Header, Heading, RHSContent, ProfileDropdown };
