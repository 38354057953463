import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { FadeLoader } from 'react-spinners';
import { colors } from '@/utils/colors';

const FactsLoader = (): ReactElement => {
  return (
    <Flex h="100vh" justify="center" align="center" direction="column" w="100%">
      <Flex maxW="594px" direction="column" justify="center" align="center">
        <FadeLoader color={colors.blue[700]} height={8} margin={-4} width={2} />
      </Flex>
    </Flex>
  );
};
export default FactsLoader;
