import { FormatDateCell } from '@/components/paginated-table/table-components/PaginatedTableCells';
import { PaginatedTableColumnsWithProps } from '@/components/paginated-table/types';
import { Row } from 'react-table';
import { DeleteQuickLinkCell } from './DeleteQuickLinkCell';
import { QuickActionsCell } from './QuickActionsCell';
import { ToggleQuickLinkCell } from './ToogleQuickLinkCell';
import { TransactionsCell } from './TransactionsCell';

export const columns = (
  onApiKeyToggle: (val: string) => void,
  handleActionButtonClick: (e: Row, type: string) => void,
): PaginatedTableColumnsWithProps[] => {
  return [
    {
      Header: 'QUICKLINK NAME',
      accessor: 'name',
      id: 'name',
      disableSortBy: true,
    },
    {
      Header: 'WORKFLOW NAME',
      accessor: 'workflowName',
      id: 'workflowName',
      disableSortBy: true,
    },
    {
      Header: 'CREATED ON',
      accessor: 'createdAt',
      id: 'created_at',
      Cell: FormatDateCell,
      disableSortBy: true,
    },
    {
      Header: 'TRANSACTIONS',
      accessor: 'transactionCount',
      id: 'transactionCount',
      Cell: TransactionsCell,
      disableSortBy: true,
    },
    {
      Header: 'STATUS',
      accessor: 'isEnabled',
      id: 'isEnabled',
      Cell: ToggleQuickLinkCell,
      getProps: { onApiKeyToggle },
      disableSortBy: true,
    },
    {
      Header: 'Quick Actions',
      accessor: 'id',
      id: 'id',
      Cell: QuickActionsCell,
      disableSortBy: true,
      getProps: { onActionIconClick: handleActionButtonClick },
    },
    {
      Header: '',
      accessor: 'transactionLimit',
      id: 'transactionLimit',
      Cell: DeleteQuickLinkCell,
      getProps: { onActionIconClick: handleActionButtonClick },
      disableSortBy: true,
    },
  ];
};
