import React, { ReactElement } from 'react';
import { Plus } from 'phosphor-react';
import { Box } from '@chakra-ui/react';

const AddButtonLabel = ({ label }: { label: string }): ReactElement => (
  <>
    <Box alignItems="center" mr={2}>
      <Plus size={20} />
    </Box>
    <Box lineHeight="18px">{label}</Box>
  </>
);

export default AddButtonLabel;
