import React, { ReactElement } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export const AlertMessageModal = ({
  onCancel,
  onClose,
  alertHeading,
  alertContent,
  buttonLabel = 'OK',
}: {
  onCancel: () => void;
  onClose: () => void;
  alertHeading?: string;
  alertContent: string | ReactElement;
  buttonLabel?: string;
}): ReactElement => {
  return (
    <Modal isOpen onClose={onCancel} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent p={4} borderRadius="xl">
        {alertHeading && (
          <ModalHeader fontSize="2xl" fontWeight="500">
            {alertHeading}
          </ModalHeader>
        )}
        <ModalCloseButton mr={4} mt={4} color="gray.600" _focus={{ border: 'none' }} />
        <ModalBody>{alertContent}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={4} colorScheme="blue" borderRadius="lg" fontWeight="light" w="full">
            {buttonLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlertMessageModal;
