import React from 'react';
import { HeaderGroup } from 'react-table';
import { Thead, Tr, Th, Flex, Text, Tooltip, Box } from '@chakra-ui/react';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import { PhosphorIcon } from '@/screens/entity-list/devices/PhosphorIcon';

const ColumnSortIcon = ({ column }: { column: HeaderGroup<object> }): React.ReactElement => {
  const { isSorted, isSortedDesc } = column;
  return (
    <Box ml={2}>
      {!isSorted || isSortedDesc ? (
        <PhosphorIcon fontWeight="regular" icon={ArrowDown} size={12} />
      ) : (
        <PhosphorIcon fontWeight="regular" icon={ArrowUp} size={12} />
      )}
    </Box>
  );
};

export const TableHeaders = ({ headerGroups }: { headerGroups: Array<HeaderGroup<object>> }): React.ReactElement => {
  return (
    <Thead
      position="sticky"
      top={0}
      zIndex={1}
      bgColor="white"
      borderTop="0px"
      borderTopWidth="0px"
      boxShadow="0 1px 0 0 #EFEFEF, inset 0 1px 0 0 #EFEFEF"
    >
      {headerGroups.map(headerGroup => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        return (
          <Tr {...headerGroupProps} key={headerGroupProps.key}>
            {headerGroup.headers.map(column => {
              const columnProps = column.getHeaderProps();
              return (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  color="gray.500"
                  key={columnProps.key}
                  p="0"
                >
                  <Flex as={Box} alignItems="center" px={5} py={4} color="gray.500" w="100%" justifyContent="start">
                    <Tooltip
                      hasArrow
                      label={column?.Header}
                      bg="white"
                      color="gray.700"
                      placement="top"
                      px={4}
                      py={3}
                      borderRadius="lg"
                      fontWeight="light"
                      fontSize="xs"
                      visibility={column.id === 'selection' ? 'hidden' : 'visible'}
                    >
                      <Text
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        maxW={200}
                        textTransform="uppercase"
                        fontSize="xs"
                        fontWeight="light"
                        lineHeight="13px"
                        color="gray.500"
                      >
                        {column.render('Header')}
                      </Text>
                    </Tooltip>
                    {column.canSort && <ColumnSortIcon column={column} />}
                  </Flex>
                </Th>
              );
            })}
          </Tr>
        );
      })}
    </Thead>
  );
};
