export const colors = {
  blue: {
    50: '#F3F7FB',
    100: '#ECF3FE',
    200: '#ADC5F9',
    300: '#5A75F0',
    400: '#4462EC',
    500: '#23368C',
    600: '#111963',
    700: '#233CAA',
    900: '#1C2D77',
  },
  gray: {
    50: '#FAFBFC',
    100: '#EEEEEE',
    200: '#E8EBF1',
    300: '#DDE1EC',
    400: '#BCC2D4',
    500: '#98A3B8',
    550: '#757575',
    600: '#727C8D',
    650: '#585656',
    700: '#484D62',
    800: '#2C303E',
    900: '#010535',
  },
  red: {
    50: '#FFEAF0',
    100: '#E989A6',
    200: '#FFF9FB',
    300: '#D93566',
    400: '#ED5039',
    500: '#C02655',
    600: '#B11F4B',
    700: '#FFF3F7',
  },
  green: {
    50: '#E9F9F2',
    100: '#D1F2E4',
    200: '#AEEBD2',
    300: '#89D3B4',
    400: '#05944F',
    500: '#089057',
    600: '#31C183',
  },
  purple: {
    50: '#EAEAFF',
    100: '#4E4AF2',
    200: '#8A9EF9',
    300: '#E7EBFD',
    400: '#D8DEF9',
    500: '#7D88BD',
    600: '#4F527A',
    700: '#3D427F',
    800: '#313566',
    900: '#22254A',
  },
  orange: {
    100: '#FBEBDE',
    200: '#F7B06E',
    500: '#D47115',
  },
  terracotta: {
    50: '#FFFBFA',
    100: '#FEE8E5',
    500: '#E3796A',
  },
  lavender: {
    100: '#F1F3FD',
  },
  transparentGray: {
    100: '#01053542',
  },
  white: {
    50: '#F5F6F9',
    100: '#F8F9FB',
  },
  blueGray: {
    50: '#ECEDF0',
    100: '#D4DFF7',
  },
  shadows: {
    blue600: '#1119630a',
    blue: '#DEE7FD',
  },
};
