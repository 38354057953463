import React, { ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, RouteProps } from 'react-router-dom';
import { bureauPlatformApi, setupAuth, setupOrganizationToken } from '@/utils/api';
import { useAuthDetails } from '@/queries/useAuth';
import FactsLoader from '@/components/loader/Loader';
import { useFetchUserOrganization } from '@/screens/user-onboarding/queries/useFetchUserOrganization';
import VerifyEmail from '@/screens/user-onboarding/VerifyEmail';
import useIdleTimer from './hooks/useIdleTimer';

const IDLE_TIMEOUT = 30 * 60 * 1000;

const ProtectedRoute = (props: RouteProps): ReactElement => {
  const { refetch: refetchUserDetails, isLoading: isUserDetailsLoading } = useAuthDetails();

  const { user: auth0User, isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading, logout } = useAuth0();
  const { isLoading: isOrganisationLoading, refetch: refetchOrganisation } = useFetchUserOrganization();

  const handleIdle = (): void => {
    // Perform logout when the user is idle
    logout({ returnTo: `${window.location.origin}/`, federated: false });
  };

  // Use the custom idle timer hook with a 30-minute timeout
  useIdleTimer({ onIdle: handleIdle, timeout: IDLE_TIMEOUT });
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const getToken = async (): Promise<string> => {
      const token = await getAccessTokenSilently();
      setupAuth(token);
      refetchOrganisation().then(res => {
        const organisationList = res.data;
        if (organisationList?.length) {
          setupOrganizationToken(organisationList[0].id);
          refetchUserDetails();
        }
      });

      return token;
    };
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const urlParams = new URLSearchParams(window.location.search);
      const organization = urlParams.get('organization') || '';
      const invitation = urlParams.get('invitation') || '';

      if (!organization) {
        loginWithRedirect();
        return;
      }

      // Trigger the login with the organization parameter if available
      loginWithRedirect({
        organization, // Include organization ID if present
        invitation,
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (
    !isAuthenticated ||
    isUserDetailsLoading ||
    isOrganisationLoading ||
    !bureauPlatformApi.defaults.headers.common.Authorization
  ) {
    return <FactsLoader />;
  }

  if (!auth0User?.email_verified) {
    return <VerifyEmail />;
  }

  return <Route {...props} />;
};
export default ProtectedRoute;
