import React, { ReactElement } from 'react';
import { Text } from '@chakra-ui/react';
import { PageErrorProps } from './types';

const PageError = ({ errorMessage }: PageErrorProps): ReactElement => {
  return (
    <Text textAlign="center" fontSize="xl">
      {errorMessage ?? 'Something went wrong'}
    </Text>
  );
};

export default PageError;
