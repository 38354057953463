import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { Plans } from '../types';

export const useGetPlans = (): UseQueryResult<Plans[], AxiosError> => {
  return useQuery('get-plans', getPlans(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const getPlans = () => {
  return async () => {
    const url = window.location.href.includes('stg1')
      ? `${process.env.REACT_APP_STG1_URL}/suppliers/plans`
      : '/suppliers/plans';
    const res = await bureauPlatformApi.get<Plans[]>(url);
    return res.data;
  };
};
export default useGetPlans;
