import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/layout';
import ColorInput from './ColorInput';
import FontSelector from './FontSelection';
import LogoCustomization from './LogoCustomization';
import ButtonSelector from './sdk-preview/components/ButtonSelector';

function QuickCustimizationTheme(): ReactElement {
  return (
    <Flex direction="column" gridGap="5">
      <LogoCustomization />
      <ColorInput path="branding.primaryColor" type="quick_customiaztion" />
      <FontSelector />
      <ButtonSelector />
    </Flex>
  );
}

export default QuickCustimizationTheme;
