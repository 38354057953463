import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { CSSObject } from '@chakra-ui/react';
import { CustomTabsProps } from '../CustomTabs';

interface CustomTabsReturnProps {
  tabIndex: number;
  history: History;
  tabSelectedStyles: CSSObject;
}

const useCustomTabs = (tabs: CustomTabsProps['tabs']): CustomTabsReturnProps => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const tabSelectedStyles = { color: 'blue.500', borderColor: 'blue.500', fontWeight: 'medium' };

  useEffect(() => {
    const initialTabIndex = tabs.findIndex(tab => pathname.includes(tab.id));
    if (initialTabIndex < 0) {
      setTabIndex(0);
      history.push(`/${tabs[tabIndex].id}`);
    } else {
      setTabIndex(initialTabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, tabs]);

  return { tabIndex, history, tabSelectedStyles };
};

export default useCustomTabs;
