import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { PaginatedQuickLinkResponse, QuickLinkQueryParams } from '../workflowv2/types';

// Fetch function
const fetchQuickLinks = async (params: QuickLinkQueryParams): Promise<PaginatedQuickLinkResponse> => {
  const { data: response } = await bureauPlatformApi.get<PaginatedQuickLinkResponse>('/workflows/quicklink', {
    params: {
      search: params.search || '',
      limit: params.limit || 10,
      offset: params.offset || 0,
    },
  });
  return response;
};

const useFetchQuickLink = (
  params: QuickLinkQueryParams,
  options?: {
    enabled?: boolean;
    retry?: boolean | number;
    retryDelay?: number;
    staleTime?: number;
    cacheTime?: number;
    refetchOnWindowFocus?: boolean;
    refetchOnMount?: boolean;
    onSuccess?: (data: PaginatedQuickLinkResponse) => void;
    onError?: (error: AxiosError) => void;
  },
): UseQueryResult<PaginatedQuickLinkResponse, AxiosError> => {
  return useQuery(['quickLinks', params], () => fetchQuickLinks(params), {
    keepPreviousData: true,
    ...options,
    refetchOnWindowFocus: false,
  });
};

export default useFetchQuickLink;
